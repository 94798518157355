import React, { useState, useEffect } from "react";
import { Card, CardBody, Table } from "reactstrap"

//Simple bar
import SimpleBar from "simplebar-react"

//Import Image
import "./scrolling.css";
import avatar4 from "../../assets/images/person-icon.png"
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
const TopUser = () => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [todaysFollowup, settodaysFollowup] = useState([]);
    useEffect(() => {
        fetch_all_todays_Followup();
    }, []);
    const fetch_all_todays_Followup=()=>{
        axios
        .get(`${API_URL}followup/todays-followup`, {
            headers: {
                "x-access-token": accessToken,
            },
        })
        .then((res) => {
            if (res.data.success === true) {
                let result = res.data.data;
                settodaysFollowup(result);
            }
        });

    }

    return (
        <React.Fragment>
            <Card>
                <CardBody style={{ paddingBottom: "7px" }}>
                    <h4 className="card-title mb-4">Today's  Followup</h4>
                    {todaysFollowup?.length>0?(
                         <SimpleBar
                         // style={{ maxHeight: "336px" }}
                         >
                             <div className="table-responsive" id="scroll-container">
                                 <Table className="table-borderless table-centered table-nowrap sliding-table" id="scroll-text">
                                     <tbody className="">
                                         {
                                             todaysFollowup?.map((item, index) => {
                                                 if (item?.cand_image)
                                                     var src = `${API_URL}uploads/candidate_images/${item?.cand_image}`;
                                                 else
                                                     var src = avatar4
     
                                                 return (
                                                     <tr className="slide-down-row" style={{ cursor: "pointer" }} onClick={() => {
                                                         window.open("/candidate/" + item?.cand_reg_no);
                                                     }}>
                                                         <td style={{ padding: "0px" }}><img src={src} className="avatar-xs rounded-circle " alt="..." /></td>
                                                         <td>
                                                             <h6 className="font-size-12 mb-1 fw-normal">{item?.name}</h6>
                                                             <p className="text-muted font-size-12 mb-0">
                                                                 <i className="mdi mdi-phone"></i> {item?.cand_mob}</p>
                                                         </td>
                                                         <td><span className={"badge " + item?.color + " font-size-10"}>{item?.follow_status}</span></td>
     
                                                     </tr>
                                                 )
                                             })
                                         }
     
     
     
                                     </tbody>
                                 </Table>
                             </div>
                         </SimpleBar>

                    ):<p>No follow Up Today</p>}
                   
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default TopUser