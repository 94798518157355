import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import $ from "jquery";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./otplog.scss";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";

import { getDate, getFirstday } from "../../helpers/globalFunctions";
// import Select from "react-select";
import moment from "moment";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
const Feedback = () => {
  const [searchData, setSearchData] = useState({
    // from_date: getFirstday(new Date()),
    // to_date: getDate(new Date()),
  });
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [details, setDetails] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  /** pagination */
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
    currentPage * PerPageCount
  );

  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
    indexOfLastTransfer - PerPageCount
  );
  const paginate = (event, value) => {
    setCurrentPage(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
    handleTableData(searchValue, searchData?.from_date, searchData?.to_date, searchData?.type, (searchData?.status !== "") ? searchData?.status : "", indexOfFirstTransfer, indexOfLastTransfer);
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  }
  const handleSearch = (value) => {
    setSearchValue(value)
    resetPaginate();
    handleTableData(value, searchData?.from_date, searchData?.to_date, searchData?.type, (searchData?.status !== "") ? searchData?.status : "");

  }
  useEffect(() => {
    if (details.length == 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData(searchValue, searchData?.from_date, searchData?.to_date, searchData?.type, (searchData?.status !== "") ? searchData?.status : "");
    }

  }, [details])
  /** end pagination */
  useEffect(() => {
    handleTableData(searchValue);
  }, []);

  const handleTableData = (search = "", from_date = "", to_date = "", type = "", status = "", start = 0, end = 20) => {
    var url = `${API_URL}manage/otp_log`;

    // if (from_date === "" && to_date === "") {
    //   from_date = from_date ? from_date : getFirstday(new Date());
    //   to_date = to_date ? to_date : getDate(new Date());
    //   }
    // eslint-disable-next-line no-restricted-globals, no-undef
    url = url + "?from_date=" + from_date + "&to_date=" + to_date + "&type=" + type + "&status=" + status + "&search=" + search + "&start=" + start + "&end=" + end;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPages(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.date).format("DD-MM-YYYY");
            item.time = moment(item?.time, "HHmmss").format("hh:mm a");
            if (item.login_type === 1)
              item.type = <h6 style={{ color: "green" }}>Teacher</h6>;
            else if (item.login_type === 2) item.type = <h6 style={{ color: "blue" }}>Management</h6>;

            if (item.registered_status === 0)
              item.status = <h6 style={{ color: "green" }}>New</h6>;
            else if (item.registered_status === 1) item.status = <h6 style={{ color: "blue" }}>Registered</h6>;
            else item.status = "";



          });

        setDetails(result);
      });
  };
  const handleSelectChange = (selected, name) => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let type = searchData?.type ? searchData.type : "";
    let status = searchData?.status !== "" ? searchData.status : "";
    resetPaginate();
    switch (name) {


      case "type":
        setSelectedType(selected);
        setSearchData({
          ...searchData,
          type: selected.value,
        });
        handleTableData(
          searchValue,
          date1,
          date2,
          selected.value,
          status,

        );
        break;
      case "status":
        setSelectedStatus(selected);
        setSearchData({
          ...searchData,
          status: selected.value,
        });
        handleTableData(
          searchValue,
          date1,
          date2,
          type,
          selected.value,

        );
        break;
      default:
        break;
    }
  };

  let handleDate = (e) => {
    if (e.target.name == "from_date") {
      var date1 = e.target.value;
      var date2 = searchData?.to_date;
    }
    else {
      var date1 = searchData?.from_date;
      var date2 = e.target.value;
    }

    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");
    let type = searchData?.type ? searchData.type : "";
    let status = searchData?.status ? searchData.status : "";

    resetPaginate();
    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(searchValue, date1, date2, type, status);
  };

  const reset = () => {
    setSearchData({
      // from_date: getFirstday(new Date()),
      // to_date: getDate(new Date()),
      from_date: "",
      to_date: "",

    });
    setSelectedType(null);
    setSelectedStatus(null);
    resetPaginate()
    handleTableData(searchValue);
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 100,
      },

      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 100,
      },


      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },

      //   {
      //     label: "Comments",
      //     field: "fdback_message",
      //     sort: "asc",
      //     width: 100,
      //   },
    ],
    rows: details,
  };
  const customStyles = {
    lineHeight: "1.74",
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Visit Log" />

          {/*Text field 1*/}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          <input
                            style={customStyles}
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={searchData?.from_date}
                            onChange={handleDate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          <input
                            style={customStyles}
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={searchData?.to_date}
                            min={searchData?.from_date}
                            onChange={handleDate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            style={customStyles}
                            name="type"
                            value={selectedType}
                            onChange={(selected) => {
                              handleSelectChange(selected, "type");
                            }}
                            options={[
                              {
                                label: "Teacher",
                                value: 1,
                              },
                              {
                                label: "Management",
                                value: 2,
                              },

                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            style={customStyles}
                            name="status"
                            value={selectedStatus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "status");
                            }}
                            options={[
                              {
                                label: "New",
                                value: 0,
                              },
                              {
                                label: "Registered",
                                value: 1,
                              },

                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      {/* <Col md="2">
                        <div className="mb-3">
                          <Label></Label>
                          <Select
                            name=""
                            value={}
                            onChange={(selected) => {
                              handleSelectChange(selected);
                            }}
                            options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col> */}
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4 mb-3">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="webFeedbackTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    paging={false}
                    onSearch={(value) => { handleSearch(value) }}
                    entries={PerPageCount}
                  />
                  {totalPages > 20 && (
                    <div className="pagination-div">
                      <div className="page-details">
                        Showing {indexOfFirstTransfer + 1} to {indexOfFirstTransfer + details.length} of {totalPages} entries
                      </div>
                      <Pagination
                        color="standard"
                        shape="rounded"
                        defaultPage={1}
                        count={Math.ceil(totalPages / PerPageCount)}
                        page={currentPage}
                        onChange={paginate}
                        size="large"
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Feedback;
