import React, { useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Badge,
  Table,
} from "reactstrap";
import { Box } from "@mui/system";
import moment from "moment";
import logoSm from "../../../assets/images/letterboard.png";

export const Printable = React.forwardRef((props, ref) => {
  const invoiveDetails = props.data;
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  return (
    <div ref={ref}>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <div
                style={{
                  position: "relative",
                }}
              >
                <img src={logoSm} alt="" style={{ height: '1000px', width: '100%' }}
                ></img>
                <div
                  style={{
                    position: "absolute",
                    top: "39%",
                    left: "40%",
                    transform: "translate(-40%, -40%)",
                    width: "90%"
                  }}
                >
                  <div className="invoice-title">
                    <h4 className="float-end font-size-16">
                      Invoice # {invoiveDetails?.invoice?.invoiceNo}
                      {invoiveDetails?.invoice?.payment == 0 ? (
                        <span className="badge bg-danger font-size-12 ms-2">
                          UnPaid
                        </span>
                      ) : null}
                      {invoiveDetails?.invoice?.payment == 1 ? (
                        <span className="badge bg-success font-size-12 ms-2">
                          Paid
                        </span>
                      ) : null}
                    </h4>
                    <div className="mb-4">
                    </div>
                    <div className="text-muted">
                      <p className="mb-1">Flemigon Ecommerce Hub Pvt.Ltd.</p>
                      <p className="mb-1">UP-XII-305, Kannur , Kerala-670 571</p>
                      {/* <p className="mb-1">
                    <i className="uil uil-envelope-alt me-1"></i> abc@123.com
                  </p>
                  <p>
                    <i className="uil uil-phone me-1"></i> 012-345-6789
                  </p> */}
                    </div>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col sm="6">
                      <div className="text-muted">
                        <h5 className="font-size-16 mb-3">Billed To:</h5>
                        <h5 className="font-size-15 mb-2">
                          {invoiveDetails?.invoice?.instituteName}
                        </h5>
                        <p className="mb-1">
                          {invoiveDetails?.invoice?.instituteState},
                          {invoiveDetails?.invoice?.instituteDistrict}
                        </p>
                        <p className="mb-1">
                          {invoiveDetails?.invoice?.instituteCity}
                        </p>
                        <p>{invoiveDetails?.invoice?.instituteMob}</p>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="text-muted text-sm-end">
                        <div>
                          <h5 className="font-size-16 mb-1">Invoice No:</h5>
                          <p>{invoiveDetails?.invoice?.invoiceNo}</p>
                        </div>
                        <div className="mt-4">
                          <h5 className="font-size-16 mb-1">Invoice Date:</h5>
                          <p>
                            {moment(invoiveDetails?.invoice?.invoiceDate).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        </div>
                        <div className="mt-4">
                          {/* <h5 className="font-size-16 mb-1">Order No:</h5>
                        <p>#1123456</p> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="py-2">
                    <h5 className="font-size-15">Order summary</h5>
                    <div className="table-responsive">
                      <Table className="table-nowrap table-centered mb-0">
                        <thead>
                          <tr>
                            <th style={{ width: "70px" }}>No.</th>
                            <th>Package Name</th>
                            <th>Package Type</th>
                            <th>Pakage Id</th>
                            <th>Price</th>
                            <th className="text-end" style={{ width: "120px" }}>
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              <h5 className="font-size-15 mb-1">
                                {invoiveDetails?.invoice?.packageName}
                              </h5>
                              <ul className="list-inline mb-0">
                                {invoiveDetails?.invoice?.packageType === 0 ? (
                                  <>
                                    No Of Resumes :{" "}
                                    <span className="fw-medium">
                                      {invoiveDetails?.invoice?.noOfResume}
                                    </span>
                                  </>
                                ) : invoiveDetails?.invoice?.packageType === 1 ? (
                                  <>
                                    No Of Jobs :{" "}
                                    <span className="fw-medium">
                                      {invoiveDetails?.invoice?.noOfJobPort}
                                    </span>
                                  </>
                                ) : (
                                  // Add any additional cases if needed
                                  <>
                                    No Of Services :{" "}
                                    <span className="fw-medium">
                                      {invoiveDetails?.invoice?.noOfServices}
                                    </span>
                                  </>
                                )}
                                <li className="list-inline-item mx-1">
                                  Validity{" "}
                                  <span className="fw-medium mx-1">{invoiveDetails?.invoice?.validity}</span>
                                </li>
                              </ul>
                            </td>

                            <td>
                              {invoiveDetails?.invoice?.packageType === 0 ? (
                                <>Resume wise</>
                              ) : invoiveDetails?.invoice?.packageType === 1 ? (
                                <>Job port wise</>
                              ) : (
                                // Add any additional cases if needed
                                <>Service wise</>
                              )}
                            </td>
                            <td>{invoiveDetails?.invoice?.packageId}</td>
                            <td>{invoiveDetails?.invoice?.price}</td>
                            <td className="text-end">
                              {invoiveDetails?.invoice?.price}
                            </td>
                          </tr>

                          <tr>
                            <th colSpan="5" className="text-end">
                              Sub Total
                            </th>
                            <td className="text-end">
                              {invoiveDetails?.invoice?.price}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="5" className="border-0 text-end">
                              Discount :
                            </th>
                            <td className="border-0 text-end">
                              - {invoiveDetails?.invoice?.discount}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="5" className="border-0 text-end">
                              Shipping Charge :
                            </th>
                            <td className="border-0 text-end">
                              {invoiveDetails?.invoice?.shippingCharge}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="5" className="border-0 text-end">
                              Tax
                            </th>
                            <td className="border-0 text-end">
                              {invoiveDetails?.invoice?.tax}
                            </td>
                          </tr>
                          <tr>
                            <th colSpan="5" className="border-0 text-end">
                              Total
                            </th>
                            <td className="border-0 text-end">
                              <h4 className="m-0">{invoiveDetails?.totalPrice}</h4>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>

                </div>

              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
});
