import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import toastr from "toastr";

function AppoinmentList() {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [instituteOption, setinstituteOption] = useState([]);
  const [selecetdinstitute, setselecetdinstitute] = useState(null);
  const [jobpostOptions, setjobpostOptions] = useState([]);
  const [selectedJobpost, setselectedJobpost] = useState(null);
  const [appointedOptions, setappointedOptions] = useState([]);
  const [selectedAppointed, setselectedAppointed] = useState(null);
  const [masterObj, setmasterObj] = useState({ create_by: 0 });
  const [provisionOptions, setProvisionOptions] = useState([]);
  const [selectedProvision, setselectedProvision] = useState(null);
  const [subjectOptions, setsubjectOptions] = useState([]);
  const [selecetdSubject, setselecetdSubject] = useState(null);
  const [classOptions, setclassOptions] = useState([]);
  const [selecetdclass, setselecetdclass] = useState(null);
  const formRef = useRef();

  useEffect(() => {
    fetchAllinstitute();
    fetch_all_provisions();
  }, []);

  function fetchAllinstitute() {
    axios
      .get(`${API_URL}institute/management/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let institute =
          result &&
          result.map((el) => {
            return {
              label: el?.inst_name,
              value: el?._id,
              address: el?.inst_address,
            };
          });
        setinstituteOption(institute);
      });
  }

  function fetchAlljobpost(id) {
    axios
      .get(`${API_URL}job/jobpost/name?inst_id=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let jobpost =
          result &&
          result.map((el) => {
            return {
              label: el.job_name,
              value: el.job_id,
              provision: el.provision,
              class: el.class,
              subject: el.subject,
            };
          });
        setjobpostOptions(jobpost);
      });
  }

  function fetchAllAppointed(id) {
    axios
      .get(`${API_URL}job/jobpost/appointed/candidates?job_id=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let joboption =
          result &&
          result.map((el) => {
            return {
              label: el.placed_cand_name,
              value: el.placed_cand_id,
            };
          });
        setappointedOptions(joboption);
      });
  }

  const fetch_all_provisions = () => {
    axios
      .get(`${API_URL}provisions/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let provision_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.provision_name,
              value: el?._id,
            };
          });
        setProvisionOptions([
          {
            options: provision_data,
          },
        ]);
      });
  };

  function handleSelectChange(selecetd, name) {
    switch (name) {
      case "inst_name":
        fetchAlljobpost(selecetd.value);
        setselecetdinstitute(selecetd);
        setmasterObj({
          create_by: "0",
          [name]: selecetd.value,
          inst_address: selecetd.address,
        });
        setselecetdSubject(null);
        setselecetdclass(null);
        setselectedProvision(null);
        setselectedJobpost(null);
        break;
      case "job_id":
        setselectedJobpost(selecetd);
        fetchAllAppointed(selecetd.value);
        let classV =
          selecetd &&
          selecetd.provision.map((el) => {
            return {
              label: el.provision_name,
              value: el._id,
            };
          });
        setselecetdclass(classV);

        let subjectV =
          selecetd &&
          selecetd.subject.map((el) => {
            return {
              label: el.subject_name,
              value: el._id,
            };
          });
        setselecetdSubject(subjectV);

        let provisionV =
          selecetd &&
          selecetd.provision.map((el) => {
            return {
              label: el.provision_name,
              value: el._id,
            };
          });
        setselectedProvision(provisionV);
        setmasterObj({
          ...masterObj,
          job_id: selecetd.value,
          provision: selecetd?.provision?.map((el) => el._id),
          classes: selecetd?.class?.map((el) => el._id),
          subject: selecetd?.subject[0]?._id,
        });

        break;
      case "candidate_id":
        setselectedAppointed(selecetd);
        setmasterObj({ ...masterObj, [name]: selecetd.value })
        break;
      case "provision":
        setselectedProvision(selecetd);
        setmasterObj({
          ...masterObj,
          provision: selecetd?.map((el) => el.value),
        });
      default:
        break;
    }
  }

  function handleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObj({ ...masterObj, [name]: value });
  }

  function handleValidSubmit(e, v) {
    axios.post(`${API_URL}appointmentLetter`, masterObj, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      if (res.data.status == 200) {
        toastr.success("Appointment Letter Created Successfully");
        formRef.current.reset();
        setmasterObj({ create_by: "0" });
        setselecetdSubject(null);
        setselecetdclass(null);
        setselecetdinstitute(null);
        setselectedAppointed(null);
        setselectedJobpost(null);
        setselectedProvision(null)
      }
    })

  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Appointment Letters" />
          <Row>
            <Card>
              <CardBody>
                <AvForm ref={formRef}
                  onValidSubmit={() => {
                    handleValidSubmit();
                  }}
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Institute</Label>
                        <Select
                          name="inst_name"
                          options={instituteOption}
                          value={selecetdinstitute}
                          onChange={(selecetd) => {
                            handleSelectChange(selecetd, "inst_name");
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Job Post</Label>
                        <Select
                          name="subject"
                          options={jobpostOptions}
                          value={selectedJobpost}
                          onChange={(selecetd) => {
                            handleSelectChange(selecetd, "job_id");
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Appointed Staff</Label>
                        <Select
                          name="staff"
                          options={appointedOptions}
                          value={selectedAppointed}
                          onChange={(selecetd) => {
                            handleSelectChange(selecetd, "candidate_id");
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Subject</Label>
                        <Select
                          isDisabled={true}
                          name="staff"
                          onChange={(selecetd) => {
                            handleSelectChange(selecetd, "subject");
                          }}
                          value={selecetdSubject}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Classes</Label>
                        <Select
                          isDisabled={true}
                          name="staff"
                          isMulti
                          onChange={(selecetd) => {
                            handleSelectChange(selecetd, "classes");
                          }}
                          value={selecetdclass}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Provisions</Label>
                        <Select
                          isMulti
                          name="staff"
                          options={provisionOptions}
                          value={selectedProvision}
                          onChange={(selecetd) => {
                            handleSelectChange(selecetd, "provision");
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Service Charge</Label>
                        <AvField
                          placeholder="Charge"
                          name="service_charge"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          type="text"
                          value={masterObj?.service_charge || ""}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Issue Date</Label>
                        <AvField
                          name="appointmentltr_issue_date"
                          type="date"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>Joining Date</Label>
                        <AvField
                          name="joining_date"
                          type="date"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3 mt-0">
                        <Button color="danger" type="submit">
                          Create
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </>
  );
}
export default AppoinmentList;
