import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import "./ManagementDetails.scss";
import Breadcrumb from "../../components/Common/Breadcrumb";
import Pagination from "@mui/material/Pagination";
import { CSVLink } from "react-csv";

function ManagementDetails() {
  const [masterObj, setmasterObj] = useState({ start: 1, from_date: "", to_date: "", from_time: "", to_time: "" });
  const [selecetdpackage, setselecetdpackage] = useState(null);
  const [selectPackageType, setselectPackageType] = useState(null);
  const [packageoptions, setpackageoptions] = useState([]);
  const [instituteOptions, setInstituteOptions] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [tableDetails, settableDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchAllManagmentDetails();
    // fetch_all_package();
    fetch_all_institute();
  }, [masterObj]);

  function paginate(event, value) {
    setmasterObj({ ...masterObj, start: value });
    setCurrentPage(value);
  }


  const fetch_all_institute = () => {
    axios
      .get(`${API_URL}institute/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let institute_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.inst_name,
              value: el?._id,
            };
          });
        setInstituteOptions([
          {
            options: institute_data,
          },
        ]);
      });
  };

  const fetch_all_package = (id) => {
    axios
      .get(`${API_URL}package/package-name-options-list?packageType=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let packagedata = result.map((item) => {
          return {
            label: item?.packageName,
            value: item?._id,
            noOfResume: item?.noOfResume,
            noOfJobPort: item?.noOfJobPort,
            noOfServices: item?.noOfServices,
            amount: item?.amount,
            validity: item?.validity,
          };
        });
        setpackageoptions(packagedata);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Institute",
        field: "management1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Package",
        field: "package_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Expiry",
        field: "date2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Remark",
        field: "remarkfield",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableDetails,
  };

  function handleChange(selected, name) {
    switch (name) {
      case "packageType":
        setselectPackageType(selected);
        setselecetdpackage(null);
        fetch_all_package(selected.value);
        setmasterObj({ ...masterObj, package_type: selected.value });
        break;
      case "package_id":
        setselecetdpackage(selected);
        setmasterObj({ ...masterObj, [name]: selected.value });
        break;
      case "institute":
        setSelectedInstitute(selected);
        setmasterObj({ ...masterObj, [name]: selected.value });

      default:
        break;
    }
  }

  function hnadledate(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObj({ ...masterObj, [name]: value });
  }


  function hnadletime(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObj({ ...masterObj, [name]: value });
  }

  function handleBlurRemark(id, e) {
    let data = e.target.value;
    axios
      .post(
        `${API_URL}account/institute/table/update/remarks`,
        {
          _id: id,
          remarks: data,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
      });
  }

  function fetchAllManagmentDetails() {
    axios
      .get(
        `${API_URL}account/institute/table?package_id=${masterObj?.package_id}&institute=${masterObj?.institute}&from_date=${masterObj?.from_date}&to_date=${masterObj?.to_date}&from_time=${masterObj?.from_time}&to_time=${masterObj?.to_time}&package_type=${masterObj?.package_type}&start=${masterObj?.start}&limit=10`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let dataToBeExported = [];
        let result = res.data.data;
        setTotalPages(res.data.count);
        let tabledata = [];
        result.map((item, index) => {
          let exportItem = {};
          // item.id = index + 1;
          item.id = (masterObj?.start - 1) * 10 + index + 1;
          item.date1 = moment(item?.actived_date).format("DD-MM-YYYY");
          item.date2 = moment(item?.expiry).format("DD-MM-YYYY");
          item.time1 = moment(item?.activated_time, "hhmmss").format("hh:mm a");
          item.remarkfield = (
            <div>
              <AvForm>
                <AvField
                  rows="1"
                  name="remarks"
                  type="textarea"
                  value={item.remarks}
                  onBlur={(e) => {
                    handleBlurRemark(item._id, e);
                  }}
                />
              </AvForm>
            </div>
          );
          item.management1 = (
            <div>
              <a href={`/institute/${item.management_regno}`} target="blank">
                {item?.management}
              </a>
            </div>
          );
          tabledata.push(item);

          exportItem.Id = item.id;
          exportItem.Date = item.date1;
          exportItem.Time = item.time1;
          exportItem.Institute = item.management;
          exportItem.Package = item.package_name;
          exportItem.Expiry = item.date2;
          exportItem.Amount = item.amount;
          exportItem.Remark = item.remarks;

          dataToBeExported.push(exportItem)
        });
        settableDetails(tabledata);
        setDataToBeExported(dataToBeExported)
      });
  }

  function handleApiCall() {
    axios.post(`${API_URL}institute/log/creation`, { type: 2 }, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
    })
  }

  const reset = () => {
    setselectPackageType(null)
    setselecetdpackage(null)
    setSelectedInstitute(null)
    setmasterObj({
      from_date: "",
      to_date: "",
      from_time: "",
      to_time: ""
    })
    fetchAllManagmentDetails()
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Subscribed Management" />

          <Row>
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Package Type</Label>
                        <Select
                          name="packageTypes"
                          classNamePrefix="select2-selection"
                          value={selectPackageType}
                          options={[
                            { label: "Resume Wise", value: 0 },
                            { label: "Job Port Wise", value: 1 },
                            { label: "Service Wise", value: 2 },
                          ]}
                          onChange={(selected) => {
                            handleChange(selected, "packageType");
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Package</Label>
                        <Select
                          name="package_id"
                          value={selecetdpackage}
                          options={packageoptions}
                          onChange={(selected) => {
                            handleChange(selected, "package_id");
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Institute</Label>
                        <Select
                          name="institute"
                          value={selectedInstitute}
                          onChange={(selected) => {
                            handleChange(selected, "institute");
                          }}
                          options={instituteOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>From Date</Label>
                        <AvField
                          name="from_date"
                          type="date"
                          onChange={(e) => {
                            hnadledate(e);
                          }}
                          value={masterObj?.from_date}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>To Date</Label>
                        <AvField
                          name="to_date"
                          type="date"
                          onChange={(e) => {
                            hnadledate(e);
                          }}
                          value={masterObj?.to_date}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>From Time</Label>
                        <AvField
                          name="from_time"
                          type="time"
                          onChange={(e) => {
                            hnadletime(e);
                          }}
                          value={masterObj?.from_time}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>To Time</Label>
                        <AvField
                          name="to_time"
                          type="time"
                          onChange={(e) => {
                            hnadletime(e);
                          }}
                          value={masterObj?.to_time}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mt-4" style={{ paddingTop: "6px", display: "flex" }} >
                        <Button onClick={handleApiCall} color="success" type="">
                          <CSVLink
                            data={dataToBeExported}
                            filename={"Subscribed_Management.xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>

                        <Button color="danger" type="submit" style={{ marginLeft: "25px" }} onClick={() => reset()}>Reset</Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <Col md="12">
                  <MDBDataTable
                    id="managementTable"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    paging={false}
                    info={false}
                    disableRetreatAfterSorting={true}
                  />
                  {totalPages > 1 && (
                    <div
                      className="pagination-div"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <Pagination
                        color="standard"
                        shape="rounded"
                        defaultPage={1}
                        count={Math.ceil(totalPages / 15)}
                        page={currentPage}
                        onChange={paginate}
                        size="large"
                      />
                    </div>
                  )}
                </Col>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ManagementDetails;
