import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select";

import {
  // getDistricts,
  // addDistrict,
  // deleteDistrict,
  apiError,
  // getPrivilagesOptions,
  // getCompaniesOptions,
  // getBranchesOptions,
  updateDistrict,
  // getCustomerCategories,
  // addCategory,
  // updateCategory,
  // deleteCategory,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody } from "../../../helpers/globalFunctions";
import "./qualification.scss";

const Qualification = (props) => {
  
  const [qualificationObject, setqualificationObject] = useState({});
  const [qualificationIdTobeUpdated, setqualificationIdTobeUpdated] = useState(null);
  const [qualificationToBeDeleted, setqualificationIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [qualificationForTable, setqualificationForTable] = useState([]);
  const [provisionValue, setprovisionValue] = useState("");
  const [passwordObject, setPasswordObject] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const {
    districts,
    addingDistrict,
    addDistrictResponse,
    deleteDistrictResponse,
    updateDistrictResponse,
  } = useSelector((state) => state.districts);
  const {
    categories,

    addCategoryResponse,
    updateCategoryResponse,
    deleteCategoryResponse,
    addingCategory,
    error,
  } = useSelector((state) => state.customerCategory);

  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    //dispatch(getDistricts());
    handleTableData(currentLocalbody);
  }, [currentLocalbody]);

  

  let preUpdateCategory = (item) => {

    if (item.qualification_name) {
      setprovisionValue(item.qualification_name);
      item.qualification_name = item.qualification_name;
    }
    setqualificationIdTobeUpdated(item._id);
    setqualificationObject({ ...item, password: null });
  };

  let preUpdateCategoryPassword = (item) => {
    setqualificationIdTobeUpdated(item._id);
    setShowModal(true);
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  function handleTableData() {
    var url =
      `${API_URL}qualification/list?localbody_id=` + currentLocalbody;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let categoryData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  preUpdateCategory(item);
                  toTop();
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setqualificationIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.provision_date).format("DD-MM-YYYY");
          // item.time = moment(item.provision_time, "HHmmss").format("hh:mm a"); // 24hours
         



          if(item?.qualification_updated_date)item.date = moment(item.qualification_updated_date).format("DD-MM-YYYY");
           else item.date = moment(item.qualification_date).format("DD-MM-YYYY");
            
          if(item?.qualification_updated_time)item.time = moment(item.qualification_updated_time, "HHmmss").format("hh:mm a"); // 24hours
          else item.time = moment(item.qualification_time, "HHmmss").format("hh:mm a"); // 24hours
            
          if(item?.qualification_updatedby)item.staff = item.qualification_updatedby.firstName;
          else item.staff = item.qualification_addedby.firstName;
            
          
          // if (item.customer_type_addedby != null) {
          //   item.staff = item.customer_type_addedby.firstName;
          // }
          categoryData.push(item);
        });
        setqualificationForTable(categoryData);
      });
  }

  
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        //sort: "desc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        //sort: "desc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        //sort: "desc",
        width: 150,
      },

      {
        label: "Qualification",
        field: "qualification_name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },

      {
        label: "Action",
        field: "action",
        width: 50,
      },
    ],
    rows: qualificationForTable,
  };

  function handleChangeCategory(e) {
    let name = e.target.name;
    let value = e.target.value;
    setprovisionValue(value);
    setqualificationObject({ ...qualificationObject, [name]: value });
  }

  

  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }
  
 
  const handleValidSubmit = () => {
    if (qualificationIdTobeUpdated) {
      
      let data={
        qualification_name:qualificationObject.qualification_name,
        qualification_id:qualificationIdTobeUpdated
    
      };
     
      axios
        .put(
          `${API_URL}qualification/update`,
          data,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Qualification updated successfully");
            setprovisionValue("");
            formRef.current.reset();
            handleTableData();
            setqualificationObject({});
            setqualificationIdTobeUpdated(null);
          } else {
            toastr.error("Failed to update qualification");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}qualification`, qualificationObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Qualification added successfully");
            setprovisionValue("");
            handleTableData();
            setqualificationObject({});
            formRef.current.reset();
          } else {
            toastr.error("Failed to add Qualification");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const handleValidSubmitPassword = (event, values) => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        // _id: districtIdTobeUpdated,
        password: passwordObject.password,
      };
      dispatch(updateDistrict(item));
    } else {
      toastr.error("Passwords are not matching");
    }
  };

  let closeModal = () => {
    setShowModal(false);
    setqualificationIdTobeUpdated(null);
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          // onConfirm={() => {
          //   dispatch(deleteCategory(categoryIdToBeDeleted));
          //   setConfirmDeleteAlert(false);
          // }}
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}qualification` + "/" + qualificationToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Qualification deleted successfully");

                  if (
                    qualificationObject &&
                    qualificationObject._id === qualificationToBeDeleted
                  ) {
                    setqualificationIdTobeUpdated(null);
                    formRef.current.reset();
                    setprovisionValue("");
                    setqualificationObject({});
                  }
                  handleTableData();
                } else {
                  toastr.error(res.data.message,"Failed to delete qualification");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Qualification" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Qualification Name
                          </Label>
                          <AvField
                            name="qualification_name"
                            placeholder="Qualification name"
                            type="text"
                            errorMessage="Enter Qualification Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={provisionValue}
                            onChange={handleChangeCategory}
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "26px" }}>
                          {qualificationIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingCategory ? true : false}
                            >
                              {addingCategory ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingCategory ? true : false}
                            >
                              {addingCategory ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="customerCatTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(
  connect(mapStateToProps, { apiError })(Qualification)
);

Qualification.propTypes = {
  error: PropTypes.any,
  categories: PropTypes.array,
};
