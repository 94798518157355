import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Badge,
  Table,
} from "reactstrap";
import ConnectWithoutContactRoundedIcon from "@mui/icons-material/ConnectWithoutContactRounded";
import { useReactToPrint } from "react-to-print";
import { Printable } from "../Subscriptions/Printable";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Box, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ArticleIcon from "@mui/icons-material/Article";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Slide from "@mui/material/Slide";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import "./details.scss";
import noimage from "./up1.jpg";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import saveAs from "file-saver";
import Pagination from "@mui/material/Pagination";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Management = (props) => {
  const printRef = useRef();
  const [details, setDetails] = useState({});
  const [selectedFilter, setselectedFilter] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [jobpostDetails, setJobpostDetails] = useState([]);
  const [appliedData, setAppliedData] = useState([]);
  const [shortlistedData, setShortlistedData] = useState([]);
  const [placedData, setPlacedData] = useState([]);
  const [jobIdToBeDeleted, setJobIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [popupAppliedView, setPopupAppliedView] = useState(false);
  const [popupAppliedData, setPopupAppliedData] = useState({});
  const [popupShortlistedView, setPopupShortlistedView] = useState(false);
  const [popupShortlistedData, setPopupShortlistedData] = useState({});
  const [popupPlacedView, setPopupPlacedView] = useState(false);
  const [popupPlacedData, setPopupPlacedData] = useState({});
  const [totalApplied, setTotalApplied] = useState(0);
  const [totalShortlisted, setTotalShortlisted] = useState(0);
  const [totalPlaced, setTotalPlaced] = useState(0);
  const [totalJobpost, setTotalJobpost] = useState(0);
  const [master, setMaster] = useState({});
  const [remarksData, setRemarksData] = useState([]);
  const [contactedDetails, setcontactedDetails] = useState([]);
  const [packageFilter, setPackageFilter] = useState(null)
  const [showDeclineAppliedForm, setShowDeclineAppliedForm] = useState(false);
  const [declineAppliedData, setDeclineAppliedData] = useState({});
  const [appliedErrors, setAppliedErrors] = useState({
    remarkError: "",
  });
  const [selectedDeclineApplied, setSelectedDeclineApplied] = useState(null);
  const [selectedDeclineShortlisted, setSelectedDeclineShortlisted] =
    useState(null);
  const [selectedDeclinePlaced, setSelectedDeclinePlaced] = useState(null);
  const [declinePlacedData, setDeclinePlacededData] = useState({});
  const [placedErrors, setPlacedErrors] = useState({
    remarkError: "",
  });
  const [showDeclinePlacedForm, setShowDeclinePlacedForm] = useState(false);
  const [ReasonsOption, setReasonsOptions] = useState([]);
  const [showDeclineShortlistedForm, setShowDeclineShortlistedForm] =
    useState(false);
  const [declineShortlistedData, setDeclineShortlistedData] = useState({});
  const [shortlistedErrors, setShortlistedErrors] = useState({
    remarkError: "",
  });

  const formRef = useRef();
  const history = useHistory();
  const { instID } = props.match.params;
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  /** pagination */
  const [searchValueJob, setSearchValueJob] = useState("");
  const [searchValueApp, setSearchValueApp] = useState("");
  const [searchValueShort, setSearchValueShort] = useState("");
  const [searchValuePlaced, setSearchValuePlaced] = useState("");

  const [totalPagesJob, setTotalPagesJob] = useState(0);
  const [totalPagesApp, setTotalPagesApp] = useState(0);
  const [totalPagesShort, setTotalPagesShort] = useState(0);
  const [totalPagesPlaced, setTotalPagesPlaced] = useState(0);
  const [totalPagesRemarks, setTotalPagesRemarks] = useState(0);
  const [inst_ID, setinst_ID] = useState(null);

  const [currentPageJob, setCurrentPageJob] = useState(1);
  const [currentPageApp, setCurrentPageApp] = useState(1);
  const [currentPageShort, setCurrentPageShort] = useState(1);
  const [currentPagePlaced, setCurrentPagePlaced] = useState(1);
  const [currentPageReamrks, setCurrentPageRemarks] = useState(1);

  const [PerPageCount, setPerPageCount] = useState(20);

  const [indexOfLastTransfer1, setindexOfLastTransfer1] = useState(
    currentPageJob * PerPageCount
  );
  const [indexOfFirstTransfer1, setindexOfFirstTransfer1] = useState(
    indexOfLastTransfer1 - PerPageCount
  );
  const [indexOfLastTransfer2, setindexOfLastTransfer2] = useState(
    currentPageApp * PerPageCount
  );

  const [indexOfFirstTransfer2, setindexOfFirstTransfer2] = useState(
    indexOfLastTransfer2 - PerPageCount
  );
  const [indexOfLastTransfer3, setindexOfLastTransfer3] = useState(
    currentPageShort * PerPageCount
  );

  const [indexOfFirstTransfer3, setindexOfFirstTransfer3] = useState(
    indexOfLastTransfer3 - PerPageCount
  );
  const [indexOfLastTransfer4, setindexOfLastTransfer4] = useState(
    currentPageShort * PerPageCount
  );

  const [indexOfFirstTransfer4, setindexOfFirstTransfer4] = useState(
    indexOfLastTransfer4 - PerPageCount
  );
  const [indexOfLastTransfer5, setindexOfLastTransfer5] = useState(
    currentPageReamrks * PerPageCount
  );

  const [indexOfFirstTransfer5, setindexOfFirstTransfer5] = useState(
    indexOfLastTransfer5 - PerPageCount
  );

  const paginateJob = (event, value) => {
    setCurrentPageJob(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer1(indexOfFirstTransfer);
    setindexOfLastTransfer1(indexOfLastTransfer);
    viewJobpost(
      details?._id,
      searchValueJob,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };

  const paginateApp = (event, value) => {
    setCurrentPageApp(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer2(indexOfFirstTransfer);
    setindexOfLastTransfer2(indexOfLastTransfer);
    fetch_applied(
      details?._id,
      searchValueApp,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginateShort = (event, value) => {
    setCurrentPageShort(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer3(indexOfFirstTransfer);
    setindexOfLastTransfer3(indexOfLastTransfer);
    fetch_shortlisted(
      details?._id,
      searchValueShort,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginatePlaced = (event, value) => {
    setCurrentPagePlaced(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer4(indexOfFirstTransfer);
    setindexOfLastTransfer4(indexOfLastTransfer);
    fetch_placed(
      details?._id,
      searchValuePlaced,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginateRemarks = (event, value) => {
    setCurrentPageRemarks(value);
    const indexOfLastTransfer = value * 10;
    const indexOfFirstTransfer = indexOfLastTransfer - 10;
    setindexOfFirstTransfer5(indexOfFirstTransfer);
    setindexOfLastTransfer5(indexOfLastTransfer);
    fetch_remarks(details?._id, indexOfFirstTransfer, indexOfLastTransfer);
  };
  const resetPaginateJob = () => {
    setCurrentPageJob(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer1(indexOfFirstTransfer);
    setindexOfLastTransfer1(indexOfLastTransfer);
  };
  const resetPaginateApp = () => {
    setCurrentPageApp(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer2(indexOfFirstTransfer);
    setindexOfLastTransfer2(indexOfLastTransfer);
  };
  const resetPaginateShort = () => {
    setCurrentPageShort(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer3(indexOfFirstTransfer);
    setindexOfLastTransfer3(indexOfLastTransfer);
  };
  const resetPaginatePlaced = () => {
    setCurrentPagePlaced(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer4(indexOfFirstTransfer);
    setindexOfLastTransfer4(indexOfLastTransfer);
  };
  const resetPaginateRemarks = () => {
    setCurrentPageRemarks(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer5(indexOfFirstTransfer);
    setindexOfLastTransfer5(indexOfLastTransfer);
  };
  const handleSearchJob = (value) => {
    setSearchValueJob(value);
    resetPaginateJob();
    viewJobpost(details?._id, value);
  };
  const handleSearchApp = (value) => {
    setSearchValueApp(value);
    resetPaginateApp();
    fetch_applied(details?._id, value);
  };
  const handleSearchShort = (value) => {
    setSearchValueShort(value);
    resetPaginateShort();
    fetch_shortlisted(details?._id, value);
  };
  const handleSearchPlaced = (value) => {
    setSearchValuePlaced(value);
    resetPaginatePlaced();
    fetch_placed(details?._id, value);
  };

  useEffect(() => {
    if (jobpostDetails.length == 0 && indexOfFirstTransfer1 != 0) {
      resetPaginateJob();
      viewJobpost(details?._id, searchValueJob);
    }
  }, [jobpostDetails]);

  useEffect(() => {
    if (appliedData.length == 0 && indexOfFirstTransfer2 != 0) {
      resetPaginateApp();
      fetch_applied(details?._id, searchValueApp);
    }
  }, [appliedData]);

  useEffect(() => {
    if (shortlistedData.length == 0 && indexOfFirstTransfer3 != 0) {
      resetPaginateShort();
      fetch_shortlisted(details?._id, searchValueShort);
    }
  }, [shortlistedData]);
  useEffect(() => {
    if (placedData.length == 0 && indexOfFirstTransfer4 != 0) {
      resetPaginatePlaced();
      fetch_placed(details?._id, searchValuePlaced);
    }
  }, [placedData]);
  /** end pagination */

  useEffect(() => {
    if (instID) {
      handleTableData(instID);
      fetch_all_reasons();
    }
  }, []);
  const fetch_all_reasons = () => {
    axios
      .get(`${API_URL}manage/decline`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let reason_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item?.remark,
              value: item?._id,
            };
          });
        setReasonsOptions([
          {
            options: reason_data,
          },
        ]);
      });
  };
  function handleTableData(id) {
    axios
      .get(`${API_URL}institute/view?institute_reg=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        setinst_ID(result._id);
        let stateData = result.inst_state || {};
        let districtData = result.inst_district || {};
        result.stateName = stateData?.name;
        result.districtName = districtData?.district_name;

        result.campusType = "";
        if (result?.inst_campus_type === 0) result.campusType = "DAY SCHOOL";
        else if (result?.inst_campus_type === 1)
          result.campusType = "RESIDENTIAL";
        else if (result?.inst_campus_type === 2)
          result.campusType = "SEMI RESIDENTIAL";
        else result.campusType = "";

        let managementData = result.inst_management || {};
        result.managementName = managementData?.name;

        result.mediumName = "";
        result.inst_medium &&
          result.inst_medium.map((el) => {
            if (result.mediumName === "") result.mediumName = el?.name;
            else result.mediumName = result?.mediumName + "," + "\n" + el?.name;
          });
        result.syllabusName = "";
        result.inst_syllabus &&
          result.inst_syllabus.map((el) => {
            if (result.syllabusName === "") result.syllabusName = el?.name;
            else
              result.syllabusName =
                result?.syllabusName + "," + "\n" + el?.name;
          });
        // result.requireVal = "";
        if (result.require === 1) result.requireVal = "Yes";
        else if (result.require === 2) result.requireVal = "No";
        else result.requireVal = "";
        setDetails(result);
        viewJobpost(result?._id, searchValueJob);
        fetch_applied(result?._id, searchValueApp);
        fetch_shortlisted(result?._id, searchValueShort);
        fetch_placed(result?._id, searchValuePlaced);
        fetch_remarks(result?._id);
      });
  }
  const fetch_applied = (id, search = "", start = 0, end = 20) => {
    var url = `${API_URL}application/list`;
    url =
      url +
      "?fromDate=" +
      "" +
      "&toDate=" +
      "" +
      "&candidate_id=" +
      "" +
      "&job_id=" +
      "" +
      "&institute_id=" +
      id +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPagesApp(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.application_date).format("DD-MM-YYYY");
            if (item?.application_current_status === 1)
              item.status = "Shortlisted";
            else if (item?.application_current_status === 2)
              item.status = "Placed";
            else if (item?.application_current_status === 0)
              item.status = "Applied";
            else if (item?.application_current_status === 3)
              item.status = "Declined";
            else item.status = "";
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenAppliedForm(item);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Candidate Profile
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    onClick={() => {
                      window.open(
                        "/candidate/" + item?.application_cand_reg_no
                      );
                    }}
                  >
                    <i className="fas fa-user"></i>
                  </button>
                </OverlayTrigger>
                <button
                  onClick={() => {
                    openDeclineAppliedForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-thumbs-down"></i>
                </button>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Shortlist
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    onClick={() => {
                      shortlistApplication(item, search, start, end);
                    }}
                  >
                    <i className="fas fa-clipboard-check"></i>
                  </button>
                </OverlayTrigger>
              </div>
            );
          });
        setAppliedData(result);
        setTotalApplied(res.data.limit);
      });
  };
  const shortlistApplication = (item, search = "", start = 0, end = 20) => {
    if (item?.application_current_status === 1) {
      toastr.error("Application is already shortlisted");
    } else if (item?.application_current_status === 2) {
      toastr.error("Application is already placed");
    } else if (item?.application_current_status === 3) {
      toastr.error("Can't shortlist declined application");
    } else {
      let data = {
        application_id: item?._id,
      };
      axios
        .post(`${API_URL}shortlist/`, data, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application is shortlisted");
            fetch_applied(item?.application_inst_id, search, start, end);
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const openDeclineAppliedForm = (item) => {
    if (item?.application_current_status === 3) {
      toastr.error("Application is already declined");
    } else {
      setDeclineAppliedData({
        application_id: item?._id,
      });
      setAppliedErrors({
        appliedErrors: "",
      });
      setSelectedDeclineApplied(null);
      setShowDeclineAppliedForm(true);
    }
  };
  const openDeclinePlacedForm = (item) => {
    if (item?.placed_current_status === 1) {
      toastr.error("Application is already declined");
    } else {
      setDeclinePlacededData({
        placed_id: item?._id,
      });
      setSelectedDeclinePlaced(null);
      setPlacedErrors({
        remarkError: "",
      });
      setShowDeclinePlacedForm(true);
    }
  };
  const openDeclineShortlistedForm = (item) => {
    if (item?.shortlisted_current_status === 2) {
      toastr.error("Application is already declined");
    } else {
      setDeclineShortlistedData({
        shortlist_id: item?._id,
      });
      setShortlistedErrors({
        remarkError: "",
      });
      setSelectedDeclineShortlisted(null);
      setShowDeclineShortlistedForm(true);
    }
  };
  const fetch_shortlisted = (id, packageee = "", search = "", start = 0, end = 20) => {
    var url = `${API_URL}shortlist/list?`;
    url = url + "&institute_id=" + id + "&findresume=" + packageee + "&start=" + start + "&end=" + end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log(res, "res");
        let result = res.data.data;
        setTotalPagesShort(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.shortlisted_date).format("DD-MM-YYYY");
            item.interviewdate = moment(
              item?.shortlisted_interview_date
            ).format("DD-MM-YYYY");
            if (item?.shortlisted_current_status === 0)
              item.status = "Shortlisted";
            else if (item?.shortlisted_current_status === 1)
              item.status = "Placed";
            else if (item?.shortlisted_current_status === 2)
              item.status = "Declined";
            else item.status = "";
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenShortlistedForm(item);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>

                <button
                  onClick={() => {
                    openDeclineShortlistedForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-thumbs-down"></i>
                </button>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Place Application
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    onClick={() => {
                      placeApplication(item, search, start, end);
                    }}
                  >
                    <i className="fas fa-user-check"></i>
                  </button>
                </OverlayTrigger>
              </div>
            );
          });
        setShortlistedData(result);
        setTotalShortlisted(res.data.short_list_count);
      });
  };
  const placeApplication = (item, search = "", start = 0, end = 20) => {
    if (item?.shortlisted_current_status === 1) {
      toastr.error("Application is already placed");
    } else if (item?.shortlisted_current_status === 2) {
      toastr.error("Can't place declined application");
    } else {
      let data = {
        application_id: item?.application_id,
      };
      axios
        .post(`${API_URL}placed/`, data, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application is placed");
            fetch_shortlisted(item?.shortlisted_inst_id, search, start, end);
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const fetch_placed = (id, packageName = "", search = "", start = 1, end = 20) => {
    var url = `${API_URL}placed/list`;
    url =
      url +
      "?from_date=" +
      "" +
      "&to_date=" +
      "" +
      "&candidate_id=" +
      "" +
      "&job_id=" +
      "" +
      "&institute_id=" +
      id +
      "&findresume=" +
      packageName +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPagesPlaced(res.data.limit);
        var id = (start - 1) * end;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.placed_date).format("DD-MM-YYYY");
            item.joiningdate = moment(item?.joining_date).format("DD-MM-YYYY");
            if (item?.placed_current_status === 0) item.status = "Placed";
            else if (item?.placed_current_status === 1)
              item.status = "Declined";
            else item.status = "";
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenPlacedForm(item);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>

                <button
                  onClick={() => {
                    openDeclinePlacedForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-thumbs-down"></i>
                </button>
                {item?.offerltr_pdf_link ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                      >
                        Offer Letter
                      </Tooltip>
                    }
                  >
                    <button
                      className="button_class"
                      onClick={() => {
                        downloadOfferletter(item);
                      }}
                    >
                      {" "}
                      <i
                        className="fas fa-file-pdf"
                        style={{ color: "red" }}
                      ></i>
                    </button>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                      >
                        Generate offer letter
                      </Tooltip>
                    }
                  >
                    <button
                      onClick={() => {
                        const queryParams = new URLSearchParams({
                          jobId: item?.placed_job_id,
                          candId: item?.placed_cand_id,
                        }).toString();

                        window.open(
                          `/offerLetter?${queryParams}`,
                          "_blank",
                          "noopener noreferrer"
                        );
                      }}
                      className="button_class"
                    >
                      <i className="fas fa-file-invoice"></i>
                    </button>
                  </OverlayTrigger>
                )}
              </div>
            );
          });
        setPlacedData(result);
        setTotalPlaced(res.data.placed_count);
      });
  };
  const downloadOfferletter = (item) => {
    saveAs(
      `${API_URL}` + item?.offerltr_pdf_link,
      "Indiana Global Teachers.pdf"
    );
  };
  const updateStatus = (item, search = "", start = 0, end = 20) => {
    axios
      .put(`${API_URL}job/status?job_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          viewJobpost(item?.job_institute_id, search, start, end);
        } else {
          toastr.error(res.data.message);
          return;
        }
      });
  };
  function viewJobpost(id, search = "", start = 1, end = 20) {
    var url = `${API_URL}job/list`;
    url =
      url +
      "?subject=" +
      "" +
      "&syllabus=" +
      "" +
      "&state=" +
      "" +
      "&district=" +
      "" +
      "&medium=" +
      "" +
      "&classes=" +
      "" +
      "&qualification=" +
      "" +
      "&mode_of_class=" +
      "" +
      "&institute=" +
      id +
      "&status=" +
      "" +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        var id = (start - 1) * end;
        setTotalPagesJob(res.data.limit);
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;

            if (item?.mode_of_class === 0) item.modeofClass = "Online";
            else if (item?.mode_of_class === 1) item.modeofClass = "Offline";
            else item.modeofClass = "";

            item.qualificationName = "";
            item.qualification &&
              item.qualification.map((el) => {
                if (item.qualificationName === "")
                  item.qualificationName = el?.qualification_name;
                else
                  item.qualificationName =
                    item?.qualificationName + "," + el?.qualification_name;
              });
            item.className = "";
            item.class &&
              item.class.map((el) => {
                if (item.className === "") item.className = el?.name;
                else item.className = item?.className + "," + el?.name;
              });

            item.date = moment(item?.job_date).format("DD-MM-YYYY");
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    window.open("/jobpost/" + item?.job_reg_no);
                  }}
                ></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    window.open("/job/" + item?.job_reg_no);
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setJobIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
            if (item.status === "Active") {
              item.status1 = (
                <h6
                  style={{
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    updateStatus(item, search, start, end);
                  }}
                >
                  {"Ongoing"}
                </h6>
              );
            } else if (item.status === "expired") {
              item.status1 = (
                <h6
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    updateStatus(item, search, start, end);
                  }}
                >
                  {"Expired"}
                </h6>
              );
            } else if (item.status === "filled") {
              item.status1 = (
                <h6
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    updateStatus(item, search, start, end);
                  }}
                >
                  {"Filled"}
                </h6>
              );
            } else {
              item.status1 = (
                <h6
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    updateStatus(item, search, start, end);
                  }}
                >
                  {"Inactive"}
                </h6>
              );
            }
          });

        setJobpostDetails(result);
        setTotalJobpost(res.data.limit);
      });
  }
  const handleClickOpenPlacedForm = (item) => {
    axios
      .get(`${API_URL}placed/view?placed_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.joiningdate = moment(result?.joining_date).format("DD-MM-YYYY");
        result.date = moment(result?.placed_date).format("DD-MM-YYYY");
        result.time = moment(result?.placed_time, "hhmmss").format("hh:mm a");
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupPlacedData(result);
        setPopupPlacedView(true);
      });
  };
  const handleClickOpenShortlistedForm = (item) => {
    axios
      .get(`${API_URL}shortlist/view?shortlisted_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.date = moment(result?.shortlisted_date).format("DD-MM-YYYY");
        result.time = moment(result?.shortlisted_time, "hhmmss").format(
          "hh:mm a"
        );
        result.interviewdate = moment(
          result?.shortlisted_interview_date
        ).format("DD-MM-YYYY");
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupShortlistedData(result);
        setPopupShortlistedView(true);
      });
  };
  const handleClickOpenAppliedForm = (item) => {
    axios
      .get(`${API_URL}application?application_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.dob = moment(result?.application_cand_dob).format("DD-MM-YYYY");
        result.class = "";
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        jobDetails.job_classes &&
          jobDetails.job_classes.map((el) => {
            if (result.class === "") result.class = el;
            else result.class = result.class + "," + el;
          });
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupAppliedData(result);
        setPopupAppliedView(true);
      });
  };

  const data = {
    //job post
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },

      {
        label: "Job ID",
        field: "job_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Subject",
        field: "subjectName",
        sort: "asc",
        width: 200,
      },

      {
        label: "Qualification",
        field: "qualificationName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: jobpostDetails,
  };
  const shortlistTableData = {
    //shortlist
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reg No",
        field: "application_reg_no",
        //sort: "asc",
        width: 100,
      },
      {
        label: "Applicant",
        field: "shortlisted_cand_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "shortlisted_cand_mob",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Package",
        field: "package",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: shortlistedData,
  };
  const placedTableData = {
    //placed
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "placed_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Applicant",
        field: "placed_cand_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "placed_cand_mob",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Package",
        field: "placed_from",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: placedData,
  };

  const applicantTableData = {
    //applicants
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "application_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "application_cand_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "application_cand_phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: appliedData,
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  function createData(si, date, time, staff) {
    return { si, date, time, staff };
  }

  const rows1 = [createData(1, 159, 6.0, 24), createData(2, 237, 9.0, 37)];

  function createData1(si, date, receiptno, amount, dueamount, staff) {
    return { si, date, receiptno, amount, dueamount, staff };
  }

  function createData3(
    si,
    date,
    time,
    referenceNo,
    customerId,
    customerName,
    amount,
    comment,
    due,
    staff
  ) {
    return {
      si,
      date,
      time,
      referenceNo,
      customerId,
      customerName,
      amount,
      comment,
      due,
      staff,
    };
  }

  const rows3 = [
    createData3(
      1,
      "26-11-2020",
      "11:11:24",
      123,
      123,
      "admin",
      700,
      "abc",
      100,
      "srv"
    ),
  ];

  const [tab3, setTab3] = React.useState("8");

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);

    resetPaginateJob();
    setSearchValueJob("");
    viewJobpost(details?._id);

    resetPaginateApp();
    fetch_applied(details?._id);
    setSearchValueApp("");

    resetPaginateShort();
    fetch_shortlisted(details?._id);
    setSearchValueShort("");

    resetPaginatePlaced();
    fetch_placed(details?._id);
    setSearchValuePlaced("");
  };

  const [date, setDate] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };

  function createData4(
    si,
    date,
    time,
    customerName,
    type,
    invoiceNo,
    debit,
    credit,
    balance
  ) {
    return {
      si,
      date,
      time,
      customerName,
      type,
      invoiceNo,
      debit,
      credit,
      balance,
    };
  }

  const rows4 = [
    createData4(
      1,
      "26-11-2020",
      "11:11:24",
      "admin",
      "Invoice",
      "SRV123",
      700,
      100,
      100
    ),
  ];
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
      institute_id: details?._id,
    });
  };
  const handleValidSubmitSummary = () => {
    axios
      .post(`${API_URL}remarks/`, master, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          formRef.current.reset();
          setMaster({
            institute_id: "",
            remarks_reason: "",
          });
          fetch_remarks(
            result?.remarks_inst_id,
            indexOfFirstTransfer5,
            indexOfLastTransfer5
          );
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const fetch_remarks = (id, start = 0, end = 10) => {
    axios
      .get(
        `${API_URL}remarks/list?institute_id=` +
        id +
        "&start=" +
        start +
        "&end=" +
        end,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        setTotalPagesRemarks(res.data?.limit);
        result &&
          result.map((item) => {
            let date = moment(item?.remarks_date).format("DD-MM-YYYY");
            let time = moment(item?.remarks_time, "hhmmss").format("hh:mm a");
            item.dateTime = date + "," + time;
          });
        setRemarksData(result);
      });
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "appliedReason":
        setSelectedDeclineApplied(selected);
        setDeclineAppliedData({
          ...declineAppliedData,
          decline_remark: selected.label,
        });
        setAppliedErrors({
          remarkError: "",
        });
        break;
      case "shortlistedReason":
        setSelectedDeclineShortlisted(selected);
        setDeclineShortlistedData({
          ...declineShortlistedData,
          decline_remark: selected.label,
        });
        setShortlistedErrors({
          remarkError: "",
        });
        break;
      case "placedReason":
        setSelectedDeclinePlaced(selected);
        setDeclinePlacededData({
          ...declinePlacedData,
          decline_remark: selected.label,
        });
        setPlacedErrors({
          remarkError: "",
        });
        break;
      default:
        break;
    }
  };
  const submitDeclineAppliedForm = () => {
    if (selectedDeclineApplied === null) {
      setAppliedErrors({
        remarkError: "Please select the reason",
      });
    } else {
      axios
        .put(
          `${API_URL}application/decline?applied_id=` +
          declineAppliedData?.application_id,
          declineAppliedData,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application declined successfully");
            fetch_applied(
              details?._id,
              searchValueApp,
              indexOfFirstTransfer2,
              indexOfLastTransfer2
            );
            setDeclineAppliedData({});
            setSelectedDeclineApplied(null);
            setShowDeclineAppliedForm(false);
          } else {
            toastr.error("Failed to decline application");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const submitDeclineShortlistedForm = () => {
    if (selectedDeclineShortlisted === null) {
      setShortlistedErrors({
        remarkError: "Please select the reason",
      });
    } else {
      axios
        .put(
          `${API_URL}shortlist/decline?shortlist_id=` +
          declineShortlistedData?.shortlist_id,
          declineShortlistedData,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application declined successfully");
            setSelectedDeclineShortlisted(null);
            fetch_shortlisted(
              details?._id,
              searchValueShort,
              indexOfFirstTransfer3,
              indexOfLastTransfer3
            );
            setDeclineShortlistedData({});
            setShowDeclineShortlistedForm(false);
          } else {
            toastr.error("Failed to decline application");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const submitDeclinePlacedForm = () => {
    if (selectedDeclinePlaced === null) {
      setPlacedErrors({
        remarkError: "Please select the reason",
      });
    } else {
      axios
        .put(
          `${API_URL}placed/decline?placed_id=` + declinePlacedData?.placed_id,
          declinePlacedData,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application declined successfully");
            setSelectedDeclinePlaced(null);
            fetch_placed(details?._id);
            setDeclinePlacededData({});
            setShowDeclinePlacedForm(false);
          } else {
            toastr.error("Failed to decline application");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  // subsecition lit
  const [subsTable, setsubsTable] = useState([]);
  const [invoiveDetails, setinvoiveDetails] = useState([]);
  const [popupView, setpopupView] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  function get_invice_list(id) {
    axios
      .get(`${API_URL}subscription/generate?subscriptionId=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setinvoiveDetails(res.data);
      });
  }

  function fetch_All_Subs() {
    axios
      .get(`${API_URL}institute/subscriptionList?instituteId=${instID}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let sub_dat = [];
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.date1 = moment(item.date).format("DD-MM-YYYY");
            item.pname = item?.packageNames?.packageName;
            if (item.packageTypes == 0) {
              item.rjs = item.noOfResume;
            }
            if (item.packageTypes == 1) {
              item.rjs = item.noOfJobPort;
            }
            if (item.packageTypes == 2) {
              item.rjs = item.noOfServices;
            }
            if (item.packageTypes == 0) {
              item.package_type = "Resume wise";
            }
            if (item.packageTypes == 1) {
              item.package_type = "Job post wise";
            }
            if (item.packageTypes == 2) {
              item.package_type = "Service wise";
            }

            if (item.payment == 0) {
              item.payment1 = (
                <div>
                  <Badge
                    className="bg-warning"
                    style={{ cursor: "pointer", padding: "5px" }}
                  >
                    UnPaid
                  </Badge>
                </div>
              );
            }
            if (item.payment == 1) {
              item.payment1 = (
                <div>
                  <Badge
                    className="bg-primary"
                    style={{ cursor: "pointer", padding: "5px" }}
                  >
                    Paid
                  </Badge>
                </div>
              );
            }

            if (item.status == 0) {
              item.status1 = (
                <div>
                  <Badge
                    className="bg-success"
                    style={{ cursor: "pointer", padding: "5px" }}
                  >
                    Active
                  </Badge>
                </div>
              );
            }

            if (item.status == 1) {
              item.status1 = (
                <div>
                  <Badge
                    className="bg-danger"
                    style={{ cursor: "pointer", padding: "5px" }}
                  >
                    Inactive
                  </Badge>
                </div>
              );
            }

            if (item.status == 2) {
              item.status1 = (
                <div>
                  <Badge
                    className="bg-warning"
                    style={{ cursor: "pointer", padding: "5px" }}
                  >
                    Expired
                  </Badge>
                </div>
              );
            }

            item.action = (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className="fas fa-eye mx-1"
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                  onClick={() => {
                    get_invice_list(item._id);
                    setpopupView(true);
                  }}
                ></i>
                <i
                  class="fa fa-print mx-1"
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                  onClick={() => {
                    print_invoice(item);
                  }}
                ></i>
              </div>
            );

            sub_dat.push(item);
          });
        setsubsTable(sub_dat);
      });
  }

  function print_invoice(item) {
    axios
      .get(`${API_URL}subscription/generate?subscriptionId=${item._id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setinvoiveDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        handlePrint();
      });
  }

  const subsData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "date",
        field: "date",
        sort: "asc",
        width: 150,
      },

      // {
      //   label: "ID",
      //   field: "packageID",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Type",
        field: "package_type",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Name",
      //   field: "pname",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Resume/Job/service",
        field: "rjs",
        sort: "asc",
        width: 150,
      },
      {
        label: "Validity",
        field: "validity",
        sort: "asc",
        width: 150,
      },
      {
        label: "Expiry",
        field: "expiry",
        sort: "asc",
        width: 150,
      },

      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Payment",
        field: "payment1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: subsTable,
  };

  useEffect(() => {
    fetch_All_Subs();
    get_all_inst_images();
  }, []);

  useEffect(() => {
    get_all_inst_images();
  }, [inst_ID]);

  const [masterObj, setmasterObj] = useState({});
  const uploadImage = (e) => {
    let name = e.target.name;
    const fd = new FormData();
    const maxFileSize = 300 * 1024; // Set the maximum file size in bytes (e.g., 220KB)
    const maxWidth = 600;
    const maxHeight = 400;
    const selectedFile = e.target.files[0];
    if (selectedFile.size > maxFileSize) {
      // Display an error message or take appropriate action for exceeding the file size limit
      toastr.error("File size exceeds the maximum allowed size.");
      return;
    }

    const image = new Image();
    image.src = URL.createObjectURL(selectedFile);

    image.onload = function () {
      if (image.width > maxWidth || image.height > maxHeight) {
        // Display an error message or take appropriate action for exceeding the dimensions limit
        toastr.error("Image dimensions exceed the maximum allowed size.");
        return;
      }

      // If the file size and dimensions are within limits, proceed with the upload
      fd.append("inst_image", selectedFile);

      axios
        .put(
          `${API_URL}institute/imagesupload/${name}?instituteId=${inst_ID}`,
          fd,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          get_all_inst_images();
          toastr.success("New image added");
        });
    };
  };

  function get_all_inst_images() {
    axios
      .get(`${API_URL}profile/viewinstImage/?insttituteId=${inst_ID}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setmasterObj({ ...masterObj, images: res.data });
      });
  }

  const contact_data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "extractedTime",
        sort: "asc",
        width: 150,
      },
      {
        label: "Candidate",
        field: "candName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Package",
        field: "contacted_package",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "asc",
      //   width: 150,
      // },
    ],
    rows: contactedDetails,
  };

  function fetch_contactedDetails(id) {
    axios
      .get(
        `${API_URL}contacted/getContactHistory?inst_reg_no=${instID}&package_filter=${id}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res?.data?.data;
        let detail_data = [];
        result.map((item, index) => {
          item.id = index + 1;
          item.extractedTime = moment(item?.contacted_time, "HH:mm").format(
            "hh:mm A"
          );
          item.date1 = moment(item.contacted_date).format("DD-MM-YYYY");
          // item.candName=item?.contacted_candidate_id?.cand_name;
          item.candName = (
            <div>
              <a
                href={"/candidate/" + item?.contacted_candidate_id?.cand_reg_no}
                target="_blank"
                style={{ color: "rgb(9 132 255)" }}
              >
                {item?.contacted_candidate_id?.cand_name}
              </a>
            </div>
          );

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href={"/candidate/" + item?.candidate_id?.cand_reg_no}
                target="_blank"
                style={{ color: "#495057" }}
              >
                <button className="button_class">
                  <i className="fas fa-eye"></i>
                </button>
              </a>
            </div>
          );
          detail_data.push(item);
        });
        setcontactedDetails(detail_data);
      });
  }

  useEffect(() => {
    fetch_contactedDetails();
  }, []);

  const verifyCandidate = (id) => {
    axios
      .put(
        `${API_URL}profile/verifyinst/?instituteId=${id}`,
        {},
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("Successfully Verified");
          handleTableData(res?.data?.inst_reg_no);
          // handleTableData(canId);
        }
      });
  };

  function handlefilter(selected, name) {
    switch (name) {
      case "package_filter":
        setselectedFilter(selected);
        fetch_contactedDetails(selected.value);
        setmasterObj({
          ...masterObj, package_filter: selected.value
        })

        break;

      default:
        break;
    }
  }

  function handleShortlisted(selected, name) {
    switch (name) {
      case "packageee":
        setSelectedPackage(selected)
        fetch_shortlisted(details?._id, selected.value)
        setmasterObj({ ...masterObj, packageee: selected.value })
    }
  }

  function handlePlaced(selected, name) {
    switch (name) {
      case "packageName":
        setPackageFilter(selected)
        fetch_placed(details?._id, selected.value)
        setmasterObj({ ...masterObj, packageName: selected.value })
    }
  }


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}job` + "/" + jobIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Job post deleted successfully");
                  viewJobpost(
                    details?._id,
                    searchValueJob,
                    indexOfFirstTransfer1,
                    indexOfLastTransfer1
                  );
                } else {
                  toastr.error("Failed to delete job post");
                  return;
                }
              })
              .catch((err) => {
                console.log("err");
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setpopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div id="invoiceDetailsModalContent" className="modal-header">
          <h5 className="modal-title mt-0">Invoice Details</h5>
          <div style={{ display: "flex" }}>
            <button
              type="button"
              onClick={() => {
                setpopupView(false);
                setinvoiveDetails([]);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="close"
            ></button>
          </div>
        </div>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="invoice-title">
                  <h4 className="float-end font-size-16">
                    Invoice # {invoiveDetails?.invoice?.invoiceNo}
                    {invoiveDetails?.invoice?.payment == 0 ? (
                      <span className="badge bg-danger font-size-12 ms-2">
                        UnPaid
                      </span>
                    ) : null}
                    {invoiveDetails?.invoice?.payment == 1 ? (
                      <span className="badge bg-success font-size-12 ms-2">
                        Paid
                      </span>
                    ) : null}
                  </h4>
                  <div className="mb-4">
                    {/* <img src={logo} alt="logo" height="20" /> */}
                  </div>
                  <div className="text-muted">
                    <p className="mb-1">641 Counts Lane Wilmore, KY 40390</p>
                    <p className="mb-1">
                      <i className="uil uil-envelope-alt me-1"></i> abc@123.com
                    </p>
                    <p>
                      <i className="uil uil-phone me-1"></i> 012-345-6789
                    </p>
                  </div>
                </div>
                <hr className="my-4" />
                <Row>
                  <Col sm="6">
                    <div className="text-muted">
                      <h5 className="font-size-16 mb-3">Billed To:</h5>
                      <h5 className="font-size-15 mb-2">
                        {invoiveDetails?.invoice?.instituteName}
                      </h5>
                      <p className="mb-1">
                        {invoiveDetails?.invoice?.instituteState},
                        {invoiveDetails?.invoice?.instituteDistrict}
                      </p>
                      <p className="mb-1">
                        {invoiveDetails?.invoice?.instituteCity}
                      </p>
                      <p>{invoiveDetails?.invoice?.instituteMob}</p>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="text-muted text-sm-end">
                      <div>
                        <h5 className="font-size-16 mb-1">Invoice No:</h5>
                        <p>{invoiveDetails?.invoice?.invoiceNo}</p>
                      </div>
                      <div className="mt-4">
                        <h5 className="font-size-16 mb-1">Invoice Date:</h5>
                        <p>
                          {moment(invoiveDetails?.invoice?.invoiceDate).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                      </div>
                      <div className="mt-4">
                        {/* <h5 className="font-size-16 mb-1">Order No:</h5>
                        <p>#1123456</p> */}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="py-2">
                  <h5 className="font-size-15">Order summary</h5>
                  <div className="table-responsive">
                    <Table className="table-nowrap table-centered mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: "70px" }}>No.</th>
                          <th>Package Name</th>
                          <th>Package Type</th>
                          <th>Pakage Id</th>
                          <th>Price</th>
                          <th className="text-end" style={{ width: "120px" }}>
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <h5 className="font-size-15 mb-1">
                              {invoiveDetails?.invoice?.packageName}
                            </h5>
                            <ul className="list-inline mb-0">
                              {invoiveDetails?.invoice?.packageType === 0 ? (
                                <>
                                  No Of Resumes :{" "}
                                  <span className="fw-medium">
                                    {invoiveDetails?.invoice?.noOfResume}
                                  </span>
                                </>
                              ) : invoiveDetails?.invoice?.packageType === 1 ? (
                                <>
                                  No Of Jobs :{" "}
                                  <span className="fw-medium">
                                    {invoiveDetails?.invoice?.noOfJobPort}
                                  </span>
                                </>
                              ) : (
                                // Add any additional cases if needed
                                <>
                                  No Of Services :{" "}
                                  <span className="fw-medium">
                                    {invoiveDetails?.invoice?.noOfServices}
                                  </span>
                                </>
                              )}
                              <li className="list-inline-item mx-1">
                                Validity{" "}
                                <span className="fw-medium mx-1">30 days</span>
                              </li>
                            </ul>
                          </td>

                          <td>
                            {invoiveDetails?.invoice?.packageType === 0 ? (
                              <>Resume wise</>
                            ) : invoiveDetails?.invoice?.packageType === 1 ? (
                              <>Job port wise</>
                            ) : (
                              // Add any additional cases if needed
                              <>Service wise</>
                            )}
                          </td>
                          <td>30PCKG</td>
                          <td>{invoiveDetails?.invoice?.price}</td>
                          <td className="text-end">
                            {invoiveDetails?.invoice?.price}
                          </td>
                        </tr>

                        <tr>
                          <th colSpan="5" className="text-end">
                            Sub Total
                          </th>
                          <td className="text-end">
                            {invoiveDetails?.invoice?.price}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="5" className="border-0 text-end">
                            Discount :
                          </th>
                          <td className="border-0 text-end">
                            - {invoiveDetails?.invoice?.discount}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="5" className="border-0 text-end">
                            Shipping Charge :
                          </th>
                          <td className="border-0 text-end">
                            {invoiveDetails?.invoice?.shippingCharge}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="5" className="border-0 text-end">
                            Tax
                          </th>
                          <td className="border-0 text-end">
                            {invoiveDetails?.invoice?.tax}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="5" className="border-0 text-end">
                            Total
                          </th>
                          <td className="border-0 text-end">
                            <h4 className="m-0">
                              {invoiveDetails?.totalPrice}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-print-none mt-4">
                    <div className="float-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handlePrint();
                        }}
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Modal>
      <Modal show={showDeclineAppliedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineAppliedForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            submitDeclineAppliedForm();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    value={selectedDeclineApplied}
                    onChange={(selected) => {
                      handleSelectChange(selected, "appliedReason");
                    }}
                    options={ReasonsOption}
                    classNamePrefix="select2-selection"
                  />
                  <p className="text-danger" style={{ fontSize: "11px" }}>
                    {selectedDeclineApplied === null
                      ? appliedErrors.remarkError
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row style={{ justifyContent: "end" }}>
              <Col md="1">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={showDeclineShortlistedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineShortlistedForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            submitDeclineShortlistedForm();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    value={selectedDeclineShortlisted}
                    onChange={(selected) => {
                      handleSelectChange(selected, "shortlistedReason");
                    }}
                    options={ReasonsOption}
                    classNamePrefix="select2-selection"
                  />
                  <p className="text-danger" style={{ fontSize: "11px" }}>
                    {selectedDeclineShortlisted === null
                      ? shortlistedErrors.remarkError
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row style={{ justifyContent: "end" }}>
              <Col md="1">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={showDeclinePlacedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclinePlacedForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            submitDeclinePlacedForm();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    value={selectedDeclinePlaced}
                    onChange={(selected) => {
                      handleSelectChange(selected, "placedReason");
                    }}
                    options={ReasonsOption}
                    classNamePrefix="select2-selection"
                  />
                  <p className="text-danger" style={{ fontSize: "11px" }}>
                    {selectedDeclinePlaced === null
                      ? placedErrors.remarkError
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row style={{ justifyContent: "end" }}>
              <Col md="1">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupShortlistedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Shortlisted Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupShortlistedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupShortlistedData?.shortlisted_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Application ID :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.application_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Shortlisted Date :</td>
                <td>{popupShortlistedData?.date}</td>
                <td style={{ paddingLeft: "25px" }}>Shortlisted Time :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.time}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupShortlistedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}>
                  {popupShortlistedData?.shortlisted_inst_name}
                </td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.shortlisted_cand_name}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td>{popupShortlistedData?.exp}</td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupShortlistedData?.qualification}
                </td>
              </tr>
              <tr
                hidden={popupShortlistedData?.shortlisted_current_status !== 2}
              >
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupShortlistedData?.decline_remark}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupPlacedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Placed Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupPlacedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupPlacedData?.placed_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}>
                  {popupPlacedData?.placed_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Placed Date :</td>
                <td>{popupPlacedData?.date}</td>
                <td style={{ paddingLeft: "25px" }}>Placed Time :</td>
                <td style={{ textAlign: "left" }}>{popupPlacedData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupPlacedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupPlacedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}>
                  {popupPlacedData?.placed_inst_name}
                </td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}>
                  {popupPlacedData?.placed_cand_name}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td>{popupPlacedData?.exp}</td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupPlacedData?.qualification}
                </td>
              </tr>
              <tr hidden={popupPlacedData?.placed_current_status !== 1}>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupPlacedData?.decline_remark}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupAppliedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupAppliedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupAppliedData?.application_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Application ID :</td>
                <td style={{ textAlign: "left" }}>
                  {popupAppliedData?.application_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupAppliedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupAppliedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Subject :</td>
                <td>{popupAppliedData?.subject}</td>
                <td style={{ paddingLeft: "25px" }}>Experience :</td>
                <td style={{ textAlign: "left" }}>{popupAppliedData?.exp}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Applicant :</td>
                <td>{popupAppliedData?.application_cand_name}</td>
                <td style={{ paddingLeft: "25px" }}>Applicant Mob :</td>
                <td style={{ textAlign: "left" }}>
                  {popupAppliedData?.application_cand_phone}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Class :</td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}>
                  {popupAppliedData?.class}
                </td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupAppliedData?.qualification}
                </td>
              </tr>
              <tr hidden={popupAppliedData?.application_current_status !== 3}>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupAppliedData?.decline_remark}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem={
              "Institute" + " " + "-" + " " + details?.inst_reg_no
            }
          />
          <Row>
            <Col xl="12">
              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        //width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown">
                          <div
                            tabindex="-1"
                            role="menu"
                            aria-hidden="true"
                            className="dropdown-menu-end dropdown-menu"
                          >
                            <a
                              to="/"
                              tabindex="0"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Edit
                            </a>
                            <a
                              to="/"
                              tabindex="1"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Action
                            </a>
                            <a
                              to="/"
                              tabindex="2"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Remove
                            </a>
                          </div>
                        </div>

                        <Stack
                          direction="row"
                          spacing={2}
                          style={{ justifyContent: "center" }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            src={`${API_URL}uploads/institute_images/${details?.inst_logo}`}
                            sx={{ width: 56, height: 56 }}
                            style={{
                              width: "6rem",
                              height: "6rem",
                              //marginTop: "20%",
                              marginBottom: "auto",
                            }}
                          />
                        </Stack>

                        {details?.verify_inst === 1 ? (
                          // <OverlayTrigger
                          //   placement="top-end"
                          //   trigger="click"
                          //   style={{transform:"translate(-850px, -564px)"}}
                          //   overlay={
                          //     <Tooltip
                          //       id="button-tooltip-2"
                          //       style={{ position: "absolute", top: "-30px" }}
                          //     >
                          //       Verified
                          //     </Tooltip>
                          //   }
                          // >
                          <a
                            aria-haspopup="true"
                            className="text-body font-size-16 ddropdown-toggle"
                            aria-expanded="false"
                          // onClick={() => {
                          //   verifyCandidate(details?._id);
                          // }}
                          >
                            <CheckCircleIcon
                              style={{
                                fontSize: "20px",
                                color: "green",
                                position: "relative",
                                float: "right",
                                bottom: "83px",
                              }}
                            />
                          </a>
                        ) : (
                          <a
                            aria-haspopup="true"
                            className="text-body font-size-16 ddropdown-toggle"
                            aria-expanded="false"
                            onClick={() => {
                              verifyCandidate(details?._id);
                            }}
                          >
                            <CancelIcon
                              style={{
                                fontSize: "20px",
                                color: "red",
                                position: "relative",
                                float: "right",
                                bottom: "83px",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        )}

                        <h5
                          className="mt-3 mb-1"
                          style={{
                            textAlignLast: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {details?.inst_name}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              class="btn btn-success"
                              style={{
                                borderRadius: "40px",
                                marginRight: "10px",
                                padding: "0",
                                width: "28px",
                                cursor: "default",
                              }}
                              hidden={!details?.inst_phone}
                            >
                              <i
                                className="uil-phone-alt"
                                style={{ fontSize: "15px" }}
                              />
                            </button>
                          </div>
                          {details?.inst_phone}
                        </div>
                        <p
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                          hidden={!details?.inst_whatsapp_no}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              class="btn btn-success"
                              style={{
                                borderRadius: "40px",
                                marginRight: "10px",
                                padding: "0",
                                width: "28px",
                                cursor: "default",
                              }}
                              hidden={!details.inst_whatsapp_no}
                            >
                              <i
                                className="uil-whatsapp"
                                style={{ fontSize: "15px" }}
                                hidden={!details.inst_whatsapp_no}
                              />
                              {/* Call */}
                            </button>
                          </div>
                          {details?.inst_whatsapp_no}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        ></p>

                        <div
                          className="mt-3 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        ></div>
                        <Divider />
                        <div hidden={!details?.managementName}>
                          <p className="mb-1 mt-1">Management:</p>
                          <h6 className="font-size-14">
                            {details?.managementName}
                          </h6>
                        </div>
                        <div hidden={!details?.mediumName}>
                          <p className="mb-1">Medium:</p>
                          <h6 className="font-size-14">
                            {details?.mediumName}
                          </h6>
                        </div>
                        <div hidden={!details?.no_of_branch}>
                          <p className="mb-1">No. Of Branch :</p>
                          <h6 className="font-size-14">
                            {details?.no_of_branch}
                          </h6>
                        </div>

                        <div hidden={!details?.syllabusName}>
                          <p className="mb-1">Syllabus:</p>
                          <h6 className="font-size-14">
                            {details?.syllabusName}
                          </h6>
                        </div>
                        <div hidden={!details?.campusType}>
                          <p className="mb-1">Campus Type:</p>
                          <h6 className="font-size-14">
                            {details?.campusType}
                          </h6>
                        </div>
                        <div hidden={!details?.requireVal}>
                          <p className="mb-1">Do you Require Teachers :</p>
                          <h6 className="font-size-14">
                            {details?.requireVal}
                          </h6>
                        </div>
                        <div hidden={!details?.requirements}>
                          <p className="mb-1">Requirements :</p>
                          <h6 className="font-size-14">
                            {details?.requirements}
                          </h6>
                        </div>
                        <div hidden={!details?.inst_locality}>
                          <p className="mb-1">Locality:</p>
                          <h6 className="font-size-14">
                            {details?.inst_locality}
                          </h6>
                        </div>
                        <div>
                          <p className="mb-1">District:</p>
                          <h6 className="font-size-14">
                            {details?.districtName}
                          </h6>
                        </div>
                        <div>
                          <p className="mb-1">State:</p>
                          <h6 className="font-size-14">{details?.stateName}</h6>
                        </div>
                        <div hidden={!details?.inst_remarks}>
                          <p className="mb-1">Remarks :</p>
                          <h6 className="font-size-14">
                            {details?.inst_remarks}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <ImageSearchIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Photos"
                                value="8"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />

                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value="4"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <LocationCityIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Job Posts"
                                value="1"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <DescriptionIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Applicants"
                                value="6"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <ReceiptIcon style={{ fontSize: "20px" }} />
                                }
                                label="Shortlist"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <ArticleIcon style={{ fontSize: "20px" }} />
                                }
                                label="Placed"
                                value="3"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <ReceiptLongIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Subscriptions"
                                value="7"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />

                              <Tab
                                icon={
                                  <ConnectWithoutContactRoundedIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Contacted"
                                value="9"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>

                          <TabPanel
                            value="9"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Package</Label>
                                  <Select
                                    onChange={(selected) => {
                                      handlefilter(selected, "package_filter");
                                    }}
                                    value={selectedFilter}
                                    options={[
                                      { label: "Find Resume ", value: "1" },
                                      { label: "Job Post", value: "2" },
                                    ]}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="contact_inst_table"
                                  responsive
                                  bordered
                                  data={contact_data}
                                />
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="8"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <form>
                                  <div class="row r-row mb-2">
                                    <div class="col-md-6">
                                      <div class="field inputText-cont form-group">
                                        <label for="">
                                          Upload School Photo
                                        </label>
                                        <input
                                          type="file"
                                          placeholder="Institution Name"
                                          name="school"
                                          onChange={uploadImage}
                                        />
                                        <small class="text-muted">
                                          <p>
                                            Size 200kb or less Max.height:600
                                            Max.width:400
                                          </p>
                                        </small>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      {masterObj?.images?.school ? (
                                        <img
                                          src={`${API_URL}uploads/institute_images/${masterObj?.images?.school?.filename}`}
                                          alt="icon"
                                          className="logo-school loadimg img-fluid"
                                        />
                                      ) : (
                                        <img
                                          className="logo-school loading img-fluid"
                                          src={noimage}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <hr />

                                  <div class="row r-row mb-2">
                                    <div class="col-md-6">
                                      <div class="field inputText-cont form-group">
                                        <label for="">
                                          Upload Accomodation Photo
                                        </label>
                                        <input
                                          type="file"
                                          placeholder="Institution Name"
                                          name="accommodation"
                                          onChange={uploadImage}
                                        />
                                        <small class="text-muted">
                                          <p>
                                            Size 200kb or less Max.height:600
                                            Max.width:400
                                          </p>
                                        </small>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      {masterObj?.images?.accommodation ? (
                                        <img
                                          src={`${API_URL}uploads/institute_images/${masterObj?.images?.accommodation?.filename}`}
                                          alt="icon"
                                          className="logo-school loadimg img-fluid"
                                        />
                                      ) : (
                                        <img
                                          className="logo-school loading img-fluid"
                                          src={noimage}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <hr />

                                  <div class="row r-row mb-2">
                                    <div class="col-md-6">
                                      <div class="field inputText-cont form-group">
                                        <label for="">Upload Other Photo</label>
                                        <input
                                          type="file"
                                          placeholder="Institution Name"
                                          onChange={uploadImage}
                                          name="other"
                                        />
                                        <small class="text-muted">
                                          <p>
                                            Size 200kb or less Max.height:600
                                            Max.width:400
                                          </p>
                                        </small>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      {masterObj?.images?.other ? (
                                        <img
                                          src={`${API_URL}uploads/institute_images/${masterObj?.images?.other?.filename}`}
                                          alt="icon"
                                          className="logo-school loadimg img-fluid"
                                        />
                                      ) : (
                                        <img
                                          className="logo-school loading img-fluid"
                                          src={noimage}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <hr />
                                </form>
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="instJobpostTable"
                                  responsive
                                  bordered
                                  data={data}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchJob(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesJob > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer1 + 1} to{" "}
                                      {indexOfFirstTransfer1 +
                                        jobpostDetails.length}{" "}
                                      of {totalPagesJob} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesJob / PerPageCount
                                      )}
                                      page={currentPageJob}
                                      onChange={paginateJob}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Package</Label>
                                  <Select
                                    name="packageee"
                                    onChange={(selected) => {
                                      handleShortlisted(selected, "packageee")
                                    }}
                                    value={selectedPackage}
                                    options={[
                                      { label: "Job Post", value: "0" },
                                      { label: "Find Resume ", value: "1" },
                                    ]}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="instShortlistTableData"
                                  data={shortlistTableData}
                                  responsive
                                  bordered
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchShort(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesShort > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer3 + 1} to{" "}
                                      {indexOfFirstTransfer3 +
                                        shortlistedData.length}{" "}
                                      of {totalPagesShort} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesShort / PerPageCount
                                      )}
                                      page={currentPageShort}
                                      onChange={paginateShort}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="3"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Package</Label>
                                  <Select
                                    name="packageName"
                                    onChange={(selected) => {
                                      handlePlaced(selected, "packageName")
                                    }}
                                    value={packageFilter}
                                    options={[
                                      { label: "Job Post", value: "0" },
                                      { label: "Find Resumes", value: "1" },
                                    ]}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="instPlacedTable"
                                  responsive
                                  bordered
                                  data={placedTableData}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchPlaced(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesPlaced > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer4 + 1} to{" "}
                                      {indexOfFirstTransfer4 +
                                        placedData.length}{" "}
                                      of {totalPagesPlaced} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesPlaced / PerPageCount
                                      )}
                                      page={currentPagePlaced}
                                      onChange={paginatePlaced}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="6"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="instApplicantTable"
                                  responsive
                                  bordered
                                  data={applicantTableData}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchApp(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesApp > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer2 + 1} to{" "}
                                      {indexOfFirstTransfer2 +
                                        appliedData.length}{" "}
                                      of {totalPagesApp} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesApp / PerPageCount
                                      )}
                                      page={currentPageApp}
                                      onChange={paginateApp}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="4"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md="12">
                                <Row>
                                  <Col
                                    md={3}
                                    xl={3}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Card>
                                      <CardBody
                                        style={{ padding: "10px 10px" }}
                                      >
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title">
                                            <span>{totalJobpost}</span>
                                          </h4>
                                          <p className="text-dark mb-0 h4-subtitle font-size-13">
                                            Job Post
                                          </p>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col
                                    md={3}
                                    xl={3}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Card>
                                      <CardBody
                                        style={{ padding: "10px 10px" }}
                                      >
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title">
                                            <span>{totalApplied}</span>
                                          </h4>
                                          <p className="text-dark mb-0 h4-subtitle font-size-13">
                                            Applications
                                          </p>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col
                                    md={3}
                                    xl={3}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Card>
                                      <CardBody
                                        style={{ padding: "10px 10px" }}
                                      >
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title">
                                            <span>{totalShortlisted}</span>
                                          </h4>
                                          <p className="text-dark mb-0 h4-subtitle font-size-13">
                                            Short Listed
                                          </p>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col
                                    md={3}
                                    xl={3}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Card>
                                      <CardBody
                                        style={{ padding: "10px 10px" }}
                                      >
                                        <div>
                                          <h4 className="mb-1 mt-1 h4-title">
                                            <span>{totalPlaced}</span>
                                          </h4>
                                          <p className="text-dark mb-0 h4-subtitle font-size-13">
                                            Placed
                                          </p>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                              </Col>
                              <Col>
                                <AvForm
                                  ref={formRef}
                                  className="needs-validation"
                                  onValidSubmit={() => {
                                    handleValidSubmitSummary();
                                  }}
                                >
                                  <Row>
                                    <Col md="9">
                                      <div className="mb-3">
                                        <AvField
                                          name="remarks_reason"
                                          placeholder="Remarks"
                                          type="textarea"
                                          className="form-control"
                                          id="validationCustom01"
                                          errorMessage="Enter remarks"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={master?.remarks_reason}
                                          onChange={handleInputChange}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="1" style={{ marginTop: "9px" }}>
                                      <Button color="primary" type="submit">
                                        {"Submit"}
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12" className="chat-conversation">
                                      <ul
                                        style={{
                                          listStyle: "none",
                                          paddingLeft: "0",
                                        }}
                                      >
                                        {remarksData &&
                                          remarksData.map((item) => {
                                            return (
                                              <li>
                                                <div
                                                  class="conversation-list"
                                                  style={{
                                                    marginBottom: "0px",
                                                  }}
                                                >
                                                  <div class="ctext-wrap">
                                                    <div
                                                      class="ctext-wrap-content w-100"
                                                      style={{
                                                        background: "#f5f6f8",
                                                      }}
                                                    >
                                                      <Row>
                                                        <Col md="8">
                                                          <h5 class="font-size-14 conversation-name">
                                                            <a
                                                              class="text-dark"
                                                              href=""
                                                            >
                                                              {item?.staff_name}
                                                            </a>
                                                          </h5>
                                                        </Col>
                                                        <Col
                                                          md="4"
                                                          style={{
                                                            textAlign: "right",
                                                          }}
                                                        >
                                                          <span class="d-inline-block font-size-12 text-muted ms-2">
                                                            {" "}
                                                            {item?.dateTime}
                                                          </span>
                                                        </Col>
                                                      </Row>

                                                      <p class="mb-0">
                                                        {item?.remarks_reason}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </Col>
                                  </Row>
                                  {totalPagesRemarks > 10 && (
                                    <div className="pagination-div">
                                      <div className="page-details">
                                        Showing {indexOfFirstTransfer5 + 1} to{" "}
                                        {indexOfFirstTransfer5 +
                                          remarksData.length}{" "}
                                        of {totalPagesRemarks} entries
                                      </div>
                                      <Pagination
                                        color="standard"
                                        shape="rounded"
                                        defaultPage={1}
                                        count={Math.ceil(
                                          totalPagesRemarks / 10
                                        )}
                                        page={currentPageReamrks}
                                        onChange={paginateRemarks}
                                        size="large"
                                      />
                                    </div>
                                  )}
                                </AvForm>
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="7"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row className="mt-3">
                              <Col md="12">
                                <MDBDataTable
                                  id="subsTable"
                                  responsive
                                  data={subsData}
                                  bordered
                                />
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                      <div style={{ display: "none" }}>
                        <Printable ref={printRef} data={invoiveDetails} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Management;
