import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Badge,
  Table,
} from "reactstrap";
import { useReactToPrint } from "react-to-print";
import toastr from "toastr";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "./Subscriptions.scss";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { Printable } from "./Printable";
import Pagination from "@mui/material/Pagination";
import { useLocation } from "react-router-dom";
import saveAs from "file-saver";
import logoSm from "../../../assets/images/letterboard.png";



function Subscriptions() {
  const [selectedInstitute, setselectedInstitute] = useState(null);
  const [masterObj, setmasterObj] = useState({});

  const location = useLocation();
  const state = location.state;
  // const package_type = location?.state?.package_type;


  useEffect(() => {
    if (state) {
      setselectedInstitute(state);
      setselectPackageType(state?.package_type)
    }
    setmasterObj({ ...masterObj, institutes: state?.value, packageTypes: state?.package_type?.value });
  }, [state]);

  const formRef = useRef();
  const printRef = useRef();
  const [loader, setLoader] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [searchObj, setsearchObj] = useState({ start: "1" });
  const [popupView, setpopupView] = useState(false);
  const [instituteOption, setinstituteOption] = useState([]);
  const [packageoptions, setpackageoptions] = useState([]);
  const [selecetedpackage, setselecetedpackage] = useState(null);
  const [selectPackageType, setselectPackageType] = useState(null);
  const [selectedType, setSelecetedType] = useState(null);
  const [selectedName, setSelecetedName] = useState(null);
  const [display, setdisplay] = useState(null);
  const [tableDetails, settableDetails] = useState([]);
  const [confirmDeleteAlert, setconfirmDeleteAlert] = useState(false);
  const [subcriptionIdToBeDeleted, setsubcriptionIdToBeDeleted] =
    useState(null);
  const [subscriptionIdToBeUpdated, setsubscriptionIdToBeUpdated] =
    useState(null);

  const [invoiveDetails, setinvoiveDetails] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentOpen, setpaymentOpen] = useState(false);
  const [paymentDetails, setpaymentDetails] = useState([]);
  const [paymentObj, setpaymentObj] = useState({});
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [singleView, setsingleView] = useState(false);
  const [singleViewDetials, setsingleViewDetials] = useState([]);
  const [jobOptions, setJobOptions] = useState([])
  const [errorsSub, seterrorsSub] = useState({
    instituteError: "",
    packageTypeError: "",
    packageError: "", methodError: ""
  });
  const [selectedMethod, setSelectedMethod] = useState(null)
  const [subjectOptions, setSubjectOptions] = useState([])

  useEffect(() => {
    fetch_all_institute();
    fetch_subject()
    fetch_all_subscription_TableData();
  }, [searchObj]);

  useEffect(() => {
    if (selectPackageType?.value === 2) {
      fetch_all_package(selectPackageType.value);
      setdisplay(2)
    }
  }, [selectPackageType]);

  const onTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  function handleValidSubmit(e, v) {
    e.preventDefault();
    let data = {
      amount: masterObj?.amount,
      conveniencefee: masterObj?.pay_method === 0 ? masterObj?.conveniencefee : 0,
      gst: masterObj?.pay_method === 0 ? masterObj?.gst : 0,
      institutes: masterObj?.institutes,
      noOfJobPort: masterObj?.noOfJobPort,
      noOfResume: masterObj?.noOfResume,
      noOfServices: masterObj?.noOfServices,
      packageNames: masterObj?.packageNames,
      packageTypes: masterObj?.packageTypes,
      pay_method: masterObj?.pay_method,
      total: masterObj?.pay_method === 0 ? masterObj?.total : masterObj?.amount,
      validity: masterObj?.validity,
      subject: masterObj?.subject,
      job_name: masterObj?.job_name,
      _id: subscriptionIdToBeUpdated ? subscriptionIdToBeUpdated : ""
    }
    if (
      selectedInstitute === null ||
      selecetedpackage === null ||
      selectPackageType === null || selectedMethod === null
    ) {
      let err = errorsSub;
      if (selectedInstitute === null) {
        err.instituteError = "please select institute";
      }
      if (selecetedpackage === null) {
        err.packageError = "please select a package";
      }
      if (selectPackageType === null) {
        err.packageTypeError = "pleae select package type";
      }
      if (selectedMethod === null) {
        err.methodError = "Please select payment method"
      }
      seterrorsSub({ ...err });
    } else {
      if (subscriptionIdToBeUpdated) {
        setIsSubmitting(true);
        axios
          .put(`${API_URL}subscription`, data, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success == true) {
              setIsSubmitting(false);
              toastr.success("subscription updated successfully");
              setmasterObj({
                ...masterObj,
                total: ""
              });
              setselecetedpackage(null);
              setselectPackageType(null);
              setdisplay(null);
              setselectedInstitute(null);
              fetch_all_subscription_TableData();
              setsubscriptionIdToBeUpdated(null);
              setsubcriptionIdToBeDeleted(null);
              setSelectedMethod(null)
              setSelectedSubject(null)
              formRef.current.reset();
              seterrorsSub({
                instituteError: "",
                packageTypeError: "",
                packageError: "",
                methodError: "",
              });
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            setIsSubmitting(false);
            seterrorsSub({
              instituteError: "",
              packageTypeError: "",
              packageError: "",
              methodError: "",
            });
          });
      } else {
        setIsSubmitting(true);
        axios
          .post(`${API_URL}subscription`, data, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              setIsSubmitting(false);
              toastr.success("Subscription added successfully");
              setmasterObj({});
              setselecetedpackage(null);
              setselectPackageType(null);
              setdisplay(null);
              setselectedInstitute(null);
              fetch_all_subscription_TableData();
              setsubscriptionIdToBeUpdated(null);
              setSelectedSubject(null)
              setSelectedMethod(null)
              setSelectedSubject(null)
              setsubcriptionIdToBeDeleted(null);
              formRef.current.reset();
              seterrorsSub({
                instituteError: "",
                packageTypeError: "",
                packageError: "",
                methodError: "",
              });
            } else {
              setIsSubmitting(false);
              toastr.error("Failed to  add subcription");
              seterrorsSub({
                instituteError: "",
                packageTypeError: "",
                packageError: "",
                methodError: "",
              });
            }
          })
          .catch((err) => {
            setIsSubmitting(false);
            toastr.error(err.response.data.message);
            seterrorsSub({
              instituteError: "",
              packageTypeError: "",
              packageError: "",
              methodError: "",
            });
          });
      }
    }
  }

  function fetch_all_subscription_TableData() {
    axios
      .get(
        `${API_URL}subscription?package_id=${searchObj?.package_id}&from_date=${searchObj?.from_date}&to_date=${searchObj?.to_date}&start=${searchObj?.start}&limit=15`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        setTotalPages(res.data.count);
        let tableData = [];
        let result = res.data.data;
        result.map((item, index) => {
          item.id = (searchObj.start - 1) * 15 + index + 1;
          item.package = item?.packageNames?.packageName;
          item.date1 = moment(item.date).format("DD-MM-YYYY");
          item.conveniencefee = item?.packageNames?.conveniencefee ? item?.packageNames?.conveniencefee : "0";
          if (item.packageTypes == 0) {
            item.rjs = item.noOfResume;
          }
          if (item.packageTypes == 1) {
            item.rjs = item.noOfJobPort;
          }
          if (item.packageTypes == 2) {
            item.rjs = item.noOfServices;
          }
          if (item.payment == 0) {
            item.payment1 = (
              <div>
                <Badge
                  className="bg-warning"
                  style={{ cursor: "pointer", padding: "5px" }}
                  onClick={() =>
                    payment_option(item)}
                >
                  UnPaid
                </Badge>
              </div>
            );
          }
          if (item.payment == 1) {
            item.payment1 = (
              <div>
                <Badge
                  className="bg-primary"
                  style={{ cursor: "pointer", padding: "5px" }}
                >
                  Paid
                </Badge>
              </div>
            );
          }
          if (item.status == 0) {
            item.status1 = (
              <div>
                <Badge
                  className="bg-success"
                  style={{ cursor: "pointer", padding: "5px" }}
                >
                  Active
                </Badge>
              </div>
            );
          }
          if (item.status == 1) {
            item.status1 = (
              <div>
                <Badge
                  className="bg-danger"
                  style={{ cursor: "pointer", padding: "5px" }}
                >
                  Inactive
                </Badge>
              </div>
            );
          }
          if (item.status == 2) {
            item.status1 = (
              <div>
                <Badge
                  className="bg-warning"
                  style={{ cursor: "pointer", padding: "5px" }}
                >
                  Expired
                </Badge>
              </div>
            );
          }
          item.inst = (
            <div>
              <a
                href={`/institute/${item?.institutes?.inst_reg_no}`}
                target="blank"
              >
                {item?.institutes?.inst_name}
              </a>
            </div>
          );

          item.expiryDate = moment(item?.expiryDate).format("DD-MM-YYYY")

          item.action = (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i
                className="fas fa-file-invoice mx-1"
                style={{ fontSize: "1rem", cursor: "pointer" }}
                onClick={() => {
                  get_invice_list(item._id);
                  setpopupView(true);
                }}
              ></i>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                }}
                onClick={() => {
                  get_invice_list(item._id);
                  setsingleView(true);
                  setsingleViewDetials(item);
                }}
              ></i>
              {item.payment == 0 ? (
                <i
                  className="far fa-edit mx-2"
                  style={{
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    preupdateItem(item);
                    setsubscriptionIdToBeUpdated(item._id);
                    onTop();
                  }}
                ></i>
              ) : (
                <i className="fas fa-file-pdf mx-2"
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                  onClick={() => {
                    downloadPdf(item)
                  }}
                ></i>
              )}
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setconfirmDeleteAlert(true);
                  setsubcriptionIdToBeDeleted(item._id);
                }}
              ></i>
            </div>
          );

          tableData.push(item);
        });
        settableDetails(tableData);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Institute",
        field: "inst",
        sort: "asc",
        width: 150,
      },
      {
        label: "Package",
        field: "package",
        sort: "asc",
        width: 150,
      },
      {
        label: "Resume/job/service",
        field: "rjs",
        sort: "asc",
        width: 150,
      },
      {
        label: "Validity",
        field: "validity",
        sort: "asc",
        width: 150,
      },
      {
        label: "Expiry",
        field: "expiryDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Convenience Fee",
      //   field: "conveniencefee",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Payment",
        field: "payment1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableDetails,
  };
  const fetch_all_institute = () => {
    axios
      .get(`${API_URL}institute/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let institute_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.inst_reg_no + "-" + el?.inst_name,
              value: el?._id,
              whatsapp: el?.inst_whatsapp_no,
              displayLabel: el?.inst_name,
            };
          });
        setinstituteOption(institute_data);
      });
  };

  const fetch_subject = () => {
    axios
      .get(`${API_URL}subject/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let sub_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.subject_name,
              value: el?._id,
            };
          });
        setSubjectOptions([
          {
            options: sub_data,
          },
        ]);
      });
  };
  const fetch_jobs = (id) => {
    axios
      .get(`${API_URL}institute/jobname?institute_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {
        let job_data =
          res.data.data.map((el) => {
            return {
              label: el?.job_name,
              value: el?._id
            }
          })
        setJobOptions([{ options: job_data }])
      })
  }

  const fetch_all_package = (id) => {
    axios
      .get(`${API_URL}package/package-name-options-list?packageType=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let packagedata = result.map((item) => {
          return {
            label: item?.packageName,
            value: item?._id,
            noOfResume: item?.noOfResume,
            noOfJobPort: item?.noOfJobPort,
            noOfServices: item?.noOfServices,
            amount: item?.amount,
            validity: item?.validity,
            conveniencefee: item?.convenience_fee,
            total: item?.total,
            gst: item?.gst,
          };


        });
        setpackageoptions(packagedata);

      });
  };

  function selectChange(selected, name) {
    switch (name) {
      case "institutes":
        setselectedInstitute(selected);
        setmasterObj({ ...masterObj, [name]: selected.value });
        fetch_jobs(selected.value)
        break;
      case "packageTypes":
        fetch_all_package(selected.value);
        setselectPackageType(selected);
        setdisplay(selected.value);
        setmasterObj({ ...masterObj, packageTypes: selected.value });
        setselecetedpackage(null);

        break;
      case "packageNames":
        setselecetedpackage(selected);
        setmasterObj({
          ...masterObj,
          packageNames: selected.value,
          noOfResume: selected.noOfResume,
          noOfJobPort: selected.noOfJobPort,
          noOfServices: selected.noOfServices,
          validity: selected.validity,
          amount: selected.amount,
          conveniencefee: selected.conveniencefee,
          total: selected.total,
          gst: selected.gst,
        });
        break;
      case "packageTypesfilter":
        setSelecetedType(selected)
        fetch_all_package(selected.value);
        setSelecetedName(null)
        setsearchObj({ ...searchObj, packageTypesfilter: selected.value })
        break;
      case "packagnamefiletr":
        setSelecetedName(selected)
        setsearchObj({ ...searchObj, package_id: selected.value });
        break;
      case "pay_method":
        setSelectedMethod(selected)
        setmasterObj({ ...masterObj, [name]: selected.value })
        break;
      case "subject":
        setSelectedSubject(selected)
        setmasterObj({ ...masterObj, [name]: selected.value })
        break;
    }
  }
  function handleDataChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObj({ ...masterObj, amount: value });
  }
  function preupdateItem(item) {
    if (item.packageTypes == 0) {
      setdisplay(0);
      let pcOp = {
        label: "Find Resume",
        value: 0,
      };
      setselectPackageType(pcOp);
      fetch_all_package(item.packageTypes);
    }
    if (item.packageTypes == 1) {
      setdisplay(1);
      let pcOp = {
        label: "Job Post",
        value: 1,
      };
      setselectPackageType(pcOp);
      fetch_all_package(item.packageTypes);
    }
    if (item.packageTypes == 2) {
      setdisplay(2);
      let pcOp = {
        label: "Recruit With Support",
        value: 2,
      };
      setselectPackageType(pcOp);
      fetch_all_package(item.packageTypes);
    }

    let intop = {
      label: item?.institutes?.inst_name,
      value: item?.institutes?._id,
    };
    setselectedInstitute(intop);

    let payop = {
      label: item?.payment_method === 0 ? "Cash" : "Google Pay",
      value: item?.payment_method
    }
    setSelectedMethod(payop)

    let packageOp = {
      label: item?.packageNames?.packageName,
      value: item?.packageNames?._id,
      conveniencefee: item?.packageNames?.conveniencefee,
      gst: item?.packageNames?.gst,
      total: item?.packageNames?.total
    };
    setselecetedpackage(packageOp);

    if (item.packageTypes == 0) {
      setdisplay(0)
      let subjectOp = {
        label: item?.subject?.subject_name,
        value: item?.subject?._id
      }
      setSelectedSubject(subjectOp)
    }

    setmasterObj({
      ...masterObj,
      _id: item._id,
      institutes: item?.institutes?._id,
      packageNames: item?.packageNames?._id,
      pay_method: item?.payment_method,
      packageTypes: item?.packageTypes,
      noOfResume: item?.noOfResume,
      noOfJobPort: item?.noOfJobPort,
      noOfServices: item?.noOfServices,
      validity: item?.validity,
      amount: item?.amount,
      conveniencefee: item?.conveniencefee,
      gst: item?.packageNames?.gst,
      total: item?.payment_method === 0 ? item?.subtotal : item?.packageNames?.total
    });

  }

  function get_invice_list(id) {
    axios
      .get(`${API_URL}subscription/generate?subscriptionId=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        setinvoiveDetails(res.data);
      });
  }

  function reset() {
    formRef.current.reset();
    setmasterObj({});
    setselecetedpackage(null);
    setselectPackageType(null);
    setselectedInstitute(null);
    setdisplay(null);
    setSelectedSubject(null)
    fetch_all_subscription_TableData();
    setsubscriptionIdToBeUpdated(null);
    setSelectedMethod(null)
    seterrorsSub({
      ...errorsSub,
      instituteError: "",
      packageTypeError: "",
      packageError: "", methodError: ""
    })
  }

  function payment_option(item) {
    setpaymentOpen(true);
    setpaymentDetails(item);
    setpaymentObj({
      ...paymentObj,
      institute_id: item.institutes?._id,
      package_id: item?.packageNames?._id,
      subscription_id: item?._id,
      amount: item?.amount,
    });
  }

  function paymentAdded(id) {
    axios
      .post(
        `${API_URL}recruit-with-support/addedfrom?id=${id}`,
        {},
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {

      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handle_payment_amount(e) {
    let name = e.target.name;
    let value = e.target.value;
    setpaymentObj({ ...paymentObj, amount: value });
  }

  function handleValidSubmitPayment(e, v) {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    axios
      .post(`${API_URL}subscription/payement`, paymentObj, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success == true) {
          toastr.success("payment successfull");
          setpaymentOpen(false);
          setpaymentObj({});
          setpaymentDetails([]);
          fetch_all_subscription_TableData();
        } else {
          toastr.error("Payment failed");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const handlePrintInvoice = () => {
    const printContent = document.getElementById("invoiceDetailsModalContent");

    // Create a new div to copy the content
    const printDiv = document.createElement("div");
    printDiv.innerHTML = printContent.innerHTML;

    // Create a new window and append the content to it
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write(printDiv.innerHTML);
    printWindow.document.write("</body></html>");

    // Close the new window after printing
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  function handleSearchDate(e) {
    let name = e.target.name;
    let value = e.target.value;
    setsearchObj({ ...searchObj, [name]: value });
  }

  function paginate(event, value) {
    setsearchObj({ ...searchObj, start: value });
    setCurrentPage(value);
  }

  function downloadPdf(item) {
    saveAs(
      `${API_URL}` + item?.invoice_link,
      "Subscription.pdf"
    )
  }

  function downloadPdf1(item) {
    saveAs(
      `${API_URL}` + item?.invoice_link,
      "Subscription.pdf"
    )
  }


  function handleChange(e) {
    let { name, value } = e.target
    setmasterObj({ ...masterObj, [name]: value })
  }


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onCancel={() => setconfirmDeleteAlert(false)}
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}subscription?_id=${subcriptionIdToBeDeleted}`,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.success == true) {
                  toastr.success("Subscription Deleted successfully");
                  fetch_all_subscription_TableData();
                  setconfirmDeleteAlert(false);
                  setsubscriptionIdToBeUpdated(null)
                  setSelectedMethod(null)
                  setselecetedpackage(null);
                  setselectPackageType(null);
                  setselectedInstitute(null);
                  setdisplay(null);
                  setmasterObj({});
                  formRef.current.reset();
                } else {
                  toastr.error("Failed to delete");
                }
              });
          }}
        >
          Are you sure want to delete it?
        </SweetAlert>
      ) : null}

      <Modal
        show={paymentOpen}
        isOpen={paymentOpen}
        toggle={() => setpaymentOpen(!paymentOpen)}
        size="md"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Payment</h5>
          <button
            type="button"
            onClick={() => {
              setpaymentOpen(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="close"
          ></button>
        </div>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleValidSubmitPayment(e, v);
                    paymentAdded(state?.Id)
                  }}
                >
                  <Row>
                    <Col md="6">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          {" "}
                          <b>Institute :</b>
                        </p>
                        <p>{paymentDetails?.institutes?.inst_name}</p>
                      </div>
                    </Col>
                    <Col md="6">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          {" "}
                          <b>package type :</b>
                        </p>
                        {paymentDetails.packageTypes == 0 && <p>Find Resume</p>}
                        {paymentDetails.packageTypes == 1 && <p>Job Post </p>}
                        {paymentDetails.packageTypes == 2 && (
                          <p>Recruit With Support</p>
                        )}
                      </div>
                    </Col>
                    <Col md="6">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          {" "}
                          <b>package Name :</b>
                        </p>
                        <p>{paymentDetails?.package}</p>
                      </div>
                    </Col>
                    <Col md="6">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          {" "}
                          <b>Validity :</b>
                        </p>
                        <p>{paymentDetails?.validity}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md="6">
                      <Label>Amount</Label>
                      <AvField
                        name="amount"
                        placeholder="Amount"
                        value={paymentObj?.amount}
                        onChange={(e) => handle_payment_amount(e)}
                        type="number"
                      />
                    </Col> */}
                    <Col md="5">
                      <div>
                        <Button
                          className="mt-4"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Modal>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setpopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div id="invoiceDetailsModalContent" className="modal-header">
          <h5 className="modal-title mt-0">Invoice Details </h5>
          <h6>SAC Code–9985</h6>
          <div style={{ display: "flex" }}>
            <button
              type="button"
              onClick={() => {
                setpopupView(false);
                setinvoiveDetails([]);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="close"
            ></button>
          </div>
        </div>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>

                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={logoSm}
                    alt=""
                    style={{ width: "100%" }}
                  ></img>
                  <div
                    style={{
                      position: "absolute",
                      top: "42%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "100%",
                    }}
                  >
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">
                        Invoice # {invoiveDetails?.invoice?.invoiceNo}
                        {invoiveDetails?.invoice?.payment == 0 ? (
                          <span className="badge bg-danger font-size-12 ms-2">
                            UnPaid
                          </span>
                        ) : null}
                        {invoiveDetails?.invoice?.payment == 1 ? (
                          <span className="badge bg-success font-size-12 ms-2">
                            Paid
                          </span>
                        ) : null}
                      </h4>
                      <div className="mb-4">
                      </div>
                      <div className="text-muted">
                        <p className="mb-1">Flemigon Ecommerce Hub Pvt.Ltd.</p>
                        <p className="mb-1">UP-XII-305, Kannur , Kerala-670 571</p>
                        {/* <p className="mb-1">
                    <i className="uil uil-envelope-alt me-1"></i> abc@123.com
                  </p>
                  <p>
                    <i className="uil uil-phone me-1"></i> 012-345-6789
                  </p> */}
                      </div>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col sm="6">
                        <div className="text-muted">
                          <h5 className="font-size-16 mb-3">Billed To:</h5>
                          <h5 className="font-size-15 mb-2">
                            {invoiveDetails?.invoice?.instituteName}
                          </h5>
                          <p className="mb-1">
                            {invoiveDetails?.invoice?.instituteState},
                            {invoiveDetails?.invoice?.instituteDistrict}
                          </p>
                          <p className="mb-1">
                            {invoiveDetails?.invoice?.instituteCity}
                          </p>
                          <p>{invoiveDetails?.invoice?.instituteMob}</p>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="text-muted text-sm-end">
                          <div>
                            <h5 className="font-size-16 mb-1">Invoice No:</h5>
                            <p>{invoiveDetails?.invoice?.invoiceNo}</p>
                          </div>
                          <div className="mt-4">
                            <h5 className="font-size-16 mb-1">Invoice Date:</h5>
                            <p>
                              {moment(invoiveDetails?.invoice?.invoiceDate).format(
                                "DD-MM-YYYY"
                              )}
                            </p>
                          </div>
                          <div className="mt-4">
                            {/* <h5 className="font-size-16 mb-1">Order No:</h5>
                        <p>#1123456</p> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="py-2">
                      <h5 className="font-size-15">Order summary</h5>
                      <div className="table-responsive">
                        <Table className="table-nowrap table-centered mb-0">
                          <thead>
                            <tr>
                              <th style={{ width: "70px" }}>No.</th>
                              <th>Package Name</th>
                              <th>Package Type</th>
                              <th>Pakage Id</th>
                              <th>Price</th>
                              <th className="text-end" style={{ width: "120px" }}>
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>
                                <h5 className="font-size-15 mb-1">
                                  {invoiveDetails?.invoice?.packageName}
                                </h5>
                                <ul className="list-inline mb-0">
                                  {invoiveDetails?.invoice?.packageType === 0 ? (
                                    <>
                                      No Of Resumes :{" "}
                                      <span className="fw-medium">
                                        {invoiveDetails?.invoice?.noOfResume}
                                      </span>
                                    </>
                                  ) : invoiveDetails?.invoice?.packageType === 1 ? (
                                    <>
                                      No Of Jobs :{" "}
                                      <span className="fw-medium">
                                        {invoiveDetails?.invoice?.noOfJobPort}
                                      </span>
                                    </>
                                  ) : (
                                    // Add any additional cases if needed
                                    <>
                                      No Of Services :{" "}
                                      <span className="fw-medium">
                                        {invoiveDetails?.invoice?.noOfServices}
                                      </span>
                                    </>
                                  )}
                                  <li className="list-inline-item mx-1">
                                    Validity{" "}
                                    <span className="fw-medium mx-1">{invoiveDetails?.invoice?.validity}</span>
                                  </li>
                                </ul>
                              </td>

                              <td>
                                {invoiveDetails?.invoice?.packageType === 0 ? (
                                  <>Resume wise</>
                                ) : invoiveDetails?.invoice?.packageType === 1 ? (
                                  <>Job port wise</>
                                ) : (
                                  // Add any additional cases if needed
                                  <>Service wise</>
                                )}
                              </td>
                              <td>{invoiveDetails?.invoice?.packageId}</td>
                              <td>{invoiveDetails?.invoice?.price}</td>
                              <td className="text-end">
                                {invoiveDetails?.invoice?.price}
                              </td>
                            </tr>

                            <tr>
                              <th colSpan="5" className="text-end">
                                Sub Total
                              </th>
                              <td className="text-end">
                                {invoiveDetails?.invoice?.price}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="border-0 text-end">
                                Discount :
                              </th>
                              <td className="border-0 text-end">
                                - {invoiveDetails?.invoice?.discount}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="border-0 text-end">
                                Shipping Charge :
                              </th>
                              <td className="border-0 text-end">
                                {invoiveDetails?.invoice?.shippingCharge}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="border-0 text-end">
                                Tax
                              </th>
                              <td className="border-0 text-end">
                                {invoiveDetails?.invoice?.tax}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="border-0 text-end">
                                Total
                              </th>
                              <td className="border-0 text-end">
                                <h4 className="m-0">{invoiveDetails?.totalPrice}</h4>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>

                  </div>
                  <div className="d-print-none mt-4">
                    <div className="float-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handlePrint();
                        }}
                      >
                        Print
                      </button>
                    </div>
                  </div>

                </div>

                {/* <div className="invoice-title">

                  <div className="float-end">
                    <h4 className="float-end font-size-16">
                      Invoice # {invoiveDetails?.invoice?.invoiceNo}
                      {invoiveDetails?.invoice?.payment == 0 ? (
                        <span className="badge bg-danger font-size-12 ms-2">
                          UnPaid
                        </span>
                      ) : null}
                      {invoiveDetails?.invoice?.payment == 1 ? (
                        <span className="badge bg-success font-size-12 ms-2">
                          Paid
                        </span>
                      ) : null}
                    </h4>

                  </div>

                  <div className="mb-4">
                  </div>
                  <div className="text-muted">
                    <p className="mb-1">Flemigon Ecommerce Hub Pvt.Ltd.</p>
                    <p className="mb-1">UP-XII-305, Kannur , Kerala-670 571</p>
                  </div>
                </div>
                <hr className="my-4" />
                <Row>
                  <Col sm="6">
                    <div className="text-muted">
                      <h5 className="font-size-16 mb-3">Billed To:</h5>
                      <h5 className="font-size-15 mb-2">
                        {invoiveDetails?.invoice?.instituteName}
                      </h5>
                      <p className="mb-1">
                        {invoiveDetails?.invoice?.instituteState},
                        {invoiveDetails?.invoice?.instituteDistrict}
                      </p>
                      <p className="mb-1">
                        {invoiveDetails?.invoice?.instituteCity}
                      </p>
                      <p>{invoiveDetails?.invoice?.instituteMob}</p>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="text-muted text-sm-end">
                      <div>
                        <h5 className="font-size-16 mb-1">Invoice No:</h5>
                        <p>{invoiveDetails?.invoice?.invoiceNo}</p>
                      </div>
                      <div className="mt-4">
                        <h5 className="font-size-16 mb-1">Invoice Date:</h5>
                        <p>
                          {moment(invoiveDetails?.invoice?.invoiceDate).format(
                            "DD-MM-YYYY"
                          )}
                        </p>
                      </div>
                      <div className="mt-4">
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="py-2">
                  <h5 className="font-size-15">Order summary</h5>
                  <div className="table-responsive">
                    <Table className="table-nowrap table-centered mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: "70px" }}>No.</th>
                          <th>Package Name</th>
                          <th>Package Type</th>
                          <th>Pakage Id</th>
                          <th>Price</th>
                          <th className="text-end" style={{ width: "120px" }}>
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <h5 className="font-size-15 mb-1">
                              {invoiveDetails?.invoice?.packageName}
                            </h5>
                            <ul className="list-inline mb-0">
                              {invoiveDetails?.invoice?.packageType === 0 ? (
                                <>
                                  No Of Resumes :{" "}
                                  <span className="fw-medium">
                                    {invoiveDetails?.invoice?.noOfResume}
                                  </span>
                                </>
                              ) : invoiveDetails?.invoice?.packageType === 1 ? (
                                <>
                                  No Of Jobs :{" "}
                                  <span className="fw-medium">
                                    {invoiveDetails?.invoice?.noOfJobPort}
                                  </span>
                                </>
                              ) : (
                                <>
                                  No Of Services :{" "}
                                  <span className="fw-medium">
                                    {invoiveDetails?.invoice?.noOfServices}
                                  </span>
                                </>
                              )}
                              <li className="list-inline-item mx-1">
                                Validity{" "}
                                <span className="fw-medium mx-1">30 days</span>
                              </li>
                            </ul>
                          </td>

                          <td>
                            {invoiveDetails?.invoice?.packageType === 0 ? (
                              <>Find Resume</>
                            ) : invoiveDetails?.invoice?.packageType === 1 ? (
                              <>Job Post</>
                            ) : (
                              <>Recruit With Support</>
                            )}
                          </td>
                          <td>30PCKG</td>
                          <td>{invoiveDetails?.invoice?.price}</td>
                          <td className="text-end">
                            {invoiveDetails?.invoice?.price}
                          </td>
                        </tr>

                        <tr>
                          <th colSpan="5" className="text-end">
                            Sub Total :
                          </th>
                          <td className="text-end">
                            {invoiveDetails?.invoice?.price}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="5" className="border-0 text-end">
                            GST :
                          </th>
                          <td className="border-0 text-end">
                            {invoiveDetails?.invoice?.gst}
                          </td>
                        </tr>

                        <tr>
                          <th colSpan="5" className="border-0 text-end">
                            Convenience Fee :
                          </th>
                          <td className="border-0 text-end">
                            {invoiveDetails?.invoice?.conveniencefee}
                          </td>
                        </tr>

                        <tr>
                          <th colSpan="5" className="border-0 text-end">
                            Total :
                          </th>
                          <td className="border-0 text-end">
                            <h4 className="m-0">
                              {invoiveDetails?.invoice?.payment_method === 0 ? invoiveDetails?.invoice?.subtotal : invoiveDetails?.invoice?.price}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-print-none mt-4">
                    <div className="float-end">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          handlePrint();
                        }}
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Modal>

      <Modal show={singleView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Subscription Details</h5>
          <button
            type="button"
            onClick={() => {
              setsingleView(false);
              setinvoiveDetails([]);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <table className="table table-bordered datatable">
            <tr>
              <td style={{ padding: "10px" }}>Institute name: </td>
              <td>{singleViewDetials?.institutes?.inst_name}</td>
              <td style={{ padding: "10px" }}> Package Id: </td>
              <td>{invoiveDetails?.invoice?.packageId}</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Date: </td>
              <td>{moment(singleViewDetials.date).format("DD-MM-YYYY")}</td>
              <td style={{ padding: "10px" }}>Package Type: </td>
              {singleViewDetials.packageTypes == 0 && <td>Find Resume</td>}
              {singleViewDetials.packageTypes == 1 && <td>Job Post</td>}
              {singleViewDetials.packageTypes == 2 && <td>Recruit With Support</td>}
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Package Name: </td>
              <td>{singleViewDetials?.packageNames?.packageName}</td>
              <td style={{ padding: "10px" }}>Amount:</td>
              <td>{singleViewDetials?.amount}</td>
            </tr>
            <tr>
              {singleViewDetials.packageTypes == 0 && (
                <td style={{ padding: "10px" }}>No of Resume:</td>
              )}
              {singleViewDetials.packageTypes == 1 && (
                <td style={{ padding: "10px" }}>No of Job Post:</td>
              )}
              {singleViewDetials.packageTypes == 2 && (
                <td style={{ padding: "10px" }}>No of Services:</td>
              )}

              {singleViewDetials.packageTypes == 0 && (
                <td style={{ padding: "10px" }}>
                  {singleViewDetials?.noOfResume}
                </td>
              )}
              {singleViewDetials.packageTypes == 1 && (
                <td style={{ padding: "10px" }}>
                  {singleViewDetials?.noOfJobPort}
                </td>
              )}
              {singleViewDetials.packageTypes == 2 && (
                <td style={{ padding: "10px" }}>
                  {singleViewDetials?.noOfServices}
                </td>
              )}

              <td style={{ padding: "10px" }}>Validity:</td>
              <td>{singleViewDetials?.validity} days</td>
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Expiry Date:</td>
              <td>{singleViewDetials?.expiryDate}</td>
              <td style={{ padding: "10px" }}>Payment status:</td>

              {singleViewDetials.payment == 0 && <td>UnPaid</td>}
              {singleViewDetials.payment == 1 && <td>Paid</td>}
            </tr>
            <tr>
              <td style={{ padding: "10px" }}>Invoice Number:</td>
              <td>{invoiveDetails?.invoice?.invoiceNo}</td>
              <td style={{ padding: "10px" }}>Subscription status:</td>
              {singleViewDetials.status == 0 && <td>Active</td>}
              {singleViewDetials.status == 1 && <td>Inactive</td>}
              {singleViewDetials.status == 2 && <td>Expired</td>}
            </tr>
          </table>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Add Subscriptions" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Institute</Label>
                          <Select
                            name="institutes"
                            classNamePrefix="select2-selection"
                            value={selectedInstitute}
                            options={instituteOption}
                            onChange={(selected) => {
                              selectChange(selected, "institutes");
                            }}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedInstitute === null
                              ? errorsSub.instituteError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Package Type</Label>
                          <Select
                            name="packageTypes"
                            classNamePrefix="select2-selection"
                            value={selectPackageType}
                            options={[
                              { label: "Find Resume", value: 0 },
                              { label: "Job Post", value: 1 },
                              { label: "Recruit With Support", value: 2 },
                            ]}
                            onChange={(selected) => {
                              selectChange(selected, "packageTypes");
                            }}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectPackageType === null
                              ? errorsSub.packageTypeError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      {display === 0 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Subject</Label>
                            <Select
                              name="subject"
                              value={selectedSubject}
                              options={subjectOptions}
                              onChange={(selected) => {
                                selectChange(selected, "subject");
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Payment Method</Label>
                          <Select
                            name="pay_method"
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "Cash", value: 0 },
                              { label: "Google Pay", value: 1 }
                            ]}
                            value={selectedMethod}
                            onChange={(selected) => {
                              selectChange(selected, "pay_method");
                            }}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedMethod === null
                              ? errorsSub.packageError
                              : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Package</Label>
                          <Select
                            name="packageNames"
                            classNamePrefix="select2-selection"
                            value={selecetedpackage}
                            options={packageoptions}
                            onChange={(selected) => {
                              selectChange(selected, "packageNames");
                            }}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selecetedpackage === null
                              ? errorsSub.packageError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      {display === 0 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>No Of Resume</Label>
                            <AvField
                              name="noOfResume"
                              readOnly
                              value={masterObj?.noOfResume}
                              placeholder="No Of Resume"
                            />
                          </div>
                        </Col>
                      )}

                      {display === 1 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>No Of Job Post</Label>
                            <AvField
                              name="noOfJobPort"
                              readOnly
                              value={masterObj?.noOfJobPort}
                              placeholder="No Of Job Post"
                            />
                          </div>
                        </Col>
                      )}
                      {display === 2 && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>No Of Services</Label>
                            <AvField
                              name="noOfJobPort"
                              readOnly
                              value={masterObj?.noOfServices}
                              placeholder="No Of Services"
                            />
                          </div>
                        </Col>
                      )}


                      <Col md="2">
                        <div className="mb-3">
                          <Label>Validity</Label>
                          <AvField
                            name="validity"
                            readOnly
                            value={masterObj?.validity}
                            placeholder="Validity"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Amount</Label>
                          <AvField
                            name="amount"
                            value={masterObj?.amount}
                            onChange={handleDataChange}
                            // validate={{ required: { value: true } }}
                            type="number"
                            placeholder="Amount"
                            readOnly={true}
                          />
                        </div>
                      </Col>
                      {selectedMethod?.value === 0 ? (
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Convenience fee</Label>
                            <AvField
                              readOnly={true}
                              type="number"
                              name="conveniencefee"
                              placeholder="convenience fee"
                              // onChange={(e) => handleChange(e)}
                              value={masterObj?.conveniencefee}
                            />
                          </div>
                        </Col>
                      ) : ""}

                      {selectedMethod?.value === 0 ? (
                        <Col md="2">
                          <div className="mb-3">
                            <Label>GST</Label>
                            <AvField
                              readOnly={true}
                              type="number"
                              name="conveniencefee"
                              placeholder="GST"
                              // onChange={(e) => handleChange(e)}
                              value={masterObj?.gst}
                            />
                          </div>
                        </Col>
                      ) : ""}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Total</Label>
                          <AvField
                            name="total"
                            type="number"
                            placeholder="Total"
                            onChange={(e) => handleChange(e)}
                            value={selectedMethod?.value === 0 ? masterObj?.total : masterObj?.amount}
                            errorMessage="Enter Total Amount"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div
                          className="mb-3 mt-4"
                          style={{ paddingTop: "5px", display: "flex" }}
                        >
                          {subscriptionIdToBeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              update
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Submit
                            </Button>
                          )}
                          <Button
                            onClick={() => reset()}
                            color="danger"
                            type="reset"
                            className="mx-3"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <AvForm>
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Package Type</Label>
                            <Select
                              name="packageTypes"
                              classNamePrefix="select2-selection"
                              value={selectedType}
                              options={[
                                { label: "Find Resume", value: 0 },
                                { label: "Job Post", value: 1 },
                                { label: "Recruit With Support", value: 2 },
                              ]}
                              onChange={(selected) => {
                                selectChange(selected, "packageTypesfilter");
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Package Name</Label>
                            <Select
                              value={selectedName}
                              options={packageoptions}
                              onChange={(selected) => {
                                selectChange(selected, "packagnamefiletr");
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>From Date</Label>
                            <AvField
                              name="from_date"
                              type="date"
                              onChange={(e) => {
                                handleSearchDate(e);
                              }}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>To Date</Label>
                            <AvField
                              name="to_date"
                              type="date"
                              onChange={(e) => {
                                handleSearchDate(e);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </Row>
                  <Row>
                    <MDBDataTable
                      id="subscriptionstable"
                      responsive
                      bordered
                      data={data}
                      paging={false}
                      info={false}
                      disableRetreatAfterSorting={true}
                    />
                    {totalPages > 1 && (
                      <div
                        className="pagination-div"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Pagination
                          color="standard"
                          shape="rounded"
                          defaultPage={1}
                          count={Math.ceil(totalPages / 15)}
                          page={currentPage}
                          onChange={paginate}
                          size="large"
                        />
                      </div>
                    )}
                  </Row>
                  <div style={{ display: "none" }}>
                    <Printable ref={printRef} data={invoiveDetails} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Subscriptions;
