import moment from "moment";

export const getDate = (date) => {
  if (!date) {
    return null;
  }
  let dateValue =
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " 00:00:00.000";

  return moment(dateValue).format("YYYY-MM-DD");
};
export const getFirstday = (date) => {
  if (!date) {
    return null;
  }
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return moment(firstDay).format("YYYY-MM-DD");
};
export const getMonth = (date) => {
  if (!date) {
    return null;
  }
  let dateValue =
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " 00:00:00.000";

  return moment(dateValue).format("YYYY-MM");
};
const pad = (number) => {
  if (number < 10) {
    return "0" + number;
  }
  return number;
};
export const getDateTime = (date) => {
  if (!date) {
    return null;
  }

  let seconds =
    pad(String(date.getMilliseconds()).padStart(3, "0")).length > 3
      ? pad(String(date.getMilliseconds()).padStart(2, "0"))
      : pad(String(date.getMilliseconds()).padStart(3, "0"));
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    ":" +
    seconds
  );
};

export const setCompany = (companyID) => {
  localStorage.setItem("companyID", companyID);
};
export const getCompany = () => {
  return localStorage.getItem("companyID");
};

export const setLocalbody = (localbodyID) => {
  localStorage.setItem("localbodyID", localbodyID);
};
export const getLocalbody = () => {
  return localStorage.getItem("localbodyID");
};
export const getRouteName = (props) => {
  let path = props.location.pathname;
  var res = path.split("/");
  return res[1];
};
export const getRouteLength = (props) => {
  let path = props.location.pathname;
  var res = path.split("/");
  let length = res.length;
  return length;
};
export const getArraySumByKey = (arr, keyName) =>
  arr
    .map((item) => parseFloat(item[keyName] ? item[keyName] : 0))
    .reduce((a, b) => parseFloat(a ? a : 0) + parseFloat(b ? b : 0), 0);

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    if (amount === "-" || amount === "+") amount = amount + "0";

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {}
};

export const getDateAndTime = (date) => {
  if (!date) {
    return null;
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes())
  );
};
export const setAssignCust = (customer) => {
  localStorage.setItem("customer", customer);
};
export const getAssignCust = () => {
  return localStorage.getItem("customer");
};
export const setMyObject = (myObject) => {
  localStorage.setItem("myObject", JSON.stringify(myObject));
};
export const getMyObject = () => {
  return localStorage.getItem("myObject");
};

export const setMasterBranch = (branchID) => {
  localStorage.setItem("branchID", branchID);
};
export const getMasterBranch = () => {
  return localStorage.getItem("branchID");
};
export const getCurrentYear = (date) => {
  if (!date) {
    return null;
  }
  return (
    date.getFullYear()
  );
};

// export const setUserimage = (userImage) => {
//   localStorage.setItem("userImage", userImage);
// };
// export const getUserImage = () => {
//   return localStorage.getItem("userImage");
// };