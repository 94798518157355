import React, { useState, useEffect } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Button, Label, Table } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Select from "react-select";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import toastr from "toastr";
import Pagination from "@mui/material/Pagination";
import { CSVLink } from "react-csv";



function TeacherDetails() {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [masterObj, setmasterObj] = useState({ start: 1 });
  const [tableDetails, settableDetails] = useState([]);
  const [instituteOptions, setInstituteOptions] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [selectedTeacher, setselectedTeacher] = useState(null);
  const [tecaherOption, settecaherOption] = useState([]);
  const [selectedPayment, setselectedPayment] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);

  useEffect(() => {
    fetchAllTeachers();
  }, [masterObj]);

  useEffect(() => {
    fetchAllAppointed();
    fetch_all_institute();
  }, [masterObj]);

  function fetchAllTeachers() {
    axios
      .get(`${API_URL}candidate/appointed/teachers-options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let techOpt =
          result &&
          result.map((item, index) => {
            let exportItem = {};
            return {
              label: item.placed_cand_name,
              value: item.placed_cand_id,
            };
          });
        settecaherOption(techOpt);
      });
  }

  const fetch_all_institute = () => {
    axios
      .get(`${API_URL}institute/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let institute_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.inst_reg_no + "-" + el?.inst_name,
              value: el?._id,
              whatsapp: el?.inst_whatsapp_no,
              displayLabel: el?.inst_name,
            };
          });
        setInstituteOptions([
          {
            options: institute_data,
          },
        ]);
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Institute",
        field: "instname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Teacher",
        field: "techername",
        sort: "asc",
        width: 150,
      },
      {
        label: "Joining Date",
        field: "joindate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Salary",
        field: "salary",
        sort: "asc",
        width: 150,
      },
      {
        label: "Charges",
        field: "service_charge",
        sort: "asc",
        width: 150,
      },
      {
        label: "Payment",
        field: "payment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Remark",
        field: "remarkfield",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableDetails,
  };

  function handledate(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObj({ ...masterObj, [name]: value });
  }

  function hnadletime(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObj({ ...masterObj, [name]: value });
  }

  function handlechangeStatus(item, num) {
    if (num == 0) {
      axios
        .post(
          `${API_URL}candidate/appointed/teachers/paymentstatus`,
          {
            type: 1,
            _id: item,
            value: 1,
          },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          toastr.success("payment status changed to Paid");
          fetchAllAppointed();
        });
    } else if (num == 1) {
      axios
        .post(
          `${API_URL}candidate/appointed/teachers/paymentstatus`,
          {
            type: 1,
            _id: item,
            value: 2,
          },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          toastr.success("payment status changed to Due");
          fetchAllAppointed();
        });
    } else if (num == 2) {
      axios
        .post(
          `${API_URL}candidate/appointed/teachers/paymentstatus`,
          {
            type: 1,
            _id: item,
            value: 0,
          },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          toastr.success("payment status changed to Pending");
          fetchAllAppointed();
        });
    }
  }

  function handleBlurRemark(id, e) {
    let data = e.target.value;
    axios
      .post(
        `${API_URL}candidate/appointed/teachers/paymentstatus`,
        {
          _id: id,
          type: 2,
          value: data,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
      });
  }

  function fetchAllAppointed() {
    axios
      .get(
        `${API_URL}candidate/appointed/teachers?from_date=${masterObj?.from_date}&to_date=${masterObj?.to_date}&institute_id=${masterObj?.institute_id}&teacher_id=${masterObj?.teacher_id}&start=${1}&limit=${15}&joining_from=${masterObj?.joining_from}&joining_to=${masterObj?.joining_to}&payment=${masterObj?.payment}&from_time=${masterObj?.from_time}&to_time=${masterObj?.to_time}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res?.data?.data;
        let dataToBeExported = [];
        setTotalPages(res.data.count);
        let data = [];
        result?.map((item, index) => {
          let exportItem = {};
          item.id = index + 1;
          item.joindate = moment(item?.joining_date).format("DD-MM-YYYY");
          item.instname = (
            <div>
              <a
                style={{ cursor: "pointer" }}
                target="blank"
                href={"/institute/" + item?.institute_regno}
              >
                {item?.institute_name}
              </a>
            </div>
          );

          item.techername = (
            <div>
              <a
                style={{ cursor: "pointer" }}
                target="blank"
                href={"/candidate/" + item?.teacher_regno}
              >
                {item?.teacher_name}
              </a>
            </div>
          );
          item.date1 = moment(item.appointed_date).format("DD-MM-YYYY");
          item.time1 = moment(item.appointed_time, "HH:mm s").format("hh:mm a");
          item.remarkfield = (
            <div>
              <AvForm>
                <AvField
                  rows="1"
                  name="remarks"
                  type="textarea"
                  value={item.remarks}
                  onBlur={(e) => {
                    handleBlurRemark(item._id, e);
                  }}
                />
              </AvForm>
            </div>
          );
          if (item.payment_status == 1) {
            item.payment = (
              <div>
                <Button
                  onClick={() => {
                    handlechangeStatus(item._id, 1);
                  }}
                  style={{
                    padding: "0px 5px",
                    width: "50px",
                    fontSize: ".7rem",
                  }}
                  color="success"
                >
                  Paid
                </Button>
              </div>
            );
          } else if (item.payment_status == 0) {
            item.payment = (
              <div>
                <Button
                  onClick={() => {
                    handlechangeStatus(item._id, 0);
                  }}
                  style={{
                    padding: "0px 5px",
                    width: "50px",
                    fontSize: ".7rem",
                  }}
                  color="warning"
                >
                  Pending
                </Button>
              </div>
            );
          } else if (item.payment_status == 2) {
            item.payment = (
              <div>
                <Button
                  onClick={() => {
                    handlechangeStatus(item._id, 2);
                  }}
                  style={{
                    padding: "0px 5px",
                    width: "50px",
                    fontSize: ".7rem",
                  }}
                  color="danger"
                >
                  Due
                </Button>
              </div>
            );
          }
          data.push(item);
          exportItem.Id = item.id;
          exportItem.AppintedDate = item.date1;
          exportItem.AppointedTime = item.time1;
          exportItem.Institute = item.institute_name;
          exportItem.Candidate = item.teacher_name;
          exportItem.JoiningDate = item.joindate;
          exportItem.Salary = item.salary;
          exportItem.Charges = item.service_charge;
          if (item.payment_status == 0) {
            exportItem.PaymentStatus = "Pending"
          }
          if (item.payment_status == 1) {
            exportItem.PaymentStatus = "Paid"
          }
          if (item.payment_status == 2) {
            exportItem.PaymentStatus = "Due"
          }
          exportItem.Remarks = item.remarks;


          dataToBeExported.push(exportItem);
        });
        settableDetails(data);
        setDataToBeExported(dataToBeExported);
      });
  }

  function handleChange(selected, name) {
    switch (name) {
      case "institute":
        setSelectedInstitute(selected);
        setmasterObj({ ...masterObj, institute_id: selected.value });
        break;
      case "teacher":
        setselectedTeacher(selected);
        setmasterObj({ ...masterObj, teacher_id: selected.value });
        break;
      case "payment":
        setselectedPayment(selected);
        setmasterObj({ ...masterObj, payment: selected.value });
        break;
      default:
        break;
    }
  }


  function paginate(event, value) {
    setmasterObj({ ...masterObj, start: value });
    setCurrentPage(value);
  }

  function handleApiCall() {
    axios.post(`${API_URL}institute/log/creation`, { type: 1 }, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
    })
  }

  const reset = () => {
    setSelectedInstitute(null)
    setselectedTeacher(null)
    setselectedPayment(null)
    fetchAllAppointed()
    setmasterObj({
      from_date: "",
      to_date: "",
      from_time: "",
      to_time: "",
      joining_from: "",
      joining_to: ""
    })
  }


  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Appointed Teachers" />
          <Row>
            <Card>
              <CardBody>
                <Row>
                  <Col md="12">
                    <AvForm>
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Institute </Label>
                            <Select
                              name="institute"
                              value={selectedInstitute}
                              onChange={(selected) =>
                                handleChange(selected, "institute")
                              }
                              options={instituteOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="2">
                          <div className="mb-3">
                            <Label>Teacher </Label>
                            <Select
                              name="teacher"
                              value={selectedTeacher}
                              onChange={(selected) =>
                                handleChange(selected, "teacher")
                              }
                              options={tecaherOption}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>From Date</Label>
                            <AvField
                              type="date"
                              name="from_date"
                              onChange={handledate}
                              value={masterObj?.from_date}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>To Date</Label>
                            <AvField
                              type="date"
                              name="to_date"
                              onChange={handledate}
                              value={masterObj?.to_date}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>From Time</Label>
                            <AvField
                              name="from_time"
                              type="time"
                              onChange={(e) => {
                                hnadletime(e);
                              }}
                              value={masterObj?.from_time}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>To Time</Label>
                            <AvField
                              name="to_time"
                              type="time"
                              onChange={(e) => {
                                hnadletime(e);
                              }}
                              value={masterObj?.to_time}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Joining Date from</Label>
                            <AvField
                              name="joining_from"
                              type="date"
                              onChange={(e) => {
                                hnadletime(e);
                              }}
                              value={masterObj?.joining_from}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Joining Date to</Label>
                            <AvField
                              name="joining_to"
                              type="date"
                              onChange={(e) => {
                                hnadletime(e);
                              }}
                              value={masterObj?.joining_to}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Payment Status</Label>
                            <Select
                              rows={1}
                              options={[
                                { label: "Paid", value: 1 },
                                { label: "Pending", value: 0 },
                                { label: "Due", value: 2 },
                              ]}
                              onChange={(selected) =>
                                handleChange(selected, "payment")
                              }
                              value={selectedPayment}
                            />
                          </div>
                        </Col>
                        <Col md="1">
                          <div className="mt-4" style={{ paddingTop: "6px", display: "flex" }} >
                            <Button onClick={handleApiCall} color="success" type="">
                              <CSVLink
                                data={dataToBeExported}
                                filename={"Appointedteacher.xls"}
                                style={{ color: "white" }}
                              >
                                Export
                              </CSVLink>
                            </Button>

                            <Button color="danger" type="submit" onClick={() => reset()} style={{ marginLeft: "25px" }}>Reset</Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="12">
                    <MDBDataTable
                      id="subscriptionstable"
                      responsive
                      bordered
                      data={data}
                      disableRetreatAfterSorting={true}
                      paging={false}
                    />
                    {totalPages > 1 && (
                      <div
                        className="pagination-div"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Pagination
                          color="standard"
                          shape="rounded"
                          defaultPage={1}
                          count={Math.ceil(totalPages / 15)}
                          page={currentPage}
                          onChange={paginate}
                          size="large"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </>
  );
}
export default TeacherDetails;
