import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./management.scss";

const AddManagement = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [managementIdTobeUpdated, setManagementIdTobeUpdated] = useState(null);
  const [managementIdTobeDeleted, setManagementIdTobeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  useEffect(() => {
    handleTableData();
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preUpdateManagement = (item) => {
    if (item?.name) {
      setMaster({
        name: item.name,
        management_id: item._id,
      });
      setManagementIdTobeUpdated(item?._id);
    }
  };
  const handleTableData = () => {
    axios
      .get(`${API_URL}management/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            if (item?.mgmtup_date)
              item.date = moment(item?.mgmtup_date).format("DD-MM-YYYY");
            else item.date = moment(item?.mgmt_date).format("DD-MM-YYYY");
            if (item?.mgmtup_time)
              item.time = moment(item?.mgmtup_time, "hhmmss").format("hh:mm a");
            // if (item?.mgmt_time)
            else
              item.time = moment(item?.mgmt_time, "hhmmss").format("hh:mm a");

            if (item?.mgmt_updatedby) {
              let updatedBy = item.mgmt_updatedby || {};
              if (updatedBy?.lastName)
                item.staff = updatedBy?.firstName + " " + updatedBy?.lastName;
              else item.staff = updatedBy?.firstName;
            } else {
              let addedBy = item.mgmt_addedby || {};
              if (addedBy?.lastName)
                item.staff = addedBy?.firstName + " " + addedBy?.lastName;
              else item.staff = addedBy?.firstName;
            }
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="uil-edit-alt"
                  style={{
                    fontSize: "1.2em",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateManagement(item);
                  }}
                ></i>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  onClick={() => {
                    setManagementIdTobeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },

      {
        label: "Management Type",
        field: "name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {
    if (managementIdTobeUpdated) {
      axios
        .put(`${API_URL}management`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Management type updated successfully");
            setMaster({});
            handleTableData();
            setManagementIdTobeUpdated(null);
          } else {
            toastr.error("Failed to update management type");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}management`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            formRef.current.reset();
            toastr.success("Management type added successfully");
            setMaster({});
            handleTableData();
          } else {
            toastr.error("Failed to add management type");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  return (
    <>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}management` + "/" + managementIdTobeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Management type deleted successfully");
                  if (
                    master &&
                    master.management_id === managementIdTobeDeleted
                  ) {
                    formRef.current.reset();
                    setMaster({});
                    setManagementIdTobeUpdated(null);
                  }
                  setManagementIdTobeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(
                    res.data.message,
                    "Failed to delete management type"
                  );
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Management Type" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleValidSubmit();
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Management Type
                          </Label>
                          <AvField
                            name="name"
                            placeholder="Management Type"
                            type="text"
                            errorMessage="Enter Management Type"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          {managementIdTobeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="managementTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddManagement;
