import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Table } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import {
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import $ from "jquery";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import "./savedApplication.scss";
import Pagination from "@mui/material/Pagination";
const SavedApplication = (props) => {
  const [searchData, setSearchData] = useState({
    // from_date: getDate(new Date()),
    // to_date: getDate(new Date()),
  });
  const [details, setDetails] = useState([]);
  const [instituteOptions, setInstituteOptions] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [candidateOptions, setCandidateOptions] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [jobpostOptions, setJobpostOptions] = useState([]);
  const [selectedJobpost, setSelectedJobpost] = useState(null);
  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  /** pagination */
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
    currentPage * PerPageCount
  );

  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
    indexOfLastTransfer - PerPageCount
  );
  const paginate = (event, value) => {
    setCurrentPage(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);

    handleTableData(searchValue, searchData?.from_date, searchData?.to_date, selectedCandidate?.value, selectedJobpost?.value, selectedInstitute?.value, indexOfFirstTransfer, indexOfLastTransfer);
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  }
  const handleSearch = (value) => {
    setSearchValue(value)
    resetPaginate();
    handleTableData(value, searchData?.from_date, searchData?.to_date, selectedCandidate?.value, selectedJobpost?.value, selectedInstitute?.value);
  }
  useEffect(() => {
    if (details.length == 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData(searchValue, searchData?.from_date, searchData?.to_date, selectedCandidate?.value, selectedJobpost?.value, selectedInstitute?.value);
    }

  }, [details])
  /** end pagination */

  useEffect(() => {
    handleTableData(searchValue);
    fetch_all_institute();
    fetch_candidate();
  }, []);

  function handleTableData(
    search = "",
    fromDate = "",
    toDate = "",
    candidate_id = "",
    job_id = "",
    institute_id = "",
    start = 0,
    end = 20
  ) {
    var url = `${API_URL}saved/list`;
    if (fromDate === "" && toDate === "") {
      // fromDate = fromDate ? fromDate : getDate(new Date());
      // toDate = toDate ? toDate : getDate(new Date());
    }
    url =
      url +
      "?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate +
      "&candidate_id=" +
      candidate_id +
      "&job_id=" +
      job_id +
      "&institute_id=" +
      institute_id + "&search=" + search + "&start=" + start + "&end=" + end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPages(res.data?.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.saved_date).format("DD-MM-YYYY");

            item.cand_phone = item?.saved_cand_phone ? item?.saved_cand_phone : "No Job data";
            item.jobName =
              item?.saved_job_name ? item?.saved_job_name + " " + "(" + item?.saved_job_reg_no + ")" : "No Job data";
            item.reg_no =
              item?.saved_reg_no ? item?.saved_reg_no : "No Job data";

            item.instName = (
              <div>
                {item?.saved_inst_name ? (
                  <a
                    target="blank"
                    style={{ cursor: "pointer" }}
                    href={"/institute/" + item?.saved_inst_reg_no}
                  >
                    {item?.saved_inst_name}
                  </a>
                ) : "No job data"}
              </div>
            )

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-eye"></i>
                </button>

                <button
                  onClick={() => {
                    window.open("/candidate/" + item?.saved_cand_reg_no);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-user"></i>
                </button>
              </div>
            );
          });
        setDetails(result);
      });
  }
  const fetch_all_institute = () => {
    axios
      .get(`${API_URL}institute/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let institute_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.inst_reg_no + "-" + el?.inst_name,
              value: el?._id,
            };
          });
        setInstituteOptions([
          {
            options: institute_data,
          },
        ]);
      });
  };
  const fetch_all_jobpost = (id) => {
    axios
      .get(`${API_URL}institute/jobname?institute_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let job_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.job_name,
              value: el?._id,
            };
          });
        setJobpostOptions([
          {
            options: job_data,
          },
        ]);
      });
  };
  const fetch_candidate = () => {
    axios
      .get(`${API_URL}candidate/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let candidate_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.cand_name,
              value: el?._id,
            };
          });
        setCandidateOptions([
          {
            options: candidate_data,
          },
        ]);
      });
  };
  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}saved?saved_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        // result.dob = moment(result?.saved_cand_dob).format("DD-MM-YYYY");
        result.date = moment(result?.saved_date).format("DD-MM-YYYY");
        result.time = moment(result?.saved_time, "hhmmss").format("hh:mm a");
        let jobDetails = result.job_details || {};
        result.class = "";
        jobDetails.job_classes &&
          jobDetails.job_classes.map((el) => {
            if (result.class === "") result.class = el;
            else result.class = result.class + "," + el;
          });
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        setPopupData(result);
        setPopupView(true);
      });
  };

  let handleDate = (e) => {
    if (e.target.name == "from_date") {
      var date1 = e.target.value;
      var date2 = searchData?.to_date;
    }
    else {
      var date1 = searchData?.from_date;
      var date2 = e.target.value;
    }
    let candidate_id = searchData?.candidate_id ? searchData.candidate_id : "";
    let job_id = searchData?.job_id ? searchData.job_id : "";
    let institute_id = searchData?.institute_id ? searchData.institute_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");
    resetPaginate();
    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(searchValue, date1, date2, candidate_id, job_id, institute_id);
  };
  const reset = () => {
    setSearchData({
      // from_date: getDate(new Date()),
      // to_date: getDate(new Date()),
    });
    setSelectedCandidate(null);
    setSelectedInstitute(null);
    setSelectedJobpost(null);
    setJobpostOptions([]);
    handleTableData(searchValue);
    resetPaginate();
  };
  const handleSelectChange = (selected, name) => {
    let fromDate = searchData?.from_date ? searchData.from_date : "";
    let toDate = searchData?.to_date ? searchData.to_date : "";
    let candidate_id = searchData?.candidate_id ? searchData.candidate_id : "";
    let job_id = searchData?.job_id ? searchData.job_id : "";
    let institute_id = searchData?.institute_id ? searchData.institute_id : "";
    resetPaginate();
    switch (name) {
      case "institute":
        setSelectedInstitute(selected);
        setSelectedJobpost(null);
        setSearchData({
          ...searchData,
          institute_id: selected.value,
          job_id: "",
        });
        fetch_all_jobpost(selected.value);
        handleTableData(searchValue, fromDate, toDate, candidate_id, "", selected.value);
        break;
      case "jobPost":
        setSelectedJobpost(selected);
        setSearchData({
          ...searchData,
          job_id: selected.value,
        });
        handleTableData(
          searchValue,
          fromDate,
          toDate,
          candidate_id,
          selected.value,
          institute_id
        );
        break;
      case "candidate":
        setSelectedCandidate(selected);
        setSearchData({
          ...searchData,
          candidate_id: selected.value,
        });
        handleTableData(searchValue, fromDate, toDate, selected.value, job_id, institute_id);
        break;
      default:
        break;
    }
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "ID",
        field: "reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Job Name",
        field: "jobName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Institute",
        field: "instName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Applicant",
        field: "saved_cand_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "cand_phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };


  return (
    <React.Fragment>
      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Saved Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupData?.saved_job_name ? popupData?.saved_job_name : "No Job data"}</td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.saved_job_reg_no ? popupData?.saved_job_reg_no : "No Job data"}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Saved Date :</td>
                <td>{popupData?.date ? popupData?.date : "No Job data"}</td>
                <td style={{ paddingLeft: "25px" }}>Saved Time :</td>
                <td style={{ textAlign: "left" }}>{popupData?.time ? popupData?.time : "No Job data"}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupData?.state ? popupData?.state : "No Job data"}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>{popupData?.district ? popupData?.district : "No Job data"}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Subject :</td>
                <td>{popupData?.subject ? popupData?.subject : "No Job data"}</td>
                <td style={{ paddingLeft: "25px" }}>Experience :</td>
                <td style={{ textAlign: "left" }}>{popupData?.exp ? popupData?.exp : "No Job data"}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Class :</td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}>
                  {popupData?.class ? popupData?.class : "No Job data"}
                </td>
                <td style={{ paddingLeft: "25px" }}>Qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupData?.qualification ? popupData?.qualification : "No Job data"}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Applicant :</td>
                <td>{popupData?.saved_cand_name ? popupData?.saved_cand_name : "No Job data"}</td>
                <td style={{ paddingLeft: "25px" }}>{ }</td>
                <td style={{ textAlign: "left" }}>{ }</td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Saved Applications" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={searchData?.from_date}
                            onChange={handleDate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={searchData?.to_date}
                            onChange={handleDate}
                            min={searchData?.from_date}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Institute</Label>
                          <Select
                            name="institute"
                            value={selectedInstitute}
                            onChange={(selected) => {
                              handleSelectChange(selected, "institute");
                            }}
                            options={instituteOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Job Post</Label>
                          <Select
                            name="job_post"
                            value={selectedJobpost}
                            onChange={(selected) => {
                              handleSelectChange(selected, "jobPost");
                            }}
                            options={jobpostOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Candidate</Label>
                          <Select
                            name="candidate"
                            value={selectedCandidate}
                            onChange={(selected) => {
                              handleSelectChange(selected, "candidate");
                            }}
                            options={candidateOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4 mb-2">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <MDBDataTable
                        id="saveApplicationTableID"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={false}
                        disableRetreatAfterSorting={true}
                        paging={false}
                        onSearch={(value) => { handleSearch(value) }}
                        entries={PerPageCount}
                      />
                      {totalPages > 20 && (
                        <div className="pagination-div">
                          <div className="page-details">
                            Showing {indexOfFirstTransfer + 1} to {indexOfFirstTransfer + details.length} of {totalPages} entries
                          </div>
                          <Pagination
                            color="standard"
                            shape="rounded"
                            defaultPage={1}
                            count={Math.ceil(totalPages / PerPageCount)}
                            page={currentPage}
                            onChange={paginate}
                            size="large"
                          />
                        </div>
                      )}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SavedApplication;
