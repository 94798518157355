import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
} from "reactstrap";
import {
    getDate,
    getFirstday
} from "../../helpers/globalFunctions";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Select from "react-select";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import "./style.scss";
const FollowupReport = () => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [followupData, setFollowupData] = useState([]);
    const [searchData, setSearchData] = useState({
        from_date: getFirstday(new Date()),
        to_date: getDate(new Date()),
    });
    const fetch_data = (from_date = getFirstday(new Date()), to_date = getDate(new Date())) => {
        axios
            .get(`${API_URL}followup/report?&from_date=${from_date}&to_date=${to_date}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                setFollowupData(res.data.data);
            });
    }
    const data = {
        columns: [
            {
                label: "Staff",
                field: "added_by",
                sort: "asc",
                width: 150,
            },
            {
                label: "WILLING",
                field: "status1",
                sort: "asc",
                width: 150,
            },
            {
                label: "APPLIED",
                field: "status2",
                sort: "asc",
                width: 150,
            },
            {
                label: "APPOINTED",
                field: "status3",
                sort: "asc",
                width: 150,
            }, {
                label: "LOCAL",
                field: "status4",
                sort: "asc",
                width: 150,
            }, {
                label: "ONLINE",
                field: "status5",
                sort: "asc",
                width: 150,
            }, {
                label: "CANCELLED",
                field: "status6",
                sort: "asc",
                width: 150,
            }, {
                label: "PENDING",
                field: "status7",
                sort: "asc",
                width: 150,
            }, {
                label: "NOT AVAILABLE",
                field: "status8",
                sort: "asc",
                width: 150,
            }, {
                label: "UNDER PROCESS",
                field: "status9",
                sort: "asc",
                width: 150,
            },
            {
                label: "TOTAL",
                field: "total",
                sort: "asc",
                width: 150,
            },

        ],
        rows: followupData,
    };
    const handleFollowFilter = (selected, type) => {
        switch (type) {
            case "from_date":
                if (selected.target.value > searchData?.to_date)
                    var to_date = selected.target.value;
                else
                    var to_date = searchData?.to_date;
                setSearchData({ ...searchData, "from_date": selected.target.value, "to_date": to_date });
                fetch_data(selected.target.value, to_date)
                break;
            case "to_date":
                if (selected.target.value < searchData?.from_date)
                    var from_date = selected.target.value;
                else
                    var from_date = searchData?.from_date;
                setSearchData({ ...searchData, "to_date": selected.target.value, "from_date": from_date });
                fetch_data(from_date, selected.target.value)
                break;
        }

    }
    const resetFollowupFilter = (value) => {
        fetch_data();
        setSearchData({
            from_date: getFirstday(new Date()),
            to_date: getDate(new Date()),
        });
    }
    useEffect(() => {
        fetch_data();
    }, []);
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Followup Report" />
                    <Row>
                        <Col md="12">
                            <Card><CardBody>
                                <Row className="mb-2">
                                    <Col md="2">
                                        <div>
                                            <Label htmlFor="validationCustom05">From</Label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                id="from_date"
                                                name="from_date"
                                                value={searchData?.from_date}
                                                onChange={(e) => {
                                                    handleFollowFilter(e, "from_date");
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div >
                                            <Label htmlFor="validationCustom05">To</Label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                id="to_date"
                                                name="to_date"
                                                value={searchData?.to_date}
                                                onChange={(e) => {
                                                    handleFollowFilter(e, "to_date");
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="1" style={{ marginTop: "2%" }}>
                                        <div className="mb-3">

                                            <Button color="danger" type="reset" onClick={resetFollowupFilter}>
                                                {"Reset"}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <MDBDataTable
                                    id="followupReport"
                                    responsive
                                    bordered
                                    data={data}
                                    searching={true}
                                    disableRetreatAfterSorting={true}
                                    entries={25}
                                />
                            </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment >
    )

}
export default FollowupReport;