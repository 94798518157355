import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import SalesAnalyticsChart from "./salesanalytics-chart";
import TopUser from "./topuser";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import CountUp from 'react-countup';
import ReactApexChart from "react-apexcharts"
const series1 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
  },
];

const options1 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};

const series2 = [70];

const options2 = {
  fill: {
    colors: ["#34c38f"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const series3 = [55];

const options3 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const series4 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
  },
];

const options4 = {
  fill: {
    colors: ["#f1b44c"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};
const options5 = {
  fill: {
    colors: ["#49a70ff2"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};

const Dashboard = () => {
  const [totalApplications, setTotalApplications] = useState(null);
  const [totalJobpost, setTotalJobpost] = useState(null);
  const [totalCandidates, setTotalCandidates] = useState(null);
  const [totalPlaced, setTotalPlaced] = useState(null);
  const [totalManagement, setTotalManagement] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    fetch_data();
  }, []);

  const fetch_data = () => {
    axios
      .get(`${API_URL}dashboard`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          let result = res.data;
          setTotalApplications(result?.Total_Applications);
          setTotalJobpost(result?.Total_Jobpost);
          setTotalCandidates(result?.Total_Candidates);
          setTotalPlaced(result?.Total_Placed);
          setTotalManagement(result?.Total_Management);
        }
      });
  };
  const reports = [
    {
      id: 1,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Applications",
      value: totalApplications,
      // prefix: "$",
      suffix: "",
      // badgeValue: "2.65%",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      // color: "success",
      // desc: "since last week",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      // icon: "mdi mdi-arrow-down-bold",
      title: "Job Post",
      value: totalJobpost,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      // badgeValue: "0.82%",
      // color: "danger",
      // desc: "since last week",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      // icon: "mdi mdi-arrow-down-bold",
      // icon: "mdi mdi-arrow-up-bold",
      title: "Candidates",
      value: totalCandidates,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      // badgeValue: "6.24%",
      // color: "danger",
      // color: "success",
      // desc: "since last week",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Placed",
      value: totalPlaced,
      // decimal: 2,
      // prefix: "+",
      // suffix: "%",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      // badgeValue: "10.51%",
      // color: "success",
      // desc: "since last week",
      series: series4,
      options: options4,
    },
    {
      id: 5,
      // icon: "mdi mdi-arrow-up-bold",
      title: "Management",
      value: totalManagement,
      // decimal: 2,
      // prefix: "+",
      // suffix: "%",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      // badgeValue: "10.51%",
      // color: "success",
      // desc: "since last week",
      series: series4,
      options: options5,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Indiana Global Teachers"
            breadcrumbItem="Dashboard"
          />
          <Row>
            {reports.map((report, key) => {
              if (key != 4) {
                return (
                  <Col md={6} xl={3} key={key}>
                    <Card>
                      <CardBody>
                        <div className="float-end mt-2">
                          <ReactApexChart
                            options={report.options}
                            series={report.series}
                            type={report.charttype}
                            height={report.chartheight}
                            width={report.chartwidth}
                          />
                        </div>
                        <div>
                          <h4 className="mb-1 mt-1"><span><CountUp end={report.value} separator="," prefix={report.prefix} suffix={report.suffix} decimals={report.decimal} /></span></h4>
                          <p className="text-muted mb-0">{report.title}</p>
                        </div>
                        <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                )
              }
              else {
                return (
                  <Col md={12} xl={12}>
                    <Row>
                      <Col md={6} xl={3}>
                        <Row>
                          <Col md="12">
                            <Card>
                              <CardBody>
                                <div className="float-end mt-2">
                                  <ReactApexChart
                                    options={report.options}
                                    series={report.series}
                                    type={report.charttype}
                                    height={report.chartheight}
                                    width={report.chartwidth}
                                  />
                                </div>
                                <div>
                                  <h4 className="mb-1 mt-1"><span><CountUp end={report.value} separator="," prefix={report.prefix} suffix={report.suffix} decimals={report.decimal} /></span></h4>
                                  <p className="text-muted mb-0">{report.title}</p>
                                </div>
                                <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
                                </p>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col md="12">
                            <TopUser />
                          </Col>
                        </Row>

                      </Col>
                      <Col md={6} xl={9}>
                        <SalesAnalyticsChart />
                      </Col>
                    </Row>
                  </Col>
                )
              }

            }
            )}



            {/* </Row>

          <Row> */}

            {/* <Col xl={4}>
              <Card className="bg-primary">
                <CardBody>
                  <Row className="align-items-center">
                    <Col sm={8}>
                      <p className="text-white font-size-18">
                        Enhance your <b>Campaign</b> for better outreach{" "}
                        <i className="mdi mdi-arrow-right"></i>
                      </p>
                      <div className="mt-4">
                        <Link
                          to="#"
                          className="btn btn-success waves-effect waves-light"
                        >
                          Upgrade Account!
                        </Link>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mt-4 mt-sm-0">
                        <img
                          src={setupanalytics}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                   </Col> */}
            {/* </Row>
                </CardBody>
              </Card>
              <TopProduct />
            </Col> */}
          </Row>
          {/* <Row>
          <Col xl={12}>
              <SalesAnalyticsChart />
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
