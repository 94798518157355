import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import Select from "react-select";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import "./style.scss";


const FollowupLogs = () => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [staffOptions, setStaffOptions] = useState([]);
    const [followupData, setFollowupData] = useState([]);
    const [followupstatusOptions, setfollowupstatusOptions] = useState([]);
    const [candidateOptions, setCandidateOptions] = useState([]);
    const [allcandidateOptions, setAllCandidateOptions] = useState([]);
    /** pagination followup */
    const [PerPageCount, setPerPageCount] = useState(20);
    const [followSearch, setfollowSearch] = useState(null);
    const [searchFollowValue, setSearchFollowValue] = useState("");
    const [totalFPages, setTotalFPages] = useState(0);
    const [currentFPage, setCurrentFPage] = useState(1);
    const [indexOfFLastTransfer, setindexOfFLastTransfer] = useState(
        currentFPage * PerPageCount
    );

    const [indexOfFFirstTransfer, setindexOfFFirstTransfer] = useState(
        indexOfFLastTransfer - PerPageCount
    );
    const followuppaginate = (event, value) => {
        setCurrentFPage(value);
        const indexOfFLastTransfer = value * PerPageCount;
        const indexOfFFirstTransfer = indexOfFLastTransfer - PerPageCount;
        setindexOfFFirstTransfer(indexOfFFirstTransfer);
        setindexOfFLastTransfer(indexOfFLastTransfer);
        fetch_candidate_followups(searchFollowValue, followSearch?.candidate_id, followSearch?.from_date, followSearch?.to_date, followSearch?.status, followSearch?.staff, indexOfFFirstTransfer, indexOfFLastTransfer);
    };
    const resetFollowupPaginate = () => {
        setCurrentFPage(1);
        const indexOfFLastTransfer = PerPageCount;
        const indexOfFFirstTransfer = 0;
        setindexOfFFirstTransfer(indexOfFFirstTransfer);
        setindexOfFLastTransfer(indexOfFLastTransfer);
    }
    /** end pagination */
    const fetch_candidate_followups = (search = "", id = "", from_date = "", to_date = "", status = "", staff = "", start = 0, end = PerPageCount) => {
        axios
            .get(`${API_URL}followup/candidate-followups?candidate_id=${id}&from_date=${from_date}&to_date=${to_date}&search=${search}&start=${start}&end=${end}&status=${status}&staff=${staff}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                setTotalFPages(res.data.limit);
                var result = res.data.data;
                result.map((item, index) => {

                    item.id = index + 1
                    item.candidate = (
                        <a href={`/candidate/${item?.candidate_no}`} style={{ color: "black" }} target="_blank">{item?.candidate}</a>
                    )
                    item.candidate_no = (
                        <a href={`/candidate/${item?.candidate_no}`} style={{ color: "black" }} target="_blank">{item?.candidate_no}</a>
                    )

                })
                setFollowupData(res.data.data);
            });
    }
    const fetch_candidate = () => {
        axios
            .get(`${API_URL}candidate/options?for=followup`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                let list = [];
                let all_list = res.data.data;
                setAllCandidateOptions(all_list);
                const firstLimited = all_list.slice(0, 20);
                firstLimited &&
                    firstLimited.map((el) => {
                        list.push({
                            label: el?.cand_name + " " + el?.cand_reg_no,
                            value: el?._id,
                            reg_no: el?.cand_reg_no
                        });
                    });
                setCandidateOptions(list);
            });
    };
    const fetch_followup_status = () => {
        axios
            .get(`${API_URL}followup/followup-status`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                let data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el?.name,
                            value: el?._id,
                        };
                    });
                setfollowupstatusOptions([
                    {
                        options: data,
                    },
                ]);
            });
    }
    const fetch_all_staff = () => {
        axios
            .get(`${API_URL}user`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var staff_data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        if (el?.lastName) el.name = el.firstName + " " + el.lastName;
                        else el.name = el.firstName;
                        return {
                            label: el.name,
                            value: el._id,
                        };
                    });
                setStaffOptions([
                    {
                        options: staff_data,
                    },
                ]);
            });
    };
    const handleFollowFilter = (selected, type) => {
        resetFollowupPaginate();
        switch (type) {
            case "status":
                setfollowSearch({ ...followSearch, "status": selected.value, "statusObject": selected });
                fetch_candidate_followups(searchFollowValue, followSearch?.candidate_id, followSearch?.from_date, followSearch?.to_date, selected.value, followSearch?.staff)
                break;
            case "from_date":
                setfollowSearch({ ...followSearch, "from_date": selected.target.value });
                fetch_candidate_followups(searchFollowValue, followSearch?.candidate_id, selected.target.value, followSearch?.to_date, followSearch?.status, followSearch?.staff)
                break;
            case "to_date":
                setfollowSearch({ ...followSearch, "to_date": selected.target.value });
                fetch_candidate_followups(searchFollowValue, followSearch?.candidate_id, followSearch?.from_date, selected.target.value, followSearch?.status, followSearch?.staff)
                break;
            case "staff":
                setfollowSearch({ ...followSearch, "staff": selected.value, "staffObject": selected });
                fetch_candidate_followups(searchFollowValue, followSearch?.candidate_id, followSearch?.from_date, followSearch?.to_date, followSearch?.status, selected.value)
                break;
            case "candidate":
                setfollowSearch({ ...followSearch, "candidate_id": selected.value, "candidateObject": selected });
                fetch_candidate_followups(searchFollowValue, selected.value, followSearch?.from_date, followSearch?.to_date, followSearch?.status, followSearch?.staff)
                break;
        }

    }
    const resetFollowupFilter = (value) => {
        resetFollowupPaginate();
        fetch_candidate_followups(searchFollowValue);
        setfollowSearch({ statusObject: null, staffObject: null, candidateObject: null, from_date: "", to_date: "" });
    }
    const handleSearchFollowup = (value) => {
        setSearchFollowValue(value)
        resetFollowupPaginate();
        fetch_candidate_followups(value, followSearch?.candidate_id, followSearch?.from_date, followSearch?.to_date, followSearch?.status, followSearch?.staff);
    }
    const handleSelectSearch = (inputValue) => {
        if (inputValue) {
            const searchQuery = inputValue.toLowerCase();

            // Filter the data array based on cand_name and cand_reg_no
            let filter_list = [];
            const filteredData = allcandidateOptions.filter((item) => {
                const lowerCaseName = item.cand_name.toLowerCase() + " " + item.cand_reg_no.toLowerCase();
                // Check if the input matches the cand_name or cand_reg_no
                if (lowerCaseName.includes(searchQuery))
                    filter_list.push({ label: item.cand_name + " " + item.cand_reg_no, value: item._id })
            });
            setCandidateOptions(filter_list)
        }
        else {
            let filter_list = [];
            const firstLimited = allcandidateOptions.slice(0, 20);
            firstLimited &&
                firstLimited.map((el) => {
                    filter_list.push({
                        label: el?.cand_name + " " + el?.cand_reg_no,
                        value: el?._id,
                        reg_no: el?.cand_reg_no
                    });
                });
            setCandidateOptions(filter_list);
        }

    }
    const followup_data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 50,
            },
            {
                label: "Date",
                field: "followup_date",
                sort: "asc",
                width: 150,
            },
            {
                label: "Time",
                field: "followup_time",
                sort: "asc",
                width: 150,
            },
            {
                label: "Candidate",
                field: "candidate",
                sort: "asc",
                width: 150,
            },
            {
                label: "Candidate ID",
                field: "candidate_no",
                sort: "asc",
                width: 150,
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: 400,
            },
            {
                label: "Next",
                field: "followup_next_date",
                sort: "asc",
                width: 200,
            },
            {
                label: "Remarks",
                field: "followup_message",
                sort: "asc",
                width: 200,
            },
            {
                label: "Staff",
                field: "staff",
                sort: "asc",
                width: 200,
            }
        ],
        rows: followupData,
    };
    useEffect(() => {
        fetch_followup_status();
        fetch_all_staff();
        fetch_candidate_followups();
        fetch_candidate();
    }, []);
    useEffect(() => {
        if (followupData.length == 0 && indexOfFFirstTransfer != 0) {
            resetFollowupPaginate();
            fetch_candidate_followups(searchFollowValue, followSearch?.candidate_id, followSearch?.from_date, followSearch?.to_date, followSearch?.status, followSearch?.staff);
        }

    }, [followupData])
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Followup Logs" />
                    <Row>
                        <Col md="12">
                            <Card><CardBody>
                                <Row>
                                    <Col md="2">
                                        <div>
                                            <Label htmlFor="validationCustom05">From</Label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                id="from_date"
                                                name="from_date"
                                                value={followSearch?.from_date}
                                                onChange={(e) => {
                                                    handleFollowFilter(e, "from_date");
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div >
                                            <Label htmlFor="validationCustom05">To</Label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                id="to_date"
                                                name="to_date"
                                                value={followSearch?.to_date}
                                                onChange={(e) => {
                                                    handleFollowFilter(e, "to_date");
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div>
                                            <Label>Status</Label>
                                            <Select
                                                name="follow_status"
                                                value={followSearch?.statusObject}
                                                onChange={(selected) => {
                                                    handleFollowFilter(selected, "status");
                                                }}
                                                options={followupstatusOptions}
                                                classNamePrefix=" select2-selection"
                                            />

                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div>
                                            <Label>Staff</Label>
                                            <Select
                                                name="staff_id"
                                                value={followSearch?.staffObject}
                                                onChange={(selected) => {
                                                    handleFollowFilter(selected, "staff");
                                                }}
                                                options={staffOptions}
                                                classNamePrefix="select2-selection"
                                            />
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className="mb-3">
                                            <Label>Candidate</Label>
                                            <Select
                                                name="candidate"
                                                value={followSearch?.candidateObject}
                                                onChange={(selected) => {
                                                    handleFollowFilter(selected, "candidate");
                                                }}
                                                options={candidateOptions}
                                                classNamePrefix="select2-selection"
                                                onInputChange={handleSelectSearch}
                                                menuPosition={"fixed"}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="1" style={{ marginTop: "2.5%" }}>
                                        <div className="mb-3">

                                            <Button color="danger" type="reset" onClick={resetFollowupFilter}>
                                                {"Reset"}
                                            </Button>
                                        </div>
                                    </Col>

                                </Row>
                                <MDBDataTable
                                    id="followupTableId"
                                    responsive
                                    bordered
                                    data={followup_data}
                                    searching={true}
                                    info={false}
                                    disableRetreatAfterSorting={true}
                                    paging={false}
                                    onSearch={(value) => { handleSearchFollowup(value) }}
                                    entries={PerPageCount}
                                />
                                {totalFPages > 20 && (
                                    <div className="pagination-div">
                                        {/* Display the page details */}
                                        <div className="page-details">
                                            Showing {indexOfFFirstTransfer + 1} to {indexOfFFirstTransfer + followupData.length} of {totalFPages} entries
                                        </div>
                                        <Pagination
                                            color="standard"
                                            shape="rounded"
                                            defaultPage={1}
                                            count={Math.ceil(totalFPages / PerPageCount)}
                                            page={currentFPage}
                                            onChange={followuppaginate}
                                            info={true}
                                            size="large"
                                        />
                                    </div>
                                )}
                            </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )

}
export default FollowupLogs;