import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Progress } from "reactstrap";
import Pagination from "@mui/material/Pagination";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { apiError } from "../../../store/actions";
import CancelIcon from "@mui/icons-material/Cancel";
import LockOpenIcon from "@mui/icons-material/LockOpen";
//import tus from 'tus-js-client';
import * as tus from "tus-js-client";
// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getLocalbody } from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./AddCustomer.scss";
import { CSVLink } from "react-csv";

const Customers = (props) => {
  const [openModal, setopenModal] = useState(false);
  const [staffOptions, setStaffOptions] = useState([]);
  const [followupData, setFollowupData] = useState([]);
  const [modalData, setmodalData] = useState("");
  const [followupErrors, setfollowupErrors] = useState({});
  const [followupObject, setfollowupObject] = useState({});
  const [selectedFollowStatus, setselectedFollowStatus] = useState(null);
  const [followupstatusOptions, setfollowupstatusOptions] = useState([]);
  const [candidateIdTobeUpdated, setCandidateIdTobeUpdated] = useState(null);
  const [candidateIdToBeDeleted, setCandidateIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [selectedGenderSearch, setSelectedGenderSearch] = useState(null)
  const [details, setDetails] = useState([]);
  const [tableData, setTableData] = useState({});
  const [master, setMaster] = useState({
    cand_reg_from: 0,
  });
  const [popupView, setPopupView] = useState(false);

  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedDistrictSearch, setSelectedDistrictSearch] = useState(null);
  const [districtOptionsSearch, setDistrictOptionsSearch] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  // const [designationOptions, setDesignationOptions] = useState([]);
  // const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedStateSearch, setSelectedStateSearch] = useState(null);

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [selectedQualificationSearch, setSelectedQualificationSearch] =
    useState(null);

  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState(null);
  // const [experienceOptions, setExperienceOptions] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedSubjectSearch, setSelectedSubjectSearch] = useState(null);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [selectedSyllabusSearch, setSelectedSyllabusSearch] = useState(null);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedClassSearch, setSelectedClassSearch] = useState(null);
  const [classOptions, setClassOptions] = useState([]);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [selectedMediumSearch, setSelectedMediumSearch] = useState(null);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [uploadProgress, setUploadProgress] = useState();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedFollowupStatus, setselectedFollowupStatus] = useState(null);

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  // const [videoData, setVideoData] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(null);
  const [uploadPercentage2, setUploadPercentage2] = useState(null);
  const [popupView1, setPopupView1] = useState(false);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [videoData, setVideoData] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [buttonssDisabled, setButtonssDisabled] = useState(false);
  const [introVideoId, setIntroVideoId] = React.useState("");
  const [introVideoIdPlay, setIntroVideoIdPlay] = useState(false);
  const [introVideoHash, setIntroVideoHash] = React.useState("");
  const [candidateVideo2, setCandidateVideo2] = React.useState("");
  const [candidateVideo2Play, setCandidateVideo2Play] = useState(false);
  const [candidateVideo2Hash, setCandidateVideo2Hash] = React.useState("");
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const accessTokens = "4ecc177c811f388e378f793e57eea426";
  const config = {
    Accept: "application/vnd.vimeo.*+json;version=3.4",
    Authorization: `bearer ${accessTokens}`,
    "Content-Type": "application/json",
  };
  // const [errors, setErrors] = useState({
  //   billingtypeError: "",
  //   groupError: "",
  //   packageError: "",
  //   wardError: "",
  //   districtError: "",
  //   customertypeError: "",
  //   designationError: "",
  //   imageError: "",
  // });

  const dispatch = useDispatch();
  const formRef = useRef();
  let history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  /** pagination */
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
    currentPage * PerPageCount
  );

  const qualification_id = searchData?.qualification_id;

  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
    indexOfLastTransfer - PerPageCount
  );
  var id = indexOfFirstTransfer;
  const paginate = (event, value) => {
    setCurrentPage(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
    handleTableData(searchValue, qualification_id, selectedSubjectSearch?.value,
      selectedMediumSearch?.value,
      selectedSyllabusSearch?.value,
      selectedClassSearch?.value,
      selectedStateSearch?.value,
      selectedDistrictSearch?.value, selectedGenderSearch?.value,
      selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date, indexOfFirstTransfer, indexOfLastTransfer);
    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: "smooth",
    // });
    // id = indexOfFirstTransfer;
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  }
  /** end pagination */

  /** pagination followup */
  const [followSearch, setfollowSearch] = useState(null);
  const [searchFollowValue, setSearchFollowValue] = useState("");
  const [totalFPages, setTotalFPages] = useState(0);
  const [currentFPage, setCurrentFPage] = useState(1);
  const [indexOfFLastTransfer, setindexOfFLastTransfer] = useState(
    currentFPage * PerPageCount
  );

  const [indexOfFFirstTransfer, setindexOfFFirstTransfer] = useState(
    indexOfFLastTransfer - PerPageCount
  );
  const followuppaginate = (event, value) => {
    setCurrentFPage(value);
    const indexOfFLastTransfer = value * PerPageCount;
    const indexOfFFirstTransfer = indexOfFLastTransfer - PerPageCount;
    setindexOfFFirstTransfer(indexOfFFirstTransfer);
    setindexOfFLastTransfer(indexOfFLastTransfer);
    fetch_candidate_followups(searchFollowValue, modalData?._id, followSearch?.from_date, followSearch?.to_date, followSearch?.status, followSearch?.staff, indexOfFFirstTransfer, indexOfFLastTransfer);
  };
  const resetFollowupPaginate = () => {
    setCurrentFPage(1);
    const indexOfFLastTransfer = PerPageCount;
    const indexOfFFirstTransfer = 0;
    setindexOfFFirstTransfer(indexOfFFirstTransfer);
    setindexOfFLastTransfer(indexOfFLastTransfer);
  }
  /** end pagination */


  useEffect(() => {
    handleTableData(searchValue);
    fetch_all_states();
    fetch_all_qualification();
    // fetch_experience();
    fetch_subject();
    fetch_syllabus();
    fetch_class();
    fetch_medium();
    fetch_followup_status();
    fetch_all_staff();
    // if(introVideoId){
    //   VideoStatus();
    // }
  }, []);
  useEffect(() => {
    setInterval(() => {
      VideoStatus(introVideoId);

    }, 1000)

  }, [introVideoId]);

  useEffect(() => {
    setInterval(() => {
      VideoStatus1(candidateVideo2);
    }, 1000)
  }, [candidateVideo2]);
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (details.length == 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData(searchValue, qualification_id, selectedSubjectSearch?.value,
        selectedMediumSearch?.value,
        selectedSyllabusSearch?.value,
        selectedClassSearch?.value,
        selectedStateSearch?.value,
        selectedDistrictSearch?.value, selectedGenderSearch?.value,
        selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date);
    }

  }, [details])

  useEffect(() => {
    if (followupData.length == 0 && indexOfFFirstTransfer != 0) {
      resetFollowupPaginate();
      fetch_candidate_followups(searchFollowValue, modalData?._id, followSearch?.from_date, followSearch?.to_date, followSearch?.status, followSearch?.staff);
    }

  }, [followupData])
  const handleFollowChange = (selected, type) => {
    switch (type) {
      case "status":
        setselectedFollowStatus(selected);
        setfollowupObject({ ...followupObject, "followup_current_status": selected.value });
        setfollowupErrors({ ...followupErrors, "followup_current_status": "" });
        break;
      default:
        setfollowupObject({ ...followupObject, [selected.target.name]: selected.target.value });
        setfollowupErrors({ ...followupErrors, [selected.target.name]: "" });
        break;
    }

  }
  const fetch_all_staff = () => {
    axios
      .get(`${API_URL}user`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  const handleFollowFilter = (selected, type) => {
    resetFollowupPaginate();
    switch (type) {
      case "status":
        setfollowSearch({ ...followSearch, "status": selected.value, "statusObject": selected });
        fetch_candidate_followups(searchFollowValue, modalData?._id, followSearch?.from_date, followSearch?.to_date, selected.value, followSearch?.staff)
        break;
      case "from_date":
        setfollowSearch({ ...followSearch, "from_date": selected.target.value });
        fetch_candidate_followups(searchFollowValue, modalData?._id, selected.target.value, followSearch?.to_date, followSearch?.status, followSearch?.staff)
        break;
      case "to_date":
        setfollowSearch({ ...followSearch, "to_date": selected.target.value });
        fetch_candidate_followups(searchFollowValue, modalData?._id, followSearch?.from_date, selected.target.value, followSearch?.status, followSearch?.staff)
        break;
      case "staff":
        setfollowSearch({ ...followSearch, "staff": selected.value, "staffObject": selected });
        fetch_candidate_followups(searchFollowValue, modalData?._id, followSearch?.from_date, followSearch?.to_date, followSearch?.status, selected.value)
        break;
    }

  }
  const resetFollowUpForm = () => {
    setselectedFollowStatus(null);
    setfollowupObject({ followup_current_status: "", followup_message: "", followup_next_date: "" });
  }
  const handleOpenFollowup = (id) => {
    axios
      .get(`${API_URL}followup/single-view?candidate_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setmodalData(res.data.data);
        setSearchFollowValue("");
        fetch_candidate_followups("", id);
        setopenModal(true);
      });
  }
  const fetch_candidate_followups = (search = "", id, from_date = "", to_date = "", status = "", staff = "", start = 0, end = PerPageCount) => {
    axios
      .get(`${API_URL}followup/candidate-followups?candidate_id=${id}&from_date=${from_date}&to_date=${to_date}&search=${search}&start=${start}&end=${end}&status=${status}&staff=${staff}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setTotalFPages(res.data.limit);
        setFollowupData(res.data.data);
      });
  }

  const fetch_all_states = () => {
    axios
      .get(`${API_URL}states/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let state_data =
          res.data.data &&
          res.data.data.map((state) => {
            return {
              label: state?.name,
              value: state?.id,
            };
          });
        setStateOptions([
          {
            options: state_data,
          },
        ]);
      });
  };
  const fetch_all_qualification = () => {
    axios
      .get(`${API_URL}qualification/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let qualification_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.qualification_name,
              value: el?._id,
            };
          });
        setQualificationOptions([
          {
            options: qualification_data,
          },
        ]);
      });
  };

  const fetch_subject = () => {
    axios
      .get(`${API_URL}subject/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let sub_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.subject_name,
              value: el?._id,
            };
          });
        setSubjectOptions([
          {
            options: sub_data,
          },
        ]);
      });
  };
  const fetch_syllabus = () => {
    axios
      .get(`${API_URL}manage/syllabus`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let syllabus_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setSyllabusOptions([
          {
            options: syllabus_data,
          },
        ]);
      });
  };
  const fetch_class = () => {
    axios
      .get(`${API_URL}manage/classes`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let class_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setClassOptions([
          {
            options: class_data,
          },
        ]);
      });
  };
  const fetch_followup_status = () => {
    axios
      .get(`${API_URL}followup/followup-status`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let list = [{ label: "No Followup", value: "0" }]
        res.data.data &&
          res.data.data.map((el) => {
            list.push({
              label: el?.name,
              value: el?._id,
            });
          });
        setfollowupstatusOptions(list);
      });
  }
  const fetch_medium = () => {
    axios
      .get(`${API_URL}manage/medium`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let medium_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setMediumOptions([
          {
            options: medium_data,
          },
        ]);
      });
  };
  function fetch_all_district(id) {
    axios
      .get(`${API_URL}districts/list?state_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        // if (res.data && res.data.status === true) {
        var district_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.district_name,
              value: el._id,
            };
          });
        // }
        // else {
        //   district_data = [];
        // }

        setDistrictOptions([
          {
            options: district_data,
          },
        ]);
      });
  }

  function fetch_all_districtSearch(id) {
    axios
      .get(`${API_URL}districts/list?state_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var district_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.district_name,
              value: el._id,
            };
          });

        setDistrictOptionsSearch([
          {
            options: district_data,
          },
        ]);
      });
  }
  function handleTableData(search = "",
    qualification_id = "",
    subject_id = "",
    medium = "",
    syllabus = "",
    classes = "",
    state = "",
    district_id = "", gender = "",
    status = "",
    follow_status = "", next_date = "",
    start = 0, end = 20
  ) {
    var url = `${API_URL}candidate/list`;

    url =
      url +
      "?qualification_id=" +
      qualification_id +
      "&subject_id=" +
      subject_id +
      "&medium=" +
      medium +
      "&syllabus=" +
      syllabus +
      "&classes=" +
      classes +
      "&state=" +
      state +
      "&district_id=" +
      district_id +
      "&gender=" +
      gender +
      "&status=" +
      status + "&start=" + start + "&end=" + end + "&search=" + search + "&follow_status=" + follow_status + "&next_date=" + next_date;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let dataToBeExported = [];
        setTotalPages(res.data.limit);
        var id = start;
        result.map((item, index) => {
          let exportItem = {};
          id = id + 1;
          item.id = id;
          // cand_current_status
          item.date = moment(item?.cand_date).format("DD-MM-YYYY");
          item.subjectName = "";
          item.subject &&
            item.subject.map((sub) => {
              if (item.subjectName === "") item.subjectName = sub?.subject_name;
              else
                item.subjectName = item?.subjectName + "," + sub?.subject_name;
            });

          item.app = (
            <div>
              {item?.cand_verification_status === 1 ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Verified
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    style={{ cursor: "default" }}
                  >
                    {" "}
                    <i
                      className="fas fa-check-circle"
                      style={{ color: "#2eb1e3" }}
                    ></i>
                  </button>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Not Verified
                    </Tooltip>
                  }
                >
                  <button
                    // onClick={() => {
                    // }}
                    className="button_class"
                    style={{ cursor: "default" }}
                  >
                    <i
                      className="mdi mdi-close-circle"
                      style={{ color: "#ed5a5a", fontSize: "1.2em" }}
                    ></i>
                  </button>
                </OverlayTrigger>
              )}
              {item?.app_user === 1 ? (
                <button className="button_class" style={{ cursor: "default" }}>
                  <i
                    className="uil-android"
                    style={{ color: "green", fontSize: "20px" }}
                  ></i>
                </button>
              ) : null}
              {item?.cand_status === 0 ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Block
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    onClick={() => updateStatus(item)}
                  >
                    <LockOpenIcon
                      style={{
                        color: "green",
                        fontSize: "18px",
                        cursor: "pointer",
                        // marginTop:"0px"
                      }}
                    />
                  </button>
                </OverlayTrigger>
              ) : item?.cand_status === 2 ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Unblock
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    onClick={() => updateStatus(item)}
                  >
                    <LockOpenIcon
                      style={{
                        color: "red",
                        fontSize: "18px",
                        cursor: "pointer",
                        //marginTop:"0px"
                      }}
                    />
                  </button>
                </OverlayTrigger>
              ) : null}
            </div>
          );

          //             item.app = (
          //               <div style={{ display: "flex", justifyContent: "center" }}>
          // <a
          //                   aria-haspopup="true"
          //                   className="text-body font-size-16 ddropdown-toggle"
          //                   aria-expanded="false"
          //                 >
          //                   <CheckCircleIcon
          //                     style={{
          //                       fontSize: "20px",
          //                       color: "green",
          //                       position: "relative",
          //                       float: "right",
          //                       bottom: "83px",
          //                     }}
          //                   />
          //                 </a>

          //                 <a
          //                   aria-haspopup="true"
          //                   className="text-body font-size-16 ddropdown-toggle"
          //                   aria-expanded="false"
          //                 >
          //                   <CancelIcon
          //                     style={{
          //                       fontSize: "20px",
          //                       color: "red",
          //                       position: "relative",
          //                       float: "right",
          //                       bottom: "83px",
          //                     }}
          //                   />
          //                 </a>
          //                 <i
          //                   className="uil-android"
          //                   style={{
          //                     fontSize: "1.5em",
          //                     cursor: "pointer",
          //                     marginLeft: "0.2em",
          //                     marginRight: "0.5em",
          //                     color: "green",
          //                   }}
          //                 ></i>
          //               </div>

          // item.app_user === 1?(
          //   <div style={{ display: "flex", justifyContent: "center" }}>

          // </div>
          // )

          // if (item.app_user === 1) {
          //   item.app = (

          //   );
          // }
          if (item.cand_current_status === 0) item.status = "New";
          else if (item.cand_current_status === 1) item.status = "Saved";
          else if (item.cand_current_status === 2) item.status = "Applied";
          else if (item.cand_current_status === 3) item.status = "Shortlisted";
          else if (item.cand_current_status === 4) item.status = "Placed";
          else if (item.cand_current_status === 5) item.status = "Declined";
          else item.status = "";
          // item.status = (
          //   <h6
          //     style={{
          //       color: "green",
          //     }}
          //   >
          //     {"Verified"}
          //   </h6>
          // );

          item.action = (
            <div>
              <a href={"/candidate/" + item.cand_reg_no} target="_blank" style={{ color: "#495057" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    //history.push("/candidate/" + item.cand_reg_no);
                  }}
                ></i>
              </a>

              {/* <i
                className="fas fa-history"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                title="Followup"
                onClick={() => {
                  handleOpenFollowup(item?._id);
                }}
              ></i> */}
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateCandidate(item);
                }}
              ></i>
              <i
                className="fas fa-upload"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  videoUp(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setCandidateIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );

          //export data
          exportItem.Id = item.id;
          exportItem.Reg_No = item.cand_reg_no;
          exportItem.Name = item.cand_name;
          exportItem.Subjects = item.subjectName;
          //exportItem.Classes = item.className;
          //exportItem.Qualification = item.qualificationName;
          exportItem.Mobile = item.cand_mob;
          exportItem.State = item?.state_name;
          exportItem.District = item?.district_name;
          exportItem.Status = item?.status;
          exportItem.FollowStatus = item?.follow_status;
          exportItem.Info =
            item?.cand_verification_status === 1 ? "Verified" : "Not Verified";

          dataToBeExported.push(exportItem);
        });

        setDetails(result);
        setDataToBeExported(dataToBeExported);
      });
  }
  const updateStatus = (item) => {
    axios
      .put(`${API_URL}candidate/cand_block?candidate_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.data.cand_status === 0) {
            toastr.success("Candidate unblocked successfully");
          } else toastr.success("Candidate blocked successfully");
          handleTableData(searchValue, qualification_id, selectedSubjectSearch?.value,
            selectedMediumSearch?.value,
            selectedSyllabusSearch?.value,
            selectedClassSearch?.value,
            selectedStateSearch?.value,
            selectedDistrictSearch?.value, selectedGenderSearch?.value,
            selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date);
        } else {
          toastr.error("Failed");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const videoUp = (item) => {
    axios
      .get(`${API_URL}candidate/view?candidate_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.candidate_id = result?._id;
        result.cand_reg_from = 0;
        if (result?.cand_video1) {
          let vdo = result.cand_video1.split("/");
          let data = vdo.at(-2);
          let data2 = vdo.slice(-1).pop();
          setIntroVideoId(data);
          setIntroVideoHash(data2);
        } else {
          setIntroVideoId("");
          setIntroVideoHash("");
        }
        if (result?.cand_video2) {
          let vdo2 = result?.cand_video2.split("/");
          let data2 = vdo2.slice(-1).pop();
          let data = vdo2.at(-2);
          setCandidateVideo2Hash(data2);
          setCandidateVideo2(data);
        } else {
          setCandidateVideo2("");
          setCandidateVideo2Hash("");
        }

        setVideoData(result);
        setPopupView1(true);
      });
  };

  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("cand_image", e.target.files[0]);
    axios
      .post(`${API_URL}candidate/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setMaster({
            ...master,
            [`cand_image`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const uploadVideo1 = (e) => {
    let val = e.target.files[0];
    const fileSize = val.size.toString();
    const url = URL.createObjectURL(val);

    let dataVal = val.type.split("/");
    let type = dataVal[0];

    if (type == "image") {
      toastr.error("Please upload video files");
    } else {
      setButtonDisabled(true);
      const response = axios({
        method: "POST",
        url: `https://api.vimeo.com/me/videos`,
        headers: config,
        data: {
          upload: {
            approach: "tus",
            size: fileSize,
          },
          privacy: {
            view: "unlisted",
          },
        },
      })
        .then((response) => {
          const upload = new tus.Upload(val, {
            endPoint: "https://api.vimeo.com/me/videos",
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
              filename: val.name,
              filetype: val.type,
            },
            headers: {},
            onError: function (error) {
            },
            onProgress: function (bytesUploaded, bytesTotal) {
              let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
              setUploadPercentage(percentage);
            },

            onSuccess: function () {
              // setUploadPercentage(null);
              toastr.success("Video uploaded to vimeo");
              setVideoData({
                ...videoData,
                [`cand_video1`]: response.data.link
              });
              let vdo = response.data.link.split("/");
              let data = vdo.at(-2);
              let data2 = vdo.slice(-1).pop();
              setIntroVideoHash(data2);
              setIntroVideoId(data);
              setButtonDisabled(false);
              //VideoStatus(data)
            },
          });
          upload.start();

        })
        .catch((err) => {
          if (err) {
            setButtonDisabled(false);
            toastr.error("Failed to upload");
            setUploadPercentage(null);
            return;
          }
        });
    }
  };
  const uploadVideo2 = (e) => {
    let val = e.target.files[0];
    const fileSize = val.size.toString();
    const url = URL.createObjectURL(val);
    let dataVal = val.type.split("/");
    let type = dataVal[0];

    if (type == "image") {
      toastr.error("Please upload video files");
    } else {
      setButtonDisabled(true);
      const response = axios({
        method: "POST",
        url: `https://api.vimeo.com/me/videos`,
        headers: config,
        data: {
          upload: {
            approach: "tus",
            size: fileSize,
          },
          // name: formData.name,
          //description: formData.description,
          privacy: {
            view: "unlisted",
          },
        },
        // onUploadProgress: (progressEvent) => {
        //   setUploadPercentage2(
        //     parseInt(
        //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
        //     )
        //   );
        // },

      })
        .then((response) => {

          const upload = new tus.Upload(val, {
            endPoint: "https://api.vimeo.com/me/videos",
            uploadUrl: response.data.upload.upload_link,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
              filename: val.name,
              filetype: val.type,
            },
            headers: {},
            onError: function (error) {
            },
            onProgress: function (bytesUploaded, bytesTotal) {
              let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
              setUploadPercentage2(percentage);

            },

            onSuccess: function () {
              // setUploadPercentage2(null);
              toastr.success("Video uploaded to vimeo");
              setVideoData({
                ...videoData,
                [`cand_video2`]: response.data.link
              });
              let vdo2 = response.data.link.split("/");
              let data = vdo2.at(-2);
              let data2 = vdo2.slice(-1).pop();
              setCandidateVideo2Hash(data2);
              setCandidateVideo2(data);
              setButtonDisabled(false);
              // videoFile.value = "";
              // setFormData({
              //   name: "",
              //   description: "",
              // });
            },
          });

          // Start the upload
          upload.start();

        })

        .catch((err) => {
          if (err) {
            setButtonDisabled(false);
            toastr.error("Failed to upload");
            setUploadPercentage2(null);
            return;
          }
        });
    }
  };

  const VideoStatus = (introVideoId) => {

    if (!introVideoId)
      return;
    let data = {
      video_id: introVideoId
    }
    axios
      .post(`${API_URL}vimeoUp/status`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.data == "playable") {
          setIntroVideoIdPlay(true)
          setUploadPercentage(null);
          // toastr.success("Video uploaded to vimeo");
        }
        else {
          setIntroVideoIdPlay(false)
          //setTimeout(VideoStatus, 5000);
        }
      });
  }

  const VideoStatus1 = (candidateVideo2) => {
    if (!candidateVideo2)
      return;
    let data = {
      video_id: candidateVideo2
    }
    axios
      .post(`${API_URL}vimeoUp/status`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.data == "playable") {
          setCandidateVideo2Play(true)
          setUploadPercentage2(null);
          // toastr.success("Video uploaded to vimeo");
        }
        else {
          setCandidateVideo2Play(false)
          //setTimeout(VideoStatus, 5000);
        }
      });
  };

  let preUpdateCandidate = (item) => {
    setCandidateIdTobeUpdated(item?._id);
    axios
      .get(`${API_URL}candidate/view?candidate_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.candidate_id = result?._id;
        result.cand_reg_from = 0;

        let stateData = result.cand_state || {};
        let state = {
          label: stateData?.name,
          value: stateData?._id,
        };
        setSelectedState(state);
        fetch_all_district(state?.value);

        let districtData = result.cand_district || {};
        let district = {
          label: districtData?.district_name,
          value: districtData?._id,
        };
        setSelectedDistrict(district);
        let qualificationArr = [];
        result.cand_qualifcatn &&
          result.cand_qualifcatn.forEach((element) => {
            let arrQualification = {
              label: element.qualification_name,
              value: element._id,
            };
            qualificationArr.push(arrQualification);
          });
        setSelectedQualification(qualificationArr);

        // let expData = result.cand_experience || {};
        let exp = {
          label: result?.cand_experience,
          value: result?.cand_experience,
        };
        setSelectedExperience(exp);

        let subArr = [];
        result.cand_subject &&
          result.cand_subject.forEach((element) => {
            let arrSub = {
              label: element.subject_name,
              value: element._id,
            };
            subArr.push(arrSub);
          });
        setSelectedSubject(subArr);
        let syllabusArr = [];
        result.cand_syllabus &&
          result.cand_syllabus.forEach((element) => {
            let arrSyllabus = {
              label: element.name,
              value: element._id,
            };
            syllabusArr.push(arrSyllabus);
          });
        setSelectedSyllabus(syllabusArr);

        let classArr = [];
        result.cand_classes &&
          result.cand_classes.forEach((element) => {
            let arrClass = {
              label: element.name,
              value: element._id,
            };
            classArr.push(arrClass);
          });
        setSelectedClass(classArr);
        let mediumArr = [];
        result.cand_medium &&
          result.cand_medium.forEach((element) => {
            let arrMedium = {
              label: element.name,
              value: element._id,
            };
            mediumArr.push(arrMedium);
          });
        setSelectedMedium(mediumArr);

        let maritalStatusOption = null;
        if (result?.cand_marital_status === 0) {
          maritalStatusOption = {
            label: "Married",
            value: 0,
          };
        } else if (result?.cand_marital_status === 1) {
          maritalStatusOption = {
            label: "Single",
            value: 1,
          };
        } else if (result?.cand_marital_status === 2) {
          maritalStatusOption = {
            label: "Separated ",
            value: 2,
          };
        } else if (result?.cand_marital_status === 3) {
          maritalStatusOption = {
            label: "Divorced",
            value: 3,
          };
        } else if (result?.cand_marital_status === 4) {
          maritalStatusOption = {
            label: "Widowed",
            value: 4,
          };
        }
        setSelectedMaritalStatus(maritalStatusOption);

        let genderOption = null;
        if (result?.cand_gender === 0) {
          genderOption = {
            label: "Male",
            value: 0,
          };
        } else if (result?.cand_gender === 1) {
          genderOption = {
            label: "Female",
            value: 1,
          };
        } else if (result?.cand_gender === 2) {
          genderOption = {
            label: "Transgender",
            value: 2,
          };
        }

        setSelectedGender(genderOption);
        setMaster(result);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Reg No",
        field: "cand_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "cand_name",
        sort: "asc",
        width: 200,
      },

      // {
      //   label: "District",
      //   field: "district_name",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Localbody",
      //   field: "localbody",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Subjects",
        field: "subjectName",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Classes",
      //   field: "className",
      //   sort: "asc",
      //   width: 100,
      // },
      // {
      //   label: "Qualification",
      //   field: "qualificationName",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Phone",
        field: "cand_mob",
        sort: "asc",
        width: 200,
      },
      {
        label: "State",
        field: "state_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Follow Status",
        field: "follow_status",
        sort: "asc",
        width: 100,
      },

      {
        label: "Info",
        field: "app",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };
  const followup_data = {
    columns: [
      {
        label: "Date",
        field: "followup_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "followup_time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 400,
      },
      {
        label: "Next Followup",
        field: "followup_next_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Remarks",
        field: "followup_message",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      }
    ],
    rows: followupData,
  };

  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "state":
        setSelectedState(selected);
        setSelectedDistrict(null);
        setMaster({
          ...master,
          cand_state: selected.value,
        });
        fetch_all_district(selected.value);
        break;
      case "district":
        setSelectedDistrict(selected);
        setMaster({
          ...master,
          cand_district: selected.value,
        });
        break;
      case "qualification":
        let arrayVal = [];
        selected &&
          selected.forEach((element) => {
            arrayVal.push(element.value);
          });
        setSelectedQualification(selected);
        setMaster({
          ...master,
          cand_qualifcatn: arrayVal,
        });
        break;
      case "experience":
        setSelectedExperience(selected);
        setMaster({
          ...master,
          cand_experience: selected.value,
        });
        break;
      case "subject":
        let subArr = [];
        selected &&
          selected.forEach((element) => {
            subArr.push(element.value);
          });
        setSelectedSubject(selected);
        setMaster({
          ...master,
          cand_subject: subArr,
        });
        break;
      case "syllabus":
        let syllabusArr = [];
        selected &&
          selected.forEach((element) => {
            syllabusArr.push(element.value);
          });
        setSelectedSyllabus(selected);
        setMaster({
          ...master,
          cand_syllabus: syllabusArr,
        });
        break;
      case "classes":
        let classArr = [];
        selected &&
          selected.forEach((element) => {
            classArr.push(element.value);
          });
        setSelectedClass(selected);
        setMaster({
          ...master,
          cand_classes: classArr,
        });
        break;
      case "medium":
        let mediumArr = [];
        selected &&
          selected.forEach((element) => {
            mediumArr.push(element.value);
          });
        setSelectedMedium(selected);
        setMaster({
          ...master,
          cand_medium: mediumArr,
        });
        break;
      case "gender":
        setSelectedGender(selected);
        setMaster({
          ...master,
          cand_gender: selected.value,
        });
        break;
      case "maritalStatus":
        setSelectedMaritalStatus(selected);
        setMaster({
          ...master,
          cand_marital_status: selected.value,
        });
        break;
      default:
        break;
    }
  };
  const handleSearch = (value) => {
    setSearchValue(value)
    resetPaginate();
    handleTableData(value, qualification_id, selectedSubjectSearch?.value,
      selectedMediumSearch?.value,
      selectedSyllabusSearch?.value,
      selectedClassSearch?.value,
      selectedStateSearch?.value,
      selectedDistrictSearch?.value, selectedGenderSearch?.value,
      selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date);
  }
  const resetFollowupFilter = (value) => {
    resetFollowupPaginate();
    fetch_candidate_followups(searchFollowValue, modalData?._id);
    setfollowSearch({ statusObject: null, staffObject: null });
  }
  const handleSearchFollowup = (value) => {
    setSearchFollowValue(value)
    resetFollowupPaginate();
    fetch_candidate_followups(value, modalData?._id, followSearch?.from_date, followSearch?.to_date, followSearch?.status, followSearch?.staff);
  }
  const handleSearchSelectChange = (selected, name) => {
    id = 0;
    setCurrentPage(1);
    setindexOfFirstTransfer(0);
    setindexOfLastTransfer(20);
    let qualification_id = searchData?.qualification_id
      ? searchData.qualification_id
      : "";
    let subject_id = searchData?.subject_id ? searchData.subject_id : "";
    let medium = searchData?.medium ? searchData.medium : "";
    let syllabus = searchData?.syllabus ? searchData.syllabus : "";
    let classes = searchData?.classes ? searchData.classes : "";
    let state = searchData?.state ? searchData.state : "";
    let district_id = searchData?.district_id ? searchData.district_id : "";
    let status = searchData?.status !== "" ? searchData.status : "";
    let follow_status = searchData?.follow_status !== "" ? searchData.follow_status : "";
    let next_date = searchData?.next_date !== "" ? searchData.next_date : "";
    let gender = searchData?.gender ? searchData.gender : "";

    switch (name) {
      case "next_date":
        setSearchData({
          ...searchData,
          next_date: selected.target.value,
        });
        handleTableData(
          searchValue,
          selected.value,
          subject_id,
          medium,
          syllabus,
          classes,
          state,
          district_id, gender,
          status, follow_status, selected.target.value
        );
        break;
      case "qualification_id":
        setSelectedQualificationSearch(selected);
        const selectedValues = selected.map(option => option.value).join(',');
        setSearchData({
          ...searchData,
          qualification_id: selectedValues,
        });
        handleTableData(
          searchValue,
          selectedValues,
          subject_id,
          medium,
          syllabus,
          classes,
          state,
          district_id, gender,
          status, follow_status, next_date
        );
        break;
      case "subject":
        setSelectedSubjectSearch(selected);
        setSearchData({
          ...searchData,
          subject_id: selected.value,
        });
        handleTableData(
          searchValue,
          qualification_id,
          selected.value,
          medium,
          syllabus,
          classes,
          state,
          district_id, gender,
          status, follow_status, next_date
        );
        break;
      case "medium":
        setSelectedMediumSearch(selected);
        setSearchData({
          ...searchData,
          medium: selected.value,
        });
        handleTableData(
          searchValue,
          qualification_id,
          subject_id,
          selected.value,
          syllabus,
          classes,
          state,
          district_id, gender,
          status, follow_status, next_date
        );
        break;
      case "syllabus":
        setSelectedSyllabusSearch(selected);
        setSearchData({
          ...searchData,
          syllabus: selected.value,
        });
        handleTableData(
          searchValue,
          qualification_id,
          subject_id,
          medium,
          selected.value,
          classes,
          state,
          district_id, gender,
          status, follow_status, next_date
        );
        break;
      case "class":
        setSelectedClassSearch(selected);
        setSearchData({
          ...searchData,
          classes: selected.value,
        });
        handleTableData(
          searchValue,
          qualification_id,
          subject_id,
          medium,
          syllabus,
          selected.value,
          state,
          district_id, gender,
          status, follow_status, next_date
        );
        break;
      case "state":
        setSelectedStateSearch(selected);
        setSelectedDistrictSearch(null);
        setSearchData({
          ...searchData,
          state: selected.value,
        });
        fetch_all_districtSearch(selected.value);
        handleTableData(
          searchValue,
          qualification_id,
          subject_id,
          medium,
          syllabus,
          classes,
          selected.value,
          "", gender,
          status, follow_status, next_date
        );
        break;
      case "district":
        setSelectedDistrictSearch(selected);
        setSearchData({
          ...searchData,
          district_id: selected.value,
        });
        handleTableData(
          searchValue,
          qualification_id,
          subject_id,
          medium,
          syllabus,
          classes,
          state,
          selected.value, gender,
          status, follow_status, next_date
        );
        break;
      case "gender":
        setSelectedGenderSearch(selected)
        setSearchData({
          ...searchData,
          gender: selected.value
        })
        handleTableData(
          searchValue,
          qualification_id,
          subject_id,
          medium,
          syllabus,
          classes,
          state,
          district_id, selected.value,
          status, follow_status, next_date
        )
        break;
      case "status":
        setSelectedStatus(selected);
        setSearchData({
          ...searchData,
          status: selected.value,
        });
        handleTableData(
          searchValue,
          qualification_id,
          subject_id,
          medium,
          syllabus,
          classes,
          state,
          district_id,
          selected.value, follow_status, next_date
        )
        break;
      case "follow_status":
        setselectedFollowupStatus(selected);
        setSearchData({
          ...searchData,
          follow_status: selected.value,
        });
        handleTableData(
          searchValue,
          qualification_id,
          subject_id,
          medium,
          syllabus,
          classes,
          state,
          district_id,
          status, selected.value, next_date
        );
        break;
      default:
        break;
    }
  };
  const deleteCustomerImage = () => {
    setMaster({
      ...master,
      [`cand_image`]: "",
    });
  };
  const deleteVideo = () => {
    setButtonDisabled(true);
    setButtonssDisabled(true);
    let data = {
      video_id: introVideoId,
      candidate_id: videoData?._id,
    };
    axios
      .post(`${API_URL}vimeoUp/delete`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Video deleted successfully");
          setIntroVideoId("");
          setVideoData({
            ...videoData,
            [`cand_video1`]: "",
          });
          setButtonssDisabled(false);
          setButtonDisabled(false);
        }
      })
      .catch((err) => {
        if (err) {
          setButtonDisabled(false);
          setButtonssDisabled(false);
          toastr.error("Failed to delete");
          return;
        }
      });
  };
  const deleteVideo2 = () => {
    setButtonDisabled(true);
    setButtonsDisabled(true);
    let data = {
      video_id: candidateVideo2,
      candidate_id: videoData?._id,
    };
    axios
      .post(`${API_URL}vimeoUp/delete`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Video deleted successfully");
          setCandidateVideo2("");
          setVideoData({
            ...videoData,
            [`cand_video2`]: "",
          });
          setButtonDisabled(false);
          setButtonsDisabled(false);
        }
      })
      .catch((err) => {
        if (err) {
          setButtonDisabled(false);
          setButtonsDisabled(false);
          toastr.error("Failed to delete");
          return;
        }
      });
  };

  const handleDate = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  // if (selectedImage === null) {
  //   setErrors({
  //     ...errors,
  //     ["imageError"]: "Please select image",
  //   });
  //   return;
  // }

  const handleValidSubmit = (event, values) => {
    if (candidateIdTobeUpdated) {
      axios
        .put(`${API_URL}candidate/update`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Candidate updated successfully");
            formRef.current.reset();
            handleTableData(searchValue, qualification_id, selectedSubjectSearch?.value,
              selectedMediumSearch?.value,
              selectedSyllabusSearch?.value,
              selectedClassSearch?.value,
              selectedStateSearch?.value,
              selectedDistrictSearch?.value, selectedGenderSearch?.value,
              selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date);
            setCandidateIdTobeUpdated(null);
            formRef.current.reset();
            setSelectedState(null);
            setSelectedDistrict(null);
            setDistrictOptions([]);
            setSelectedQualification(null);
            setSelectedExperience(null);
            setSelectedSubject(null);
            setSelectedSyllabus(null);
            setSelectedMedium(null);
            setSelectedClass(null);
            setSelectedGender(null);
            setSelectedMaritalStatus(null);
            handleTableData(searchValue, qualification_id, selectedSubjectSearch?.value,
              selectedMediumSearch?.value,
              selectedSyllabusSearch?.value,
              selectedClassSearch?.value,
              selectedStateSearch?.value,
              selectedDistrictSearch?.value, selectedGenderSearch?.value,
              selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date);
            setMaster({
              cand_dob: "",
              cand_reg_from: 0,
            });
            // setErrors({
            //   billingtypeError: "",
            //   groupError: "",
            //   packageError: "",
            //   wardError: "",
            //   districtError: "",
            //   customertypeError: "",
            //   designationError: "",
            //   imageError: "",
            // });
          } else {
            toastr.error(res.data.message, "Failed to update candidate");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}candidate`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Candidate created successfully");
            formRef.current.reset();
            setSelectedState(null);
            setSelectedDistrict(null);
            setDistrictOptions([]);
            setSelectedQualification(null);
            setSelectedExperience(null);
            setSelectedSubject(null);
            setSelectedSyllabus(null);
            setSelectedMedium(null);
            setSelectedClass(null);
            setSelectedGender(null);
            setSelectedMaritalStatus(null);
            handleTableData(searchValue, qualification_id, selectedSubjectSearch?.value,
              selectedMediumSearch?.value,
              selectedSyllabusSearch?.value,
              selectedClassSearch?.value,
              selectedStateSearch?.value,
              selectedDistrictSearch?.value, selectedGenderSearch?.value,
              selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date);
            setMaster({
              cand_dob: "",
              cand_reg_from: 0,
            });
            // setErrors({
            //   billingtypeError: "",
            //   groupError: "",
            //   packageError: "",
            //   wardError: "",
            //   districtError: "",
            //   customertypeError: "",
            //   designationError: "",
            //   imageError: "",
            // });
          } else {
            toastr.error(res.data.message, "Failed to create candidate");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const reset = () => {
    setSearchData({ next_date: "" });
    setSelectedQualificationSearch(null);
    setSelectedSubjectSearch(null);
    setSelectedMediumSearch(null);
    setSelectedClassSearch(null);
    setSelectedStateSearch(null);
    setSelectedDistrictSearch(null);
    setSelectedSyllabusSearch(null)
    setSelectedStatus(null);
    setDistrictOptionsSearch([]);
    setselectedFollowupStatus(null);
    setSelectedGenderSearch(null)
    handleTableData(searchValue);
    resetPaginate();
  };
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };
  const handleFollowupSubmit = () => {
    var errors = {}
    var err = 0;
    if (!followupObject?.followup_current_status) {
      errors.followup_current_status = "Select Status";
      err++;
    }

    if (!followupObject?.followup_message) {
      errors.followup_message = "Enter Message";
      err++;
    }
    if (err) {
      setfollowupErrors(errors)
      return;
    }
    else {
      followupObject.followup_candidate = modalData?._id
      axios
        .post(`${API_URL}followup/add`, followupObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success) {
            fetch_candidate_followups(searchFollowValue, modalData?._id, followSearch?.from_date, followSearch?.to_date, followSearch?.status, followSearch?.staff, indexOfFFirstTransfer, indexOfFLastTransfer);
            setfollowupObject({ followup_current_status: "", followup_message: "", followup_next_date: "" });
            setselectedFollowStatus(null);
            handleTableData(searchValue, qualification_id, selectedSubjectSearch?.value,
              selectedMediumSearch?.value,
              selectedSyllabusSearch?.value,
              selectedClassSearch?.value,
              selectedStateSearch?.value,
              selectedDistrictSearch?.value, selectedGenderSearch?.value,
              selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date, indexOfFirstTransfer, indexOfLastTransfer);
            toastr.success("Submitted Successfully");
          }
          else {
            toastr.success("Failed to Submit Followup");
          }
        });
    }

  }
  function submit(e) {
    axios
      .put(`${API_URL}candidate/update`, videoData, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("Candidate updated successfully");
          handleTableData(searchValue, qualification_id, selectedSubjectSearch?.value,
            selectedMediumSearch?.value,
            selectedSyllabusSearch?.value,
            selectedClassSearch?.value,
            selectedStateSearch?.value,
            selectedDistrictSearch?.value, selectedGenderSearch?.value,
            selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date);
          setCandidateIdTobeUpdated(null);
          setVideoData({});
          setPopupView1(false);
          setButtonDisabled(false);
        } else {
          toastr.error(res.data.message, "Failed to update candidate");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  }
  const closeVideoPopup = () => {
    setButtonDisabled(false);
    setVideoData({});
    setPopupView1(false);
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}candidate` + "/" + candidateIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Candidate deleted successfully");
                  if (master && master?._id === candidateIdToBeDeleted) {
                    formRef.current.reset();
                    setCandidateIdTobeUpdated(null);
                    formRef.current.reset();
                    setSelectedState(null);
                    setSelectedDistrict(null);
                    setDistrictOptions([]);
                    setSelectedQualification(null);
                    setSelectedExperience(null);
                    setSelectedSubject(null);
                    setSelectedSyllabus(null);
                    setSelectedMedium(null);
                    setSelectedClass(null);
                    setSelectedGender(null);
                    setSelectedMaritalStatus(null);
                    handleTableData(searchValue, qualification_id, selectedSubjectSearch?.value,
                      selectedMediumSearch?.value,
                      selectedSyllabusSearch?.value,
                      selectedClassSearch?.value,
                      selectedStateSearch?.value,
                      selectedDistrictSearch?.value, selectedGenderSearch?.value,
                      selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date);
                    setMaster({
                      cand_dob: "",
                      cand_reg_from: 0,
                    });
                  }
                  handleTableData(searchValue, qualification_id, selectedSubjectSearch?.value,
                    selectedMediumSearch?.value,
                    selectedSyllabusSearch?.value,
                    selectedClassSearch?.value,
                    selectedStateSearch?.value,
                    selectedDistrictSearch?.value, selectedGenderSearch?.value,
                    selectedStatus?.value, selectedFollowupStatus?.value, searchData?.next_date);
                } else {
                  toastr.error("Failed to delete candidate");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      {/* video upload popup start */}
      <Modal show={popupView1} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Upload Videos here</h5>
          <button
            type="button"
            onClick={() => {
              closeVideoPopup();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => { }}
          onChange={changeHandler}
        >
          <div className="modal-body">
            <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom03">Intro video</Label>
                  {/* {videoData?.cand_video1 ? (
                    <div div className="img-wraps">
                      {videoData.cand_video1 &&
                      videoData.cand_video1.startsWith("http") ? (
                        <video
                          className="VideoInput_video"
                          width="150"
                          height="150"
                          controls
                          src={`${videoData.cand_video1}`}
                        />
                      ) : (
                        <video
                          className="VideoInput_video"
                          width="150"
                          height="150"
                          controls
                          src=""
                        />
                      )}

                      <button
                        className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                        onClick={deleteVideo}
                        style={{ width: "150px" }}
                      >
                        Delete
                      </button>
                    </div>
                  ) : ( */}

                  {introVideoId && introVideoIdPlay === true ? (
                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                      {/* <video controls>
                      <source src={`https://player.vimeo.com/video/${introVideoId}?h=${introVideoHash}`} type="video/mp4"></source>
                      <source src={`https://player.vimeo.com/video/${introVideoId}?h=${introVideoHash}`} type="video/ogg"></source>
                     
                      </video> */}




                      <iframe
                        title="test"
                        className="embed-responsive-item"
                        //src={`https://vimeo.com/${introVideoId}?h=${introVideoHash}`}
                        src={`https://player.vimeo.com/video/${introVideoId}?h=${introVideoHash}`}
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      />
                      <button
                        className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                        onClick={deleteVideo}
                        style={{ width: "226px" }}
                        disabled={buttonsDisabled}
                      >
                        Delete
                      </button>
                    </div>
                  ) : (
                    <AvField
                      name="cand_video1"
                      type="file"
                      className="form-control"
                      id="validationCustom03"
                      onChange={uploadVideo1}
                      rows="1"
                    />
                  )}
                  {uploadPercentage && (

                    <div className="mt-4">
                      <Progress
                        color="primary"
                        value={uploadPercentage}
                        striped
                        animated
                        style={{ height: "20px" }}
                      >
                        {/* Uploading {uploadPercentage}% */}
                      </Progress>
                    </div>


                  )}

                </div>
              </Col>

              {/* {isSelected ? (
                      <div>
                        <p>Video Link:{master.vid_url} </p>
                      </div>
                    ) : (
                      <p>Select a file to show details</p>
                    )} */}
              <Col md="6">
                <div className="mb-3">
                  <Label htmlFor="validationCustom03">Teaching video</Label>
                  {/* {videoData?.candVideo2 ? (
                  <div div className="img-wraps">
                    {videoData.candVideo2 &&
                    videoData.candVideo2.startsWith("http") ? (
                      <video
                        className="VideoInput_video"
                        width="150"
                        height="150"
                        controls
                        src={`${videoData.candVideo2}`}
                      />
                    ) : (
                      <video
                        className="VideoInput_video"
                        width="150"
                        height="150"
                        controls
                        src={videoData?.candVideo2}
                      />
                    )}

                    <button
                      className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                      onClick={deleteVideo2}
                      style={{ width: "150px" }}
                    >
                      Delete
                    </button>
                  </div>
                ) : ( */}
                  {candidateVideo2 && candidateVideo2Play === true ? (
                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                      <iframe
                        title="test"
                        className="embed-responsive-item"
                        src={`https://player.vimeo.com/video/${candidateVideo2}?h=${candidateVideo2Hash}`}
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      />
                      <button
                        className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                        onClick={deleteVideo2}
                        style={{ width: "226px" }}
                        disabled={buttonssDisabled}
                      >
                        Delete
                      </button>
                    </div>
                  ) : (
                    <AvField
                      name="cand_video2"
                      type="file"
                      className="form-control"
                      id="validationCustom03"
                      onChange={uploadVideo2}
                      rows="1"
                    />
                  )}
                  {uploadPercentage2 && (
                    <div className="mt-4">
                      <Progress
                        color="primary"
                        value={uploadPercentage2}
                        striped
                        animated
                        style={{ height: "20px" }}
                      ></Progress>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <Col md="12">
              <div className="mb-3" style={{ float: "right" }}>
                {/* {candidateIdTobeUpdated ? (
                  <Button color="primary" type="submit">
                    {"Update"}
                  </Button>
                ) : ( */}
                <Button
                  color="primary"
                  type="submit"
                  onClick={submit}
                  disabled={buttonDisabled}
                >
                  {"Submit"}
                </Button>
                {/* )} */}
              </div>
            </Col>
          </div>
        </AvForm>
      </Modal>
      {/* video upload popup end */}
      {/** Followup Modal */}
      <Modal show={openModal} size="xl" centered={true} id="followupModal">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Register No #{modalData?.cand_reg_no}</h5>
          <button
            type="button"
            onClick={() => {
              setopenModal(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>

        </div>
        <div className="modal-body">
          <Row>
            <Col md="12">
              <Card><CardBody>
                <table className="followupBasictable">
                  <tr>
                    <td><b>Name</b></td><td>: {modalData?.cand_name}</td>
                    <td><b>Email</b></td><td>: {modalData?.cand_email}</td>
                    <td><b>Mobile</b></td><td>: {modalData?.cand_mob}</td>
                    <td><b>Alternate No</b></td><td>: {modalData?.cand_alt_no}</td>
                  </tr>
                  <tr>
                    <td><b>Address</b></td><td>: {modalData?.cand_address}</td>
                    <td><b>City</b></td><td>: {modalData?.cand_city}</td>
                    <td><b>District</b></td><td>: {modalData?.cand_district?.district_name}</td>
                    <td><b>State</b></td><td>: {modalData?.cand_state?.name}</td>
                  </tr>
                </table>
              </CardBody>

              </Card>

            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleFollowupSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <div className={followupErrors?.followup_current_status ? "my-is-invalid" : ""}
                          >
                            <Select
                              name="follow_status"
                              value={selectedFollowStatus}
                              onChange={(selected) => {
                                handleFollowChange(selected, "status");
                              }}
                              options={followupstatusOptions}
                              classNamePrefix=" select2-selection"
                            />

                          </div>

                          <div class="my-invalid-feedback">{followupErrors?.followup_current_status}</div>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Next Followup</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="followup_next_date"
                            name="followup_next_date"
                            value={followupObject?.followup_next_date}
                            onChange={(e) => {
                              handleFollowChange(e, "followup_next_date");
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label htmlFor="followup_message">Remarks </Label>
                          <AvField
                            name="followup_message"
                            placeholder="Remarks"
                            type="textarea"
                            // errorMessage="Enter Remarks "
                            className={followupErrors?.followup_message ? "form-control my-is-invalid" : "form-control"}
                            //validate={{ required: { value: true } }}
                            id="followup_message"
                            value={followupObject?.followup_message}
                            onChange={(e) => {
                              handleFollowChange(e, "followup_message");
                            }}
                          />
                          <div class="my-invalid-feedback">{followupErrors?.followup_message}</div>
                        </div>
                      </Col>
                      <Col md="1" style={{ marginTop: "2.5%" }}>
                        <div className="mb-3">

                          <Button color="primary" type="submit">
                            {"Submit"}
                          </Button>
                        </div>
                      </Col>
                      <Col md="1" style={{ marginTop: "2.5%" }}>
                        <div className="mb-3">

                          <Button color="danger" type="reset" onClick={resetFollowUpForm}>
                            {"Reset"}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                  </AvForm>
                </CardBody>

              </Card>
            </Col>

            <Col md="12">
              <Card><CardBody>
                <Row>
                  <Col md="2">
                    <div>
                      <Label htmlFor="validationCustom05">From</Label>
                      <input
                        className="form-control"
                        type="date"
                        id="from_date"
                        name="from_date"
                        value={followSearch?.from_date}
                        onChange={(e) => {
                          handleFollowFilter(e, "from_date");
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div >
                      <Label htmlFor="validationCustom05">To</Label>
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        value={followSearch?.to_date}
                        onChange={(e) => {
                          handleFollowFilter(e, "to_date");
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div>
                      <Label>Status</Label>
                      <Select
                        name="follow_status"
                        value={followSearch?.statusObject}
                        onChange={(selected) => {
                          handleFollowFilter(selected, "status");
                        }}
                        options={followupstatusOptions}
                        classNamePrefix=" select2-selection"
                      />

                    </div>
                  </Col>
                  <Col md="2">
                    <div>
                      <Label>Staff</Label>
                      <Select
                        name="staff_id"
                        value={followSearch?.staffObject}
                        onChange={(selected) => {
                          handleFollowFilter(selected, "staff");
                        }}
                        options={staffOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="1" style={{ marginTop: "2.5%" }}>
                    <div className="mb-3">

                      <Button color="danger" type="reset" onClick={resetFollowupFilter}>
                        {"Reset"}
                      </Button>
                    </div>
                  </Col>

                </Row>
                <MDBDataTable
                  id="candidatefollowupTableId"
                  responsive
                  bordered
                  data={followup_data}
                  searching={true}
                  info={false}
                  disableRetreatAfterSorting={true}
                  paging={false}
                  onSearch={(value) => { handleSearchFollowup(value) }}
                  entries={PerPageCount}
                />
                {totalFPages > 20 && (
                  <div className="pagination-div">
                    {/* Display the page details */}
                    <div className="page-details">
                      Showing {indexOfFFirstTransfer + 1} to {indexOfFFirstTransfer + followupData.length} of {totalFPages} entries
                    </div>
                    <Pagination
                      color="standard"
                      shape="rounded"
                      defaultPage={1}
                      count={Math.ceil(totalFPages / PerPageCount)}
                      page={currentFPage}
                      onChange={followuppaginate}
                      info={true}
                      size="large"
                    />
                  </div>
                )}
              </CardBody>

              </Card>
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Candidates" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label>Customer Type</Label>
                          <Select
                            name="cust_type"
                            value={selectedCustomerType}
                            onChange={(selected) => {
                              handleSelectChange(selected, "customerType");
                            }}
                            options={customerType}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedBillingtype === null
                              ? errors.customertypeError
                              : ""}
                          </p>
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">
                            Candidate Name
                          </Label>
                          <AvField
                            name="cand_name"
                            placeholder="Candidate Name"
                            type="text"
                            errorMessage="Enter name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={master?.cand_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Email</Label>
                          <AvField
                            name="cand_email"
                            placeholder="Email"
                            type="email"
                            errorMessage="Enter valid email"
                            className="form-control"
                            validate={{ email: true }}
                            id="validationCustom01"
                            value={master?.cand_email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Mobile Number
                          </Label>
                          <AvField
                            name="cand_mob"
                            placeholder="Mobile Number"
                            type="phone"
                            errorMessage="Enter valid 10 digit number"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              pattern: { value: "/^[6-9][0-9]{9}$/" },
                            }}
                            id="validationCustom03"
                            value={master?.cand_mob}
                            onChange={handleInputChange}
                            minLength={10}
                            maxLength={10}
                          />
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Landline Number
                          </Label>
                          <AvField
                            name="cust_landline_no"
                            placeholder="Landline Number"
                            type="phone"
                            errorMessage="Enter valid 11 digit number"
                            className="form-control"
                            validate={{
                              minLength: { value: 11 },
                              maxLength: { value: 11 },
                            }}
                            id="validationCustom03"
                            value={master?.cust_landline_no}
                            onChange={handleInputChange}
                            // minLength={11}
                            // maxLength={11}
                          />
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Alternate Number
                          </Label>
                          <AvField
                            name="cand_alt_no"
                            placeholder="Alternate Number"
                            type="phone"
                            errorMessage="Enter valid 10 digit number"
                            className="form-control"
                            validate={{
                              minLength: { value: 10 },
                              maxLength: { value: 10 },
                              pattern: { value: "/^[6-9][0-9]{9}$/" },
                            }}
                            id="validationCustom01"
                            value={master?.cand_alt_no}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Gender</Label>
                          <Select
                            name="cand_gender"
                            value={selectedGender}
                            onChange={(selected) => {
                              handleSelectChange(selected, "gender");
                            }}
                            options={[
                              {
                                label: "Male",
                                value: 0,
                              },
                              {
                                label: "Female",
                                value: 1,
                              },
                              {
                                label: "Transgender",
                                value: 2,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">DOB</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="cand_dob"
                            name="cand_dob"
                            value={master?.cand_dob}
                            onChange={handleDate}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Marital Status</Label>
                          <Select
                            name="cand_marital_status"
                            value={selectedMaritalStatus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "maritalStatus");
                            }}
                            options={[
                              {
                                label: "Married",
                                value: 0,
                              },
                              {
                                label: "Single",
                                value: 1,
                              },
                              {
                                label: "Separated ",
                                value: 2,
                              },
                              {
                                label: "Divorced",
                                value: 3,
                              },
                              {
                                label: "Widowed",
                                value: 4,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Address </Label>
                          <AvField
                            name="cand_address"
                            placeholder="Address"
                            type="textarea"
                            // errorMessage="Enter Address "
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.cand_address}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Address 2</Label>
                          <AvField
                            name="cust_address1"
                            placeholder="Address 2"
                            type="text"
                            // errorMessage="Enter Address1"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.cust_address1}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col> */}

                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label>Country</Label>
                          <Select
                            name="cust_designation"
                            value={selectedDesignation}
                            onChange={(selected) => {
                              handleSelectChange(selected, "designation");
                            }}
                            options={[
                              {
                                label: "India",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            name="cand_state"
                            value={selectedState}
                            onChange={(selected) => {
                              handleSelectChange(selected, "state");
                            }}
                            options={stateOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="cand_district"
                            value={selectedDistrict}
                            onChange={(selected) => {
                              handleSelectChange(selected, "district");
                            }}
                            options={districtOptions}
                            classNamePrefix="select2-selection"
                          />
                          {/* <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedDistrict === null
                              ? errors.districtError
                              : ""}
                          </p> */}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">City</Label>
                          <AvField
                            name="cand_city"
                            placeholder="City"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={master?.cand_city}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">PIN</Label>
                          <AvField
                            name="cand_pin"
                            placeholder="PIN"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={handleInputChange}
                            validate={{
                              pattern: {
                                value: "^[0-9]+$",
                                errorMessage: "PIN must be only with numbers",
                              },
                            }}
                            value={master?.cand_pin ? master.cand_pin : ""}
                          // min={0}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Qualification</Label>
                          <Select
                            isMulti
                            name="cand_qualifcatn"
                            value={selectedQualification}
                            onChange={(selected) => {
                              handleSelectChange(selected, "qualification");
                            }}
                            options={qualificationOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Experience</Label>
                          <Select
                            name="cand_experience"
                            value={selectedExperience}
                            onChange={(selected) => {
                              handleSelectChange(selected, "experience");
                            }}
                            // options={experienceOptions}
                            options={[
                              {
                                label: "0",
                                value: 0,
                              },
                              {
                                label: "1",
                                value: 1,
                              },
                              {
                                label: "2",
                                value: 2,
                              },
                              {
                                label: "3",
                                value: 3,
                              },
                              {
                                label: "4",
                                value: 4,
                              },
                              {
                                label: "5",
                                value: 5,
                              },
                              {
                                label: "6",
                                value: 6,
                              },
                              {
                                label: "7",
                                value: 7,
                              },
                              {
                                label: "8",
                                value: 8,
                              },
                              {
                                label: "9",
                                value: 9,
                              },
                              {
                                label: "10",
                                value: 10,
                              },
                              {
                                label: "11",
                                value: 11,
                              },
                              {
                                label: "12",
                                value: 12,
                              },
                              {
                                label: "13",
                                value: 13,
                              },
                              {
                                label: "14",
                                value: 14,
                              },
                              {
                                label: "15",
                                value: 15,
                              },
                              {
                                label: "16",
                                value: 16,
                              },
                              {
                                label: "17",
                                value: 17,
                              },
                              {
                                label: "18",
                                value: 18,
                              },
                              {
                                label: "19",
                                value: 19,
                              },
                              {
                                label: "20",
                                value: 20,
                              },
                              {
                                label: "21",
                                value: 21,
                              },
                              {
                                label: "22",
                                value: 22,
                              },
                              {
                                label: "23",
                                value: 23,
                              },
                              {
                                label: "24",
                                value: 24,
                              },
                              {
                                label: "25",
                                value: 25,
                              },
                              {
                                label: "26",
                                value: 26,
                              },
                              {
                                label: "27",
                                value: 27,
                              },
                              {
                                label: "28",
                                value: 28,
                              },
                              {
                                label: "29",
                                value: 29,
                              },
                              {
                                label: "30",
                                value: 30,
                              },
                              // {
                              //   label: "30+",
                              //   value: 30,
                              // },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Subject</Label>
                          <Select
                            isMulti
                            name="cand_subject"
                            value={selectedSubject}
                            onChange={(selected) => {
                              handleSelectChange(selected, "subject");
                            }}
                            options={subjectOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Syllabus</Label>
                          <Select
                            isMulti
                            name="cand_syllabus"
                            value={selectedSyllabus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "syllabus");
                            }}
                            options={syllabusOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Classes</Label>
                          <Select
                            isMulti
                            name="cand_classes"
                            value={selectedClass}
                            onChange={(selected) => {
                              handleSelectChange(selected, "classes");
                            }}
                            options={classOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Medium</Label>
                          <Select
                            isMulti
                            name="cand_medium"
                            value={selectedMedium}
                            onChange={(selected) => {
                              handleSelectChange(selected, "medium");
                            }}
                            options={mediumOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Remarks</Label>
                          <AvField
                            name="cand_remarks"
                            placeholder="Remarks"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={master?.cand_remarks}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col> */}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Candidate Image</Label>
                          {master?.cand_image ? (
                            <div div className="img-wraps">
                              {master.cand_image && master.cand_image.startsWith("http") ? (
                                <img alt="" width="150" height="150" src={`${master.cand_image}`} />
                              ) : (
                                <img alt="" width="150" height="150" src={`${API_URL}uploads/candidate_images/${master.cand_image}`} />
                              )}

                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteCustomerImage}
                                style={{ width: "150px" }}>
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField name="cand_image" type="file" className="form-control" id="validationCustom03" onChange={uploadImage} rows="1" />
                          )}

                          {/* {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )} */}
                          {/* <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master.cand_image ? errors.imageError : ""}
                          </p> */}
                        </div>
                      </Col>
                      {/* </Row> */}

                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Candidate video 1
                          </Label>
                          {master?.cand_video1 ? (
                            <div div className="img-wraps">
                              {master.cand_video1 &&
                              master.cand_video1.startsWith("http") ? (
                                <video
                                  className="VideoInput_video"
                                  width="150"
                                  height="150"
                                  controls
                                  src={`${master.cand_video1}`}
                                />
                              ) : (
                                <video
                                  className="VideoInput_video"
                                  width="150"
                                  height="150"
                                  controls
                                  src={master?.cand_video1}
                                />
                              )}

                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteVideo}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="cand_video1"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadVideo1}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col> */}

                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Candidate video 2
                          </Label>
                          {master?.cand_video2 ? (
                            <div div className="img-wraps">
                              {master.cand_video2 &&
                              master.cand_video2.startsWith("http") ? (
                                <video
                                  className="VideoInput_video"
                                  width="150"
                                  height="150"
                                  controls
                                  src={`${master.cand_video2}`}
                                />
                              ) : (
                                <video
                                  className="VideoInput_video"
                                  width="150"
                                  height="150"
                                  controls
                                  src={master?.cand_video2}
                                />
                              )}

                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteVideo2}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="cand_video2"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadVideo2}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col> */}

                      <Col md="3" style={{ marginTop: "2.5%" }}>
                        <div className="mb-3">
                          {candidateIdTobeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Qualification</Label>
                        <Select
                          isMulti
                          name="qualification_id"
                          value={selectedQualificationSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "qualification_id");
                          }}
                          options={qualificationOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Subject</Label>
                        <Select
                          name="cust_type_id"
                          value={selectedSubjectSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "subject");
                          }}
                          options={subjectOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Medium</Label>
                        <Select
                          name="ward_id"
                          value={selectedMediumSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "medium");
                          }}
                          options={mediumOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Syllabus</Label>
                        <Select
                          name="group_id"
                          value={selectedSyllabusSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "syllabus");
                          }}
                          options={syllabusOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Classes</Label>
                        <Select
                          name="class"
                          value={selectedClassSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "class");
                          }}
                          options={classOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>State</Label>
                        <Select
                          name="verification_status"
                          value={selectedStateSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "state");
                          }}
                          options={stateOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>District</Label>
                        <Select
                          name="verification_status"
                          value={selectedDistrictSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "district");
                          }}
                          options={districtOptionsSearch}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Gender</Label>
                        <Select
                          name="gender"
                          value={selectedGenderSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "gender")
                          }}
                          options={[
                            { label: "Male", value: 0 },
                            { label: "Female", value: 1 },
                            { label: "Transgender", value: 2 }
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>Status</Label>
                        <Select
                          name="verification_status"
                          value={selectedStatus}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "status");
                          }}
                          options={[
                            {
                              label: "New",
                              value: 0,
                            },
                            {
                              label: "Saved",
                              value: 1,
                            },
                            {
                              label: "Applied",
                              value: 2,
                            },
                            {
                              label: "Shortlisted",
                              value: 3,
                            },
                            {
                              label: "Placed",
                              value: 4,
                            },
                            {
                              label: "Declined",
                              value: 5,
                            },
                            {
                              label: "Verified",
                              value: 6,
                            },
                            {
                              label: "Not Verified",
                              value: 7,
                            },
                            {
                              label: "With Video",
                              value: 8,
                            },
                            {
                              label: "Without Video",
                              value: 9,
                            },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div>
                        <Label>Followup Status</Label>
                        <Select
                          name="follow_status"
                          value={selectedFollowupStatus}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "follow_status");
                          }}
                          options={followupstatusOptions}
                          classNamePrefix=" select2-selection"
                        />

                      </div>
                    </Col>
                    <Col md="2">
                      <div>
                        <Label htmlFor="validationCustom05">Next Followup</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="next_date"
                          name="next_date"
                          value={searchData?.next_date}
                          onChange={(e) => {
                            handleSearchSelectChange(e, "next_date");
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4 mb-3" style={{ paddingTop: "5px" }}>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4 mb-3" style={{ paddingTop: "4px" }}>
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={"Candidates.xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {/* <MDBDataTable
                    id="candidateTableId"
                    
                    
                    entries={20}
                  /> */}
                  <MDBDataTable
                    id="candidateTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    paging={false}
                    onSearch={(value) => { handleSearch(value) }}
                    entries={PerPageCount}
                  />
                  {totalPages > 20 && (
                    <div className="pagination-div">
                      <div className="page-details">
                        Showing {indexOfFirstTransfer + 1} to {indexOfFirstTransfer + details.length} of {totalPages} entries
                      </div>
                      <Pagination
                        color="standard"
                        shape="rounded"
                        defaultPage={1}
                        count={Math.ceil(totalPages / PerPageCount)}
                        page={currentPage}
                        onChange={paginate}
                        size="large"
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(Customers));

Customers.propTypes = {
  error: PropTypes.any,
  users: PropTypes.array,
};