import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
} from "reactstrap";
import $ from "jquery";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Enquiry.scss";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";

import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import Select from "react-select";
import moment from "moment";

const Enquiry = () => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [details, setDetails] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedStaff1, setSelectedStaff1] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
    fetch_all_staff(currentLocalbody);
  }, [currentLocalbody]);

  const handleTableData = (from_date = "", to_date = "", staff_id = "") => {
    let userid = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      userid = user_obj._id;
    }
    var url =
      `${API_URL}webforms/enquiry`;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }
    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&staff_id=" +
      staff_id;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;

            item.time = moment(item.time, "HHmmss").format("hh:mm a");
          });
        setDetails(result);
      });
  };
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  let handleDate = (e) => {
    if (e.target.name == "from_date") {
      var date1 = e.target.value;
      var date2 = searchData?.to_date;
    }
    else {
      var date1 = searchData?.from_date;
      var date2 = e.target.value;
    }

    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(date1, date2, staff_id);
  };
  const handleSelectChange1 = (selected) => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    setSelectedStaff1(selected);
    setSearchData({
      ...searchData,
      ["staff_id"]: selected.value,
    });
    handleTableData(date1, date2, selected.value);
  };
  const handleSelectChange = (selected) => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    setSelectedStaff(selected);
    setSearchData({
      ...searchData,
      ["staff_id"]: selected.value,
    });
    handleTableData(date1, date2, selected.value);
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedStaff(null);
    handleTableData();
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 100,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Contact Number",
        field: "cnumber",
        sort: "asc",
        width: 100,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 100,
      },

      {
        label: "Comments",
        field: "comments",
        sort: "asc",
        width: 100,
      },
    ],
    rows: [{
      id: "1",
      date: "17-05-2022",
      time: "5:00 pm",
      category: "Institution",
      state: "Kerala",
      name: "Shisira",
      cnumber: "7594051199",
      email: "shishirasrv@gmail.com",
      comments: "Want to know more about your process and payment details ",
    },
    {
      id: "2",
      date: "16-05-2022",
      time: "11:00 pm",
      category: "Candidate",
      state: "Karnataka",
      name: "Rijina",
      cnumber: "7594051999",
      email: "rijinasrv@gmail.com",
      comments: "Is registeration paid?",
    },
    {
      id: "3",
      date: "16-05-2022",
      time: "3:00 pm",
      category: "Institution",
      state: "Kerala",
      name: "Bindiya",
      cnumber: "7594059999",
      email: "bindiyasrv@gmail.com",
      comments: "Unable to contact you,need to know more about your recruitments",
    },
    ],
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Web Enquiries" />

          {/*Text field 1*/}
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={searchData?.from_date}
                            onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={searchData?.to_date}
                            min={searchData?.from_date}
                            onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <Select
                            name="staff_id"
                            value={selectedStaff1}
                            onChange={(selected) => {
                              handleSelectChange1(selected);
                            }}
                            options={[
                              { label: "Institution", value: 0 },
                              { label: "Candidate", value: 1 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            name="staff_id"
                            value={selectedStaff}
                            onChange={(selected) => {
                              handleSelectChange(selected);
                            }}
                            options={[
                              { label: "Andhra Pradesh", value: 1 },
                              { label: "Arunachal Pradesh", value: 2 },
                              { label: "Assam", value: 3 },
                              { label: "Bihar", value: 4 },
                              { label: "Chhattisgarh", value: 5 },
                              { label: "Goa", value: 6 },
                              { label: "Gujarat", value: 7 },
                              { label: "Haryana", value: 8 },
                              { label: "Himachal Pradesh", value: 9 },
                              { label: "Jammu and Kashmir", value: 10 },
                              { label: "Jharkhand", value: 11 },
                              { label: "Karnataka", value: 12 },
                              { label: "Kerala", value: 13 },
                              { label: "Madhya Pradesh", value: 14 },
                              { label: "Maharashtra", value: 15 },
                              { label: "Manipur", value: 16 },
                              { label: "Meghalaya", value: 17 },
                              { label: "Mizoram", value: 18 },
                              { label: "Nagaland", value: 19 },
                              { label: "Odisha", value: 20 },
                              { label: "Punjab", value: 21 },
                              { label: "Rajasthan", value: 22 },
                              { label: "Sikkim", value: 23 },
                              { label: "Tamil Nadu", value: 24 },
                              { label: "Telangana", value: 25 },
                              { label: "Tripura", value: 26 },
                              { label: "Uttar Pradesh", value: 27 },
                              { label: "Uttarakhand", value: 28 },
                              { label: "West Bengal", value: 29 },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4 mb-3">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="useractivitylog1Id"
                    responsive
                    bordered
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Enquiry;