import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { apiError } from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./subject.scss";

const Subject = (props) => {
  const [subjectObject, setSubjectObject] = useState({});
  const [subjectIdTobeUpdated, setSubjectIdToBeUpdated] = useState(null);
  const [subjectIdToBeDeleted, setSubjectIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [subjectForTable, setSubjectForTable] = useState([]);
  const [subjectValue, setsubjectValue] = useState("");
  const [selectedSubject, setselectedSubject] = useState(null);
  const [errors, setErrors] = useState({ typeError: "" })

  const {
    districts,
    addingSubject,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    handleTableData();
  }, []);

  function handleTableData() {
    var url = `${API_URL}subject/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let subjectData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  setSubjectIdToBeUpdated(item._id)
                  preUpdateSubject(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setSubjectIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.date = moment(item.subject_date).format("DD-MM-YYYY");
          //  item.time = moment(item.subject_time, "HHmmss").format("hh:mm a");
          if (item?.subjectup_date)
            item.date = moment(item.subjectup_date).format("DD-MM-YYYY");
          else item.date = moment(item.subject_date).format("DD-MM-YYYY");

          if (item?.subjectup_time)
            item.time = moment(item.subjectup_time, "HHmmss").format("hh:mm a");
          else
            item.time = moment(item.subject_time, "HHmmss").format("hh:mm a");

          if (item?.subject_updatedby)
            item.staff = item.subject_updatedby.firstName;
          else item.staff = item?.subject_addedby.firstName;
          subjectData.push(item);
        });
        setSubjectForTable(subjectData);
      });
  }

  let preUpdateSubject = (item) => {

    if (item.type == 0) {
      setselectedSubject({ label: "Teacher jobs", value: "0" });
    } else if (item.type == 1) {
      setselectedSubject({ label: "Department job", value: "1" });
    }
    setSubjectObject(item);
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },

      {
        label: "Subject Name",
        field: "subject_name",
        sort: "desc",
        width: 400,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: subjectForTable,
  };

  function handleChangeSubject(e) {
    let name = e.target.name;
    let value = e.target.value;
    setsubjectValue(value);
    setSubjectObject({ ...subjectObject, [name]: value });
  }

  const handleValidSubmit = () => {
    if (selectedSubject === null) {
      let errorVal = errors
      if (selectedSubject === null) {
        errorVal.typeError = "Please select type"
      }
      setErrors({
        ...errorVal
      })
    } else {
      if (subjectIdTobeUpdated) {
        let bodydata = {
          subject_id: subjectIdTobeUpdated,
          subject_name: subjectObject.subject_name,
          type: subjectObject.type
        };
        axios
          .put(`${API_URL}subject`, bodydata, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Subject updated successfully");
              setsubjectValue("");
              formRef.current.reset();
              handleTableData();
              setSubjectObject({});
              setSubjectIdToBeUpdated(null);
              setselectedSubject(null);
              setErrors({
                ...errors,
                typeError: ""
              })
            } else {
              toastr.error("Failed to update subject");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}subject`, subjectObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Subject added succesfully");
              handleTableData();
              setsubjectValue("");
              formRef.current.reset();
              setSubjectObject({
                ...subjectObject,
                type: ""
              });
              setselectedSubject(null);
              setErrors({
                ...errors,
                typeError: ""
              })
            } else {
              toastr.error("Failed to add subject");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };
  function handleSelectChange(selecetd, name) {
    switch (name) {
      case "type":
        setselectedSubject(selecetd);
        setSubjectObject({ ...subjectObject, [name]: selecetd.value });
        break;

      default:
        break;
    }
  }


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}subject` + "/" + subjectIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Subject deleted successfully");
                  if (
                    subjectObject &&
                    subjectObject._id === subjectIdToBeDeleted
                  ) {
                    setsubjectValue("");
                    formRef.current.reset();
                    setSubjectObject({});
                    setselectedSubject(null)
                    setSubjectIdToBeUpdated(null);
                  }
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Subject");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Subject" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <Select
                            name="type"
                            options={[
                              { label: "Teacher job", value: "0" },
                              { label: "Department job", value: "1" },
                            ]}
                            value={selectedSubject}
                            onChange={(selecetd) => {
                              handleSelectChange(selecetd, "type");
                            }}
                            validate={{ required: { value: true } }}
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedSubject === null ? errors?.typeError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Subject</Label>
                          <AvField
                            name="subject_name"
                            placeholder="Subject name"
                            type="text"
                            errorMessage="Enter Subject Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={subjectObject?.subject_name}
                            onChange={handleChangeSubject}
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          {subjectIdTobeUpdated ? (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingSubject ? true : false}
                            >
                              {addingSubject ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingSubject ? true : false}
                            >
                              {addingSubject ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="subjecttTableId"
                    responsive
                    // striped
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(Subject));

Subject.propTypes = {
  error: PropTypes.any,
  districts: PropTypes.array,
};
