import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  // Table,
  // Container,
  CardTitle,
} from "reactstrap";
import ConnectWithoutContactRoundedIcon from "@mui/icons-material/ConnectWithoutContactRounded";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Box, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ArticleIcon from "@mui/icons-material/Article";
import CancelIcon from "@mui/icons-material/Cancel";
// import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
// import Inventory2Icon from "@mui/icons-material/Inventory2";
// import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";


import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Slide from "@mui/material/Slide";

import DescriptionIcon from "@mui/icons-material/Description";
import SummarizeIcon from "@mui/icons-material/Summarize";
import "./ViewCustomer.scss";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import logoSm from "../../../assets/images/letterboard.png";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { AvForm, AvField } from "availity-reactstrap-validation";

import VideocamIcon from "@mui/icons-material/Videocam";
import Select from "react-select";
import DownloadIcon from "@mui/icons-material/Download";
import saveAs from "file-saver";
import Pagination from "@mui/material/Pagination";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewCustomer = (props) => {
  const [details, setDetails] = useState({});
  const [appliedData, setAppliedData] = useState([]);
  const [savedData, setSavedData] = useState([]);
  const [shortlistedData, setShortlistedData] = useState([]);
  const [placedData, setPlacedData] = useState([]);
  const [showRemainingDetails, setShowRemainingDetails] = useState(false);
  // const [agentNumber, setAgentNumber] = useState("");
  const [popupSavedView, setPopupSavedView] = useState(false);
  const [popupSavedData, setPopupSavedData] = useState({});
  const [popupAppliedView, setPopupAppliedView] = useState(false);
  const [popupAppliedData, setPopupAppliedData] = useState({});
  const [popupShortlistedView, setPopupShortlistedView] = useState(false);
  const [popupShortlistedData, setPopupShortlistedData] = useState({});
  const [popupPlacedView, setPopupPlacedView] = useState(false);
  const [popupPlacedData, setPopupPlacedData] = useState({});
  const [totalApplied, setTotalApplied] = useState(0);
  const [totalSaved, setTotalSaved] = useState(0);
  const [totalShortlisted, setTotalShortlisted] = useState(0);
  const [totalPlaced, setTotalPlaced] = useState(0);
  const [master, setMaster] = useState({});
  const [remarksData, setRemarksData] = useState([]);
  const [videoForm, setVideoForm] = useState(false);
  const [popupContacted, setpopupContacted] = useState(false);
  const [showDeclineAppliedForm, setShowDeclineAppliedForm] = useState(false);
  const [declineAppliedData, setDeclineAppliedData] = useState({});
  const [appliedErrors, setAppliedErrors] = useState({
    remarkError: "",
  });
  const [selectedDeclineApplied, setSelectedDeclineApplied] = useState(null);
  const [selectedDeclineShortlisted, setSelectedDeclineShortlisted] =
    useState(null);
  const [selectedDeclinePlaced, setSelectedDeclinePlaced] = useState(null);
  const [declinePlacedData, setDeclinePlacededData] = useState({});
  const [placedErrors, setPlacedErrors] = useState({
    remarkError: "",
  });
  const [showDeclinePlacedForm, setShowDeclinePlacedForm] = useState(false);
  const [ReasonsOption, setReasonsOptions] = useState([]);
  const [showDeclineShortlistedForm, setShowDeclineShortlistedForm] =
    useState(false);
  const [declineShortlistedData, setDeclineShortlistedData] = useState({});
  const [shortlistedErrors, setShortlistedErrors] = useState({
    remarkError: "",
  });

  const formRef = useRef();
  const { canId } = props.match.params;
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  /** pagination */
  const [searchValueJob, setSearchValueJob] = useState("");
  const [searchValueApp, setSearchValueApp] = useState("");
  const [searchValueShort, setSearchValueShort] = useState("");
  const [searchValuePlaced, setSearchValuePlaced] = useState("");

  const [totalPagesJob, setTotalPagesJob] = useState(0);
  const [totalPagesApp, setTotalPagesApp] = useState(0);
  const [totalPagesShort, setTotalPagesShort] = useState(0);
  const [totalPagesPlaced, setTotalPagesPlaced] = useState(0);
  const [totalPagesRemarks, setTotalPagesRemarks] = useState(0);

  const [currentPageJob, setCurrentPageJob] = useState(1);
  const [currentPageApp, setCurrentPageApp] = useState(1);
  const [currentPageShort, setCurrentPageShort] = useState(1);
  const [currentPagePlaced, setCurrentPagePlaced] = useState(1);
  const [currentPageReamrks, setCurrentPageRemarks] = useState(1);

  const [PerPageCount, setPerPageCount] = useState(20);

  const [indexOfLastTransfer1, setindexOfLastTransfer1] = useState(
    currentPageJob * PerPageCount
  );
  const [indexOfFirstTransfer1, setindexOfFirstTransfer1] = useState(
    indexOfLastTransfer1 - PerPageCount
  );
  const [indexOfLastTransfer2, setindexOfLastTransfer2] = useState(
    currentPageApp * PerPageCount
  );

  const [indexOfFirstTransfer2, setindexOfFirstTransfer2] = useState(
    indexOfLastTransfer2 - PerPageCount
  );
  const [indexOfLastTransfer3, setindexOfLastTransfer3] = useState(
    currentPageShort * PerPageCount
  );

  const [indexOfFirstTransfer3, setindexOfFirstTransfer3] = useState(
    indexOfLastTransfer3 - PerPageCount
  );
  const [indexOfLastTransfer4, setindexOfLastTransfer4] = useState(
    currentPageShort * PerPageCount
  );

  const [indexOfFirstTransfer4, setindexOfFirstTransfer4] = useState(
    indexOfLastTransfer4 - PerPageCount
  );
  const [indexOfLastTransfer5, setindexOfLastTransfer5] = useState(
    currentPageReamrks * PerPageCount
  );

  const [indexOfFirstTransfer5, setindexOfFirstTransfer5] = useState(
    indexOfLastTransfer5 - PerPageCount
  );
  /** Followup Properties */
  const [staffOptions, setStaffOptions] = useState([]);
  const [followupData, setFollowupData] = useState([]);
  const [followupErrors, setfollowupErrors] = useState({});
  const [followupObject, setfollowupObject] = useState({});
  const [selectedFollowStatus, setselectedFollowStatus] = useState(null);
  const [followupstatusOptions, setfollowupstatusOptions] = useState([]);
  /** pagination followup */
  const [followSearch, setfollowSearch] = useState(null);
  const [searchFollowValue, setSearchFollowValue] = useState("");
  const [totalFPages, setTotalFPages] = useState(0);
  const [currentFPage, setCurrentFPage] = useState(1);
  const [indexOfFLastTransfer, setindexOfFLastTransfer] = useState(
    currentFPage * PerPageCount
  );

  const [indexOfFFirstTransfer, setindexOfFFirstTransfer] = useState(
    indexOfFLastTransfer - PerPageCount
  );
  const followuppaginate = (event, value) => {
    setCurrentFPage(value);
    const indexOfFLastTransfer = value * PerPageCount;
    const indexOfFFirstTransfer = indexOfFLastTransfer - PerPageCount;
    setindexOfFFirstTransfer(indexOfFFirstTransfer);
    setindexOfFLastTransfer(indexOfFLastTransfer);
    fetch_candidate_followups(
      searchFollowValue,
      details?._id,
      followSearch?.from_date,
      followSearch?.to_date,
      followSearch?.status,
      followSearch?.staff,
      indexOfFFirstTransfer,
      indexOfFLastTransfer
    );
  };
  const resetFollowupPaginate = () => {
    setCurrentFPage(1);
    const indexOfFLastTransfer = PerPageCount;
    const indexOfFFirstTransfer = 0;
    setindexOfFFirstTransfer(indexOfFFirstTransfer);
    setindexOfFLastTransfer(indexOfFLastTransfer);
  };
  /** end pagination */
  useEffect(() => {
    if (followupData?.length == 0 && indexOfFFirstTransfer != 0) {
      resetFollowupPaginate();
      fetch_candidate_followups(
        searchFollowValue,
        details?._id,
        followSearch?.from_date,
        followSearch?.to_date,
        followSearch?.status,
        followSearch?.staff
      );
    }
  }, [followupData]);
  const handleFollowChange = (selected, type) => {
    switch (type) {
      case "status":
        setselectedFollowStatus(selected);
        setfollowupObject({
          ...followupObject,
          followup_current_status: selected.value,
        });
        setfollowupErrors({ ...followupErrors, followup_current_status: "" });
        break;
      default:
        setfollowupObject({
          ...followupObject,
          [selected.target.name]: selected.target.value,
        });
        setfollowupErrors({ ...followupErrors, [selected.target.name]: "" });
        break;
    }
  };
  const fetch_all_staff = () => {
    axios
      .get(`${API_URL}user`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };

  const handleFollowFilter = (selected, type) => {
    resetFollowupPaginate();
    switch (type) {
      case "status":
        setfollowSearch({
          ...followSearch,
          status: selected.value,
          statusObject: selected,
        });
        fetch_candidate_followups(
          searchFollowValue,
          details?._id,
          followSearch?.from_date,
          followSearch?.to_date,
          selected.value,
          followSearch?.staff
        );
        break;
      case "from_date":
        setfollowSearch({ ...followSearch, from_date: selected.target.value });
        fetch_candidate_followups(
          searchFollowValue,
          details?._id,
          selected.target.value,
          followSearch?.to_date,
          followSearch?.status,
          followSearch?.staff
        );
        break;
      case "to_date":
        setfollowSearch({ ...followSearch, to_date: selected.target.value });
        fetch_candidate_followups(
          searchFollowValue,
          details?._id,
          followSearch?.from_date,
          selected.target.value,
          followSearch?.status,
          followSearch?.staff
        );
        break;
      case "staff":
        setfollowSearch({
          ...followSearch,
          staff: selected.value,
          staffObject: selected,
        });
        fetch_candidate_followups(
          searchFollowValue,
          details?._id,
          followSearch?.from_date,
          followSearch?.to_date,
          followSearch?.status,
          selected.value
        );
        break;
    }
  };
  const resetFollowUpForm = () => {
    setselectedFollowStatus(null);
    setfollowupObject({
      followup_current_status: "",
      followup_message: "",
      followup_next_date: "",
    });
  };
  const fetch_candidate_followups = (
    search = "",
    id,
    from_date = "",
    to_date = "",
    status = "",
    staff = "",
    start = 0,
    end = PerPageCount
  ) => {
    axios
      .get(
        `${API_URL}followup/candidate-followups?candidate_id=${id}&from_date=${from_date}&to_date=${to_date}&search=${search}&start=${start}&end=${end}&status=${status}&staff=${staff}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        setTotalFPages(res.data.limit);
        setFollowupData(res.data.data);
      });
  };
  const fetch_followup_status = () => {
    axios
      .get(`${API_URL}followup/followup-status`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setfollowupstatusOptions([
          {
            options: data,
          },
        ]);
      });
  };
  const handleFollowupSubmit = () => {
    var errors = {};
    var err = 0;
    if (!followupObject?.followup_current_status) {
      errors.followup_current_status = "Select Status";
      err++;
    }

    if (!followupObject?.followup_message) {
      errors.followup_message = "Enter Message";
      err++;
    }
    if (err) {
      setfollowupErrors(errors);
      return;
    } else {
      followupObject.followup_candidate = details?._id;
      axios
        .post(`${API_URL}followup/add`, followupObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success) {
            fetch_candidate_followups(
              searchFollowValue,
              details?._id,
              followSearch?.from_date,
              followSearch?.to_date,
              followSearch?.status,
              followSearch?.staff,
              indexOfFFirstTransfer,
              indexOfFLastTransfer
            );
            setfollowupObject({
              followup_current_status: "",
              followup_message: "",
              followup_next_date: "",
            });
            setselectedFollowStatus(null);
            toastr.success("Submitted Successfully");
          } else {
            toastr.success("Failed to Submit Followup");
          }
        });
    }
  };
  const resetFollowupFilter = (value) => {
    resetFollowupPaginate();
    fetch_candidate_followups(searchFollowValue, details?._id);
    setfollowSearch({ statusObject: null, staffObject: null, to_date: "", from_date: "" });
  };
  const handleSearchFollowup = (value) => {
    setSearchFollowValue(value);
    resetFollowupPaginate();
    fetch_candidate_followups(
      value,
      details?._id,
      followSearch?.from_date,
      followSearch?.to_date,
      followSearch?.status,
      followSearch?.staff
    );
  };
  const followup_data = {
    columns: [
      {
        label: "Date",
        field: "followup_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "followup_time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 400,
      },
      {
        label: "Next Followup",
        field: "followup_next_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Remarks",
        field: "followup_message",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
    ],
    rows: followupData,
  };



  const [candContacted, setcandContacted] = useState([]);

  function fetch_student_contacted() {
    axios
      .get(`${API_URL}contacted/candidatecontactedinst?cand_reg_no=${canId}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data[0];
        let detai_can = [];
        result.map((item, index) => {
          item.id = index + 1;
          item.date1 = moment(item.contactedDate).format("DD-MM-YYYY");
          // item.time = moment(item.conducted_time, "HH:mm:ss").format("hh:mm A");
          item.inst_name = item?.instituteId?.inst_name;
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href={"/institute/" + item?.instituteId?.inst_reg_no}
                target="_blank"
                style={{ color: "#495057" }}
              >
                <button className="button_class">
                  <i className="fas fa-eye"></i>
                </button>
              </a>
            </div>
          );
          detai_can.push(item);
        });
        setcandContacted(detai_can);
      })
      .catch((err) => {
        console.log("err");
      });
  }



  const candidate_conatct = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "extractedTime",
        sort: "asc",
        width: 150,
      },
      {
        label: "Institue",
        field: "inst_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "status",
        field: "contactedStatus",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: candContacted,
  };



  /** End Followup Properties */
  const paginateJob = (event, value) => {
    setCurrentPageJob(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer1(indexOfFirstTransfer);
    setindexOfLastTransfer1(indexOfLastTransfer);
    fetch_saved(
      details?._id,
      searchValueJob,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginateApp = (event, value) => {
    setCurrentPageApp(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer2(indexOfFirstTransfer);
    setindexOfLastTransfer2(indexOfLastTransfer);
    fetch_applied(
      details?._id,
      searchValueApp,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginateShort = (event, value) => {
    setCurrentPageShort(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer3(indexOfFirstTransfer);
    setindexOfLastTransfer3(indexOfLastTransfer);
    fetch_shortlisted(
      details?._id,
      searchValueShort,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginatePlaced = (event, value) => {
    setCurrentPagePlaced(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer4(indexOfFirstTransfer);
    setindexOfLastTransfer4(indexOfLastTransfer);
    fetch_placed(
      details?._id,
      searchValuePlaced,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginateRemarks = (event, value) => {
    setCurrentPageRemarks(value);
    const indexOfLastTransfer = value * 10;
    const indexOfFirstTransfer = indexOfLastTransfer - 10;
    setindexOfFirstTransfer5(indexOfFirstTransfer);
    setindexOfLastTransfer5(indexOfLastTransfer);
    fetch_remarks(details?._id, indexOfFirstTransfer, indexOfLastTransfer);
  };
  const resetPaginateJob = () => {
    setCurrentPageJob(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer1(indexOfFirstTransfer);
    setindexOfLastTransfer1(indexOfLastTransfer);
  };
  const resetPaginateApp = () => {
    setCurrentPageApp(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer2(indexOfFirstTransfer);
    setindexOfLastTransfer2(indexOfLastTransfer);
  };
  const resetPaginateShort = () => {
    setCurrentPageShort(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer3(indexOfFirstTransfer);
    setindexOfLastTransfer3(indexOfLastTransfer);
  };
  const resetPaginatePlaced = () => {
    setCurrentPagePlaced(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer4(indexOfFirstTransfer);
    setindexOfLastTransfer4(indexOfLastTransfer);
  };
  const resetPaginateRemarks = () => {
    setCurrentPageRemarks(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer5(indexOfFirstTransfer);
    setindexOfLastTransfer5(indexOfLastTransfer);
  };
  const handleSearchJob = (value) => {
    setSearchValueJob(value);
    resetPaginateJob();
    fetch_saved(details?._id, value);
  };
  const handleSearchApp = (value) => {
    setSearchValueApp(value);
    resetPaginateApp();
    fetch_applied(details?._id, value);
  };
  const handleSearchShort = (value) => {
    setSearchValueShort(value);
    resetPaginateShort();
    fetch_shortlisted(details?._id, value);
  };
  const handleSearchPlaced = (value) => {
    setSearchValuePlaced(value);
    resetPaginatePlaced();
    fetch_placed(details?._id, value);
  };

  useEffect(() => {
    if (savedData?.length == 0 && indexOfFirstTransfer1 != 0) {
      resetPaginateJob();
      fetch_saved(details?._id, searchValueJob);
    }
  }, [savedData]);
  useEffect(() => {
    if (appliedData.length == 0 && indexOfFirstTransfer2 != 0) {
      resetPaginateApp();
      fetch_applied(details?._id, searchValueApp);
    }
  }, [appliedData]);
  useEffect(() => {
    if (shortlistedData?.length == 0 && indexOfFirstTransfer3 != 0) {
      resetPaginateShort();
      fetch_shortlisted(details?._id, searchValueShort);
    }
  }, [shortlistedData]);
  useEffect(() => {
    if (placedData?.length == 0 && indexOfFirstTransfer4 != 0) {
      resetPaginatePlaced();
      fetch_placed(details?._id, searchValuePlaced);
    }
  }, [placedData]);
  /** end pagination */

  useEffect(() => {
    if (canId) {
      handleTableData(canId);
      fetch_all_reasons();
      fetch_followup_status();
      fetch_all_staff();
      fetch_student_contacted();
    }
  }, [canId]);

  const fetch_all_reasons = () => {
    axios
      .get(`${API_URL}manage/decline`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let reason_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item?.remark,
              value: item?._id,
            };
          });
        setReasonsOptions([
          {
            options: reason_data,
          },
        ]);
      });
  };
  function handleTableData(id) {
    axios
      .get(`${API_URL}candidate/view?candidate_reg=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        fetch_candidate_followups("", result._id);

        if (result.cand_current_status === 0) result.status = "New";
        else if (result.cand_current_status === 1) result.status = "Saved";
        else if (result.cand_current_status === 2) result.status = "Applied";
        else if (result.cand_current_status === 3)
          result.status = "Shortlisted";
        else if (result.cand_current_status === 4) result.status = "Placed";
        else if (result.cand_current_status === 5) result.status = "Declined";
        else result.status = "";

        if (result?.cand_dob) {
          result.dob = moment(result?.cand_dob).format("DD-MM-YYYY");
        }
        if (result.dob == "Invalid date") {
          result.dob = result?.cand_dob;
        }

        if (result?.cand_gender === 0) result.gender = "Male";
        else if (result?.cand_gender === 1) result.gender = "Female";
        else if (result?.cand_gender === 2) result.gender = "Transgender";
        else result.gender = "";

        if (result?.cand_marital_status === 0) result.maritalStatus = "Married";
        else if (result?.cand_marital_status === 1)
          result.maritalStatus = "Single";
        else if (result?.cand_marital_status === 2)
          result.maritalStatus = "Separated";
        else if (result?.cand_marital_status === 3)
          result.maritalStatus = "Divorced";
        else if (result?.cand_marital_status === 4)
          result.maritalStatus = "Widowed";
        else result.maritalStatus = "";

        let stateData = result.cand_state || {};
        result.stateName = stateData?.name;
        let districtData = result.cand_district || {};
        result.districtName = districtData?.district_name;
        //if(result?.cand_experience !=null || result?.cand_experience!="" || result?.cand_experience!=undefined || result?.cand_experience!="null") {
        if (result?.cand_experience || result?.cand_experience === 0) {
          if (result?.cand_experience == 1 || result?.cand_experience == 0)
            result.expName = result?.cand_experience + " " + "YEAR";
          else result.expName = result?.cand_experience + " " + "YEARS";
        } else {
          result.expName = "";
        }

        result.subjectName = "";
        result.cand_subject &&
          result.cand_subject.map((sub) => {
            if (result.subjectName === "")
              result.subjectName = sub?.subject_name;
            else
              result.subjectName =
                result?.subjectName + "," + "\n" + sub?.subject_name;
          });
        result.className = "";
        result.cand_classes &&
          result.cand_classes.map((el) => {
            if (result.className === "") result.className = el?.name;
            else result.className = result?.className + "," + "\n" + el?.name;
          });
        result.qualificationName = "";
        result.cand_qualifcatn &&
          result.cand_qualifcatn.map((el) => {
            if (result.qualificationName === "")
              result.qualificationName = el?.qualification_name;
            else
              result.qualificationName =
                result?.qualificationName + "," + "\n" + el?.qualification_name;
          });
        result.mediumName = "";
        result.cand_medium &&
          result.cand_medium.map((el) => {
            if (result.mediumName === "") result.mediumName = el?.name;
            else result.mediumName = result?.mediumName + "," + "\n" + el?.name;
          });
        result.syllabusName = "";
        result.cand_syllabus &&
          result.cand_syllabus.map((el) => {
            if (result.syllabusName === "") result.syllabusName = el?.name;
            else
              result.syllabusName =
                result?.syllabusName + "," + "\n" + el?.name;
          });

        result.subName = result.subjectName.toUpperCase();
        result.video1 = "";
        result.video2 = "";
        if (result?.cand_video1) {
          let vdo = result.cand_video1.split("/");
          let data = vdo.at(-2);
          let data2 = vdo.slice(-1).pop();
          result.video1 = data;
          result.video1hash = data2;
        }
        if (result?.cand_video2) {
          let vdo = result.cand_video2.split("/");
          let data = vdo.at(-2);
          let data2 = vdo.slice(-1).pop();
          result.video2 = data;
          result.video2hash = data2;
        }
        setDetails(result);
        fetch_applied(result?._id, searchValueApp);
        fetch_saved(result?._id, searchValueJob);
        fetch_shortlisted(result?._id, searchValueShort);
        fetch_placed(result?._id, searchValuePlaced);
        fetch_remarks(result?._id);
      });
  }
  const fetch_applied = (id, search = "", start = 0, end = 20) => {
    var url = `${API_URL}application/list`;
    url =
      url +
      "?fromDate=" +
      "" +
      "&toDate=" +
      "" +
      "&candidate_id=" +
      id +
      "&job_id=" +
      "" +
      "&institute_id=" +
      "" +
      "&start=" +
      start +
      "&end=" +
      end +
      "&search=" +
      search;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPagesApp(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.application_date).format("DD-MM-YYYY");

            item.className = "";
            item.apply_class &&
              item.apply_class.map((el) => {
                if (item.className === "") item.className = el?.name;
                else item.className = item.className + "," + el?.name;
              });
            if (item?.application_current_status === 1)
              item.status = "Shortlisted";
            else if (item?.application_current_status === 2)
              item.status = "Placed";
            else if (item?.application_current_status === 0)
              item.status = "Applied";
            else if (item?.application_current_status === 3)
              item.status = "Declined";
            else item.status = "";
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenAppliedForm(item);
                    setPopupAppliedView(true);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>

                <button
                  onClick={() => {
                    openDeclineAppliedForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-thumbs-down"></i>
                </button>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Contact history
                    </Tooltip>
                  }
                >
                  <button
                    onClick={() => {
                      setpopupContacted(true);
                      handleClickOpenAppliedForm(item);
                    }}
                    className="button_class"
                  >
                    <i class="fa fa-phone"></i>
                  </button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Shortlist
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    onClick={() => {
                      shortlistApplication(item, search, start, end);
                    }}
                  >
                    <i className="fas fa-clipboard-check"></i>
                  </button>
                </OverlayTrigger>
              </div>
            );
          });
        setAppliedData(result);
        setTotalApplied(res.data.limit);
      });
  };
  const shortlistApplication = (item, search, start, end) => {
    if (item?.application_current_status === 1) {
      toastr.error("Application is already shortlisted");
    } else if (item?.application_current_status === 2) {
      toastr.error("Application is already placed");
    } else if (item?.application_current_status === 3) {
      toastr.error("Can't shortlist declined application");
    } else {
      let data = {
        application_id: item?._id,
      };
      axios
        .post(`${API_URL}shortlist/`, data, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application is shortlisted");
            fetch_applied(item?.application_cand_id, search, start, end);
            // fetch_shortlisted(item?.application_cand_id);
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const openDeclineAppliedForm = (item) => {
    if (item?.application_current_status === 3) {
      toastr.error("Application is already declined");
    } else {
      setDeclineAppliedData({
        application_id: item?._id,
      });
      setAppliedErrors({
        appliedErrors: "",
      });
      setSelectedDeclineApplied(null);
      setShowDeclineAppliedForm(true);
    }
  };
  const fetch_saved = (id, search = "", start = 0, end = 20) => {
    var url = `${API_URL}saved/list`;
    url =
      url +
      "?fromDate=" +
      "" +
      "&toDate=" +
      "" +
      "&candidate_id=" +
      id +
      "&job_id=" +
      "" +
      "&institute_id=" +
      "" +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPagesJob(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.saved_date).format("DD-MM-YYYY");

            item.className = "";
            item.saved_class &&
              item.saved_class.map((el) => {
                if (item.className === "") item.className = el?.name;
                else item.className = item.className + "," + el?.name;
              });
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    handleClickOpenSavedForm(item);
                  }}
                ></i>
              </div>
            );
          });
        setSavedData(result);
        setTotalSaved(res.data.limit);
      });
  };
  const fetch_shortlisted = (id, search = "", start = 0, end = 10) => {
    var url = `${API_URL}shortlist/list`;
    url =
      url +
      "?from_date=" +
      "" +
      "&to_date=" +
      "" +
      "&candidate_id=" +
      id +
      "&job_id=" +
      "" +
      "&institute_id=" +
      "" +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPagesShort(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.shortlisted_date).format("DD-MM-YYYY");
            item.interviewdate = moment(
              item?.shortlisted_interview_date
            ).format("DD-MM-YYYY");
            if (item?.shortlisted_current_status === 0)
              item.status = "Shortlisted";
            else if (item?.shortlisted_current_status === 1)
              item.status = "Placed";
            else if (item?.shortlisted_current_status === 2)
              item.status = "Declined";
            else item.status = "";
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenShortlistedForm(item);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>

                <button
                  onClick={() => {
                    openDeclineShortlistedForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-thumbs-down"></i>
                </button>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Place Application
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    onClick={() => {
                      placeApplication(item, search, start, end);
                    }}
                  >
                    <i className="fas fa-user-check"></i>
                  </button>
                </OverlayTrigger>
              </div>
            );
          });
        setShortlistedData(result);
        // let arr =
        //   result && result.filter((el) => el?.shortlisted_current_status === 0);
        setTotalShortlisted(res.data.limit);
      });
  };
  const placeApplication = (item, search = "", start = 0, end = 20) => {
    if (item?.shortlisted_current_status === 1) {
      toastr.error("Application is already placed");
    } else if (item?.shortlisted_current_status === 2) {
      toastr.error("Can't place declined application");
    } else {
      let data = {
        application_id: item?.application_id,
      };
      axios
        .post(`${API_URL}placed/`, data, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application is placed");
            fetch_shortlisted(item?.shortlisted_cand_id, search, start, end);
            // fetch_placed(item?.shortlisted_cand_id);
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const openDeclineShortlistedForm = (item) => {
    if (item?.shortlisted_current_status === 2) {
      toastr.error("Application is already declined");
    } else {
      setDeclineShortlistedData({
        shortlist_id: item?._id,
      });
      setShortlistedErrors({
        remarkError: "",
      });
      setSelectedDeclineShortlisted(null);
      setShowDeclineShortlistedForm(true);
    }
  };
  const fetch_placed = (id, search = "", start = 1, end = 20) => {
    var url = `${API_URL}placed/list`;
    url =
      url +
      "?from_date=" +
      "" +
      "&to_date=" +
      "" +
      "&candidate_id=" +
      id +
      "&job_id=" +
      "" +
      "&institute_id=" +
      "" +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPagesPlaced(res.data.limit);
        var id = (start - 1) * end;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.placed_date).format("DD-MM-YYYY");
            item.joiningdate = moment(item?.joining_date).format("DD-MM-YYYY");
            item.qualificationName = "";
            item.saved_qualification &&
              item.saved_qualification.map((el) => {
                if (item.qualificationName === "")
                  item.qualificationName = el?.qualification_name;
                else
                  item.qualificationName =
                    item.qualificationName + "," + el?.qualification_name;
              });
            if (item?.placed_current_status === 0) item.status = "Placed";
            else if (item?.placed_current_status === 1)
              item.status = "Declined";
            else item.status = "";
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenPlacedForm(item);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>

                <button
                  onClick={() => {
                    openDeclinePlacedForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-thumbs-down"></i>
                </button>
              </div>
            );
          });
        setPlacedData(result);
        setTotalPlaced(res.data.placed_count);
      });
  };
  const openDeclinePlacedForm = (item) => {
    if (item?.placed_current_status === 1) {
      toastr.error("Application is already declined");
    } else {
      setDeclinePlacededData({
        placed_id: item?._id,
      });
      setSelectedDeclinePlaced(null);
      setPlacedErrors({
        remarkError: "",
      });
      setShowDeclinePlacedForm(true);
    }
  };
  const handleClickOpenSavedForm = (item) => {
    axios
      .get(`${API_URL}saved?saved_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        //result.dob = moment(result?.saved_date).format("DD-MM-YYYY");
        result.date = moment(result?.saved_date).format("DD-MM-YYYY");
        result.time = moment(result?.saved_time, "hhmmss").format("hh:mm a");
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.class = "";
        jobDetails.job_classes &&
          jobDetails.job_classes.map((el) => {
            if (result.class === "") result.class = el;
            else result.class = result.class + "," + el;
          });
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupSavedData(result);
        setPopupSavedView(true);
      });
  };
  const handleClickOpenAppliedForm = (item) => {
    axios
      .get(`${API_URL}application?application_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.applied_date = moment(result?.application_date).format(
          "DD-MM-YYYY"
        );
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.class = "";
        jobDetails.job_classes &&
          jobDetails.job_classes.map((el) => {
            if (result.class === "") result.class = el;
            else result.class = result.class + "," + el;
          });
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupAppliedData(result);
      });
  };


  const handleClickOpenShortlistedForm = (item) => {
    axios
      .get(`${API_URL}shortlist/view?shortlisted_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.date = moment(result?.shortlisted_date).format("DD-MM-YYYY");
        result.time = moment(result?.shortlisted_time, "hhmmss").format(
          "hh:mm a"
        );
        // result.interviewdate = moment(
        //   result?.shortlisted_interview_date
        // ).format("DD-MM-YYYY");
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupShortlistedData(result);
        setPopupShortlistedView(true);
      });
  };
  const handleClickOpenPlacedForm = (item) => {
    axios
      .get(`${API_URL}placed/view?placed_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.joiningdate = moment(result?.joining_date).format("DD-MM-YYYY");
        result.date = moment(result?.placed_date).format("DD-MM-YYYY");
        result.time = moment(result?.placed_time, "hhmmss").format("hh:mm a");
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupPlacedData(result);
        setPopupPlacedView(true);
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "saved_reg_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Job ID",
        field: "saved_job_reg_no",
        sort: "asc",
        width: 100,
      },
      {
        label: "Institute",
        field: "saved_inst_name",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Subject",
      //   field: "saved_subject",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Classes",
      //   field: "className",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: savedData,
  };
  const shortlistedTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "shortlisted_reg_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Job ID",
        field: "job_reg_no",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Institute",
      //   field: "shortlisted_inst_name",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "State",
      //   field: "shortlisted_state",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Institute",
        field: "shortlisted_inst_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: shortlistedData,
  };
  const placedTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "placed_reg_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Job ID",
        field: "job_reg_no",
        sort: "asc",
        width: 200,
      },
      //   {
      //     label: "Customer Name",
      //     field: "CustomerName",
      //     sort: "asc",
      //     width: 200,
      //   },
      {
        label: "Institute",
        field: "placed_inst_name",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Subject",
      //   field: "subject",
      //   sort: "asc",
      //   width: 200,
      // },

      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Offer Letter",
      //   field: "offerletter",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: placedData,
  };

  const appliedTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "application_reg_no",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Job ID",
        field: "application_job_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Institute",
        field: "application_inst_name",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Package",
      //   field: "invoice_package_name",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Subject",
      //   field: "application_subject",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: appliedData,
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  function createData(si, date, time, staff) {
    return { si, date, time, staff };
  }

  const rows1 = [createData(1, 159, 6.0, 24), createData(2, 237, 9.0, 37)];

  // Receipt table
  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow1 = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData1(si, date, receiptno, amount, dueamount, staff) {
    return { si, date, receiptno, amount, dueamount, staff };
  }

  const rows2 = [
    createData1(1, "24 - 11 - 2021", "RE2111000004", "50/-", "50/-", "Prabija"),
    createData1(
      2,
      "24 - 11 - 2021",
      "RE2111000005",
      "400/-",
      "-350/-",
      "Prabija"
    ),
  ];

  function createData3(
    si,
    date,
    time,
    referenceNo,
    customerId,
    customerName,
    amount,
    comment,
    due,
    staff
  ) {
    return {
      si,
      date,
      time,
      referenceNo,
      customerId,
      customerName,
      amount,
      comment,
      due,
      staff,
    };
  }

  const rows3 = [
    createData3(
      1,
      "26-11-2020",
      "11:11:24",
      123,
      123,
      "admin",
      700,
      "abc",
      100,
      "srv"
    ),
  ];

  const [tab3, setTab3] = React.useState("4");

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);

    resetPaginateJob();
    setSearchValueJob("");
    fetch_saved(details?._id);

    resetPaginateApp();
    fetch_applied(details?._id);
    setSearchValueApp("");

    resetPaginateShort();
    fetch_shortlisted(details?._id);
    setSearchValueShort("");

    resetPaginatePlaced();
    fetch_placed(details?._id);
    setSearchValuePlaced("");
  };

  const [date, setDate] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };


  function createData4(
    si,
    date,
    time,
    customerName,
    type,
    invoiceNo,
    debit,
    credit,
    balance
  ) {
    return {
      si,
      date,
      time,
      customerName,
      type,
      invoiceNo,
      debit,
      credit,
      balance,
    };
  }

  const rows4 = [
    createData4(
      1,
      "26-11-2020",
      "11:11:24",
      "admin",
      "Invoice",
      "SRV123",
      700,
      100,
      100
    ),
  ];
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
      candidate_id: details?._id,
    });
  };
  const handleValidSubmitSummary = () => {
    axios
      .post(`${API_URL}remarks/`, master, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          formRef.current.reset();
          setMaster({
            candidate_id: "",
            remarks_reason: "",
          });
          fetch_remarks(
            result?.remarks_cand_id,
            indexOfFirstTransfer5,
            indexOfLastTransfer5
          );
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const fetch_remarks = (id, start = 0, end = 10) => {
    axios
      .get(
        `${API_URL}remarks/list?candidate_id=` +
        id +
        "&start=" +
        start +
        "&end=" +
        end,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        setTotalPagesRemarks(res.data.limit);
        result &&
          result.map((item) => {
            let date = moment(item?.remarks_date).format("DD-MM-YYYY");
            let time = moment(item?.remarks_time, "hhmmss").format("hh:mm a");
            item.dateTime = date + "," + time;
          });
        setRemarksData(result);
      });
  };
  const openVideoForm = () => {
    if (details?.cand_video1 || details?.cand_video2) {
      setVideoForm(true);
    } else toastr.error("No video is available");
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "appliedReason":
        setSelectedDeclineApplied(selected);
        setDeclineAppliedData({
          ...declineAppliedData,
          decline_remark: selected.label,
        });
        setAppliedErrors({
          remarkError: "",
        });
        break;
      case "shortlistedReason":
        setSelectedDeclineShortlisted(selected);
        setDeclineShortlistedData({
          ...declineShortlistedData,
          decline_remark: selected.label,
        });
        setShortlistedErrors({
          remarkError: "",
        });
        break;
      case "placedReason":
        setSelectedDeclinePlaced(selected);
        setDeclinePlacededData({
          ...declinePlacedData,
          decline_remark: selected.label,
        });
        setPlacedErrors({
          remarkError: "",
        });
        break;
      default:
        break;
    }
  };
  const submitDeclineAppliedForm = () => {
    if (selectedDeclineApplied === null) {
      setAppliedErrors({
        remarkError: "Please select the reason",
      });
    } else {
      axios
        .put(
          `${API_URL}application/decline?applied_id=` +
          declineAppliedData?.application_id,
          declineAppliedData,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application declined successfully");
            fetch_applied(
              details?._id,
              searchValueApp,
              indexOfFirstTransfer2,
              indexOfLastTransfer2
            );
            setDeclineAppliedData({});
            setSelectedDeclineApplied(null);
            setShowDeclineAppliedForm(false);
          } else {
            toastr.error("Failed to decline application");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const submitDeclineShortlistedForm = () => {
    if (selectedDeclineShortlisted === null) {
      setShortlistedErrors({
        remarkError: "Please select the reason",
      });
    } else {
      axios
        .put(
          `${API_URL}shortlist/decline?shortlist_id=` +
          declineShortlistedData?.shortlist_id,
          declineShortlistedData,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application declined successfully");
            setSelectedDeclineShortlisted(null);
            fetch_shortlisted(
              details?._id,
              searchValueShort,
              indexOfFirstTransfer3,
              indexOfLastTransfer3
            );
            setDeclineShortlistedData({});
            setShowDeclineShortlistedForm(false);
          } else {
            toastr.error("Failed to decline application");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const submitDeclinePlacedForm = () => {
    if (selectedDeclinePlaced === null) {
      setPlacedErrors({
        remarkError: "Please select the reason",
      });
    } else {
      axios
        .put(
          `${API_URL}placed/decline?placed_id=` + declinePlacedData?.placed_id,
          declinePlacedData,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application declined successfully");
            setSelectedDeclinePlaced(null);
            fetch_placed(
              details?._id,
              searchValuePlaced,
              indexOfFirstTransfer4,
              indexOfLastTransfer4
            );
            setDeclinePlacededData({});
            setShowDeclinePlacedForm(false);
          } else {
            toastr.error("Failed to decline application");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const downloadResume = () => {

    saveAs(
      `${API_URL}` + details?.cand_resumes + "?t=" + new Date().getTime(),
      details?.cand_name + "_Resume" + ".pdf"
    );
  };
  const verifyCandidate = () => {
    let data = {
      candidate_id: details?._id,
    };
    axios
      .post(`${API_URL}candidate/cand_verification/`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("Successfully Verified");
          handleTableData(canId);
        }
      });
  };
  const [contatedTableData, setcontatedTableData] = useState([]);
  const contacted_data = {
    column: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Institute",
        filed: "inst_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "acs",
        width: 150,
      },
      {
        label: "Last contacted",
        field: "las_contacted",
        sort: "acs",
        width: 150,
      },
    ],
    rows: contatedTableData,
  };

  return (
    <React.Fragment>
      <Modal show={popupContacted} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Contacted History</h5>
          <button
            type="button"
            onClick={() => {
              setpopupContacted(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              <MDBDataTable responsive bordered data={contacted_data} />
            </CardBody>
          </Card>
        </div>
      </Modal>

      <Modal show={showDeclineAppliedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineAppliedForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            submitDeclineAppliedForm();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    value={selectedDeclineApplied}
                    onChange={(selected) => {
                      handleSelectChange(selected, "appliedReason");
                    }}
                    options={ReasonsOption}
                    classNamePrefix="select2-selection"
                  />
                  <p className="text-danger" style={{ fontSize: "11px" }}>
                    {selectedDeclineApplied === null
                      ? appliedErrors.remarkError
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row style={{ justifyContent: "end" }}>
              <Col md="1">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={showDeclineShortlistedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineShortlistedForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            submitDeclineShortlistedForm();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    value={selectedDeclineShortlisted}
                    onChange={(selected) => {
                      handleSelectChange(selected, "shortlistedReason");
                    }}
                    options={ReasonsOption}
                    classNamePrefix="select2-selection"
                  />
                  <p className="text-danger" style={{ fontSize: "11px" }}>
                    {selectedDeclineShortlisted === null
                      ? shortlistedErrors.remarkError
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row style={{ justifyContent: "end" }}>
              <Col md="1">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={showDeclinePlacedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclinePlacedForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            submitDeclinePlacedForm();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    value={selectedDeclinePlaced}
                    onChange={(selected) => {
                      handleSelectChange(selected, "placedReason");
                    }}
                    options={ReasonsOption}
                    classNamePrefix="select2-selection"
                  />
                  <p className="text-danger" style={{ fontSize: "11px" }}>
                    {selectedDeclinePlaced === null
                      ? placedErrors.remarkError
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row style={{ justifyContent: "end" }}>
              <Col md="1">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>

      <Modal show={videoForm} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Candidate Videos</h5>
          <button
            type="button"
            onClick={() => {
              setVideoForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <Row>
              <Col xl={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Intro Video</CardTitle>
                    <p className="card-title-desc"></p>

                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                      {details?.video1 && (
                        <iframe
                          title="test"
                          className="embed-responsive-item"
                          src={`https://player.vimeo.com/video/${details?.video1}?h=${details?.video1hash}`}
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>

              </Col>
              <Col xl={6}>

                <Card>
                  <CardBody>
                    <CardTitle className="h4">Teaching Video</CardTitle>
                    <p className="card-title-desc"></p>

                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                      {details?.video2 && (
                        <iframe
                          title="test"
                          className="embed-responsive-item"
                          src={`https://player.vimeo.com/video/${details?.video2}?h=${details?.video2hash}`}
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupSavedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Saved Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupSavedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupSavedData?.saved_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}>
                  {popupSavedData?.saved_job_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Saved Date :</td>
                <td>{popupSavedData?.date}</td>
                <td style={{ paddingLeft: "25px" }}>Saved Time :</td>
                <td style={{ textAlign: "left" }}>{popupSavedData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupSavedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupSavedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Subject :</td>
                <td>{popupSavedData?.subject}</td>
                <td style={{ paddingLeft: "25px" }}>Experience :</td>
                <td style={{ textAlign: "left" }}>{popupSavedData?.exp}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Candidate :</td>
                <td>{popupSavedData?.saved_cand_name}</td>
                <td style={{ paddingLeft: "25px" }}>Candidate Mob :</td>
                <td style={{ textAlign: "left" }}>
                  {popupSavedData?.saved_cand_phone}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Class :</td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}>
                  {popupSavedData?.class}
                </td>
                <td style={{ paddingLeft: "25px" }}>Qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupSavedData?.qualification}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupAppliedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupAppliedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupAppliedData?.application_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Application ID :</td>
                <td style={{ textAlign: "left" }}>
                  {popupAppliedData?.application_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupAppliedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupAppliedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Subject :</td>
                <td>{popupAppliedData?.subject}</td>
                <td style={{ paddingLeft: "25px" }}>Experience :</td>
                <td style={{ textAlign: "left" }}>{popupAppliedData?.exp}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Applicant :</td>
                <td>{popupAppliedData?.application_cand_name}</td>
                <td style={{ paddingLeft: "25px" }}>Applied Date :</td>
                <td style={{ textAlign: "left" }}>
                  {popupAppliedData?.applied_date}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Class :</td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}>
                  {popupAppliedData?.class}
                </td>
                <td style={{ paddingLeft: "25px" }}>Qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupAppliedData?.qualification}
                </td>
              </tr>
              <tr hidden={popupAppliedData?.application_current_status !== 3}>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupAppliedData?.decline_remark}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupShortlistedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Shortlisted Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupShortlistedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupShortlistedData?.shortlisted_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Application ID :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.job_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Shortlisted Date :</td>
                <td>{popupShortlistedData?.date}</td>
                <td style={{ paddingLeft: "25px" }}>Shortlisted Time :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.time}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupShortlistedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}>
                  {popupShortlistedData?.shortlisted_inst_name}
                </td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.shortlisted_cand_name}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td>{popupShortlistedData?.exp}</td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupShortlistedData?.qualification}
                </td>
              </tr>
              <tr
                hidden={popupShortlistedData?.shortlisted_current_status !== 2}
              >
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupShortlistedData?.decline_remark}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupPlacedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Placed Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupPlacedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupPlacedData?.placed_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}>
                  {popupPlacedData?.job_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Placed Date :</td>
                <td>{popupPlacedData?.date}</td>
                <td style={{ paddingLeft: "25px" }}>Placed Time :</td>
                <td style={{ textAlign: "left" }}>{popupPlacedData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupPlacedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupPlacedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}>
                  {popupPlacedData?.placed_inst_name}
                </td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}>
                  {popupPlacedData?.placed_cand_name}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td>{popupPlacedData?.exp}</td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupPlacedData?.qualification}
                </td>
              </tr>
              <tr hidden={popupPlacedData?.placed_current_status !== 1}>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupPlacedData?.decline_remark}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem={
              "Candidate" + " " + "-" + " " + details?.cand_reg_no
            }
          />
          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        //width: "fit-content",
                        //width: "351px",
                        //width:"300px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown">


                        </div>
                        <Stack
                          direction="row"
                          spacing={2}
                          style={{ justifyContent: "center" }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            src={`${API_URL}uploads/candidate_images/${details?.cand_image}`}
                            sx={{ width: 56, height: 56 }}
                            style={{
                              width: "6rem",
                              height: "6rem",
                              //marginTop: "20%",
                              marginBottom: "auto",
                            }}
                          />
                        </Stack>
                        {details?.cand_verification_status === 1 ? (
                          // <OverlayTrigger
                          //   placement="top-end"
                          //   trigger="click"
                          //   style={{transform:"translate(-850px, -564px)"}}
                          //   overlay={
                          //     <Tooltip
                          //       id="button-tooltip-2"
                          //       style={{ position: "absolute", top: "-30px" }}
                          //     >
                          //       Verified
                          //     </Tooltip>
                          //   }
                          // >
                          <a
                            aria-haspopup="true"
                            className="text-body font-size-16 ddropdown-toggle"
                            aria-expanded="false"
                          >
                            <CheckCircleIcon
                              style={{
                                fontSize: "20px",
                                color: "green",
                                position: "relative",
                                float: "right",
                                bottom: "83px",
                              }}
                            />
                          </a>
                        ) : (

                          <a
                            aria-haspopup="true"
                            className="text-body font-size-16 ddropdown-toggle"
                            aria-expanded="false"
                            onClick={() => {
                              verifyCandidate();
                            }}
                          >
                            <CancelIcon
                              style={{
                                fontSize: "20px",
                                color: "red",
                                position: "relative",
                                float: "right",
                                bottom: "83px",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        )}
                        <h5
                          className="mt-3 mb-1"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {details?.cand_name}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            {/* <CallIcon style={{ width: "10px", height: "10px" }}/> */}
                            {/* <OverlayTrigger
                              placement="top"
                              //delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip
                                  id="button-tooltip-2"
                                  style={{ position: "absolute", top: "-30px" }}
                                >
                                  Call Now
                                </Tooltip>
                              }
                            > */}
                            <button
                              class="btn btn-success"
                              style={{
                                borderRadius: "40px",
                                marginRight: "10px",
                                padding: "0",
                                width: "28px",
                                cursor: "default",
                              }}
                              hidden={!details.cand_mob}
                            // onClick={() => customerCall()}
                            >
                              <i
                                className="uil-phone-alt"
                                style={{ fontSize: "15px" }}
                              />
                            </button>
                            {/* </OverlayTrigger> */}
                          </div>
                          {details?.cand_mob}
                        </div>
                        {/* <p
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                          hidden={!details?.cust_whatsapp_no}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              class="btn btn-success"
                              style={{
                                borderRadius: "40px",
                                marginRight: "10px",
                                padding: "0",
                                width: "28px",
                              }}
                              hidden={!details.cust_whatsapp_no}
                            >
                              <i
                                className="uil-whatsapp"
                                style={{ fontSize: "15px" }}
                                //hidden={!details.cust_whatsapp_no}
                              />
                              Call
                            </button>
                          </div>
                          {details?.cust_whatsapp_no}
                          <img
                            //src="https://api.ir.ee/static/ssb_icon.png"
                            data-regcode="12336685"
                            
                          />
                        </p> */}
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        >
                          {details?.status}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <OverlayTrigger
                            placement="top"
                            //delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip
                                id="button-tooltip-2"
                                style={{ position: "absolute", top: "-30px" }}
                              >
                                Candidate Video
                              </Tooltip>
                            }
                          >
                            <VideocamIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => openVideoForm()}
                            />
                          </OverlayTrigger>
                        </p>

                        <div
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        ></div>
                        <Divider />
                        <div>
                          <p className="mb-1 mt-1">Email:</p>
                          <h6 className="font-size-14">
                            {details?.cand_email}
                          </h6>
                        </div>

                        <div hidden={!details?.gender}>
                          <p className="mb-1">Gender:</p>
                          <h6 className="font-size-14">{details?.gender}</h6>
                        </div>
                        <div hidden={!details?.dob}>
                          <p className="mb-1">DOB:</p>
                          <h6 className="font-size-14">{details?.dob}</h6>
                        </div>
                        <div hidden={!details?.maritalStatus}>
                          <p className="mb-1">Marital Status:</p>
                          <h6 className="font-size-14">
                            {details?.maritalStatus}
                          </h6>
                        </div>
                        <div>
                          <p className="mb-1">Address:</p>
                          <h6 className="font-size-14">
                            {details?.cand_address}
                          </h6>
                        </div>
                        <div>
                          <p className="mb-1">State:</p>
                          <h6 className="font-size-14">{details?.stateName}</h6>
                        </div>
                        <div>
                          <p className="mb-1">District:</p>
                          <h6 className="font-size-14">
                            {details?.districtName}
                          </h6>
                        </div>

                        <div hidden={!details?.qualificationName}>
                          <p className="mb-1">Qualification:</p>
                          <h6 className="font-size-14">
                            {details?.qualificationName}
                          </h6>
                        </div>
                        <div hidden={!details?.subjectName}>
                          <p className="mb-1">Subject:</p>
                          <h6 className="font-size-14">
                            {details?.subjectName}
                          </h6>
                        </div>
                        <div hidden={!details?.expName}>
                          <p className="mb-1">Experience:</p>
                          <h6 className="font-size-14">{details?.expName}</h6>
                        </div>
                        <div hidden={!details?.syllabusName}>
                          <p className="mb-1">Syllabus:</p>
                          <h6 className="font-size-14">
                            {details?.syllabusName}
                          </h6>
                        </div>
                        <div hidden={!details?.className}>
                          <p className="mb-1">Class:</p>
                          <h6 className="font-size-14">{details?.className}</h6>
                        </div>
                        <div hidden={!details?.mediumName}>
                          <p className="mb-1">Medium:</p>
                          <h6 className="font-size-14">
                            {details?.mediumName}
                          </h6>
                        </div>
                        <div hidden={!details?.app_version}>
                          <p className="mb-1">Version:</p>
                          <h6 className="font-size-14">
                            {details?.app_version}
                          </h6>
                        </div>
                        {/* <div
                          style={{ textAlign: "center" }}
                          hidden={!showRemainingDetails}
                        >
                          <p
                            className="mb-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowRemainingDetails(false)}
                          >
                            Show less
                          </p>
                          <ArrowDropUpIcon style={{ fontSize: "20px",cursor:"pointer"}} onClick={()=>setShowRemainingDetails(false)} />
                        </div> */}
                        {/* second paper */}

                        {/* Customer Details Tab end */}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value="4"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <i
                                    className="uil-comment-alt-message"
                                    style={{ fontSize: "20px" }}
                                  ></i>
                                }
                                label="Followup"
                                value="7"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <DescriptionIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Resume"
                                value="5"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />

                              <Tab
                                icon={
                                  <BookmarkIcon style={{ fontSize: "20px" }} />
                                }
                                label="Saved"
                                value="1"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <DescriptionIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Applied"
                                value="6"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <ReceiptIcon style={{ fontSize: "20px" }} />
                                }
                                label="Shortlisted"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <ArticleIcon style={{ fontSize: "20px" }} />
                                }
                                label="Placed"
                                value="3"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />

                              <Tab
                                icon={
                                  <ConnectWithoutContactRoundedIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="contacted"
                                value="8"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>

                          <TabPanel
                            value="8"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md="12">
                                <MDBDataTable
                                  id="candidate_conatct"
                                  responsive
                                  bordered
                                  data={candidate_conatct}
                                />
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="candSavedDataTable"
                                  responsive
                                  bordered
                                  data={data}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchJob(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesJob > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer1 + 1} to{" "}
                                      {indexOfFirstTransfer1 + savedData?.length}{" "}
                                      of {totalPagesJob} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesJob / PerPageCount
                                      )}
                                      page={currentPageJob}
                                      onChange={paginateJob}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="candShortlistTable"
                                  data={shortlistedTableData}
                                  responsive
                                  bordered
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchShort(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesShort > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer3 + 1} to{" "}
                                      {indexOfFirstTransfer3 +
                                        shortlistedData?.length}{" "}
                                      of {totalPagesShort} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesShort / PerPageCount
                                      )}
                                      page={currentPageShort}
                                      onChange={paginateShort}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="3"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="candPlacedTable"
                                  data={placedTableData}
                                  responsive
                                  bordered
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchPlaced(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesPlaced > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer4 + 1} to{" "}
                                      {indexOfFirstTransfer4 +
                                        placedData?.length}{" "}
                                      of {totalPagesPlaced} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesPlaced / PerPageCount
                                      )}
                                      page={currentPagePlaced}
                                      onChange={paginatePlaced}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="5"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <button
                                hidden={!details?.cand_resumes}
                                style={{ float: "right" }}
                                className="button_class"
                                onClick={() => downloadResume()}
                              >
                                <DownloadIcon />
                              </button>
                              <img
                                src={logoSm}
                                alt=""
                                style={{ width: "100%" }}
                              ></img>
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "100%",
                                }}
                                hidden={!details?.cand_reg_no}
                              >
                                {details?.cand_image && (
                                  <Avatar
                                    alt="Remy Sharp"
                                    src={`${API_URL}uploads/candidate_images/${details?.cand_image}`}
                                    sx={{ width: 56, height: 56 }}
                                    style={{
                                      width: "9rem",
                                      height: "9rem",
                                      margin: "auto",
                                      marginBottom: "auto",
                                      marginTop: "auto",
                                      borderRadius: "0",
                                    }}
                                  />
                                )}
                                <p
                                  style={{
                                    marginTop: "15px",
                                    textAlign: "center",
                                  }}
                                >
                                  <h3 style={{ color: "#01afee" }}>
                                    {details?.cand_name
                                      ? details.cand_name.toUpperCase()
                                      : ""}{" "}
                                  </h3>
                                </p>
                                <p
                                  style={{
                                    textDecoration: "underline",
                                    marginTop: "15px",
                                    textAlign: "center",
                                  }}
                                >
                                  <h5>
                                    {"PROFILE ID"}
                                    {"-"}
                                    {details?.cand_reg_no}{" "}
                                  </h5>
                                </p>
                                <p
                                  style={{
                                    marginTop: "15px",
                                    textAlign: "center",
                                  }}
                                >
                                  <h6>
                                    {details?.cand_address
                                      ? details.cand_address.toUpperCase()
                                      : ""}{" "}
                                  </h6>
                                </p>

                                <div
                                  style={{
                                    backgroundColor: "#00aeed",
                                    backgroundColor: "#22b7ef",
                                    borderRadius: " 6px",
                                  }}
                                >
                                  <h6
                                    style={{
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      fontSize: "13px",
                                      color: "white",
                                    }}
                                  >
                                    PROFILE
                                  </h6>
                                </div>
                                <div className="container-fluid">
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "13px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <div className="col-sm-4">
                                      <span style={{ fontWeight: "bold" }}>
                                        STATE :
                                      </span>{" "}
                                      {details?.stateName
                                        ? details.stateName.toUpperCase()
                                        : ""}
                                    </div>
                                    <div className="col-sm-4">
                                      <span style={{ fontWeight: "bold" }}>
                                        DOB :
                                      </span>{" "}
                                      {details?.dob}
                                    </div>
                                    <div className="col-sm-4">
                                      <span style={{ fontWeight: "bold" }}>
                                        DISTRICT :
                                      </span>{" "}
                                      {details?.districtName
                                        ? details.districtName.toUpperCase()
                                        : ""}
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "13px",
                                    }}
                                  >
                                    <div className="col-sm-4">
                                      <span style={{ fontWeight: "bold" }}>
                                        CITY :
                                      </span>{" "}
                                      {details?.cand_city
                                        ? details.cand_city.toUpperCase()
                                        : ""}
                                    </div>

                                    <div className="col-sm-4">
                                      <span style={{ fontWeight: "bold" }}>
                                        PIN :
                                      </span>{" "}
                                      {details?.cand_pin}
                                    </div>
                                    <div className="col-sm-4">
                                      <span style={{ fontWeight: "bold" }}>
                                        MOB :
                                      </span>{" "}
                                      +91 {details?.cand_mob}
                                    </div>
                                  </div>
                                  {/* <div class="row f-res-size"> */}
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "13px",
                                    }}
                                  >
                                    <div className="col-sm-4">
                                      <span style={{ fontWeight: "bold" }}>
                                        GENDER :
                                      </span>{" "}
                                      {details?.gender
                                        ? details.gender.toUpperCase()
                                        : ""}
                                    </div>
                                    <div className="col-sm-4">
                                      <span style={{ fontWeight: "bold" }}>
                                        MARITAL STATUS :
                                      </span>{" "}
                                      {details?.maritalStatus
                                        ? details.maritalStatus.toUpperCase()
                                        : ""}
                                    </div>
                                    <div className="col-sm-4">
                                      <span style={{ fontWeight: "bold" }}>
                                        E-MAIL :
                                      </span>{" "}
                                      {details?.cand_email}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#00aeed",
                                    backgroundColor: "#22b7ef",
                                    borderRadius: " 6px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <h6
                                    style={{
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      fontSize: "13px",
                                      color: "white",
                                    }}
                                  >
                                    QUALIFICATION
                                  </h6>
                                </div>
                                <div className="container-fluid">
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "13px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <div className="col-sm-12">
                                      {details?.qualificationName
                                        ? details.qualificationName.toUpperCase()
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#00aeed",
                                    backgroundColor: "#22b7ef",
                                    borderRadius: " 6px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <h6
                                    style={{
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      fontSize: "13px",
                                      color: "white",
                                    }}
                                  >
                                    EXPERIENCE
                                  </h6>
                                </div>
                                <div className="container-fluid">
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "13px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <div className="col-sm-12">
                                      {details?.expName}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    backgroundColor: "#00aeed",
                                    backgroundColor: "#22b7ef",
                                    borderRadius: " 6px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <h6
                                    style={{
                                      padding: "5px",
                                      paddingLeft: "15px",
                                      fontSize: "13px",
                                      color: "white",
                                    }}
                                  >
                                    MASTERSHIP IN
                                  </h6>
                                </div>
                                <div className="container-fluid">
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "13px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <div className="col-sm-6">
                                      <span style={{ fontWeight: "bold" }}>
                                        SUBJECTS :
                                      </span>{" "}
                                      {details?.subjectName
                                        ? details.subjectName.toUpperCase()
                                        : ""}
                                    </div>
                                    <div className="col-sm-6">
                                      <span style={{ fontWeight: "bold" }}>
                                        CLASSES :
                                      </span>{" "}
                                      {details?.className
                                        ? details.className.toUpperCase()
                                        : ""}
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{
                                      fontSize: "13px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <div className="col-sm-6">
                                      <span style={{ fontWeight: "bold" }}>
                                        SYLLABUS :
                                      </span>{" "}
                                      {details?.syllabusName
                                        ? details.syllabusName.toUpperCase()
                                        : ""}
                                    </div>
                                    <div className="col-sm-6">
                                      <span style={{ fontWeight: "bold" }}>
                                        MEDIUM :
                                      </span>{" "}
                                      {details?.mediumName
                                        ? details.mediumName.toUpperCase()
                                        : ""}
                                    </div>
                                  </div>
                                  {/* <div
                                    className="row"
                                    style={{
                                      fontSize: "13px",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <div className="col-sm-6">
                                      <span style={{ fontWeight: "bold" }}>
                                        DOB :
                                      </span>{" "}
                                      {details?.dob}
                                    </div>
                                    <div className="col-sm-6">
                                      <span style={{ fontWeight: "bold" }}>
                                        DISTRICT :
                                      </span>{" "}
                                      {details?.districtName}
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </TabPanel>

                          <TabPanel
                            value="6"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="candApplicationTable"
                                  responsive
                                  bordered
                                  data={appliedTableData}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchApp(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesApp > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer2 + 1} to{" "}
                                      {indexOfFirstTransfer2 +
                                        appliedData?.length}{" "}
                                      of {totalPagesApp} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesApp / PerPageCount
                                      )}
                                      page={currentPageApp}
                                      onChange={paginateApp}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="4"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md={3} xl={3} style={{ cursor: "pointer" }}>
                                <Card>
                                  <CardBody style={{ padding: "10px 10px" }}>
                                    <div>
                                      <h4 className="mb-1 mt-1 h4-title">
                                        <span>{totalSaved}</span>
                                      </h4>
                                      <p className="text-dark mb-0 h4-subtitle font-size-13">
                                        Saved
                                      </p>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col md={3} xl={3} style={{ cursor: "pointer" }}>
                                <Card>
                                  <CardBody style={{ padding: "10px 10px" }}>
                                    <div>
                                      <h4 className="mb-1 mt-1 h4-title">
                                        <span>{totalApplied}</span>
                                      </h4>
                                      <p className="text-dark mb-0 h4-subtitle font-size-13">
                                        Applications
                                      </p>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col md={3} xl={3} style={{ cursor: "pointer" }}>
                                <Card>
                                  <CardBody style={{ padding: "10px 10px" }}>
                                    <div>
                                      <h4 className="mb-1 mt-1 h4-title">
                                        <span>{totalShortlisted}</span>
                                      </h4>
                                      <p className="text-dark mb-0 h4-subtitle font-size-13">
                                        ShortListed
                                      </p>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                              <Col md={3} xl={3} style={{ cursor: "pointer" }}>
                                <Card>
                                  <CardBody style={{ padding: "10px 10px" }}>
                                    <div>
                                      <h4 className="mb-1 mt-1 h4-title">
                                        <span>{totalPlaced}</span>
                                      </h4>
                                      <p className="text-dark mb-0 h4-subtitle font-size-13">
                                        Placed
                                      </p>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <AvForm
                                  ref={formRef}
                                  className="needs-validation"
                                  onValidSubmit={() => {
                                    handleValidSubmitSummary();
                                  }}
                                >
                                  <Row>
                                    <Col md="9">
                                      <div className="mb-3">
                                        <AvField
                                          name="remarks_reason"
                                          placeholder="Remarks"
                                          type="textarea"
                                          className="form-control"
                                          id="validationCustom01"
                                          errorMessage="Enter remarks"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          value={master?.remarks_reason}
                                          onChange={handleInputChange}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="1" style={{ marginTop: "9px" }}>
                                      <Button color="primary" type="submit">
                                        {"Submit"}
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12" className="chat-conversation">
                                      <ul
                                        style={{
                                          listStyle: "none",
                                          paddingLeft: "0",
                                        }}
                                      >
                                        {remarksData &&
                                          remarksData.map((item) => {
                                            return (
                                              <li>
                                                <div
                                                  class="conversation-list"
                                                  style={{
                                                    marginBottom: "0px",
                                                  }}
                                                >
                                                  <div class="ctext-wrap">
                                                    <div
                                                      class="ctext-wrap-content w-100"
                                                      style={{
                                                        background: "#f5f6f8",
                                                      }}
                                                    >
                                                      <Row>
                                                        <Col md="8">
                                                          <h5 class="font-size-14 conversation-name">
                                                            <a
                                                              class="text-dark"
                                                              href=""
                                                            >
                                                              {item?.staff_name}
                                                            </a>
                                                          </h5>
                                                        </Col>
                                                        <Col
                                                          md="4"
                                                          style={{
                                                            textAlign: "right",
                                                          }}
                                                        >
                                                          <span class="d-inline-block font-size-12 text-muted ms-2">
                                                            {" "}
                                                            {item?.dateTime}
                                                          </span>
                                                        </Col>
                                                      </Row>

                                                      <p class="mb-0">
                                                        {item?.remarks_reason}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </Col>
                                  </Row>
                                  {totalPagesRemarks > 10 && (
                                    <div className="pagination-div">
                                      <div className="page-details">
                                        Showing {indexOfFirstTransfer5 + 1} to{" "}
                                        {indexOfFirstTransfer5 +
                                          remarksData?.length}{" "}
                                        of {totalPagesRemarks} entries
                                      </div>
                                      <Pagination
                                        color="standard"
                                        shape="rounded"
                                        defaultPage={1}
                                        count={Math.ceil(
                                          totalPagesRemarks / 10
                                        )}
                                        page={currentPageReamrks}
                                        onChange={paginateRemarks}
                                        size="large"
                                      />
                                    </div>
                                  )}
                                </AvForm>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="7"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col
                                md="12"
                                style={{ borderBottom: "black 1px dotted" }}
                              >
                                <AvForm
                                  className="needs-validation"
                                  onValidSubmit={(e, v) => {
                                    handleFollowupSubmit(e, v);
                                  }}
                                >
                                  <Row>
                                    <Col md="2">
                                      <div className="mb-3">
                                        <Label>Status</Label>
                                        <div
                                          className={
                                            followupErrors?.followup_current_status
                                              ? "my-is-invalid"
                                              : ""
                                          }
                                        >
                                          <Select
                                            name="follow_status"
                                            value={selectedFollowStatus}
                                            onChange={(selected) => {
                                              handleFollowChange(
                                                selected,
                                                "status"
                                              );
                                            }}
                                            options={followupstatusOptions}
                                            classNamePrefix=" select2-selection"
                                          />
                                        </div>

                                        <div class="my-invalid-feedback">
                                          {
                                            followupErrors?.followup_current_status
                                          }
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="2">
                                      <div className="mb-3">
                                        <Label htmlFor="validationCustom05">
                                          Next Followup
                                        </Label>
                                        <input
                                          className="form-control"
                                          type="date"
                                          id="followup_next_date"
                                          name="followup_next_date"
                                          value={
                                            followupObject?.followup_next_date
                                          }
                                          onChange={(e) => {
                                            handleFollowChange(
                                              e,
                                              "followup_next_date"
                                            );
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className="mb-3">
                                        <Label htmlFor="followup_message">
                                          Remarks{" "}
                                        </Label>
                                        <AvField
                                          name="followup_message"
                                          placeholder="Remarks"
                                          type="textarea"
                                          // errorMessage="Enter Remarks "
                                          className={
                                            followupErrors?.followup_message
                                              ? "form-control my-is-invalid"
                                              : "form-control"
                                          }
                                          //validate={{ required: { value: true } }}
                                          id="followup_message"
                                          value={
                                            followupObject?.followup_message
                                          }
                                          onChange={(e) => {
                                            handleFollowChange(
                                              e,
                                              "followup_message"
                                            );
                                          }}
                                        />
                                        <div class="my-invalid-feedback">
                                          {followupErrors?.followup_message}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="2" style={{ marginTop: "3.7%", }}>
                                      <div className="mb-3" style={{ display: "flex", marginLeft: "-9px" }}>
                                        <Button className="mx-1" color="primary" type="submit">
                                          {"Submit"}
                                        </Button>
                                        <Button
                                          color="danger"
                                          type="reset"
                                          onClick={resetFollowUpForm}
                                        >
                                          {"Reset"}
                                        </Button>
                                      </div>
                                    </Col>

                                  </Row>
                                </AvForm>
                              </Col>

                              <Col md="12" className="mt-3">
                                <Row>
                                  <Col md="2">
                                    <div>
                                      <Label htmlFor="validationCustom05">
                                        From
                                      </Label>
                                      <input
                                        className="form-control"
                                        type="date"
                                        id="from_date"
                                        name="from_date"
                                        value={followSearch?.from_date}
                                        onChange={(e) => {
                                          handleFollowFilter(e, "from_date");
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="2">
                                    <div>
                                      <Label htmlFor="validationCustom05">
                                        To
                                      </Label>
                                      <input
                                        className="form-control"
                                        type="date"
                                        id="to_date"
                                        name="to_date"
                                        value={followSearch?.to_date}
                                        onChange={(e) => {
                                          handleFollowFilter(e, "to_date");
                                        }}
                                      />
                                    </div>
                                  </Col>
                                  <Col md="2">
                                    <div>
                                      <Label>Status</Label>
                                      <Select
                                        name="follow_status"
                                        value={followSearch?.statusObject}
                                        onChange={(selected) => {
                                          handleFollowFilter(
                                            selected,
                                            "status"
                                          );
                                        }}
                                        options={followupstatusOptions}
                                        classNamePrefix=" select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="2">
                                    <div>
                                      <Label>Staff</Label>
                                      <Select
                                        name="staff_id"
                                        value={followSearch?.staffObject}
                                        onChange={(selected) => {
                                          handleFollowFilter(selected, "staff");
                                        }}
                                        options={staffOptions}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="1" style={{ marginTop: "1.8rem" }}>
                                    <div className="mb-3">
                                      <Button
                                        color="danger"
                                        type="reset"
                                        onClick={resetFollowupFilter}
                                      >
                                        {"Reset"}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <MDBDataTable
                                  id="candidatefollowupTableId"
                                  responsive
                                  bordered
                                  data={followup_data}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchFollowup(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalFPages > 20 && (
                                  <div className="pagination-div">
                                    {/* Display the page details */}
                                    <div className="page-details">
                                      Showing {indexOfFFirstTransfer + 1} to{" "}
                                      {indexOfFFirstTransfer +
                                        followupData?.length}{" "}
                                      of {totalFPages} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalFPages / PerPageCount
                                      )}
                                      page={currentFPage}
                                      onChange={followuppaginate}
                                      info={true}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewCustomer;
