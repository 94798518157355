import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "./Recruit.scss";
import Select from "react-select";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr, { error } from "toastr";
import { result } from "lodash";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";


function Recruit() {
  const history = useHistory();

  const OnTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [instituteOption, setinstituteOption] = useState();
  const [selectedInst, setselectedInst] = useState(null);
  const [masterObj, setmasterObj] = useState({});
  const [subjectOptions, setsubjectOptions] = useState();
  const [selectSubject, setselectSubject] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectClass, setselectClass] = useState(null);
  const [selectedSalary, setSelectedSalary] = useState(null)
  const [classOptions, setclassOptions] = useState();
  const [item, setItem] = useState(null); // Initial state
  const [tableData, settableData] = useState([]);
  const [confirmDeleteAlert, setconfirmDeleteAlert] = useState(false);
  const [dataTobeDeleted, setdataTobeDeleted] = useState(null);
  const [preUpdateItem, setpreUpdateItem] = useState({});
  const [recruitIdToUpdated, setrecruitIdToUpdated] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchObect, setsearchObect] = useState({ start: 1 });
  const [selectedSubscription, setselectedSubscription] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(false)
  const [recruitStatus, setRecruitStatus] = useState(null)
  const [recruitId, setRecruitId] = useState(null)
  const [recruitInst, setRecruitInst] = useState(null)
  const [errorsV, setErrorsV] = useState({
    institue: "",
    class: "",
    subject: "", salaryError: ""
  });
  useEffect(() => {
    fetch_all_institute();
    fetch_subject();
    fetch_class();
    handleTabledata();
  }, [searchObect]);


  // Set options for the status dropdown
  const statusOptions = [
    { label: "Active", value: 1 },
    { label: "Expired", value: 2 },
    { label: "No Subscription", value: 3 },
  ];


  const fetch_subject = () => {
    axios
      .get(`${API_URL}subject/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let sub_data =
          res?.data?.data &&
          res?.data?.data?.map((el) => {
            return {
              label: el?.subject_name,
              value: el?._id,
            };
          });
        setsubjectOptions(sub_data);
      });
  };

  function paginate(event, value) {
    setsearchObect({ ...searchObect, start: value });
    setCurrentPage(value);
  }
  const fetch_all_institute = () => {
    axios
      .get(`${API_URL}institute/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let institute_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.inst_reg_no + "-" + el?.inst_name,
              value: el?._id,
              whatsapp: el?.inst_whatsapp_no,
              displayLabel: el?.inst_name,
            };
          });
        setinstituteOption(institute_data);
      });
  };

  const fetch_class = () => {
    axios
      .get(`${API_URL}manage/classes`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let class_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setclassOptions(class_data);
      });
  };

  // const data = {
  //   columns: [
  //     {
  //       label: "#",
  //       field: "id",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Date",
  //       field: "date1",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Institute",
  //       field: "instname",
  //       sort: "asc",
  //       width: 150,
  //     },

  //     {
  //       label: "Subject",
  //       field: "subject1",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Class",
  //       field: "class1",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Salary",
  //       field: "salary",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Package",
  //       field: "package_name",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Status",
  //       field: "status",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Action",
  //       field: "action",
  //       sort: "asc",
  //       width: 150,
  //     },
  //   ],
  //   rows: tableData,
  // };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Institute",
        field: "selectInstitute",
        sort: "asc",
        width: 150,
      },

      {
        label: "Subject",
        field: "selectsubject",
        sort: "asc",
        width: 150,
      },
      {
        label: "Class",
        field: "selectclass",
        sort: "asc",
        width: 150,
      },
      {
        label: "Salary",
        field: "salary",
        sort: "asc",
        width: 150,
      },
      {
        label: "Package Added",
        field: "package_nme",
        sort: "asc",
        width: 150,
      },
      {
        label: "Subscription Status",
        field: "subscr_status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData,
  };

  function handleChange(selected, name) {
    switch (name) {
      case "selectInstitute":
        setselectedInst(selected);
        setmasterObj({ ...masterObj, [name]: selected.value });
        break;
      case "selectsubject":
        setselectSubject(selected);
        setmasterObj({ ...masterObj, [name]: selected.value });
        break;
      case "selectclass":
        setselectClass(selected);
        setmasterObj({ ...masterObj, [name]: selected.value });
        break;
      case "subscriptionstatus":
        setselectedSubscription(selected);
        setsearchObect({ ...searchObect, [name]: selected.value });
        break;
      case "salary":
        setSelectedSalary(selected)
        setmasterObj({ ...masterObj, [name]: selected.value })
        break;
      case "subscription_status":
        setSelectedStatus(selected)
        setmasterObj({ ...masterObj, [name]: selected.value })
        break;

      default:
        break;
    }
  }
  function handleChangefiled(e) {
    let value = e.target.value;
    let name = e.target.name;
    setmasterObj({ ...masterObj, [name]: value });
  }

  let preupdatePackage = (item) => {
    setrecruitIdToUpdated(item?._id);

    axios
      .get(`${API_URL}recruit-with-support/single/list?requirement_id=${item?._id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then(res => {
        console.log(res, "res");

        let result = res.data.data;

        let instituteOp = {
          label: result?.selectInstitute?.inst_name,
          value: result?.selectInstitute?._id,
        };
        setselectedInst(instituteOp);

        let subjectOp = {
          label: result?.selectsubject?.subject_name,
          value: result?.selectsubject?._id,
        };
        setselectSubject(subjectOp);

        let classOp = {
          label: result?.selectclass?.name,
          value: result?.selectclass?._id,
        };
        setselectClass(classOp);

        let salaryop = {
          label: result?.selectSalary,
          value: result?.selectSalary,
        };
        setSelectedSalary(salaryop)

        setmasterObj(result);

      })

  };

  function handleValidSubmit(e, v) {
    e.preventDefault();

    let data = {
      _id: masterObj?._id,
      selectsubject: masterObj?.subject?._id,
      selectclass: masterObj?.class?._id,
      selectInstitute: masterObj?.institute?._id,
      selectSalary: masterObj?.salary
    }
    if (
      selectedInst === null ||
      selectClass === null ||
      selectSubject === null || selectedSalary === null
    ) {
      let err = errorsV;
      if (selectedInst == null) {
        err.institue = "Please select institute";
      }
      if (selectClass == null) {
        err.class = "Please slecet class";
      }
      if (selectSubject == null) {
        err.subject = "Plese select subject";
      }
      if (selectedSalary == null) {
        err.salaryError = "Please select salary"
      }

      setErrorsV({ ...err });
    } else {
      if (recruitIdToUpdated) {
        // setmasterObj({...masterObj,_id:recruitIdToUpdated})
        axios
          .put(`${API_URL}recruit-with-support`, masterObj, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              toastr.success("Updated Successfully");
              formRef.current.reset();
              handleTabledata();
              setmasterObj({});
              setselectClass(null);
              setselectSubject(null);
              setselectedInst(null);
              setSelectedSalary(null)
              setErrorsV({ institue: "", class: "", subject: "", salaryError: '' });
              setrecruitIdToUpdated(null);
            } else {
              toastr.error("Failed to Update Recruit");
              setrecruitIdToUpdated(null);
            }
          });
      } else {
        setIsSubmitting(true);
        axios
          .post(`${API_URL}recruit-with-support`, masterObj, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setIsSubmitting(false);
              toastr.success("Added Successfully");
              handleTabledata();
              setselectClass(null);
              setselectSubject(null);
              setselectedInst(null);
              setSelectedSalary(null)
              formRef.current.reset();
              setmasterObj({});
              setErrorsV({ institue: "", class: "", subject: "", salaryError: '' });
              setrecruitIdToUpdated(null);
            } else {
              toastr.error("Failed to Add Recruit");
              setrecruitIdToUpdated(null);
            }
          })
          .catch((error) => {
            setIsSubmitting(false);
            toastr.error(error.response.message);
            setrecruitIdToUpdated(null);
          });
      }
    }
  }


  function handleTabledata() {
    axios
      .get(
        `${API_URL}recruit-with-support?subscriptionstatus=${searchObect?.subscriptionstatus}&from_date=${searchObect?.from_date}&to_date=${searchObect?.to_date}&start=${searchObect?.start}&limit=10`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        setTotalPages(res.data.count);
        result &&
          result?.map((item, index) => {
            item.id = (searchObect.start - 1) * 15 + index + 1;
            item.action = (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item?.subscription_status ? (
                  <i
                    class="fas fa-plus"
                    style={{ fontSize: "1rem", cursor: "pointer" }}
                    onClick={() => {
                      history.push({
                        pathname: `/subscription`,
                        state: {
                          Id: item?._id,
                          label: item?.institute?.management_name,
                          value: item?.institute?._id,
                          package_type: {
                            value: 2,
                            label: "Recruit with Support"
                          },
                        }
                      });
                    }}
                  ></i>
                ) : (
                  ""
                )}


                <i
                  className="uil-edit-alt"
                  style={{
                    fontSize: "1rem",
                    cursor: "pointer",
                    marginLeft: "0.4rem",
                    marginRight: "0.4rem",
                  }}
                  onClick={() => {
                    OnTop();
                    preupdatePackage(item);
                  }}
                ></i>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                  onClick={() => {
                    setdataTobeDeleted(item._id);
                    setconfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
            item.instname = (
              <div>
                <a
                  style={{ cursor: "pointer" }}
                  href={"/institute/" + item?.institute?.inst_reg_no}
                >
                  {item.institute?.management_name}
                </a>
              </div>
            );
            item.date1 = moment(item?.created_date).format("DD-MM-YYYY");
            item.selectInstitute = item?.institute?.management_name;
            item.selectsubject = item?.subject?.subject_name;
            item.selectclass = item?.class?.name;


            if (item.subscription_status == 1) {
              item.subscr_status = (
                <h6 style={{ textAlign: "center" }}>{"Active"}</h6>
              )
            } else if (item.subscription_status == 2) {
              item.subscr_status = (
                <h6 style={{ textAlign: "center" }}>{"Expired"}</h6>
              )
            } else {
              item.subscr_status = (
                <h6 style={{ textAlign: "center" }}>{"No Subscription"}</h6>
              )
            }

            if (item.package_added_from == 1) {
              item.package_nme = (
                <h6 style={{ textAlign: "center" }}>{"Crm"}</h6>
              )
            }

            // item.status = (
            //   <div className="mb-3">
            //     <Select
            //       styles={style}
            //       name="subscription_status"
            //       // value={statusOptions.find(option => option.value === item.subscription_status)}
            //       value={selectedStatus}
            //       options={statusOptions}
            //       menuposition="fixed"
            //       onChange={(selected) => {
            //         updateStatus({ ...item, subscription_status: selected.value });
            //         handleChangeStatus(selected, "subscription_status");
            //       }}
            //     />
            //   </div>
            // )
            if (item.current_status == 0) {
              item.status = (
                <h6 style={{ color: "green", cursor: "pointer", textAlign: "center" }} onClick={() => {
                  setConfirmAlert(true);
                  setRecruitId(item?._id)
                  setRecruitInst(item?.institute?._id)
                  setRecruitStatus(item?.current_status)
                }}>{"Pending"}</h6>
              )
            } else {
              item.status = (
                <h6 style={{ color: "red", textAlign: "center" }}>{"Completed"}</h6>
              )
            }
          });
        settableData(result);
      });
  }

  const handleChangeStatus = (selected, field) => {
    setItem(prevItem => ({ ...prevItem, [field]: selected.value }));
  };

  function updateStatus(updatedItem) {
    let data = {
      institute_id: updatedItem?.institute?._id,
      filled: updatedItem?.subscription_status
    }

    axios
      .put(`${API_URL}recruit-with-support/update/filled-active`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then(res => {
        handleTabledata()
      })
  }

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

  function reset() {
    setIsSubmitting(false);
    setmasterObj({});
    setselectClass(null);
    setselectSubject(null);
    setselectedInst(null);
    setSelectedSalary(null)
    setErrorsV({ institue: "", class: "", subject: "", salaryError: "" });
    formRef.current.reset();
  }

  function handleDateChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setsearchObect({ ...searchObect, [name]: value });
  }

  function resetFilter() {
    setselectedSubscription(null)
    handleTabledata()
    setsearchObect({ from_date: "", to_date: "" })
  }


  return (
    <React.Fragment>

      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onCancel={() => setconfirmDeleteAlert(false)}
          onConfirm={() => {
            axios
              .delete(`${API_URL}recruit-with-support?_id=${dataTobeDeleted}`, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  toastr.success(" Deleted Successfully");
                  handleTabledata();
                  setconfirmDeleteAlert(false);
                  setdataTobeDeleted(null);
                  setselectClass(null);
                  setselectSubject(null);
                  setselectedInst(null);
                  setSelectedSalary(null)
                  setmasterObj({});
                  setrecruitIdToUpdated(null);
                  formRef.current.reset();
                } else {
                  toastr.error("Failed To Delete Package");
                }
              })
              .catch((error) => {
                toastr.error(error.response.data.message);
              });
            setconfirmDeleteAlert(false);
          }}
        >
          {" "}
          Are you sure want to delete it?
        </SweetAlert>
      ) : null}

      {confirmAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          cancelBtnText="No"
          onConfirm={() => {
            axios
              .put(`${API_URL}recruit-with-support/update/filled-active`, { _id: recruitId, institute_id: recruitInst, status: recruitStatus }, {
                headers: {
                  "x-access-token": accessToken,
                },
              }).then(res => {
                handleTabledata()
                setConfirmAlert(false)
              }).catch(err => {
                toastr.error("Failed to update status")
              })
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          Are you sure want to change the status to completed?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Recruit with support" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Institute</Label>
                          <Select
                            name="selectInstitute"
                            value={selectedInst}
                            options={instituteOption}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleChange(selected, "selectInstitute");
                            }}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedInst === null ? errorsV.institue : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Subject</Label>
                          <Select
                            name="selectsubject"
                            value={selectSubject}
                            options={subjectOptions}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleChange(selected, "selectsubject");
                            }}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectSubject === null ? errorsV.subject : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Class</Label>
                          <Select
                            name="selectclass"
                            value={selectClass}
                            options={classOptions}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleChange(selected, "selectclass");
                            }}
                          />{" "}
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectClass === null ? errorsV.class : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Salary</Label>
                          <Select
                            name="salary"
                            value={selectedSalary}
                            validate={{ required: { value: true } }}
                            onWheel={(e) => e.target.blur()}
                            options={[
                              { label: "10000-20000", value: "10000-20000" },
                              { label: "20000-30000", value: "20000-30000" },
                              { label: "30000-40000", value: "30000-40000" },
                              { label: "40000-50000", value: "40000-50000" },
                              { label: "50000-60000", value: "50000-60000" },
                              { label: "60000-70000", value: "60000-70000" },
                              { label: "70000-80000", value: "70000-80000" },
                              { label: "80000-90000", value: "80000-90000" },
                              { label: "100000-150000", value: "100000-150000" },
                              { label: "150000-200000", value: "150000-200000" },
                              { label: "200000+", value: "200000+" }
                            ]}
                            onChange={(selected) => {
                              handleChange(selected, "salary")
                            }}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedSalary === null ? errorsV.salaryError : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mt-4">
                          {recruitIdToUpdated ? (
                            <Button
                              className="mx-2"
                              color="primary"
                              type="Update"
                              disabled={isSubmitting}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              className="mx-2"
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Submit
                            </Button>
                          )}
                          <Button
                            onClick={() => reset()}
                            color="danger"
                            type="reset"
                            className="mx-3"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>

                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>

                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Subscription status</Label>
                          <Select
                            name="subscriptionstatus"
                            onChange={(selected) => {
                              handleChange(selected, "subscriptionstatus");
                            }}
                            options={[
                              { label: "Active", value: "1" },
                              { label: "Expired", value: "2" },
                              { label: "No Subsciption", value: "3" },
                            ]}
                            value={selectedSubscription}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>From date</Label>
                          <AvField
                            name="from_date"
                            type="date"
                            onChange={(e) => {
                              handleDateChange(e);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>To Date</Label>
                          <AvField
                            name="to_date"
                            type="date"
                            onChange={(e) => {
                              handleDateChange(e);
                            }}
                          />
                        </div>
                      </Col>

                      {/* <Col md="3">
                        <Button type="submit" color="danger" onClick={() => resetFilter()}>Reset</Button>
                      </Col> */}

                    </Row>
                  </AvForm>

                  <Row>
                    <MDBDataTable
                      id="recruittable"
                      responsive
                      bordered
                      data={data}
                      info={false}
                      paging={false}
                      disableRetreatAfterSorting={true}
                    />
                    {totalPages > 1 && (
                      <div
                        className="pagination-div"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <Pagination
                          color="standard"
                          shape="rounded"
                          defaultPage={1}
                          count={Math.ceil(totalPages / 10)}
                          page={currentPage}
                          onChange={paginate}
                          size="large"
                        />
                      </div>
                    )}
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>

    </React.Fragment>
  );
}

export default Recruit;
