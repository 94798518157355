import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
} from "reactstrap";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import moment from "moment";
import {
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import $ from "jquery";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Select from "react-select";
import toastr from "toastr";
import "./placedApplications.scss";
import { useHistory } from "react-router-dom";
//import ReactPlayer from "react-player";
import saveAs from "file-saver";
import Pagination from "@mui/material/Pagination";
const PlacedApplication = (props) => {
  const [searchData, setSearchData] = useState({
    // from_date: getDate(new Date()),
    // to_date: getDate(new Date()),
  });
  const [searchObj, setsearchObj] = useState({})
  const [details, setDetails] = useState([]);
  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [instituteOptions, setInstituteOptions] = useState([]);
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [candidateOptions, setCandidateOptions] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [jobpostOptions, setJobpostOptions] = useState([]);
  const [selectedJobpost, setSelectedJobpost] = useState(null);
  const [showDeclineForm, setShowDeclineForm] = useState(false);
  const [master, setMaster] = useState({});
  const [errors, setErrors] = useState({
    remarkError: "",
  });
  const [selectedDecline, setSelectedDecline] = useState(null);
  const [ReasonsOption, setReasonsOptions] = useState([]);
  const [videoForm, setVideoForm] = useState(false);
  // const [candidateVideo, setCandidateVideo] = useState({});
  const [candidateVideo1, setCandidateVideo1] = useState("");
  const [candidateVideo2, setCandidateVideo2] = useState("");
  const [candidateVideo1Hash, setCandidateVideo1Hash] = React.useState("");
  const [candidateVideo2Hash, setCandidateVideo2Hash] = React.useState("");
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const history = useHistory();
  /** pagination */
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
    currentPage * PerPageCount
  );

  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
    indexOfLastTransfer - PerPageCount
  );
  const paginate = (event, value) => {
    setCurrentPage(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
    handleTableData(searchValue, searchData?.from_date, searchData?.to_date, selectedCandidate?.value, selectedJobpost?.value, selectedInstitute?.value, indexOfFirstTransfer, indexOfLastTransfer);
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  }
  const handleSearch = (value) => {
    setSearchValue(value)
    resetPaginate();
    handleTableData(value, searchData?.from_date, searchData?.to_date, selectedCandidate?.value, selectedJobpost?.value, selectedInstitute?.value);
  }
  useEffect(() => {
    if (details.length == 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData(searchValue, searchData?.from_date, searchData?.to_date, selectedCandidate?.value, selectedJobpost?.value, selectedInstitute?.value);
    }

  }, [details])
  /** end pagination */
  useEffect(() => {
    handleTableData(searchValue);
    fetch_all_institute();
    fetch_candidate();
    fetch_all_reasons();
  }, []);
  const fetch_all_reasons = () => {
    axios
      .get(`${API_URL}manage/decline`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let reason_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item?.remark,
              value: item?._id,
            };
          });
        setReasonsOptions([
          {
            options: reason_data,
          },
        ]);
      });
  };
  const downloadOfferletter = (item) => {
    saveAs(
      `${API_URL}` + item?.offerltr_pdf_link,
      "Indiana Global Teachers.pdf"
    );
  };
  function handleTableData(
    search = "",
    from_date = "",
    to_date = "",
    candidate_id = "",
    institute_id = "",
    job_id = "",
    start = 1, end = 20
  ) {
    var url = `${API_URL}placed/list`;

    // if (from_date === "" && to_date === "") {
    //   from_date = from_date ? from_date : getDate(new Date());
    //   to_date = to_date ? to_date : getDate(new Date());
    // }

    url =
      url +
      "?from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&candidate_id=" +
      candidate_id +
      "&institute_id=" +
      institute_id +
      "&job_id=" +
      job_id + "&search=" + search + "&start=" + start + "&end=" + end;


    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPages(res.data.limit);
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.date = moment(item?.placed_date).format("DD-MM-YYYY");
            item.qualificationName = "";
            item.qualification &&
              item.qualification.map((el) => {
                if (item.qualificationName === "")
                  item.qualificationName = el?.qualification_name;
                else
                  item.qualificationName =
                    item.qualificationName + "," + el?.qualification_name;
              });
            if (item?.placed_current_status === 0) item.status = "Placed";
            else if (item?.placed_current_status === 1)
              item.status = "Declined";
            else item.status = "";

            item.jobName =
              item?.placed_job_name ? item?.placed_job_name + " " + "(" + item?.job_reg_no + ")" : "No Job data";
            item.reg_no =
              item?.placed_reg_no ? item?.placed_reg_no : "No Job data";

            item.instName = (
              <div>
                {item?.placed_inst_name ? (
                  <a
                    target="blank"
                    style={{ cursor: "pointer" }}
                    href={"/institute/" + item?.inst_reg_no}
                  >
                    {item?.placed_inst_name}
                  </a>
                ) : "No job data"}
              </div>
            )

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>
                <button
                  onClick={() => {
                    openVideoForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-video"></i>
                </button>
                <button
                  onClick={() => {
                    openDeclineForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-thumbs-down"></i>
                </button>

                {item?.offerltr_pdf_link &&
                  item?.placed_current_status === 0 ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                      >
                        Offer Letter
                      </Tooltip>
                    }
                  >
                    <button
                      className="button_class"
                      onClick={() => {
                        downloadOfferletter(item);
                      }}
                    >
                      {" "}
                      <i
                        className="fas fa-file-pdf"
                        style={{ color: "red" }}
                      ></i>
                    </button>
                  </OverlayTrigger>
                ) : !item?.offerltr_pdf_link &&
                  item?.placed_current_status === 0 ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                      >
                        Generate offer letter
                      </Tooltip>
                    }
                  >
                    <button
                      onClick={() => {
                        const queryParams = new URLSearchParams({
                          jobId: item?.placed_job_id,
                          candId: item?.placed_cand_id,
                        }).toString();

                        window.open(`/offerLetter?${queryParams}`, "_blank", "noopener noreferrer");
                        // history.push("/offerLetter", {
                        //   jobId: item?.placed_job_id,
                        //   candId: item?.placed_cand_id,
                        // });
                      }}
                      className="button_class"
                    >
                      <i className="fas fa-file-invoice"></i>
                    </button>
                  </OverlayTrigger>
                ) : null}
              </div>
            );
          });
        setDetails(result);
      });
  }
  const openVideoForm = (item) => {
    axios
      .get(`${API_URL}candidate/file?candidate_id=` + item.placed_cand_id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        if (result?.cand_video1 || result?.cand_video2) {
          if (result?.cand_video1) {
            let vdo = result.cand_video1.split("/");
            let data1 = vdo.slice(-1).pop();
            let data = vdo.at(-2);
            setCandidateVideo1(data);
            setCandidateVideo1Hash(data1);
          } else {
            setCandidateVideo1("");
            setCandidateVideo1Hash("");
          }
          if (result?.cand_video2) {
            let vdo2 = result?.cand_video2.split("/");
            let value2 = vdo2.slice(-1).pop();
            let value = vdo2.at(-2);
            setCandidateVideo2(value);
            setCandidateVideo2Hash(value2);
          } else {
            setCandidateVideo2("");
            setCandidateVideo2Hash("");
          }
          setVideoForm(true);
        } else {
          toastr.error("No video is available");
          setCandidateVideo1("");
          setCandidateVideo2("");
          setCandidateVideo2Hash("");
          setCandidateVideo1Hash("");
        }
      });
  };
  const openDeclineForm = (item) => {
    if (item?.placed_current_status === 1) {
      toastr.error("Application is already declined");
    } else {
      setMaster({
        placed_id: item?._id,
      });
      setSelectedDecline(null);
      setErrors({
        remarkError: "",
      });
      setShowDeclineForm(true);
    }
  };
  const submitDeclineForm = () => {
    if (selectedDecline === null) {
      setErrors({
        remarkError: "Please select the reason",
      });
    } else {
      axios
        .put(
          `${API_URL}placed/decline?placed_id=` + master?.placed_id,
          master,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application declined successfully");
            setSelectedDecline(null);
            handleTableData(searchValue, searchData?.from_date, searchData?.to_date, selectedCandidate?.value, selectedJobpost?.value, selectedInstitute?.value, indexOfFirstTransfer, indexOfLastTransfer);
            setMaster({});
            setShowDeclineForm(false);
          } else {
            toastr.error("Failed to decline application");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const fetch_all_institute = () => {
    axios
      .get(`${API_URL}institute/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let institute_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.inst_reg_no + "-" + el?.inst_name,
              value: el?._id,
            };
          });
        setInstituteOptions([
          {
            options: institute_data,
          },
        ]);
      });
  };
  const fetch_all_jobpost = (id) => {
    axios
      .get(`${API_URL}institute/jobname?institute_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let job_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.job_name,
              value: el?._id,
            };
          });
        setJobpostOptions([
          {
            options: job_data,
          },
        ]);
      });
  };
  const fetch_candidate = () => {
    axios
      .get(`${API_URL}candidate/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let candidate_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.cand_name,
              value: el?._id,
            };
          });
        setCandidateOptions([
          {
            options: candidate_data,
          },
        ]);
      });
  };
  const handleClickOpenForm = (item) => {
    setPopupView(true);

    axios
      .get(`${API_URL}placed/view?placed_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.joiningdate = moment(result?.joining_date).format("DD-MM-YYYY");
        result.date = moment(result?.placed_date).format("DD-MM-YYYY");
        result.time = moment(result?.placed_time, "hhmmss").format("hh:mm a");
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupData(result);
      });
  };
  let handleDate = (e) => {
    if (e.target.name == "from_date") {
      var date1 = e.target.value;
      var date2 = searchData?.to_date;
    }
    else {
      var date1 = searchData?.from_date;
      var date2 = e.target.value;
    }
    let candidate_id = searchData?.candidate_id ? searchData.candidate_id : "";
    let job_id = searchData?.job_id ? searchData.job_id : "";
    let institute_id = searchData?.institute_id ? searchData.institute_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");
    resetPaginate();

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(searchValue, date1, date2, candidate_id, job_id, institute_id);
  };
  const reset = () => {
    setSearchData({
      // from_date: getDate(new Date()),
      // to_date: getDate(new Date()),
    });
    setSelectedCandidate(null);
    setSelectedInstitute(null);
    setSelectedJobpost(null);
    setJobpostOptions([]);
    handleTableData(searchValue);
    resetPaginate();
  };
  const handleSelectChange = (selected, name) => {
    let from_date = searchData?.from_date ? searchData.from_date : "";
    let to_date = searchData?.to_date ? searchData.to_date : "";
    let candidate_id = searchData?.candidate_id ? searchData.candidate_id : "";
    let job_id = searchData?.job_id ? searchData.job_id : "";
    let institute_id = searchData?.institute_id ? searchData.institute_id : "";
    if (name != "reason")
      resetPaginate();
    switch (name) {
      case "institute_id":
        setSelectedInstitute(selected);
        setSelectedJobpost(null);
        setSearchData({
          ...searchData,
          institute_id: selected.value,
          job_id: "",
        });
        fetch_all_jobpost(selected.value);
        handleTableData(searchValue, from_date, to_date, candidate_id, selected.value, job_id);
        break;
      case "job_id":
        setSelectedJobpost(selected);
        setSearchData({
          ...searchData,
          job_id: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          candidate_id,
          institute_id,
          selected.value,
        );
        break;
      case "candidate_id":
        setSelectedCandidate(selected);
        setSearchData({
          ...searchData,
          candidate_id: selected.value,
        });
        handleTableData(searchValue, from_date, to_date, selected.value, institute_id, job_id);
        break;
      case "reason":
        setSelectedDecline(selected);
        setMaster({
          ...master,
          decline_remark: selected.label,
        });
        setErrors({
          remarkError: "",
        });
        break;
      default:
        break;
    }
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "reg_no",
        sort: "asc",
        width: 400,
      },

      // {
      //   label: "Mobile",
      //   field: "mobile",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Job Name",
        field: "jobName",
        sort: "asc",
        width: 400,
      },
      {
        label: "Institute",
        field: "instName",
        sort: "asc",
        width: 400,
      },
      {
        label: "Applicant",
        field: "placed_cand_name",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Experience",
      //   field: "placed_cand_experience",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Qualification",
      //   field: "qualificationName",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Mobile",
        field: "placed_cand_mob",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  return (
    <React.Fragment>
      <Modal show={videoForm} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Candidate Videos</h5>
          <button
            type="button"
            onClick={() => {
              setVideoForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <Row>
              <Col xl={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Intro video</CardTitle>
                    <p className="card-title-desc"></p>

                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                      {candidateVideo1 && (
                        <iframe
                          title="test"
                          className="embed-responsive-item"
                          src={`https://player.vimeo.com/video/${candidateVideo1}?h=${candidateVideo1Hash}`}
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
                {/* <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <ReactPlayer url={candidateVideo?.cand_video1} />
                      </Row>
                    </div>
                  </CardBody>
                </Card> */}
              </Col>
              <Col xl={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Teaching video</CardTitle>
                    <p className="card-title-desc"></p>

                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                      {candidateVideo2 && (
                        <iframe
                          title="test"
                          className="embed-responsive-item"
                          src={`https://player.vimeo.com/video/${candidateVideo2}?h=${candidateVideo2Hash}`}
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
                {/* <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <ReactPlayer url={candidateVideo?.cand_video2} />
                      </Row>
                    </div>
                  </CardBody>
                </Card> */}
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Placed Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupData?.placed_job_name ? popupData?.placed_job_name : "No Job data"}</td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.placed_reg_no ? popupData?.placed_reg_no : "No Job data"}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Placed Date :</td>
                <td>{popupData?.date ? popupData?.date : "No Job data"}</td>
                <td style={{ paddingLeft: "25px" }}>Placed Time :</td>
                <td style={{ textAlign: "left" }}>{popupData?.time ? popupData?.time : "No Job data"}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupData?.state ? popupData?.state : "No Job data"}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>{popupData?.district ? popupData?.district : "No Job data"}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}>
                  {popupData?.placed_inst_name ? popupData?.placed_inst_name : "No Job data"}
                </td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}>
                  {popupData?.placed_cand_name ? popupData?.placed_cand_name : "No Job data"}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td>{popupData?.exp ? popupData?.exp : "No Job data"}</td>
                <td style={{ paddingLeft: "25px" }}>Qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupData?.qualification ? popupData?.qualification : "No Job data"}
                </td>
              </tr>
              <tr hidden={popupData?.placed_current_status !== 1}>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupData?.decline_remark ? popupData?.decline_remark : "No Job data"}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <Modal show={showDeclineForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={() => {
            submitDeclineForm();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    value={selectedDecline}
                    onChange={(selected) => {
                      handleSelectChange(selected, "reason");
                    }}
                    options={ReasonsOption}
                    classNamePrefix="select2-selection"
                  />
                  <p className="text-danger" style={{ fontSize: "11px" }}>
                    {selectedDecline === null ? errors.remarkError : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Placed Applications" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={searchData?.from_date}
                            onChange={handleDate}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={searchData?.to_date}
                            onChange={handleDate}
                            min={searchData?.from_date}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Institute</Label>
                          <Select
                            name="institute_id"
                            value={selectedInstitute}
                            onChange={(selected) => {
                              handleSelectChange(selected, "institute_id");
                            }}
                            options={instituteOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Job Post</Label>
                          <Select
                            name="job_id"
                            value={selectedJobpost}
                            onChange={(selected) => {
                              handleSelectChange(selected, "job_id");
                            }}
                            options={jobpostOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Candidate</Label>
                          <Select
                            name="candidate_id"
                            value={selectedCandidate}
                            onChange={(selected) => {
                              handleSelectChange(selected, "candidate_id");
                            }}
                            options={candidateOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "4px" }}>
                        <div className="mt-4 mb-2">
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <MDBDataTable
                        id="placedApplicationTableID"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={false}
                        disableRetreatAfterSorting={true}
                        paging={false}
                        onSearch={(value) => { handleSearch(value) }}
                        entries={PerPageCount}
                      />
                      {totalPages > 20 && (
                        <div className="pagination-div">
                          <div className="page-details">
                            Showing {indexOfFirstTransfer + 1} to {indexOfFirstTransfer + details.length} of {totalPages} entries
                          </div>
                          <Pagination
                            color="standard"
                            shape="rounded"
                            defaultPage={1}
                            count={Math.ceil(totalPages / PerPageCount)}
                            page={currentPage}
                            onChange={paginate}
                            size="large"
                          />
                        </div>
                      )}
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PlacedApplication;
