import React, { useEffect, useRef, useState } from "react"
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import "./webFaq.scss"


function InstituteFaq() {

    const formRef = useRef()
    const [master, setmaster] = useState({})
    const API_URL = process.env.REACT_APP_APIURL || "https://localhost:3099"

    const [faqDetails, setFaqDetails] = useState([])
    const [faqIdToBeUpdated, setFaqIdToBeUpdated] = useState(null)
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false)
    const [faqIdToBeDeleted, setFaqIdToBeDeleted] = useState(null)


    useEffect(() => {
        handleTableData()
    }, []);


    function handleTableData() {
        axios
            .get(`${API_URL}instfaq/faqs`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {
                let result = res.data.data
                result.map((item, indx) => {

                    item.id = indx + 1
                    item.date = moment(item?.createdAt).format("DD-MM-YYYY")
                    item.time = moment(item?.createdAt, "HH:mm s").format("hh:mm a")


                    item.action = (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <i className="far fa-edit"
                                style={{
                                    cursor: "pointer",
                                    fontSize: "1em",
                                    marginRight: "0.5rem",
                                    marginLeft: "0.2rem"
                                }}
                                onClick={() => {
                                    toTop()
                                    preUpdate(item)
                                }}
                            ></i>
                            <i className="far fa-trash-alt"
                                style={{ cursor: "pointer", fontSize: "1em" }}
                                onClick={() => {
                                    setFaqIdToBeDeleted(item?._id)
                                    setConfirmDeleteAlert(true)
                                }}
                            ></i>
                        </div>
                    )
                })
                setFaqDetails(result)
            })
    }

    function handleChange(e) {
        let { name, value } = e.target
        setmaster({ ...master, [name]: value })
    }

    function reset() {
        handleTableData()
        setmaster({})
    }

    function handleSubmit() {
        if (faqIdToBeUpdated) {
            axios
                .put(`${API_URL}instfaq/faqs/${faqIdToBeUpdated}`, master, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }).then((res) => {
                    if (res.data.success === true) {
                        toastr.success("FAQ Updated successfully")
                        formRef.current.reset()
                        reset()
                        setFaqIdToBeUpdated(null)
                    } else {
                        toastr.error("Failed to update")
                        return;
                    }
                }).catch((err) => {
                    toastr.error(err.response.data.message)
                    return;
                })
        } else {
            axios
                .post(`${API_URL}instfaq/faqs`, master, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }).then((res) => {
                    if (res.data.success === true) {
                        toastr.success("FAQ Created successfully")
                        formRef.current.reset()
                        reset()
                    } else {
                        toastr.error("Failed to create")
                        return;
                    }
                }).catch((err) => {
                    toastr.error(err.response.data.message)
                    return;
                })
        }
    }

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 50,
            },
            {
                label: "Date",
                field: "date",
                width: 400,
            },
            // {
            //     label: "Time",
            //     field: "time",
            //     width: 400,
            // },
            {
                label: "Question",
                field: "question",
                width: 400,
            },
            {
                label: "Answer",
                field: "answer",
                width: 400,
            },
            {
                label: "Action",
                field: "action",
                width: 400,
            },
        ],
        rows: faqDetails,
    }

    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }

    let preUpdate = (item) => {
        setFaqIdToBeUpdated(item?._id)
        setmaster(item)
    }


    return (
        <>
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}instfaq/faqs/${faqIdToBeDeleted}`, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                if (res.data.success === true) {
                                    toastr.success("FAQ deleted successfully");
                                    handleTableData()
                                    formRef.current.reset()
                                    setFaqIdToBeUpdated(null)
                                } else {
                                    toastr.error("Failed to delete FAQ");
                                    return;
                                }
                            });
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Institute Faq" />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm className="needs-validation" ref={formRef}
                                        onValidSubmit={(e, v) => {
                                            handleSubmit(e, v)
                                        }}
                                    >

                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label>Question</Label>
                                                    <AvField
                                                        name="question"
                                                        placeholder="Question"
                                                        errorMessage="Enter Question"
                                                        validate={{ required: { value: true } }}
                                                        className="form-control"
                                                        value={master?.question}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="4">
                                                <div className="mb-3">
                                                    <Label>Answer</Label>
                                                    <AvField
                                                        name="answer"
                                                        type="textarea"
                                                        rows={1}
                                                        placeholder="Answer"
                                                        errorMessage="Enter Answer"
                                                        validate={{ required: { value: true } }}
                                                        className="form-control"
                                                        value={master?.answer}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="2" style={{ marginTop: "25px" }}>
                                                {faqIdToBeUpdated ? (
                                                    <Button color="primary" type="update">Update</Button>
                                                ) : (
                                                    <Button color="primary" type="submit">Submit</Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        id="fadTableId"
                                        responsive
                                        bordered
                                        info={true}
                                        searching={true}
                                        disableRetreatAfterSorting={true}
                                        data={data}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )

}

export default InstituteFaq;