import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./offerletter.scss";
import saveAs from "file-saver";
import { getDate, getFirstday } from "../../helpers/globalFunctions";
import { useHistory } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import $ from "jquery"

const OfferLetter = (props) => {
  // const state = props.location.state;
  const queryParams = new URLSearchParams(window.location.search);
  const jobId = queryParams.get("jobId");
  const candId = queryParams.get("candId");
  if (jobId && candId) {
    var state = { jobId: jobId, candId: candId };
  } else {
    var state = {};
  }

  const [master, setMaster] = useState({
    offerltr_issue_date: getDate(new Date()),
    crm_request: true,
    create_by: 1,
  });


  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [candidateOptions, setCandidateOptions] = useState([]);
  const [searchData, setSearchData] = useState({
    joining_date_from: "",
    joining_date_to: ""
  })
  const [provisionOptions, setProvisionOptions] = useState([]);
  const [selectedProvision, setSelectedProvision] = useState(null);
  const [selectedJobpost, setSelectedJobpost] = useState(null);
  const [jobpostOptions, setJobpostOptions] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState(null);
  const [classOptions, setClassOptions] = useState([]);
  const [details, setDetails] = useState([]);
  const [errors, setErrors] = useState({
    dateError: "",
    provisionError: "",
    classError: "",
    candidateError: "",
    jobpostError: "",
  });
  const formRef = useRef();
  const history = useHistory();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  /** pagination */
  const [searchValue, setSearchValue] = useState({});
  const [selectedSource, setselectedSource] = useState(null)
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
    currentPage * PerPageCount
  );
  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
    indexOfLastTransfer - PerPageCount
  );
  const paginate = (event, value) => {
    setCurrentPage(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    // setindexOfFirstTransfer(indexOfFirstTransfer);
    // setindexOfLastTransfer(indexOfLastTransfer);
    handleTableData(
      searchValue,
      selectedSource?.value,
      searchData?.joining_date_from,
      searchData?.joining_date_to,
      indexOfFirstTransfer,
      indexOfLastTransfer
    )
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  };
  const handleSearch = (value) => {
    resetPaginate();
    setSearchValue(value)

    let create_by = searchData?.create_by ? searchData?.create_by : "";
    let joining_date_from = searchData?.joining_date_from ? searchData?.joining_date_from : "";
    let joining_date_to = searchData?.joining_date_to ? searchData?.joining_date_to : "";
    handleTableData(searchValue, joining_date_from, joining_date_to, create_by);
  };

  const reset = () => {
    setselectedSource(null)
    setSearchData({
      joining_date_from: "",
      joining_date_to: "",
    });
    handleTableData()
  }


  useEffect(() => {
    if (details.length == 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData();
    }
  }, [details]);
  /** end pagination */
  useEffect(() => {
    handleTableData();
    fetch_jobs();
    fetch_all_provisions();
    // fetch_class();
  }, [searchValue, indexOfFirstTransfer, indexOfLastTransfer]);
  const fetch_candidate = (id) => {
    axios
      .get(`${API_URL}offerletter/candidate?job_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let candidate_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.placed_cand_name,
              value: el?.placed_cand_id,
            };
          });
        setCandidateOptions([
          {
            options: candidate_data,
          },
        ]);
        if (state) {
          candidate_data &&
            candidate_data.map((el) => {
              if (el?.value === state?.candId) {
                setSelectedCandidate(el);
              }
            });
        }
      });
  };

  function handleFilter(selected, name) {
    switch (name) {
      case "create_by":
        setSearchData({ ...searchData, create_by: selected.value })
        setselectedSource(selected)
        break;

      default:
        break;
    }
  }


  const fetch_jobs = () => {
    axios
      .get(`${API_URL}offerletter/placedjob`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let job_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.job_reg_no + "-" + el?.placed_job_name,
              displayLabel: el?.placed_job_name,
              value: el?._id,
            };
          });
        setJobpostOptions([
          {
            options: job_data,
          },
        ]);

        if (state) {
          job_data &&
            job_data.map((el) => {
              if (el?.value === state?.jobId) {
                handleSelectChange(el, "jobpost");
              }
            });
        }
      });
  };

  const fetch_all_provisions = () => {
    axios
      .get(`${API_URL}provisions/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let provision_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.provision_name,
              value: el?._id,
            };
          });
        setProvisionOptions([
          {
            options: provision_data,
          },
        ]);
      });
  };
  // const fetch_class = () => {
  //   axios
  //     .get(`${API_URL}manage/classes`, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       let class_data =
  //         res.data.data &&
  //         res.data.data.map((el) => {
  //           return {
  //             label: el?.name,
  //             value: el?._id,
  //           };
  //         });
  //       setClassOptions([
  //         {
  //           options: class_data,
  //         },
  //       ]);
  //     });
  // };handleInputChange
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleDate = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      dateError: "",
    });
  };
  const fetch_job_details = (id) => {
    axios
      .get(`${API_URL}offerletter/job?job_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        if (result) {
          let district = result?.job_district || {};
          let state = result?.job_state || {};
          let sub = result?.job_subject || {};

          let address = district?.district_name + "," + state?.name;
          let class_data =
            result.job_classes &&
            result.job_classes.map((el) => {
              return {
                label: el?.name,
                value: el?._id,
              };
            });
          setClassOptions([
            {
              options: class_data,
            },
          ]);

          let provision_data =
            result.job_provision &&
            result.job_provision.map((el) => {
              return {
                label: el?.provision_name,
                value: el?._id,
              };
            });

          if (provision_data && provision_data.length > 0) {
            setSelectedProvision(provision_data);
          } else setSelectedProvision(null);

          if (class_data && class_data.length > 0) {
            setSelectedClasses(class_data);
          } else setSelectedClasses(null);

          let classesArray = [];
          class_data &&
            class_data.forEach((element) => {
              classesArray.push(element.value);
            });

          let provisionArray = [];
          provision_data &&
            provision_data.forEach((element) => {
              provisionArray.push(element.value);
            });
          if (state) {
            setMaster({
              ...master,
              job_id: id,
              inst_name: result?.job_institute_name,
              inst_address: address,
              subjectName: sub?.subject_name,
              subject: sub?._id,
              classes: classesArray,
              provision: provisionArray,
              candidate_id: state?.candId,
            });
          } else {
            setMaster({
              ...master,
              job_id: id,
              inst_name: result?.job_institute_name,
              inst_address: address,
              subjectName: sub?.subject_name,
              subject: sub?._id,
              classes: classesArray,
              provision: provisionArray,
            });
          }
        } else {
          setMaster({
            ...master,
            job_id: id,
            inst_name: "",
            inst_address: "",
            subjectName: "",
            subject: "",
            classes: [],
            provision: [],
          });
        }
      });
  };
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "jobpost":
        let data = {
          label: selected?.displayLabel,
          value: selected.value,
        };
        setSelectedJobpost(data);
        setSelectedCandidate(null);
        fetch_job_details(selected.value);
        fetch_candidate(selected.value);
        setErrors({
          ...errors,
          jobpostError: "",
        });
        break;
      case "candidate":
        setSelectedCandidate(selected);
        setMaster({
          ...master,
          candidate_id: selected.value,
        });
        setErrors({
          ...errors,
          provisionError: "",
        });
        break;
      case "class":
        setSelectedClasses(selected);
        let classesArray = [];
        selected &&
          selected.forEach((element) => {
            classesArray.push(element.value);
          });
        setMaster({
          ...master,
          classes: classesArray,
        });
        setErrors({
          ...errors,
          classError: "",
        });
        break;
      case "provision":
        setSelectedProvision(selected);
        let provisionArray = [];
        selected &&
          selected.forEach((element) => {
            provisionArray.push(element.value);
          });
        setMaster({
          ...master,
          provision: provisionArray,
        });
        setErrors({
          ...errors,
          provisionError: "",
        });
        break;
      default:
        break;
    }
  };
  const downloadOfferletter = (item) => {
    saveAs(
      `${API_URL}` + item?.appointmentltr_pdf_link,
      "Indiana Global Teachers.pdf"
    );
  };

  const sendResume = (item) => {
    let data = {
      candidate_id: item?.appointmentltr_cand_id,
      application_id: item?.application_id,
    };
    axios
      .post(`${API_URL}appointmentLetter/send`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("Offer letter Send Successfully");
        } else {
          toastr.error("Failed to send offer letter");
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  // const handleValidSubmit = () => {
  //   if (
  //     selectedJobpost === null ||
  //     selectedCandidate === null ||
  //     selectedProvision === null ||
  //     selectedClasses === null ||
  //     !master?.joining_date
  //   ) {
  //     let errorVal = errors;
  //     if (selectedJobpost === null)
  //       errorVal.jobpostError = "Please select job post";
  //     if (selectedCandidate === null)
  //       errorVal.candidateError = "Please select candidate";
  //     if (selectedProvision === null)
  //       errorVal.provisionError = "Please select provision";
  //     if (selectedClasses === null) errorVal.classError = "Please select class";
  //     if (!master?.joining_date)
  //       errorVal.dateError = "Please select joining date";

  //     setErrors({
  //       ...errorVal,
  //     });
  //   } else {
  //     var object = master;
  //     if (state && typeof state.candId !== "undefined")
  //       object.candidate_id = state.candId;
  //     axios
  //       .post(`${API_URL}offerletter`, object, {
  //         headers: {
  //           "x-access-token": accessToken,
  //         },
  //       })
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           toastr.success("Offer letter generated successfully");
  //           formRef.current.reset();
  //           setMaster({
  //             inst_name: "",
  //             inst_address: "",
  //             joining_date: "",
  //             offerltr_issue_date: getDate(new Date()),
  //           });
  //           setErrors({
  //             dateError: "",
  //             provisionError: "",
  //             classError: "",
  //             candidateError: "",
  //             jobpostError: "",
  //           });
  //           setSelectedCandidate(null);
  //           setSelectedJobpost(null);
  //           setSelectedProvision(null);
  //           setSelectedClasses(null);
  //           setCandidateOptions([]);
  //           handleTableData(searchValue);
  //           if (state) {
  //             history.replace("/offerLetter", null);
  //           }
  //         } else {
  //           toastr.error("Failed to generate offer letter");
  //         }
  //       })
  //       .catch((err) => {
  //         toastr.error(err.response.data.message);
  //         return;
  //       });
  //   }
  // };

  const handleValidSubmit = () => {
    if (
      selectedJobpost === null ||
      selectedCandidate === null ||
      selectedProvision === null ||
      selectedClasses === null ||
      !master?.joining_date
    ) {
      let errorVal = errors;
      if (selectedJobpost === null)
        errorVal.jobpostError = "Please select job post";
      if (selectedCandidate === null)
        errorVal.candidateError = "Please select candidate";
      if (selectedProvision === null)
        errorVal.provisionError = "Please select provision";
      if (selectedClasses === null) errorVal.classError = "Please select class";
      if (!master?.joining_date)
        errorVal.dateError = "Please select joining date";

      setErrors({
        ...errorVal,
      });
    } else {
      var object = master;
      if (state && typeof state.candId !== "undefined")
        object.candidate_id = state.candId;
      axios
        .post(`${API_URL}appointmentLetter`, object, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Offer letter generated successfully");
            formRef.current.reset();
            setMaster({
              inst_name: "",
              inst_address: "",
              joining_date: "",
              offerltr_issue_date: getDate(new Date()),
              crm_request: true,
            });
            setErrors({
              dateError: "",
              provisionError: "",
              classError: "",
              candidateError: "",
              jobpostError: "",
            });
            setSelectedCandidate(null);
            setSelectedJobpost(null);
            setSelectedProvision(null);
            setSelectedClasses(null);
            setCandidateOptions([]);
            handleTableData();
            if (state) {
              history.replace("/offerLetter", null);
            }
          } else {
            toastr.error("Failed to generate offer letter");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const handleTableData = (search = "", joining_date_from = "", joining_date_to = "", create_by = "", start = 0, end = 20) => {
    var url =
      `${API_URL}appointmentLetter/view?`;

    // if (joining_date_from === "" && joining_date_to === "") {
    //   joining_date_from = joining_date_from ? joining_date_from : getFirstday(new Date());
    //   joining_date_to = joining_date_to ? joining_date_to : getDate(new Date());
    // }

    url =
      url +
      "&search=" +
      search +
      "&joining_date_from=" +
      joining_date_from +
      "&joining_date_to=" +
      joining_date_to +
      "&create_by=" +
      create_by +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        setTotalPages(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.appointmentltr_date).format("DD-MM-YYYY");
            item.time = moment(item?.appointmentltr_time, "hhmmss").format(
              "hh:mm a"
            );
            item.doj = moment(item?.joining_date).format("DD-MM-YYYY");
            if (item?.offerltr_inst_name && item?.inst_reg_no)
              item.instName =
                item?.offerltr_inst_name + " " + "(" + item?.inst_reg_no + ")";
            else item.instName = item?.offerltr_inst_name;
            if (item?.job_name && item?.job_reg_no)
              item.jobName =
                item?.job_name + " " + "(" + item?.job_reg_no + ")";
            else item.jobName = item?.job_name;
            item.appointmentltr_inst_name1 = (
              <div>
                <a href={`/institute/${item?.inst_reg_no}`}>{item?.appointmentltr_inst_name}</a>
              </div>
            )
            item.appointmentltr_cand_name1 = (
              <div>
                <a href={`/candidate/${item.cand_reg_no}`}>{item?.appointmentltr_cand_name}</a>
              </div>
            )
            if (item.create_by == 0) {
              item.source = "CRM"
            } else if (item.create_by == 1) {
              item.source = "Web"
            } else if (item.create_by == 2) {
              item.source = "APP"
            }
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Appointment Letter
                    </Tooltip>
                  }
                >
                  <i
                    className="fas fa-file-pdf"
                    style={{
                      fontSize: "1.5em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.5em",
                      //color:"#d12020"
                      color: "red",
                    }}
                    onClick={() => {
                      downloadOfferletter(item);
                    }}
                  ></i>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Sent Offer letter
                    </Tooltip>
                  }
                >
                  <i
                    className="fab fa-whatsapp"
                    style={{
                      fontSize: "1.5em",
                      cursor: "pointer",
                      marginLeft: "0.2em",
                      marginRight: "0.5em",
                      color: "green",
                    }}
                    onClick={() => {
                      sendResume(item);
                    }}
                  ></i>
                </OverlayTrigger>
              </div>
            );
          });
        setDetails(result);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },

      {
        label: "Candidate",
        field: "appointmentltr_cand_name1",
        sort: "desc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "appointmentltr_cand_mob",
        sort: "desc",
        width: 400,
      },
      {
        label: "Institute",
        field: "appointmentltr_inst_name1",
        sort: "desc",
        width: 400,
      },
      {
        label: "Job Name",
        field: "jobName",
        sort: "desc",
        width: 400,
      },
      {
        label: "Joining Date",
        field: "doj",
        sort: "desc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
      {
        label: "Source",
        field: "source",
        width: 300,
      },
    ],
    rows: details,
  };

  let handleSearchDate = (e) => {
    var date1 = $("#joining_date_from").val();
    var date2 = $("#joining_date_to").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["joining_date_to"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

  };


  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Offer Letter" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleValidSubmit();
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Job post</Label>
                          <Select
                            name="jobpost"
                            value={selectedJobpost}
                            onChange={(selected) => {
                              handleSelectChange(selected, "jobpost");
                            }}
                            options={jobpostOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedJobpost === null
                              ? errors.jobpostError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Candidate</Label>
                          <Select
                            name="candidate"
                            value={selectedCandidate}
                            onChange={(selected) => {
                              handleSelectChange(selected, "candidate");
                            }}
                            options={candidateOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedCandidate === null
                              ? errors.candidateError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Institute</Label>
                          <AvField
                            name="inst_name"
                            placeholder="Institute"
                            type="text"
                            errorMessage="Enter Institute"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.inst_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Institution Address
                          </Label>
                          <AvField
                            name="inst_address"
                            placeholder="Address"
                            type="textarea"
                            className="form-control"
                            errorMessage="Enter Address"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={master?.inst_address}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Issued Date
                          </Label>
                          <input
                            className="form-control"
                            type="date"
                            id="offerltr_issue_date"
                            name="offerltr_issue_date"
                            value={master?.offerltr_issue_date}
                            onChange={handleDate}
                            min={getDate(new Date())}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Joining Date
                          </Label>
                          <input
                            className="form-control"
                            type="date"
                            id="joining_date"
                            name="joining_date"
                            value={master?.joining_date}
                            onChange={handleDate}
                            min={getDate(new Date())}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {!master?.joining_date ? errors.dateError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Salary</Label>
                          <AvField
                            name="salary"
                            placeholder="Salary"
                            type="number"
                            className="form-control"
                            id="validationCustom01"
                            value={master?.salary}
                            onChange={handleInputChange}
                            min={0}
                            errorMessage="Enter Salary"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Subject</Label>
                          <AvField
                            name="subjectName"
                            placeholder="Subject"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={master?.subjectName}
                            onChange={handleInputChange}
                            readOnly
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Provision</Label>
                          <Select
                            isMulti
                            name="provision"
                            value={selectedProvision}
                            onChange={(selected) => {
                              handleSelectChange(selected, "provision");
                            }}
                            options={provisionOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedProvision === null
                              ? errors.provisionError
                              : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Class</Label>
                          <Select
                            isMulti
                            name="classes"
                            value={selectedClasses}
                            onChange={(selected) => {
                              handleSelectChange(selected, "class");
                            }}
                            options={classOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedClasses === null ? errors.classError : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Service Charge
                          </Label>
                          <AvField
                            name="service_charge"
                            placeholder="Service Charge"
                            type="number"
                            className="form-control"
                            id="validationCustom01"
                            value={master?.service_charge}
                            onChange={handleInputChange}
                            min={0}
                            errorMessage="Enter Service Charge"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button color="primary" type="submit">
                            {"Submit"}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                  </AvForm>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <Row>

                    <Col md="2">
                      <div className="">
                        <Label>Source</Label>
                        <Select
                          name="create_by"
                          onChange={(selected) => {
                            handleFilter(selected, "create_by")
                          }}
                          value={selectedSource}
                          options={[
                            { label: "CRM", value: "0" },
                            { label: "Web", value: "1" },
                            { label: "APP", value: "2" }
                          ]} />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="">
                        <Label>From Joining Date</Label>
                        <input
                          type="date"
                          name="joining_date_from"
                          onChange={handleSearchDate}
                          className="form-control"
                          value={searchData?.joining_date_from}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="">
                        <Label>To Joining Date</Label>
                        <input
                          type="date"
                          name="joining_date_to"
                          onChange={handleSearchDate}
                          className="form-control"
                          value={searchData?.joining_date_to}
                          min={searchData?.joining_date_from}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div style={{ display: "flex", marginTop: "27px" }}>
                        <Button type="submit" color="primary" onClick={() => handleSearch()}>Search</Button>

                        <Button type="submit" color="danger" onClick={() => reset()} style={{ marginLeft: "23px" }}>Reset</Button>
                      </div>
                    </Col>
                  </Row>

                  <MDBDataTable
                    id="offerLetterTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    paging={false}
                    // onSearch={debounce(handleSearch, 500)}
                    entries={PerPageCount}
                  />
                  {totalPages > 20 && (
                    <div className="pagination-div">
                      <div className="page-details">
                        Showing {((currentPage - 1) * PerPageCount) + 1} to{" "}
                        {Math.min(currentPage * PerPageCount, totalPages)} of{" "}
                        {totalPages} entries
                      </div>
                      <Pagination
                        color="standard"
                        shape="rounded"
                        defaultPage={1}
                        count={Math.ceil(totalPages / PerPageCount)}
                        page={currentPage}
                        onChange={paginate}
                        size="large"
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </>
  );
};

export default OfferLetter;
