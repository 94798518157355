import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import CountUp from "react-countup";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { indexOf } from "lodash";
import { getCurrentYear } from "../../helpers/globalFunctions";

const SalesAnalyticsChart = () => {
  const [sortBy, setSortBy] = useState("Daily");
  const [totalApplications, setTotalApplications] = useState([]);
  const [totalCandidate, setTotalCandidate] = useState([]);
  const [currentYear, setCurrentYear] = useState(getCurrentYear(new Date()));
  const [values, setValues] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    fetch_daily_data();
  }, []);
  const fetch_month_data = () => {
    axios
      .get(`${API_URL}dashboard/graph_monthwise`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data;
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        let monthWise = result?.Monthwise;
        monthWise &&
          monthWise.map((el) => {
            arr1.push(el?.Total_application);
            arr2.push(el?.Total_candidate);
            //let monthName = moment(el?.date, "M").format("MMMM");
            // let data = monthName + " " + currentYear;
            let month = el?.date;

            let daysInMonth = new Date(currentYear, month, 0).getDate();
            let date = month + "/" + daysInMonth + "/" + currentYear;

            arr3.push(date);
          });
        setTotalApplications(arr1);
        setTotalCandidate(arr2);
        setValues(arr3);
      });
  };
  const fetch_yearly_data = () => {
    axios
      .get(`${API_URL}dashboard/graph_yearwise`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data;
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        let yearWise = result?.Yearwise;
        yearWise &&
          yearWise.map((el) => {
            arr1.push(el?.Total_application);
            arr2.push(el?.Total_candidate);

            var d = new Date(currentYear, 12, 0);
            function convert(str) {
              var date = new Date(str),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2);
              return [mnth, day, date.getFullYear()].join("/");
            }
            let year = convert(d);

            arr3.push(year);
          });
        setTotalApplications(arr1);
        setTotalCandidate(arr2);
        setValues(arr3);
      });
  };
  const fetch_daily_data = () => {
    axios
      .get(`${API_URL}dashboard/graph`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data;
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];

        let daywise = result?.Daywise;
        daywise &&
          daywise.map((el) => {
            arr1.push(el?.Total_application);
            arr2.push(el?.Total_candidate);
            arr3.push(moment(el?.date).format("MM/DD/YYYY"));
          });
        setTotalApplications(arr1);
        setTotalCandidate(arr2);
        setValues(arr3);
      });
  };

  const series = [
    {
      name: "Total Applications",
      type: "column",
      data: totalApplications,
    },
    {
      name: "Total Candidates",
      type: "area",
      data: totalCandidate,
    },
  ];
  // const series2 = [
  //   {
  //     name: "Total Applications",
  //     data: totalApplications,
  //   },
  //   {
  //     name: "Total Candidates",
  //     data: totalCandidate,
  //   },
  // ];

  const options1 = {
    chart: {
      stacked: !1,
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [0, 2, 4],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    colors: ["#5b73e8", "#dfe2e6", "#f1b44c"],

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: values,
    markers: {
      size: 0,
    },

    xaxis: {
      type: "datetime",
      labels: {
        format: "dd MMM",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  const options2 = {
    chart: {
      stacked: !1,
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [0, 2, 4],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    colors: ["#5b73e8", "#dfe2e6", "#f1b44c"],

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: values,
    markers: {
      size: 0,
    },

    xaxis: {
      type: "datetime",
      labels: {
        format: "MMM yyyy",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  // const options2 = {
  //   chart: {
  //     toolbar: {
  //       show: !1,
  //     },
  //   },
  //   plotOptions: {
  //     bar: {
  //       horizontal: !1,
  //       columnWidth: '45%',
  //       endingShape: 'rounded'
  //     },
  //   },
  //   dataLabels: {
  //     enabled: !1
  //   },
  //   stroke: {
  //     show: !0,
  //     width: 2,
  //     colors: ['transparent']
  //   },
  //   series: [ {
  //     name: "Total Applications",
  //     data: totalApplications,
  //   },
  //   {
  //     name: "Total Candidates",
  //     data: totalCandidate,
  //   },],
  //   colors: ['#f1b44c', '#5b73e8', '#34c38f'],
  //   xaxis: {
  //     categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
  //   },
  //   yaxis: {
  //     title: {
  //       text: ''
  //     }
  //   },
  //   grid: {
  //     borderColor: '#f1f1f1',
  //   },
  //   fill: {
  //     opacity: 1

  //   },
  //   tooltip: {
  //     y: {
  //       formatter: function (val) {
  //         return val
  //       }
  //     }
  //   }
  // };

  const options3 = {
    chart: {
      stacked: !1,
      toolbar: {
        show: !1,
      },
    },
    stroke: {
      width: [0, 2, 4],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    colors: ["#5b73e8", "#dfe2e6", "#f1b44c"],

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: !1,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: values,
    markers: {
      size: 0,
    },

    xaxis: {
      type: "datetime",
      labels: {
        format: "yyyy",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      shared: !0,
      intersect: !1,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };
  const handleSelectChange = (name) => {
    switch (name) {
      case "daily":
        setSortBy("Daily");
        fetch_daily_data();
        break;
      case "monthly":
        setSortBy("Monthly");
        fetch_month_data();
        break;
      case "yearly":
        setSortBy("Yearly");
        fetch_yearly_data();
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="float-end">
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="text-reset"
                id="dropdownMenuButton5"
                caret
                href="#"
              >
                <span className="fw-semibold">Sort By:</span>{" "}
                <span className="text-muted">
                  {sortBy}
                  {/* <i className="mdi mdi-chevron-down ms-1"></i> */}
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  href="#"
                  onClick={() => handleSelectChange("daily")}
                >
                  Daily
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() => handleSelectChange("monthly")}
                >
                  Monthly
                </DropdownItem>
                <DropdownItem
                  href="#"
                  onClick={() => handleSelectChange("yearly")}
                >
                  Yearly
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <CardTitle className="mb-4 h4">{""}</CardTitle>
          <div className="mt-3">
            {sortBy === "Daily" ? (
              <ReactApexChart
                options={options1}
                series={series}
                height="339"
                type="line"
                className="apex-charts"
              />
            ) : sortBy === "Monthly" ? (
              <ReactApexChart
                options={options2}
                series={series}
                height="339"
                type="line"
                // type="bar"
                // height={350}
                className="apex-charts"
              />
            ) : (
              <ReactApexChart
                options={options3}
                series={series}
                height="339"
                type="line"
                className="apex-charts"
              />
            )}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SalesAnalyticsChart;
