import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { apiError } from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./jobpost.scss";
import Pagination from "@mui/material/Pagination";
import moment from "moment";

const Jobpost = (props) => {
  const { jobpostId } = props.match.params;
  const [jobIdTobeUpdated, setJobIdTobeUpdated] = useState(null);
  const [jobIdToBeDeleted, setJobIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [details, setDetails] = useState([]);
  const [master, setMaster] = useState({ job_type: "0", job_source: 0 });
  const [selectjobtype, setselectjobtype] = useState({
    label: "Teacher Jobs",
    value: "0",
  });

  const [whatsappAlert, setwhatsappAlert] = useState({ show: false });
  const [changeJob, setChangeJob] = useState(null)
  // const [selectedClasses, setSelectedClasses] = useState(null);
  const [selectedSyllabus, setSelectedSyllabus] = useState(null);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [expOptions, setExpOptions] = useState([]);
  // const [selectedDistrict, setSelectedDistrict] = useState(null);
  // const [districtOptions, setDistrictOptions] = useState([]);
  const [provisionOptions, setProvisionOptions] = useState([]);
  const [selectedProvision, setSelectedProvision] = useState(null);
  const [selectedInterviewmode, setSelectedInterviewmode] = useState(null);
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState(null);
  const [classOptions, setClassOptions] = useState([]);
  const [selectedClassesSearch, setSelectedClassesSearch] = useState(null);
  const [selectedStatusSearch, setSelectedStatusSearch] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedSubjectSearch, setSelectedSubjectSearch] = useState(null);
  const [selectedSyllabusSearch, setSelectedSyllabusSearch] = useState(null);
  const [selectedMediumSearch, setSelectedMediumSearch] = useState(null);
  const [selectedQualificationSearch, setSelectedQualificationSearch] =
    useState(null);
  const [selectedModeofClassSearch, setSelectedModeofClassSearch] =
    useState(null);
  const [selectedStateSearch, setSelectedStateSearch] = useState(null);
  const [selectedDistrictSearch, setSelectedDistrictSearch] = useState(null);
  const [districtOptionsSearch, setDistrictOptionsSearch] = useState([]);
  const [instituteOptions, setInstituteOptions] = useState([]);
  const [selectedSourceSearch, setSelectedSourceSearch] = useState(null)
  const [selectedModeofClass, setSelectedModeofClass] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedInstituteSearch, setSelectedInstituteSearch] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [errors, setErrors] = useState({
    subjectError: "",
    instituteError: "",
    classError: "",
    modeError: "",
    syllabusError: "",
    mediumError: "",
    qualificationError: "",
    expError: "",
    genderError: "",
    provisionError: "",
    interviewError: "",
  });
  // const dispatch = useDispatch();
  const formRef = useRef();
  let history = useHistory();
  /** pagination */
  const [searchValue, setSearchValue] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(currentPage * PerPageCount);
  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(indexOfLastTransfer - PerPageCount);

  const paginate = (event, value) => {
    setCurrentPage(value);
    // const indexOfLastTransfer = value * PerPageCount;
    // const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    // setindexOfFirstTransfer(indexOfFirstTransfer);
    // setindexOfLastTransfer(indexOfLastTransfer);
    handleTableData(
      searchValue,
      searchData?.from_date,
      searchData?.to_date,
      searchData?.from_time,
      searchData?.to_time,
      selectedSubjectSearch?.value,
      selectedSyllabusSearch?.value,
      selectedStateSearch?.value,
      selectedDistrictSearch?.value,
      selectedMediumSearch?.value,
      selectedClassesSearch?.value,
      selectedQualificationSearch?.value,
      selectedModeofClassSearch?.value,
      selectedInstituteSearch?.value,
      selectedStatus?.value !== "" ? selectedStatus?.value : "",
      searchData?.verified,
      selectedSourceSearch?.value,
      value,
      PerPageCount
    );
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  }
  const handleSearch = (value) => {
    setSearchValue(value);
    resetPaginate();
    handleTableData(
      value,
      searchData?.from_date,
      searchData?.to_date,
      searchData?.from_time,
      searchData?.to_time,
      selectedSubjectSearch?.value,
      selectedSyllabusSearch?.value,
      selectedStateSearch?.value,
      selectedDistrictSearch?.value,
      selectedMediumSearch?.value,
      selectedClassesSearch?.value,
      selectedQualificationSearch?.value,
      selectedModeofClassSearch?.value,
      selectedInstituteSearch?.value,
      selectedStatus?.value !== "" ? selectedStatus?.value : "",
      searchData?.verified,
      selectedSourceSearch?.value
    );
  };
  useEffect(() => {
    if (details.length === 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData(
        searchValue,
        searchData?.from_date,
        searchData?.to_date,
        searchData?.from_time,
        searchData?.to_time,
        selectedSubjectSearch?.value,
        selectedSyllabusSearch?.value,
        selectedStateSearch?.value,
        selectedDistrictSearch?.value,
        selectedMediumSearch?.value,
        selectedClassesSearch?.value,
        selectedQualificationSearch?.value,
        selectedModeofClassSearch?.value,
        selectedInstituteSearch?.value,
        selectedStatus?.value !== "" ? selectedStatus?.value : "",
        searchData?.verified,
        selectedSourceSearch?.value,
        currentPage,
        PerPageCount
      );
    }
  }, [details]);

  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => func(...args), delay);
    };
  }

  useEffect(() => {
    fetch_subject();
  }, [master]);

  /** end pagination */
  useEffect(() => {
    handleTableData(searchValue);
    fetch_all_qualification();
    fetch_experience();
    // fetch_syllabus();
    fetch_class();
    // fetch_medium();
    fetch_all_institute();
    fetch_all_provisions();
    fetch_all_states();
  }, []);
  useEffect(() => {
    if (jobpostId) {
      let item = {
        _id: jobpostId,
      };
      preUpdateJob(item);
    }
  }, []);
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const updateStatus = (
    selected,
    item,
    search,
    subject,
    syllabus,
    state,
    district,
    medium,
    classes,
    qualification,
    mode_of_class,
    institute,
    status,
    start,
    end
  ) => {
    let data = {
      status: selected.value,
    };
    axios
      .put(`${API_URL}job/status?job_id=${item._id}`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          // toastr.success("Status updated successfully");
          handleTableData(
            search,
            subject,
            syllabus,
            state,
            district,
            medium,
            classes,
            qualification,
            mode_of_class,
            institute,
            status,
            start,
            end
          );
        } else {
          toastr.error(res.data.message);
          return;
        }
      });
  };
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };
  function handleTableData(
    search = "",
    from_date = "",
    to_date = "",
    from_time = "",
    to_time = "",
    subject = "",
    syllabus = "",
    state = "",
    district = "",
    medium = "",
    classes = "",
    qualification = "",
    mode_of_class = "",
    institute = "",
    status = "",
    verified = "",
    source = "",
    start = 1,
    end = 20
  ) {
    var url = `${API_URL}job/list`;

    url =
      url +
      "?from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&from_time=" +
      from_time +
      "&to_time=" +
      to_time +
      "&subject=" +
      subject +
      "&syllabus=" +
      syllabus +
      "&state=" +
      state +
      "&district=" +
      district +
      "&medium=" +
      medium +
      "&classes=" +
      classes +
      "&qualification=" +
      qualification +
      "&mode_of_class=" +
      mode_of_class +
      "&institute=" +
      institute +
      "&status=" +
      status +
      "&start=" +
      start +
      "&end=" +
      end +
      "&search=" +
      search +
      "&verified=" +
      verified +
      "&source=" +
      source
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        setTotalPages(res.data.limit);
        let id = (start - 1) * end;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            if (item?.mode_of_class === 0) item.modeofClass = "Online";
            else if (item?.mode_of_class === 1) item.modeofClass = "Offline";
            else if (item?.mode_of_class === 2) item.modeofClass = "Part Time";
            else item.modeofClass = "";
            item.qualificationName = "";
            item.qualification &&
              item.qualification.map((el) => {
                if (item.qualificationName === "")
                  item.qualificationName = el?.qualification_name;
                else
                  item.qualificationName =
                    item?.qualificationName + "," + el?.qualification_name;
              });
            let selectedJobStatus = {};
            if (item.job_current_status === 0) {
              selectedJobStatus = {
                label: "Ongoing",
                value: 0,
              };
            } else if (item.job_current_status === 1) {
              selectedJobStatus = {
                label: "Expired",
                value: 1,
              };
            } else if (item.job_current_status === 2) {
              selectedJobStatus = { label: "Filled", value: 2 };
            } else {
              selectedJobStatus = {
                label: "Inactive",
                value: 3,
              };
            }
            item.date1 = moment(item.date).format("DD-MM-YYYY")
            item.job_institute_name1 = (
              <div>
                <a
                  target="blank"
                  style={{ cursor: "pointer" }}
                  href={"/institute/" + item?.institute_regno}
                >
                  {item?.institute}
                </a>
              </div>
            );
            item.status = (
              <div className="mb-3">
                <Select
                  styles={style}
                  name="job_status"
                  value={selectedJobStatus}
                  onChange={(selected) => {
                    updateStatus(
                      selected,
                      item,
                      search,
                      subject,
                      syllabus,
                      state,
                      district,
                      medium,
                      classes,
                      qualification,
                      mode_of_class,
                      institute,
                      status,
                      verified,
                      start,
                      end
                    );
                    handleSelectChange(selected, "job_status")
                  }}
                  options={[
                    {
                      label: "Ongoing",
                      value: 0,
                    },
                    {
                      label: "Expired",
                      value: 1,
                    },
                    {
                      label: "Filled",
                      value: 2,
                    },
                    {
                      label: "Inactive",
                      value: 3,
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </div>
            );


            if (item.job_source == 0) {
              item.job_source1 = "CRM"
            }
            if (item.job_source == 1) {
              item.job_source1 = "Web"
            }
            if (item.job_source == 2) {
              item.job_source1 = "APP"
            }
            // item.date1 = moment(item?.job_date).format("DD-MM-YYYY")
            item.time1 = moment(item?.time, "HH:mm s").format("hh:mm a")
            if (item.verified == 0) {
              item.verify = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <input
                    onClick={() => {
                      setwhatsappAlert({
                        show: true,
                        verified: item.verified,
                        id: item._id,
                      });
                    }}
                    type="checkbox"
                    checked={item.verified == 1}
                  />
                </div>
              );
            }

            if (item.verified == 1) {
              item.verify = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <input
                    onClick={() => {
                      // setsubmitObj({num:item.verified,id:item._id})
                      handleVrifyJob(item.verified, item._id, alertWh);
                    }}
                    type="checkbox"
                    checked={item.verified == 1}
                  />
                </div>
              );
            }
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    window.open("/jobpost/" + item?.job_reg_no);
                    // history.push("/jobpost/" + item?.job_reg_no);
                  }}
                ></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateJob(item);
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setJobIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });

        setDetails(result);
      });
  }
  const fetch_all_institute = () => {
    axios
      .get(`${API_URL}institute/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let institute_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.inst_reg_no + "-" + el?.inst_name,
              value: el?._id,
              whatsapp: el?.inst_whatsapp_no,
              displayLabel: el?.inst_name,
            };
          });
        setInstituteOptions([
          {
            options: institute_data,
          },
        ]);
      });
  };
  const fetch_all_provisions = () => {
    axios
      .get(`${API_URL}provisions/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let provision_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.provision_name,
              value: el?._id,
            };
          });
        setProvisionOptions([
          {
            options: provision_data,
          },
        ]);
      });
  };
  const fetch_all_qualification = () => {
    axios
      .get(`${API_URL}qualification/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let qualification_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.qualification_name,
              value: el?._id,
            };
          });
        setQualificationOptions([
          {
            options: qualification_data,
          },
        ]);
      });
  };
  const fetch_experience = () => {
    axios
      .get(`${API_URL}manage/experience`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let exp_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setExpOptions([
          {
            options: exp_data,
          },
        ]);
      });
  };
  function fetch_all_states() {
    axios
      .get(`${API_URL}states/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var state_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el.id,
            };
          });

        setStateOptions([
          {
            options: state_data,
          },
        ]);
      });
  }
  const fetch_subject = () => {
    axios
      .get(`${API_URL}subject/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let sub_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.subject_name,
              value: el?._id,
            };
          });
        setSubjectOptions([
          {
            options: sub_data,
          },
        ]);
      });
  };

  // verify jobs in crm
  function handleVrifyJob(num, id, alertWh) {
    let verify_num = num;
    if (num == 0) {
      axios
        .put(
          `${API_URL}profile/verified/?JobId=${id}&alert=${alertWh}`,
          mainObj,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.success == true) {
            toastr.success("Job post verified");
            handleTableData();
            setwhatsappAlert({ show: false });
          } else {
            toastr.error("Failed to verify");
          }
        })
        .catch((err) => {
        });
    } else if (num == 1) {
      axios
        .put(
          `${API_URL}profile/verified/?JobId=${id}`,
          { verified: 0 },
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.success == true) {
            handleTableData();
            toastr.success("Job post unverified");
            setwhatsappAlert({ show: false });
          } else {
            toastr.error("failed to verify");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const fetch_class = () => {
    axios
      .get(`${API_URL}manage/classes`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let class_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setClassOptions([
          {
            options: class_data,
          },
        ]);
      });
  };

  let preUpdateJob = (item) => {
    setJobIdTobeUpdated(item._id);
    if (jobpostId) {
      axios
        .get(`${API_URL}job/?job_reg=` + item._id, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          let result = res.data.data;
          result.job_id = result?._id;
          let inst = {
            label: result?.job_institute_name,
            value: result?.job_institute_id,
          };
          setSelectedInstitute(inst);
          fetch_inst_details(result?.job_institute_id);
          let subData = result.job_subject || {};
          let sub = {
            label: subData?.subject_name,
            value: subData?._id,
          };
          setSelectedSubject(sub);

          let classArr = [];
          result.job_classes &&
            result.job_classes.forEach((element) => {
              let arrClass = {
                label: element.name,
                value: element._id,
              };
              classArr.push(arrClass);
            });
          setSelectedClasses(classArr);

          let jobtype = {};
          if (result?.job_type == 0) {
            jobtype = {
              label: "Teacher Jobs",
              value: 0,
            };
          } else if (result?.job_type == 1) {
            jobtype = {
              label: "Department Jobs",
              value: 1,
            };
          } else if (result?.job_type == 2) {
            jobtype = {
              label: "Other Jobs",
              value: 2,
            };
          }
          setselectjobtype(jobtype);

          let modeofclass = {};
          if (result?.mode_of_class === 1) {
            modeofclass = {
              label: "Offline",
              value: 1,
            };
          } else if (result?.mode_of_class === 0) {
            modeofclass = {
              label: "Online",
              value: 0,
            };
          } else if (result?.mode_of_class === 2) {
            modeofclass = {
              label: "Part Time",
              value: 0,
            };
          } else {
            modeofclass = null;
          }
          setSelectedModeofClass(modeofclass);

          let syllabus = null;
          if (result?.job_syllabus) {
            let syllabusData = result.job_syllabus;
            syllabus = {
              label: syllabusData?.name,
              value: syllabusData?._id,
            };
          }
          setSelectedSyllabus(syllabus);

          let medium = null;
          if (result?.job_medium) {
            let mediumData = result.job_medium;
            medium = {
              label: mediumData?.name,
              value: mediumData?._id,
            };
          }
          setSelectedMedium(medium);

          let qualificationArr = [];
          result.job_qualification &&
            result.job_qualification.forEach((element) => {
              let arrQualification = {
                label: element.qualification_name,
                value: element._id,
              };
              qualificationArr.push(arrQualification);
            });
          setSelectedQualification(qualificationArr);

          let exp = null;
          if (result?.job_experience) {
            let expData = result.job_experience;
            exp = {
              label: expData?.name,
              value: expData?._id,
            };
          }
          setSelectedExperience(exp);
          let gender = {};
          if (result?.job_gender === 0) {
            gender = {
              label: "Male",
              value: 0,
            };
          } else if (result?.job_gender === 1) {
            gender = {
              label: "Female",
              value: 1,
            };
          } else if (result?.job_gender === 2) {
            gender = {
              label: "Transgender",
              value: 2,
            };
          } else if (result?.job_gender === 3) {
            gender = {
              label: "Anyone",
              value: 3,
            };
          } else {
            gender = null;
          }
          setSelectedGender(gender);

          let provisionArr = [];
          result.job_provision &&
            result.job_provision.forEach((element) => {
              let arrProvision = {
                label: element.provision_name,
                value: element._id,
              };
              provisionArr.push(arrProvision);
            });
          setSelectedProvision(provisionArr);

          let interviewMode = {};

          if (result?.mode_of_interview === 0) {
            interviewMode = {
              label: "Online",
              value: 0,
            };
          } else if (result?.mode_of_interview === 1) {
            interviewMode = {
              label: "Face To Face",
              value: 1,
            };
          } else if (result?.mode_of_interview === 2) {
            interviewMode = {
              label: "Telephonic",
              value: 2,
            };
          } else {
            interviewMode = null;
          }
          setSelectedInterviewmode(interviewMode);
          setMaster(result);
        });
    } else {
      axios
        .get(`${API_URL}job/?job_id=` + item._id, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          console.log(res, "res");
          setChangeJob(false);
          let result = res.data.data;
          result.job_id = result?._id;
          // let instData = result.job_institute_id || {};
          // let inst = {
          //   label: instData?.inst_reg_no+"-"+instData?.inst_name,
          //   value: instData?._id,
          // };
          let inst = {
            label: result?.job_institute_id?.inst_name,
            value: result?.job_institute_id?._id,
            whatsapp_no: result?.job_institute_id?.inst_whatsapp_no
          };
          setSelectedInstitute(inst);
          fetch_inst_details(result?.job_institute_id);

          let subData = result.job_subject || {};
          let sub = {
            label: subData?.subject_name,
            value: subData?._id,
          };
          setSelectedSubject(sub);

          let classArr = [];
          result.job_classes &&
            result.job_classes.forEach((element) => {
              let arrClass = {
                label: element.name,
                value: element._id,
              };
              classArr.push(arrClass);
            });
          setSelectedClasses(classArr);

          let jobtype = {};
          if (result?.job_type == 0) {
            jobtype = {
              label: "Teacher Jobs",
              value: 0,
            };
          } else if (result?.job_type == 1) {
            jobtype = {
              label: "Mangement Jobs",
              value: 1,
            };
          } else if (result?.job_type == 2) {
            jobtype = {
              label: "Other Jobs",
              value: 2,
            };
          }
          setselectjobtype(jobtype);

          let modeofclass = {};
          if (result?.mode_of_class === 1) {
            modeofclass = {
              label: "Offline",
              value: 1,
            };
          } else if (result?.mode_of_class === 0) {
            modeofclass = {
              label: "Online",
              value: 0,
            };
          } else if (result?.mode_of_class === 2) {
            modeofclass = {
              label: "Part Time",
              value: 0,
            };
          } else {
            modeofclass = null;
          }
          setSelectedModeofClass(modeofclass);

          let syllabus = null;
          if (result?.job_syllabus) {
            let syllabusData = result.job_syllabus;
            syllabus = {
              label: syllabusData?.name,
              value: syllabusData?._id,
            };
          }
          setSelectedSyllabus(syllabus);

          let medium = null;
          if (result?.job_medium) {
            let mediumData = result.job_medium;
            medium = {
              label: mediumData?.name,
              value: mediumData?._id,
            };
          }
          setSelectedMedium(medium);

          let qualificationArr = [];
          result.job_qualification &&
            result.job_qualification.forEach((element) => {
              let arrQualification = {
                label: element.qualification_name,
                value: element._id,
              };
              qualificationArr.push(arrQualification);
            });
          setSelectedQualification(qualificationArr);

          let exp = null;
          if (result?.job_experience) {
            let expData = result.job_experience;
            exp = {
              label: expData?.name,
              value: expData?._id,
            };
          }
          setSelectedExperience(exp);
          let gender = {};
          if (result?.job_gender === 0) {
            gender = {
              label: "Male",
              value: 0,
            };
          } else if (result?.job_gender === 1) {
            gender = {
              label: "Female",
              value: 1,
            };
          } else if (result?.job_gender === 2) {
            gender = {
              label: "Transgender",
              value: 2,
            };
          } else if (result?.job_gender === 3) {
            gender = {
              label: "Anyone",
              value: 3,
            };
          } else {
            gender = null;
          }
          setSelectedGender(gender);

          let provisionArr = [];
          result.job_provision &&
            result.job_provision.forEach((element) => {
              let arrProvision = {
                label: element.provision_name,
                value: element._id,
              };
              provisionArr.push(arrProvision);
            });
          setSelectedProvision(provisionArr);

          let interviewMode = {};

          if (result?.mode_of_interview === 0) {
            interviewMode = {
              label: "Online",
              value: 0,
            };
          } else if (result?.mode_of_interview === 1) {
            interviewMode = {
              label: "Face To Face",
              value: 1,
            };
          } else if (result?.mode_of_interview === 2) {
            interviewMode = {
              label: "Telephonic",
              value: 2,
            };
          } else {
            interviewMode = null;
          }
          setSelectedInterviewmode(interviewMode);
          // setMaster(null);

          setMaster(result);
        });
    }
  };

  useEffect(() => {
    jobName();
  }, [selectedInstitute, selectedSubject, selectjobtype]);

  function jobName() {
    if (changeJob && selectedInstitute && selectedSubject && selectjobtype) {
      fetchjobname();
    }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time1",
        sort: "asc",
        width: 150,
      },
      {
        label: "ID",
        field: "job_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Job Name",
        field: "job_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Institute",
        field: "job_institute_name1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Subject/Department",
        field: "subjectName",
        sort: "asc",
        width: 200,
      },

      {
        label: "Medium",
        field: "mediumName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Mode",
        field: "modeofClass",
        sort: "asc",
        width: 200,
      },

      // {
      //   label: "Source",
      //   field: "job_source1",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Verify",
        field: "verify",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };

  const handleInputChange = (e) => {
    if (e.target.name == "job_name") {
      let permalink = e.target.value.replace(/\s+/g, "-").toLowerCase();
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
        permalink,
      });
    } else {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
      });
    }
  };
  const fetchjobname = () => {
    let data = {
      job_institute_id: selectedInstitute?.value,
      job_subject: selectedSubject?.value,
      job_type: selectjobtype?.value.toString(),
    };
    axios
      .post(`${API_URL}job/name`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          let permalink = res?.data?.permalink
            ?.replace(/\s+/g, "-")
            .toLowerCase()
            .replace(/-+/g, "-");
          setMaster({
            ...master,
            job_name: res.data.name,
            permalink: permalink,
          });
        }
      })
      .catch((err) => {
        // setMaster({
        //   ...master,
        //   job_institute_id: selectedInstitute?.value,
        //   job_subject: selectedSubject?.value,
        //   whatsapp_no: selectedInstitute?.whatsapp,
        // });
      });
  };

  const [isChecked, setIsChecked] = useState(false);
  const [isCheckeddist, setIsCheckeddist] = useState(false);
  const [isCheckedGen, setIsCheckedGen] = useState(false);
  const [alertWh, setalertWh] = useState(false);

  const [selectWhState, setselectWhState] = useState(null);
  const [selectWhDist, setselectWhDist] = useState(null);
  const [selectWhGen, setselectWhgen] = useState(null);

  const [alertStats, setalertStats] = useState([]);
  const [alertDistrict, setalertDistrict] = useState();
  const [alertDist, setalertDist] = useState([]);
  const [alertGender, setalertGender] = useState([]);
  const [mainObj, setmainObj] = useState({
    verified: 1,
    state: [],
    district: [],
    gender: [],
  });
  const [submitObj, setsubmitObj] = useState({});


  useEffect(() => {
    if (
      isChecked == false &&
      isCheckedGen == false &&
      isCheckeddist == false &&
      mainObj.state.length == 0 &&
      mainObj.district.length == 0 &&
      mainObj.gender.length == 0
    ) {
      setalertWh(false);
    } else {
      setalertWh(true);
    }
  }, [isChecked, isCheckedGen, isCheckeddist, mainObj]);

  const fetchAllAlertState = (id) => {
    axios
      .get(`${API_URL}districts/list?state_id=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let dist =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.district_name,
              value: el?._id,
            };
          });
        setalertDistrict(dist);
      });
  };

  const fetchAllAlertStateAll = () => {
    axios
      .get(`${API_URL}districts/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let dist =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.district_name,
              value: el?._id,
            };
          });
        setalertDistrict(dist);
      });
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "classes":
        setSelectedClasses(selected);
        let classesArray = [];
        selected &&
          selected.forEach((element) => {
            classesArray.push(element.value);
          });
        setMaster({
          ...master,
          job_classes: classesArray,
        });
        setErrors({
          ...errors,
          classError: "",
        });
        break;

      case "syllabus":
        setSelectedSyllabus(selected);
        setMaster({
          ...master,
          job_syllabus: selected.value,
        });
        setErrors({
          ...errors,
          syllabusError: "",
        });
        break;

      case "medium":
        setSelectedMedium(selected);
        setMaster({
          ...master,
          ["job_medium"]: selected.value,
        });
        setErrors({
          ...errors,
          mediumError: "",
        });
        break;

      case "experience":
        setSelectedExperience(selected);
        setMaster({
          ...master,
          ["job_experience"]: selected.value,
        });
        setErrors({
          ...errors,
          expError: "",
        });
        break;
      case "institute":
        setChangeJob(true);
        let data = {
          label: selected?.displayLabel,
          value: selected?.value,
          whatsapp: selected?.whatsapp,
        };
        setSelectedInstitute(data);
        setSelectedMedium(null);
        setSelectedSyllabus(null);
        setMaster({
          ...master,
          ["job_institute_id"]: selected.value,
          ["whatsapp_no"]: selected.whatsapp,
        });
        let subject = master?.job_subject ? master.job_subject : "";
        fetch_inst_details(selected.value);
        setErrors({
          ...errors,
          instituteError: "",
        });
        break;
      case "interviewmode":
        setSelectedInterviewmode(selected);
        setMaster({
          ...master,
          ["mode_of_interview"]: selected.value,
        });
        setErrors({
          ...errors,
          interviewError: "",
        });
        break;
      case "modeofclass":
        setSelectedModeofClass(selected);
        setMaster({
          ...master,
          mode_of_class: selected.value,
        });
        setErrors({
          ...errors,
          modeError: "",
        });
        break;
      case "subject":
        setChangeJob(true);

        setSelectedSubject(selected);
        setMaster({
          ...master,
          job_subject: selected.value,
        });

        let selectedInst = selectedInstitute;
        setErrors({
          ...errors,
          subjectError: "",
        });
        break;
      case "qualification":
        setSelectedQualification(selected);
        let qualificationArray = [];
        selected &&
          selected.forEach((element) => {
            qualificationArray.push(element.value);
          });
        setMaster({
          ...master,
          job_qualification: qualificationArray,
        });
        setErrors({
          ...errors,
          qualificationError: "",
        });
        break;
      case "gender":
        setSelectedGender(selected);
        setMaster({
          ...master,
          job_gender: selected.value,
        });
        setErrors({
          ...errors,
          genderError: "",
        });
        break;
      case "provision":
        setSelectedProvision(selected);
        let provisionArray = [];
        selected &&
          selected.forEach((element) => {
            provisionArray.push(element.value);
          });
        setMaster({
          ...master,
          job_provision: provisionArray,
        });
        setErrors({
          ...errors,
          provisionError: "",
        });
        break;
      case "job_type":
        setChangeJob(true);
        setselectjobtype(selected);
        setMaster({ ...master, [name]: selected.value });
        break;
      case "whatsState":
        setselectWhState(selected);
        let array = [];
        selected &&
          selected.map((element) => {
            array.push(element.value);
          });
        setmainObj({ ...mainObj, state: array });
        setalertStats(array);
        fetchAllAlertState(array.join());
        break;
      case "whatsDist":
        setselectWhDist(selected);
        let distArray = [];
        selected &&
          selected.map((ele) => {
            distArray.push(ele.value);
          });
        setmainObj({ ...mainObj, district: distArray });
        setalertDist(distArray);
        break;
      case "job_status":
        setSelectedStatus(selected)
        setMaster({ ...master, [name]: selected.value })
        break;
      case "whatsGender":
        setselectWhgen(selected);
        let genderArray = [];
        selected &&
          selected.map((ele) => {
            genderArray.push(ele.value);
          });
        setmainObj({ ...mainObj, gender: genderArray });
        setalertGender(genderArray);
      default:
        break;
    }
  };

  function handleClickCheckboxstate() {
    setIsChecked(!isChecked);
    setselectWhState(null);
    setselectWhDist(null);
    setmainObj({ ...mainObj, state: [], district: [] });
    fetchAllAlertStateAll();
  }

  function handleClickCheckboxdist() {
    setIsCheckeddist(!isCheckeddist);
    setselectWhDist(null);
    setmainObj({ ...mainObj, district: [] });
  }

  function handleClickCheckboxGen() {
    setIsCheckedGen(!isCheckedGen);
    setselectWhgen(null);
    setmainObj({ ...mainObj, gender: [] });
  }

  const fetch_inst_details = (id) => {
    axios
      .get(`${API_URL}institute/details?institute_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let medium_data =
          result.inst_medium &&
          result.inst_medium.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setMediumOptions([
          {
            options: medium_data,
          },
        ]);
        let syllabus_data =
          result.inst_syllabus &&
          result.inst_syllabus.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setSyllabusOptions([
          {
            options: syllabus_data,
          },
        ]);
      });
  };
  const handleSearchSelectChange = (selected, name) => {
    let from_date = searchData?.from_date ? searchData?.from_date : "";
    let to_date = searchData?.to_date ? searchData?.to_date : "";
    let from_time = searchData?.from_time ? searchData?.from_time : "";
    let to_time = searchData?.to_time ? searchData?.to_time : "";
    let subject = searchData?.subject ? searchData.subject : "";
    let syllabus = searchData?.syllabus ? searchData.syllabus : "";
    let state = searchData?.state ? searchData.state : "";
    let district = searchData?.district ? searchData.district : "";
    let medium = searchData?.medium ? searchData.medium : "";
    let classes = searchData?.classes ? searchData.classes : "";
    let qualification = searchData?.qualification
      ? searchData.qualification
      : "";
    let mode_of_class = searchData?.mode_of_class
      ? searchData.mode_of_class
      : "";
    let institute = searchData?.institute ? searchData.institute : "";
    let source = searchData?.source ? searchData.source : "";
    let status = searchData?.status !== "" ? searchData.status : "";
    resetPaginate();
    switch (name) {
      case "from_date":
        setSearchData({
          ...searchData,
          from_date: selected.target.value,
        });
        handleTableData(
          searchValue,
          selected.target.value,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          district,
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "to_date":
        setSearchData({
          ...searchData,
          to_date: selected.target.value,
        });
        handleTableData(
          searchValue,
          from_date,
          selected.target.value,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          district,
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "from_time":
        setSearchData({
          ...searchData,
          from_time: selected.target.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          selected.value,
          to_time,
          subject,
          syllabus,
          state,
          district,
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "to_time":
        setSearchData({
          ...searchData,
          to_time: selected.target.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          selected.target.value,
          subject,
          syllabus,
          state,
          district,
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "subject":
        setSelectedSubjectSearch(selected);
        setSearchData({
          ...searchData,
          subject: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          selected.value,
          syllabus,
          state,
          district,
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "institute":
        setSelectedInstituteSearch(selected);
        setSearchData({
          ...searchData,
          institute: selected.value,
        });
        fetch_inst_details(selected.value)
        setSelectedMediumSearch(null);
        setSelectedSyllabusSearch(null);
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          district,
          medium,
          classes,
          qualification,
          mode_of_class,
          selected.value,
          status,
          searchData.verified,
          source
        );
        break;
      case "class":
        setSelectedClassesSearch(selected);
        setSearchData({
          ...searchData,
          classes: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          district,
          medium,
          selected.value,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "syllabus":
        setSelectedSyllabusSearch(selected);
        setSearchData({
          ...searchData,
          syllabus: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          selected.value,
          state,
          district,
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source

        );
        break;
      case "medium":
        setSelectedMediumSearch(selected);
        setSearchData({
          ...searchData,
          medium: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          district,
          selected.value,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "qualification":
        setSelectedQualificationSearch(selected);
        setSearchData({
          ...searchData,
          qualification: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          district,
          medium,
          classes,
          selected.value,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "modeofclass":
        setSelectedModeofClassSearch(selected);
        setSearchData({
          ...searchData,
          mode_of_class: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          district,
          medium,
          classes,
          qualification,
          selected.value,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "state":
        setSelectedStateSearch(selected);
        setSelectedDistrictSearch(null);
        setSearchData({
          ...searchData,
          state: selected.value,
          district: "",
        });
        fetch_all_districtSearch(selected.value);
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          selected.value,
          "",
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "status":
        setSelectedStatus(selected);
        setSearchData({
          ...searchData,
          status: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          district,
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          selected.value,
          searchData.verified,
          source
        );
        break;
      case "district":
        setSelectedDistrictSearch(selected);
        setSearchData({
          ...searchData,
          district: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          selected.value,
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData.verified,
          source
        );
        break;
      case "verified":
        setSelectedStatusSearch(selected)
        setSearchData({
          ...searchData,
          verified: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          district,
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          selected.value,
          source
        );
        break;
      case "source":
        setSelectedSourceSearch(selected)
        setSearchData({
          ...searchData,
          source: selected.value,
        });
        handleTableData(
          searchValue,
          from_date,
          to_date,
          from_time,
          to_time,
          subject,
          syllabus,
          state,
          district,
          medium,
          classes,
          qualification,
          mode_of_class,
          institute,
          status,
          searchData?.verified,
          selected.value
        );
        break;

      default:
        break;
    }
  };
  function fetch_all_districtSearch(id) {
    axios
      .get(`${API_URL}districts/list?state_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var district_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.district_name,
              value: el._id,
            };
          });

        setDistrictOptionsSearch([
          {
            options: district_data,
          },
        ]);
      });
  }
  const handleValidSubmit = (event, values) => {
    if (
      selectedSubject === null ||
      selectedInstitute === null ||
      // selectedClasses === null ||
      selectedModeofClass === null ||
      selectedSyllabus === null ||
      selectedMedium === null ||
      selectedQualification === null ||
      // selectedExperience === null ||
      selectedGender === null ||
      selectedProvision === null ||
      selectedInterviewmode === null
    ) {
      let errorVal = errors;
      if (selectedSubject === null)
        errorVal.subjectError = "Please select subject";

      if (selectedInstitute === null)
        errorVal.instituteError = "Please select institute";

      // if (selectedClasses === null) errorVal.classError = "Please select class";

      if (selectedModeofClass === null)
        errorVal.modeError = "Please select mode of class";
      if (selectedSyllabus === null)
        errorVal.syllabusError = "Please select syllabus";
      if (selectedMedium === null)
        errorVal.mediumError = "Please select medium";
      if (selectedQualification === null)
        errorVal.qualificationError = "Please select qualification";
      if (selectedExperience === null)
        errorVal.expError = "Please select experience";
      if (selectedGender === null)
        errorVal.genderError = "Please select gender";
      if (selectedProvision === null)
        errorVal.provisionError = "Please select provision";
      if (selectedInterviewmode === null)
        errorVal.interviewError = "Please select interview mode";
      setErrors({
        ...errorVal,
      });
    } else {
      if (jobIdTobeUpdated) {
        axios
          .put(`${API_URL}job`, master, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Job post updated successfully");
              formRef.current.reset();
              setSelectedInterviewmode(null);
              setSelectedInstitute(null);
              setSelectedClasses(null);
              setSelectedSyllabus(null);
              setSelectedQualification(null);
              setSelectedGender(null);
              setSelectedProvision(null);
              setSelectedMedium(null);
              setSelectedModeofClass(null);
              setSelectedExperience(null);
              setSelectedSubject(null);
              // handleTableData(
              //   searchValue,
              //   selectedSubjectSearch?.value,
              //   selectedSyllabusSearch?.value,
              //   selectedStateSearch?.value,
              //   selectedDistrictSearch?.value,
              //   selectedMediumSearch?.value,
              //   selectedClassesSearch?.value,
              //   selectedQualificationSearch?.value,
              //   selectedModeofClassSearch?.value,
              //   selectedInstituteSearch?.value,
              //   selectedStatus?.value !== "" ? selectedStatus?.value : "",
              //   indexOfFirstTransfer,
              //   indexOfLastTransfer
              // );
              handleTableData()
              setMaster({ job_type: "0", job_source: 0 });
              setErrors({
                subjectError: "",
                instituteError: "",
                classError: "",
                modeError: "",
                syllabusError: "",
                mediumError: "",
                qualificationError: "",
                expError: "",
                genderError: "",
                provisionError: "",
                interviewError: "",
              });
              setMediumOptions([]);
              setSyllabusOptions([]);
              setJobIdTobeUpdated(null);
              if (jobpostId) {
                history.push("/jobpost");
              }
            } else {
              toastr.error(res.data.message, "Failed to update job post");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}job`, master, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Job post created successfully");
              formRef.current.reset();
              setSelectedInterviewmode(null);
              setSelectedInstitute(null);
              setSelectedClasses(null);
              setSelectedSyllabus(null);
              setSelectedQualification(null);
              setSelectedGender(null);
              setSelectedProvision(null);
              setSelectedMedium(null);
              setSelectedModeofClass(null);
              setSelectedExperience(null);
              setSelectedSubject(null);
              handleTableData()
              // handleTableData(
              //   searchValue,
              //   selectedSubjectSearch?.value,
              //   selectedSyllabusSearch?.value,
              //   selectedStateSearch?.value,
              //   selectedDistrictSearch?.value,
              //   selectedMediumSearch?.value,
              //   selectedClassesSearch?.value,
              //   selectedQualificationSearch?.value,
              //   selectedModeofClassSearch?.value,
              //   selectedInstituteSearch?.value,
              //   selectedStatus?.value !== "" ? selectedStatus?.value : "",
              //   indexOfFirstTransfer,
              //   indexOfLastTransfer
              // );
              setMediumOptions([]);
              setSyllabusOptions([]);
              setMaster({ job_type: "0", job_source: 0 });
              setErrors({
                subjectError: "",
                instituteError: "",
                classError: "",
                modeError: "",
                syllabusError: "",
                mediumError: "",
                qualificationError: "",
                expError: "",
                genderError: "",
                provisionError: "",
                interviewError: "",
              });
            } else {
              toastr.error(res.data.message, "Failed to create job post");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };
  const reset = () => {
    setSearchData({});
    resetPaginate();
    handleTableData(searchValue);
    setSelectedInstituteSearch(null);
    setSelectedSubjectSearch(null);
    setSelectedClassesSearch(null);
    setSelectedSyllabusSearch(null);
    setSelectedMediumSearch(null);
    setSelectedQualificationSearch(null);
    setSelectedModeofClassSearch(null);
    setSelectedStateSearch(null);
    setSelectedDistrictSearch(null);
    setSelectedStatusSearch(null)
    setSelectedSourceSearch(null)
    setDistrictOptionsSearch([]);
    setSelectedStatus(null);
  };

  function handleValidSubmitVrify(e, v) {
    handleVrifyJob(whatsappAlert.verified, whatsappAlert.id, alertWh);
  }

  //whats appp message

  return (
    <React.Fragment>
      <Modal
        show={whatsappAlert.show}
        isOpen={whatsappAlert.show}
        size="lg"
        centered={true}
        toggle={() => setwhatsappAlert({ show: false })}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            Do you want to send whatsapp alert ?{" "}
          </h5>
          <div style={{ display: "flex" }}>
            <button
              type="button"
              onClick={() => {
                setwhatsappAlert({ show: false });
              }}
              className="close"
              data-dismiss="modal"
              aria-label="close"
            ></button>
          </div>
        </div>
        <div className="modal-body">
          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => {
              handleValidSubmitVrify(e, v);
            }}
          >
            <Row>
              <p>
                {" "}
                (All fields are optional; if you don't want to send a WhatsApp
                alert, keep it empty.){" "}
              </p>
              <Col md="3">
                <div className="mb-3">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Label>Choose State </Label>
                    <div>
                      <span>All</span>
                      <input
                        onClick={() => {
                          handleClickCheckboxstate();
                        }}
                        className="mx-2"
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <Select
                    isMulti
                    name="state"
                    value={selectWhState}
                    options={stateOptions}
                    onChange={(selected) => {
                      handleSelectChange(selected, "whatsState");
                    }}
                    isDisabled={isChecked}
                  />
                </div>
              </Col>
              <Col md="3">
                <div className="mb-3">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Label>Choose District </Label>
                    <div>
                      <span>All</span>
                      <input
                        onClick={() => {
                          handleClickCheckboxdist();
                        }}
                        className="mx-2"
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <Select
                    isMulti
                    options={alertDistrict}
                    name="District"
                    onChange={(selected) => {
                      handleSelectChange(selected, "whatsDist");
                    }}
                    value={selectWhDist}
                    isDisabled={isCheckeddist}
                  />
                </div>
              </Col>
              <Col md="3">
                <div className="mb-3">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Label>Select Gender </Label>
                    <div>
                      <span>All</span>
                      <input
                        onClick={() => {
                          handleClickCheckboxGen();
                        }}
                        className="mx-2"
                        type="checkbox"
                      />
                    </div>
                  </div>
                  <Select
                    isMulti
                    name="District"
                    options={[
                      {
                        label: "Male",
                        value: 0,
                      },
                      {
                        label: "Female",
                        value: 1,
                      },
                      {
                        label: "Transgender",
                        value: 2,
                      },
                    ]}
                    onChange={(selected) => {
                      handleSelectChange(selected, "whatsGender");
                    }}
                    value={selectWhGen}
                    isDisabled={isCheckedGen}
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="mt-4" style={{ paddingTop: "5px" }}>
                  <Button color="primary" type="submit">
                    verify post
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <p></p>
              </Col>
            </Row>
          </AvForm>
        </div>
      </Modal>

      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}job` + "/" + jobIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Job post deleted successfully");
                  if (master && master?._id === jobIdToBeDeleted) {
                    formRef.current.reset();
                    setSelectedInterviewmode(null);
                    setSelectedInstitute(null);
                    setSelectedClasses(null);
                    setSelectedSyllabus(null);
                    setSelectedQualification(null);
                    setSelectedGender(null);
                    setSelectedProvision(null);
                    setSelectedMedium(null);
                    setSelectedModeofClass(null);
                    setSelectedExperience(null);
                    setSelectedSubject(null);
                    setMaster({ job_type: "0", job_source: 0 });
                    setMediumOptions([]);
                    setSyllabusOptions([]);
                    setJobIdTobeUpdated(null);
                    setErrors({
                      subjectError: "",
                      instituteError: "",
                      classError: "",
                      modeError: "",
                      syllabusError: "",
                      mediumError: "",
                      qualificationError: "",
                      expError: "",
                      genderError: "",
                      provisionError: "",
                      interviewError: "",
                    });
                  }
                  handleTableData(
                    searchValue,
                    selectedSubjectSearch?.value,
                    selectedSyllabusSearch?.value,
                    selectedStateSearch?.value,
                    selectedDistrictSearch?.value,
                    selectedMediumSearch?.value,
                    selectedClassesSearch?.value,
                    selectedQualificationSearch?.value,
                    selectedModeofClassSearch?.value,
                    selectedInstituteSearch?.value,
                    selectedStatus?.value !== "" ? selectedStatus?.value : "",
                    indexOfFirstTransfer,
                    indexOfLastTransfer
                  );
                } else {
                  toastr.error("Failed to delete job post");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Job Post" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label>Customer Type</Label>
                          <Select
                            name="cust_type"
                            value={selectedCustomerType}
                            onChange={(selected) => {
                              handleSelectChange(selected, "customerType");
                            }}
                            options={customerType}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedBillingtype === null
                              ? errors.customertypeError
                              : ""}
                          </p>
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Institute Name</Label>
                          <Select
                            name="job_institute_id"
                            value={selectedInstitute}
                            onChange={(selected) => {
                              handleSelectChange(selected, "institute");
                            }}
                            options={instituteOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedInstitute === null
                              ? errors.instituteError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>job Type</Label>
                          <Select
                            name="job_type"
                            value={selectjobtype}
                            onChange={(selected) => {
                              handleSelectChange(selected, "job_type");
                            }}
                            options={[
                              {
                                label: "Teacher Jobs",
                                value: 0,
                              },
                              {
                                label: "Department Jobs",
                                value: 1,
                              },
                              // {
                              //   label: "Other Jobs",
                              //   value: 2,
                              // },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Whatsapp No.
                          </Label>
                          <AvField
                            name="whatsapp_no"
                            placeholder=" Whatsapp No."
                            type="phone"
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Enter Whatsapp number",
                              },
                              minLength: {
                                value: 10,
                                errorMessage: "Enter valid 10 digit number",
                              },
                              maxLength: {
                                value: 10,
                                errorMessage: "Enter valid 10 digit number",
                              },
                            }}
                            errorMessage="Enter name"
                            id="validationCustom04"
                            onChange={handleInputChange}
                            value={
                              master?.whatsapp_no ? master.whatsapp_no : ""
                            }
                          />
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            name="ward"
                            value={selectedWard}
                            onChange={(selected) => {
                              handleSelectChange(selected, "ward");
                            }}
                            options={[
                              {
                                label: "Kerala",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="district"
                            value={selectedDistrict}
                            onChange={(selected) => {
                              handleSelectChange(selected, "district");
                            }}
                            options={[
                              {
                                label: "Kannur",
                                value: 0,
                              },
                              {
                                label: "Kozhikode",
                                value: 1,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col> */}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            {master?.job_type == 0 ? "Subject" : "Department"}
                          </Label>
                          <Select
                            //isMulti
                            name="job_subject"
                            value={selectedSubject}
                            onChange={(selected) => {
                              handleSelectChange(selected, "subject");
                            }}
                            options={subjectOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedSubject === null
                              ? errors.subjectError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Job Post Name</Label>
                          <AvField
                            name="job_name"
                            placeholder="Job Post Name"
                            type="text"
                            errorMessage="Enter name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom00"
                            value={master?.job_name}
                            onChange={handleInputChange}
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      {master?.job_type == 0 ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Classes</Label>
                            <Select
                              isMulti
                              name="job_classes"
                              value={selectedClasses}
                              onChange={(selected) => {
                                handleSelectChange(selected, "classes");
                              }}
                              options={classOptions}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedClasses === null
                                ? errors.classError
                                : ""}
                            </p>
                          </div>
                        </Col>
                      ) : null}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>
                            {master?.job_type == 0
                              ? "Mode of class"
                              : "Mode of job"}
                          </Label>
                          <Select
                            name="mode_of_class"
                            value={selectedModeofClass}
                            onChange={(selected) => {
                              handleSelectChange(selected, "modeofclass");
                            }}
                            options={[
                              {
                                label: "Online",
                                value: 0,
                              },
                              {
                                label: "Offline",
                                value: 1,
                              },
                              {
                                label: "Part Time",
                                value: 2,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedModeofClass === null
                              ? errors.modeError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Syllabus</Label>
                          <Select
                            name="job_syllabus"
                            value={selectedSyllabus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "syllabus");
                            }}
                            options={syllabusOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedSyllabus === null
                              ? errors.syllabusError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Medium</Label>
                          <Select
                            name="job_medium"
                            value={selectedMedium}
                            onChange={(selected) => {
                              handleSelectChange(selected, "medium");
                            }}
                            options={mediumOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedMedium === null ? errors.mediumError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Qualification</Label>
                          <Select
                            isMulti
                            name="job_qualification"
                            value={selectedQualification}
                            onChange={(selected) => {
                              handleSelectChange(selected, "qualification");
                            }}
                            options={qualificationOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedQualification === null
                              ? errors.qualificationError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Experience</Label>
                          <Select
                            name="job_experience"
                            value={selectedExperience}
                            onChange={(selected) => {
                              handleSelectChange(selected, "experience");
                            }}
                            options={expOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedExperience === null ? errors.expError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Gender</Label>
                          <Select
                            name="job_gender"
                            value={selectedGender}
                            onChange={(selected) => {
                              handleSelectChange(selected, "gender");
                            }}
                            options={[
                              {
                                label: "Male",
                                value: 0,
                              },
                              {
                                label: "Female",
                                value: 1,
                              },
                              {
                                label: "Transgender",
                                value: 2,
                              },
                              {
                                label: "Anyone",
                                value: 3,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedGender === null ? errors.genderError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>No.Of.Openings</Label>
                          <AvField
                            name="no_of_openings"
                            placeholder="No. Of Openings"
                            type="number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter No of Openings"
                            value={master?.no_of_openings}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label>Management</Label>
                          <Select
                            name="job_management"
                            value={selectedManagement}
                            onChange={(selected) => {
                              handleSelectChange(selected, "management");
                            }}
                            options={managementOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col> */}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Provisions</Label>
                          <Select
                            isMulti
                            name="job_provision"
                            value={selectedProvision}
                            onChange={(selected) => {
                              handleSelectChange(selected, "provision");
                            }}
                            options={provisionOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedProvision === null
                              ? errors.provisionError
                              : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Mode Of Interview</Label>
                          <Select
                            name="job_interview"
                            value={selectedInterviewmode}
                            onChange={(selected) => {
                              handleSelectChange(selected, "interviewmode");
                            }}
                            options={[
                              {
                                label: "Online",
                                value: 0,
                              },
                              {
                                label: "Face To Face",
                                value: 1,
                              },
                              {
                                label: "Telephonic",
                                value: 2,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedInterviewmode === null
                              ? errors.interviewError
                              : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Salary Range From </Label>
                          <AvField
                            name="salary_range_from"
                            placeholder="Salary Range From "
                            type="number"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            errorMessage="Enter Salary Range From"
                            value={master?.salary_range_from}
                            onChange={handleInputChange}
                            min={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Salary Range To</Label>
                          <AvField
                            name="salary_range_to"
                            placeholder="Salary Range To"
                            type="number"
                            className="form-control"
                            value={master?.salary_range_to}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Enter Salary Range To",
                              },
                              min: {
                                value: master?.salary_range_from,
                                errorMessage:
                                  "Should be greater than salary range from",
                              },
                            }}
                            errorMessage="Enter Salary Range From"
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Eligibility
                          </Label>
                          <AvField
                            name="job_eligibility"
                            placeholder="Eligibility"
                            type="text"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            // errorMessage="Enter Eligibility"
                            id="validationCustom01"
                            value={master?.job_eligibility}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Job Description
                          </Label>
                          <AvField
                            name="description"
                            placeholder="Job Description"
                            type="textarea"
                            className="form-control"
                            // validate={{ required: { value: true } }}
                            // errorMessage="Enter Eligibility"
                            id="validationCustom01"
                            value={master?.description}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ marginTop: "2.5%" }}>
                        <div className="mb-3">
                          {jobIdTobeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row className="mb-3">
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From Date</Label>
                          <AvField
                            name="from_date"
                            type="date"
                            value={searchData?.from_date}
                            className="form-control"
                            onChange={(e) => handleSearchSelectChange(e, "from_date")}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To Date</Label>
                          <AvField
                            name="to_date"
                            type="date"
                            value={searchData?.to_date}
                            className="form-control"
                            onChange={(e) => handleSearchSelectChange(e, "to_date")}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From Time</Label>
                          <AvField
                            name="from_time"
                            type="time"
                            className="form-control"
                            value={searchData?.from_time}
                            onChange={(e) => handleSearchSelectChange(e, "from_time")}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To Time</Label>
                          <AvField
                            name="to_time"
                            type="time"
                            className="form-control"
                            value={searchData?.to_time}
                            onChange={(e) => handleSearchSelectChange(e, "to_time")}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Institute</Label>
                          <Select
                            name="subject"
                            value={selectedInstituteSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "institute");
                            }}
                            options={instituteOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Subject</Label>
                          <Select
                            name="subject"
                            value={selectedSubjectSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "subject");
                            }}
                            options={subjectOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      {master?.job_type === 0 ? (
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Classes</Label>
                            <Select
                              name="class"
                              value={selectedClassesSearch}
                              onChange={(selected) => {
                                handleSearchSelectChange(selected, "class");
                              }}
                              options={classOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : null}

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Syllabus</Label>
                          <Select
                            name="syllabus"
                            value={selectedSyllabusSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "syllabus");
                            }}
                            options={syllabusOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Medium</Label>
                          <Select
                            name="ward_id"
                            value={selectedMediumSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "medium");
                            }}
                            options={mediumOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Qualification</Label>
                          <Select
                            name="qualification"
                            value={selectedQualificationSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "qualification");
                            }}
                            options={qualificationOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Mode Of Class</Label>
                          <Select
                            name="modeofclass"
                            value={selectedModeofClassSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "modeofclass");
                            }}
                            options={[
                              {
                                label: "Online",
                                value: 0,
                              },
                              {
                                label: "Offline",
                                value: 1,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            name="state"
                            value={selectedStateSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "state");
                            }}
                            options={stateOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="verification_status"
                            value={selectedDistrictSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "district");
                            }}
                            options={districtOptionsSearch}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Source</Label>
                          <Select
                            name="source"
                            value={selectedSourceSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "source");
                            }}
                            options={[
                              {
                                label: "CRM",
                                value: 0,
                              },
                              {
                                label: "Web",
                                value: 1,
                              },
                              {
                                label: "APP",
                                value: 2,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="status"
                            value={selectedStatus}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "status");
                            }}
                            options={[
                              {
                                label: "Ongoing",
                                value: 0,
                              },
                              {
                                label: "Expired",
                                value: 1,
                              },
                              {
                                label: "Filled",
                                value: 2,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Verified/Unverified</Label>
                          <Select
                            name="status"
                            value={selectedStatusSearch}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "verified");
                            }}
                            options={[
                              {
                                label: "Verified",
                                value: 1,
                              },
                              {
                                label: "Unverified",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="1">
                        <div className="mt-4 mb-3" style={{ paddingTop: "5px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>

                  </AvForm>
                  <Row>
                    <Col className="col-12">
                      <MDBDataTable
                        id="TotalTableId"
                        responsive
                        bordered
                        info={false}
                        searching={true}
                        disbaleRetreatAfterSorting={true}
                        paging={false}
                        data={data}
                        // onSearch={(value) => { handleSearch(value) }}
                        entries={PerPageCount}
                      />
                      {totalPages > 20 && (
                        <div className="pagination-div">
                          <div className="page-details">
                            Showing {((currentPage - 1) * PerPageCount) + 1} to{" "}
                            {Math.min(currentPage * PerPageCount, totalPages)} of{" "}
                            {totalPages} entries
                          </div>
                          <Pagination
                            color="standard"
                            shape="rounded"
                            defaultPage={1}
                            count={Math.ceil(totalPages / PerPageCount)}
                            page={currentPage}
                            onChange={paginate}
                            info={true}
                            size="large"
                          />
                        </div>
                      )}

                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(Jobpost));

Jobpost.propTypes = {
  error: PropTypes.any,
  users: PropTypes.array,
};
