import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toastr from "toastr";
import Pagination from "@mui/material/Pagination";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { CSVLink } from "react-csv";
import SweetAlert from "react-bootstrap-sweetalert";

const Appregistred = (props) => {
  const [details, setDetails] = useState([]);
  const [selecteddistrict_id, setSelecteddistrict_id] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [selectedPackageNot, setSelectedPackageNot] = useState(null)
  const [stateOptions, setStateOptions] = useState([]);
  const [district_idOptions, setdistrict_idOptions] = useState([]);
  const [instIdToBeDeleted, setInstIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [statusOptions, setstatusOptions] = useState([
    { label: "New", value: 1 },
    { label: "Registered", value: 2 },
    { label: "Cancelled", value: 3 },
  ]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchData, setSearchData] = useState({
    package_id: []
    // from_date: getFirstday(new Date()),
    // to_date: getDate(new Date()),
  });
  // const [dataToBeExported, setDataToBeExported] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  // const dispatch = useDispatch();
  const history = useHistory();

  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [managementOptions, setManagementOptions] = useState([]);
  const [syllabusSearch, setSyllabusSearch] = useState(null);
  const [mediumSearch, setMediumSearch] = useState(null);
  const [managementSearch, setManagementSearch] = useState(null);
  const [selectedBlocked, setSelectedBlocked] = useState(null);
  const [selectedVerifiedSearch, setSelectedVerifiedSearch] = useState(null)

  /** pagination */
  const [searchValue, setSearchValue] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(currentPage * PerPageCount);
  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(indexOfLastTransfer - PerPageCount);

  const from_date = searchData?.from_date;
  const to_date = searchData?.to_date;
  const syllabus = searchData?.syllabus;
  const package_id = searchData?.package_id;
  const medium = searchData?.medium;
  const management = searchData?.management;
  const state = searchData?.state;
  const district_id = searchData?.district_id;
  const status = searchData?.status;
  const blocked = searchData?.blocked;

  const paginate = (event, value) => {
    setCurrentPage(value);
    // Call handleTableData with updated page value
    handleTableData(searchValue, from_date, to_date, syllabus, package_id, medium, management, state, district_id, status, blocked, value, PerPageCount);
  };

  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  }

  const handleSearch = (value) => {
    setSearchValue(value);
    resetPaginate();
    handleTableData(
      value,
      searchData?.from_date,
      searchData?.to_date,
      selectedState?.value,
      selecteddistrict_id?.value,
      selectedStatus?.value,
      selectedPackage?.value
    );
  };
  useEffect(() => {
    if (details?.length === 0 && indexOfFirstTransfer !== 0) {
      resetPaginate();
      handleTableData(searchValue, from_date, to_date, syllabus, package_id, medium, management, state, district_id, status, blocked, currentPage, PerPageCount);
    }
  }, [details]);
  /** end pagination */

  useEffect(() => {
    handleTableData(searchValue);
    fetch_all_states();
    fetch_syllabus();
    fetch_medium();
    fetch_management();
  }, []);

  const fetch_syllabus = () => {
    axios
      .get(`${API_URL}manage/syllabus`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let syllabus_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setSyllabusOptions([
          {
            options: syllabus_data,
          },
        ]);
      });
  };

  const fetch_medium = () => {
    axios
      .get(`${API_URL}manage/medium`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let medium_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setMediumOptions([
          {
            options: medium_data,
          },
        ]);
      });
  };

  const fetch_management = () => {
    axios
      .get(`${API_URL}manage/management`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let manage_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setManagementOptions([
          {
            options: manage_data,
          },
        ]);
      });
  };

  const updateStatus = (item) => {
    axios
      .put(`${API_URL}institute/inst_block?institute_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.data.inst_status === 0) {
            toastr.success("Institute unblocked successfully");
          } else toastr.success("Institute blocked successfully");
          handleTableData();
        } else {
          toastr.error("Failed");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  function handleTableData(
    search = "",
    from_date = "",
    to_date = "",
    syllabus = "",
    medium = "",
    management = "",
    state = "",
    district_id = "",
    status = "",
    blocked = "",
    package_having = "",
    package_not_having = "",
    verify = "",
    start = 1,
    end = 20,
  ) {
    var url = `${API_URL}institute/app?`;
    url =
      url +
      "search=" +
      search +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&syllabus=" +
      syllabus +
      "&medium=" +
      medium +
      "&management=" +
      management +
      "&state=" +
      state +
      "&district_id=" +
      district_id +
      "&status=" +
      status +
      "&blocked=" +
      blocked +
      "&package_having=" +
      package_having +
      "&package_not_having=" +
      package_not_having +
      "&verify=" +
      verify +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let dataToBeExported = [];
        let result = res.data.data;
        setTotalPages(res.data.limit);
        let id = (start - 1) * end;
        result &&
          result.map((item, index) => {
            let exportItem = {};
            id++;
            item.id = id;
            item.syllabusName = "";
            item.syllabus &&
              item.syllabus.map((el) => {
                if (item.syllabusName === "") item.syllabusName = el?.name;
                else item.syllabusName = item.syllabusName + "," + el?.name;
              });
            item.mediumName = "";
            item.medium &&
              item.medium.map((el) => {
                if (item.mediumName === "") item.mediumName = el?.name;
                else item.mediumName = item.mediumName + "," + el?.name;
              });
            item.date = moment(item?.inst_date).format("DD-MM-YYYY");
            item.time = moment(item?.inst_time, "hhmmss").format("hh:mm a");
            if (item?.web_update === 0)
              item.status = (
                <label style={{ color: "green" }}>Registered</label>
              );
            else if (item?.web_update === 1)
              item.status = <label style={{ color: "blue" }}>New</label>;
            else if (item?.web_update === 2)
              item.status = <label style={{ color: "red" }}>Cancelled</label>;
            else item.status = "";

            item.inst_name1 = (
              <div>
                <a href={`/institute/${item?.inst_reg_no}`}>{item.inst_name}</a>
              </div>
            );
            item.pack = (
              <div>
                <ul>
                  {item?.subscription?.map((el) => {
                    return (
                      <>
                        <li>Resume: {el?.Find_Resume}</li>
                        <li>Job: {el?.Job_Post}</li>
                        <li>Recruit: {el?.Recruit_with_Support}</li>
                      </>
                    )
                  })}
                </ul>
              </div>
            );


            if (item.inst_status === 0) {
              item.block = (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                      >
                        Block
                      </Tooltip>
                    }
                  >
                    <LockOpenIcon
                      style={{
                        color: "green",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                      onClick={() => updateStatus(item)}
                    />
                  </OverlayTrigger>
                </>
              );
            } else if (item.inst_status === 2) {
              item.block = (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                      >
                        Unblock
                      </Tooltip>
                    }
                  >
                    <LockOpenIcon
                      style={{
                        color: "red",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                      onClick={() => updateStatus(item)}
                    />
                  </OverlayTrigger>
                </>
              );
            }
            // item.action = (
            //   <div style={{ display: "flex", justifyContent: "center" }}>
            //     {item?.web_update === 1 ? (
            //       <OverlayTrigger
            //         placement="top"
            //         overlay={
            //           <Tooltip
            //             id="button-tooltip-2"
            //             style={{ position: "absolute", top: "-30px" }}
            //           >
            //             Register
            //           </Tooltip>
            //         }
            //       >
            //         <button
            //           className="button_class"
            //           onClick={() => {
            //             window.open("/webregistered/" + item?._id);
            //             //history.push("/webregistered/" + item?._id);
            //           }}
            //         >
            //           <i className="far fa-edit"></i>
            //         </button>
            //       </OverlayTrigger>
            //     ) : null}
            //     {item?.web_update === 1 ? (
            //       <OverlayTrigger
            //         placement="top"
            //         overlay={
            //           <Tooltip
            //             id="button-tooltip-2"
            //             style={{ position: "absolute", top: "-30px" }}
            //           >
            //             Cancel
            //           </Tooltip>
            //         }
            //       >
            //         <button
            //           onClick={() => {
            //             cancelInstitute(
            //               item?._id,
            //               search,
            //               from_date,
            //               to_date,
            //               state,
            //               district_id,
            //               status,
            //               start,
            //               end
            //             );
            //           }}
            //           className="button_class"
            //         >
            //           <i
            //             className="fas fa-window-close"
            //             style={{ color: "red" }}
            //           ></i>
            //         </button>
            //       </OverlayTrigger>
            //     ) : null}
            //   </div>
            // );

            item.action = (
              <div>
                {/* <a style={{color:"unset"}} href={`/institute/${item?.inst_reg_no}`}> */}
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    window.open(`/institute/${item?.inst_reg_no}`);
                  }}
                ></i>
                {/* </a> */}

                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    window.open("/appregistered/" + item?._id);
                  }}
                ></i>
                <i
                  onClick={() => {
                    setInstIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                ></i>
              </div>
            );
            exportItem.Id = item.id;
            exportItem.Date = item.date;
            exportItem.Time = item.time;
            exportItem.Institute = item.inst_name;
            exportItem.State = item.state_name;
            exportItem.district_id = item.district_id_name;
            exportItem.Mobile = item.inst_phone;
            exportItem.Packages = item?.subscription?.map(
              (el) => el?.packageName
            );
            dataToBeExported.push(exportItem)
          });
        setDetails(result);
        setDataToBeExported(dataToBeExported)

      });
  }
  const cancelInstitute = (
    id,
    search,
    from_date,
    to_date,
    state,
    district_id,
    status,
    start,
    end
  ) => {
    axios
      .put(
        `${API_URL}institute/cancel`,
        { institute_id: id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("Cancelled");
          handleTableData(
            search,
            from_date,
            to_date,
            state,
            district_id,
            status,
            start,
            end
          );
        } else toastr.error("Failed to cancel");
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  function fetch_all_states() {
    axios
      .get(`${API_URL}states/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var state_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el.id,
            };
          });

        setStateOptions([
          {
            options: state_data,
          },
        ]);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      {
        label: "Institute",
        field: "inst_name1",
        sort: "asc",
        width: 270,
      },
      {
        label: "State",
        field: "state_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "District",
        field: "district_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "inst_phone",
        sort: "asc",
        width: 100,
      },
      {
        label: "Package",
        field: "pack",
        sort: "asc",
        width: 100,
      },
      // {
      //   label: "Status",
      //   field: "status",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "Block",
        field: "block",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  };

  let handleDate = (e) => {
    if (e.target.name == "from_date") {
      var date1 = e.target.value;
      var date2 = searchData?.to_date;
    } else {
      var date1 = searchData?.from_date;
      var date2 = e.target.value;
    }
    let state = searchData?.state ? searchData.state : "";
    let district_id = searchData?.district_id ? searchData.district_id : "";

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    resetPaginate();
    handleTableData(
      searchValue,
      date1,
      date2,
      state,
      district_id,
      selectedStatus?.value
    );
  };

  function fetch_all_district_ids(id) {
    axios
      .get(`${API_URL}districts/list?state_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var district_id_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.district_name,
              value: el._id,
            };
          });

        setdistrict_idOptions([
          {
            options: district_id_data,
          },
        ]);
      });
  }

  const handleSelectChange = (selected, name) => {
    let state = searchData?.state ? searchData.state : "";
    let district_id = searchData?.district_id ? searchData.district_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let blocked = searchData?.blocked ? searchData.blocked : "";
    let syllabus = searchData?.syllabus ? searchData.syllabus : "";
    let medium = searchData?.medium ? searchData.medium : "";
    let management = searchData?.management ? searchData.management : "";
    let package_having = searchData?.package_having ? searchData.package_having : "";
    let verify = searchData?.verify ? searchData.verify : "";
    let package_not_having = searchData?.package_not_having ? searchData.package_not_having : "";

    resetPaginate();
    switch (name) {
      case "state":
        setSelectedState(selected);
        setSelecteddistrict_id(null);
        setSearchData({
          ...searchData,
          state: selected.value,
          district_id: "",
        });
        fetch_all_district_ids(selected.value);
        handleTableData(
          searchValue,
          date1,
          date2,
          syllabus,
          medium,
          management,
          selected.value,
          "",
          selectedStatus?.value,
          blocked,
          package_having,
          package_not_having,
          verify
        );
        break;
      case "status":
        setSelectedStatus(selected);
        setSearchData({
          ...searchData,
          status: selected.value,
        });
        handleTableData(
          searchValue,
          date1,
          date2,
          syllabus,
          medium,
          management,
          state,
          district_id,
          selected.value,
          blocked,
          package_having,
          package_not_having,
          verify
        );
        break;
      case "district_id":
        setSelecteddistrict_id(selected);
        setSearchData({
          ...searchData,
          district_id: selected.value,
        });
        handleTableData(
          searchValue,
          date1,
          date2,
          syllabus,
          medium,
          management,
          state,
          selected.value,
          selectedStatus?.value,
          blocked,
          package_having,
          package_not_having,
          verify
        );
        break;
      case "syllabus":
        setSyllabusSearch(selected);
        setSearchData({
          ...searchData,
          syllabus: selected.value,
        });
        handleTableData(
          searchValue,
          date1,
          date2,
          selected.value,
          medium,
          management,
          state,
          district_id,
          blocked,
          package_having,
          package_not_having,
          verify
        );
        break;
      case "medium":
        setMediumSearch(selected);
        setSearchData({
          ...searchData,
          medium: selected.value,
        });
        handleTableData(
          searchValue,
          date1,
          date2,
          syllabus,
          selected.value,
          management,
          state,
          district_id,
          blocked,
          package_having,
          package_not_having,
          verify
        );
        break;
      case "management":
        setManagementSearch(selected);
        setSearchData({
          ...searchData,
          management: selected.value,
        });
        handleTableData(
          searchValue,
          date1,
          date2,
          syllabus,
          medium,
          selected.value,
          state,
          district_id,
          blocked,
          package_having,
          package_not_having,
          verify
        );
        break;
      case "blocked":
        setSelectedBlocked(selected);
        setSearchData({
          ...searchData,
          blocked: selected.value,
        });
        handleTableData(
          searchValue,
          date1,
          date2,
          syllabus,
          medium,
          management,
          state,
          district_id,
          selectedStatus?.value,
          selected.value,
          package_having,
          package_not_having,
          verify
        );
        break;
      case "package_having":
        setSelectedPackage(selected)
        const selectedValues = selected.map(options => options.value)
        setSearchData({
          ...searchData,
          package_having: selectedValues
        })
        handleTableData(
          searchValue,
          date1,
          date2,
          syllabus,
          medium,
          management,
          state,
          district_id,
          selectedStatus?.value,
          blocked,
          selectedValues,
          package_not_having,
          verify
        )
        break;
      case "package_not_having":
        setSelectedPackageNot(selected)
        const selectedValues1 = selected.map(options => options.value)
        setSearchData({
          ...searchData,
          package_not_having: selectedValues1
        })
        handleTableData(
          searchValue,
          date1,
          date2,
          syllabus,
          medium,
          management,
          state,
          district_id,
          selectedStatus?.value,
          blocked,
          package_having,
          selectedValues1,
          verify
        )
        break;
      case "verify":
        setSelectedVerifiedSearch(selected)
        setSearchData({
          ...searchData,
          verify: selected.value
        })
        handleTableData(
          searchValue,
          date1,
          date2,
          syllabus,
          medium,
          management,
          state,
          district_id,
          selectedStatus?.value,
          blocked,
          package_having,
          package_not_having,
          selected.value
        )
        break;

      default:
        break;
    }
  };



  const reset = () => {
    setSearchData({
      from_date: "",
      to_date: "",
      state: "",
      district_id: "",
      status: "",
    });
    setSyllabusSearch(null)
    setMediumSearch(null)
    setManagementSearch(null)
    setSelectedPackage(null)
    setSelectedPackageNot(null)
    setSelectedStatus(null)
    setSelectedBlocked(null)
    setSelectedVerifiedSearch(null)
    setSelectedState(null);
    setSelecteddistrict_id(null);
    setSelectedStatus(null);
    setdistrict_idOptions([]);
    handleTableData(searchValue);
    resetPaginate();
  };

  function handleApiCall() {
    axios.post(`${API_URL}institute/log/creation`, { type: 4 }, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
    })
  }


  return (
    <React.Fragment>

      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}institute` + "/" + instIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Institute deleted successfully");
                  handleTableData();
                } else {
                  toastr.error("Failed to delete institute");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="App Registered" />

          <Row>
            <Col className="col-12">
              {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {grandTotal}</h5>
              </Row> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Syllabus</Label>
                        <Select
                          name="syllabus"
                          value={syllabusSearch}
                          onChange={(selected) => {
                            handleSelectChange(selected, "syllabus");
                          }}
                          options={syllabusOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Medium</Label>
                        <Select
                          name="medium"
                          value={mediumSearch}
                          onChange={(selected) => {
                            handleSelectChange(selected, "medium");
                          }}
                          options={mediumOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Management</Label>
                        <Select
                          name="management"
                          value={managementSearch}
                          onChange={(selected) => {
                            handleSelectChange(selected, "management");
                          }}
                          options={managementOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>State</Label>
                        <Select
                          name="state"
                          value={selectedState}
                          onChange={(selected) => {
                            handleSelectChange(selected, "state");
                          }}
                          options={stateOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>District</Label>
                        <Select
                          name="district_id"
                          value={selecteddistrict_id}
                          onChange={(selected) => {
                            handleSelectChange(selected, "district_id");
                          }}
                          options={district_idOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>With Package</Label>
                        <Select
                          isMulti
                          name="package_having"
                          value={selectedPackage}
                          onChange={(selected) => {
                            handleSelectChange(selected, "package_having")
                          }}
                          options={[
                            { label: "Find Resume", value: 0 },
                            { label: "Job Post", value: 1 },
                            { label: "Recruit with support", value: 2 }
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Without Package</Label>
                        <Select
                          isMulti
                          name="package_not_having"
                          value={selectedPackageNot}
                          onChange={(selected) => {
                            handleSelectChange(selected, "package_not_having");
                          }}
                          options={[
                            { label: "Find Resume", value: 0 },
                            { label: "Job Post", value: 1 },
                            { label: "Recruit with support", value: 2 }
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Blocked</Label>
                        <Select
                          name="blocked"
                          value={selectedBlocked}
                          onChange={(selected) => {
                            handleSelectChange(selected, "blocked");
                          }}
                          options={[
                            { label: "Blocked", value: "true" },
                            { label: "Active", value: "false" },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Verified/Unverified</Label>
                        <Select
                          name="verify"
                          value={selectedVerifiedSearch}
                          onChange={(selected) => {
                            handleSelectChange(selected, "verify");
                          }}
                          options={[
                            { label: "Verified", value: 1 },
                            { label: "Unverified", value: 0 }
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="1">
                      <div style={{ paddingTop: '27px' }}>
                        <Button onClick={handleApiCall} color="success" type="">
                          <CSVLink
                            data={dataToBeExported}
                            filename={"Appregistered.xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>

                    <Col md="1" style={{ paddingTop: "27px" }}>
                      <div>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <MDBDataTable
                    id="webRegTableId"
                    responsive
                    bordered
                    info={false}
                    searching={true}
                    disbaleRetreatAfterSorting={true}
                    paging={false}
                    data={data}
                    entries={PerPageCount}
                  />
                  {totalPages > 20 && (
                    <div className="pagination-div">
                      <div className="page-details">
                        Showing {((currentPage - 1) * PerPageCount) + 1} to{" "}
                        {Math.min(currentPage * PerPageCount, totalPages)} of{" "}
                        {totalPages} entries
                      </div>
                      <Pagination
                        color="standard"
                        shape="rounded"
                        defaultPage={1}
                        count={Math.ceil(totalPages / PerPageCount)}
                        page={currentPage}
                        onChange={paginate}
                        info={true}
                        size="large"
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>

    </React.Fragment>
  );
};

export default Appregistred;