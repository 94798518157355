import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { AvForm, AvField } from "availity-reactstrap-validation";
import "./Sitemap.scss";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import moment from "moment";
function SiteMap() {
  const [tableData, settableData] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    fetch_sitemap_list();
  }, []);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "IP",
        field: "ipAddress",
        sort: "asc",
        width: 150,
      },
      {
        label: "Generated Date & Time",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Link Count",
        field: "linkCount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Added by",
        field: "addedBy",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData
  };

  function generateSitemap() {
    axios
      .get(`${API_URL}sitemap`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        // window.open("https://uat.indianaglobalteachers.com/sitemap.xml");
      });
  }

  function fetch_sitemap_list() {
    axios
      .get(`${API_URL}sitemap/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let tabled = [];
        let result = res.data;
        result.map((item, index) => {
          item.id = index + 1;
          item.date = moment(item.generatedAt).format("DD-MM-YYYY,hh:mm A")

          tabled.push(item);
        });
        settableData(tabled);
      });
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Site Map" />
          <Row>
            <Card>
              <CardBody>
                <Button onClick={generateSitemap} color="primary">
                  <i class="fa fa-print mx-2"></i>Generate Sitemap
                </Button>

                <Row className="mt-4">
                  <MDBDataTable
                    id="sitemaptable"
                    responsive
                    bordered
                    data={data}
                  />
                </Row>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SiteMap;
