import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import User from "../pages/User/index";
import Staff from "../pages/User/Staff";
import StaffDetails from "../pages/User/Staff/Staff Detail/StaffDetails";

import Company from "../pages/MasterSettings/Company";
import Branch from "../pages/MasterSettings/Manage Branch";
import Privilage from "../pages/MasterSettings/Privilage";
import Rules from "../pages/MasterSettings/Rules";
import Settings from "../pages/MasterSettings/Master_Setting";
import RulesApp from "../pages/MasterSettings/RulesApp";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiImageCropper from "../pages/Ui/ui-image-cropper";

// Forms
import BasicElements from "../pages/Forms/BasicElements";
import FormLayouts from "../pages/Forms/FormLayouts";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";

//Icons

import IconUnicons from "../pages/Icons/IconUnicons";
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import VerifyOtp from "../pages/Authentication/otpPage";
import ResetPassword from "../pages/Authentication/resetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Profile
import UserProfile from "../pages/Authentication/user-profile";
//gernate sitemap
import SiteMap from "../pages/SiteMap/SiteMap";
//Subject
import Subject from "../pages/Manage/Subject";
//Institute
import Institute from "../pages/Institute/Add";
import Package from "../pages/Institute/Package/Package";
import Recruit from "../pages/Institute/Recruit/Recruit";
import Subscriptions from "../pages/Institute/Subscriptions/Subscriptions";
//webregistered
// import WebRegistered from "../pages/Institute/Webregistered";
import Webrigster from "../pages/Institute/Webregistered/Webrigster";
//jobpost
import Management from "../pages/Institute/view";
import Jobpost from "../pages/Jobpost/Add";
// Provision
import Provision from "../pages/Manage/Provision";
import Qualification from "../pages/Manage/Qualification";
import AddCustomer from "../pages/Customers/AddCustomer";
import ViewCustomer from "../pages/Customers/ViewCustomer";
import FollowupLogs from "../pages/FollowUp/followupLog";
//Chat
import Chat from "../pages/Chat/index";

import AppoinmentList from "../pages/Appointment/AppoinmentList";

//Post
import Post from "../pages/Post/Post";

//security
import BlockedIP from "../pages/Security/Blocked IP/BlockedIP";
import UserBlock from "../pages/Security/User Block/UserBlock";
import UserActivityLog from "../pages/Security/User Activity Log/UserActivityLog";
import CronJobActivity from "../pages/Security/Cron Job Activity/CronJobActivity";

//webforms
import Enquiry from "../pages/Webforms/Enquiry";
import Feedback from "../pages/Webforms/Feedback";
import Otplog from "../pages/Otplog";
//jobpost
import viewJobpost from "../pages/Jobpost/viewJobpost";

//applications
import ViewApplication from "../pages/Applications/viewApplications";
import SavedApplication from "../pages/Applications/savedApplications";
import ShortlistedApplication from "../pages/Applications/shortlistedApplications";
import PlacedApplication from "../pages/Applications/placedApplications";

//import Gantt_Tool_React from "../pages/Gantt_tool_React/main";
//web register
import WebFaq from "../pages/Website/faq/index";
import ManagementFaq from "../pages/Website/faq/managementFaq"

//app regiter 
import Appregistred from "../pages/Institute/Appregistred/Appregistred";
//offer letter
import OfferLetter from "../pages/Offerletter";

import AddManagement from "../pages/Manage/Management";
import AddClass from "../pages/Manage/Class";
import ManageMedium from "../pages/Manage/Medium";
import Requirements from "../pages/Requirements";
import FollowupReport from "../pages/FollowUp/report";


// ACCOUNTS
import ManagementDetails from "../pages/Accounts/ManagementDetails";
import TeacherDetails from "../pages/Accounts/TeacherDetails";

// Package management
import PackageManagement from "../pages/PackageManagement"


const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/user", component: User },
  { path: "/staff", component: Staff },
  { path: "/staff/:userId", component: StaffDetails },
  { path: "/user/:userId", component: StaffDetails },
  { path: "/profile/:userId", component: StaffDetails },

  { path: "/company", component: Company },
  { path: "/branch", component: Branch },
  { path: "/privilage", component: Privilage },
  { path: "/rules", component: Rules },
  { path: "/settings", component: Settings },
  { path: "/rules_app", component: RulesApp },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-image-cropper", component: UiImageCropper },

  // Forms
  { path: "/basic-elements", component: BasicElements },
  { path: "/form-layouts", component: FormLayouts },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },

  // Icons
  { path: "/icons-unicons", component: IconUnicons },
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },
  { path: "/appointmentLetter", component: AppoinmentList },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // //profile
  { path: "/profile", component: UserProfile },

  //institute
  { path: "/institute", component: Institute },
  { path: "/institute/:instID", component: Management },
  { path: "/webregistered/:webRegID", component: Institute },
  { path: "/appregistered/:webRegID", component: Institute },
  { path: "/package", component: Package },
  { path: "/recruit", component: Recruit },
  { path: "/subscription", component: Subscriptions },
  { path: "/subscription/:instId", component: Subscriptions },



  //WebRegistered
  { path: "/webregistered", component: Webrigster },

  // Appregistred
  { path: "/app-registered", component: Appregistred },

  // //manage
  { path: "/subject", component: Subject },
  { path: "/provision", component: Provision },
  { path: "/qualification", component: Qualification },

  //Customers
  { path: "/candidate", component: AddCustomer },
  { path: "/followup-logs", component: FollowupLogs },
  { path: "/followup-report", component: FollowupReport },

  //jobpost
  { path: "/jobpost", component: Jobpost },
  { path: "/jobpost/:jobId", component: viewJobpost },
  { path: "/job/:jobpostId", component: Jobpost },
  { path: "/candidate/:canId", component: ViewCustomer },
  //chat
  { path: "/chat", component: Chat },

  //post
  { path: "/post", component: Post },
  //site map
  { path: "/sitemap", component: SiteMap },
  // //webforms
  { path: "/webenquiry", component: Enquiry },
  { path: "/webfeedback", component: Feedback },

  { path: "/visitlog", component: Otplog },

  { path: "/requirements", component: Requirements },
  { path: "/blockedip", component: BlockedIP },
  { path: "/userblock", component: UserBlock },
  { path: "/UserActivityLog", component: UserActivityLog },
  { path: "/CronJobActivity", component: CronJobActivity },

  //{ path: "/gantt_tool", component: Gantt_Tool_React },

  //application
  { path: "/viewApplications", component: ViewApplication },
  { path: "/savedApplications", component: SavedApplication },
  { path: "/shortlistedApplications", component: ShortlistedApplication },
  { path: "/placed", component: PlacedApplication },

  //web register
  { path: "/webFAQ", component: WebFaq },
  { path: "/FaqInstitute", component: ManagementFaq },

  { path: "/offerLetter", component: OfferLetter },
  // { path: "/placed/:placedJobid", component: OfferLetter },

  //App register


  // ACCOUNTS
  { path: "/subscribed/management", component: ManagementDetails },
  { path: "/appointed/candidate", component: TeacherDetails },

  { path: "/management", component: AddManagement },
  { path: "/manageClass", component: AddClass },
  { path: "/manageMedium", component: ManageMedium },


  // Package Management
  { path: "/package-management", component: PackageManagement },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/verify-otp", component: VerifyOtp },
  { path: "/reset-password", component: ResetPassword },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  // { path: "/qr-code/:qrcodeId", component: ViewQrCode },
  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
];

export { userRoutes, authRoutes };
