import React, { useState, useEffect, useRef } from "react";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { Modal } from "react-bootstrap";
import "./webFaq.scss";
import Pagination from "@mui/material/Pagination";
const WebFaq = (props) => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [faqIdToBeUpdated, setFaqIdToBeUpdated] = useState(null);
  const [faqIdToBeDeleted, setFaqIdToBeDelected] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  /** pagination */
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
    currentPage * PerPageCount
  );

  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
    indexOfLastTransfer - PerPageCount
  );
  const paginate = (event, value) => {
    setCurrentPage(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
    handleTableData(searchValue, indexOfFirstTransfer, indexOfLastTransfer);
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  }
  const handleSearch = (value) => {
    setSearchValue(value)
    resetPaginate();
    handleTableData(value);

  }
  useEffect(() => {
    if (details.length == 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData(searchValue);
    }

  }, [details])
  /** end pagination */
  useEffect(() => {
    handleTableData(searchValue);
  }, []);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preUpdateWebFaq = (item) => {
    setFaqIdToBeUpdated(item?._id);
    axios
      .get(`${API_URL}faq?faq_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.faq_id = result?._id;
        setMaster(result);
      });
  };
  function handleTableData(search = "", start = 0, end = 20) {
    axios
      .get(`${API_URL}faq/list?search=` + search + "&start=" + start + "&end=" + end, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPages(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.faq_date).format("DD-MM-YYYY");
            item.time = moment(item?.faq_time, "hhmmss").format("hh:mm a");

            if (item?.faq_updatedby) {
              let updatedBy = item?.faq_updatedby || {};
              if (updatedBy?.lastName)
                item.staff = updatedBy?.firstName + " " + updatedBy?.lastName;
              else item.staff = updatedBy?.firstName;
            } else {
              let addedBy = item?.faq_addedby || {};
              if (addedBy?.lastName)
                item.staff = addedBy?.firstName + " " + addedBy?.lastName;
              else item.staff = addedBy?.firstName;
            }

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {}}
                ></i> */}
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateWebFaq(item);
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setFaqIdToBeDelected(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });
        setDetails(result);
      });
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Question",
        field: "faq_question",
        sort: "asc",
        width: 100,
      },
      {
        label: "Answer",
        field: "faq_answer",
        sort: "asc",
        width: 100,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: details,
  };

  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleValidSubmit = () => {
    if (faqIdToBeUpdated) {
      axios
        .put(`${API_URL}faq`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("FAQ updated successfully");
            formRef.current.reset();
            setMaster({});
            handleTableData(searchValue, indexOfFirstTransfer, indexOfLastTransfer);
            setFaqIdToBeUpdated(null);
          } else {
            toastr.error("Failed to update FAQ");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}faq`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("FAQ created successfully");
            formRef.current.reset();
            setMaster({});
            handleTableData(searchValue, indexOfFirstTransfer, indexOfLastTransfer);
          } else {
            toastr.error("Failed to create FAQ");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  return (
    <>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}faq` + "/" + faqIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("FAQ deleted successfully");
                  if (master && master?._id === faqIdToBeDeleted) {
                    formRef.current.reset();
                    setMaster({});
                    setFaqIdToBeUpdated(null);
                  }
                  handleTableData(searchValue, indexOfFirstTransfer, indexOfLastTransfer);
                } else {
                  toastr.error("Failed to delete FAQ");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="FAQ" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={() => {
                      handleValidSubmit();
                    }}
                  >
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <AvField
                            label="Question"
                            name="faq_question"
                            placeholder="Question"
                            type="text"
                            errorMessage="Enter Question"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={master?.faq_question}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <AvField
                            label="Answer"
                            name="faq_answer"
                            placeholder="Answer"
                            type="textarea"
                            errorMessage="Enter Answer"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={master?.faq_answer}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col md="1" style={{ marginTop: "2.3%" }}>
                        <div className="mb-3">
                          {faqIdToBeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="webFaqTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    paging={false}
                    onSearch={(value) => { handleSearch(value) }}
                    entries={PerPageCount}
                  />
                  {totalPages > 20 && (
                    <div className="pagination-div">
                      <div className="page-details">
                        Showing {indexOfFirstTransfer + 1} to {indexOfFirstTransfer + details.length} of {totalPages} entries
                      </div>
                      <Pagination
                        color="standard"
                        shape="rounded"
                        defaultPage={1}
                        count={Math.ceil(totalPages / PerPageCount)}
                        page={currentPage}
                        onChange={paginate}
                        size="large"
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default WebFaq;
