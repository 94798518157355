import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { Paper, Box, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Stack from "@mui/material/Stack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Select from "react-select";
import { apiError } from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { Work } from "@mui/icons-material";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./jobpostDetails.scss";
import saveAs from "file-saver";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { getLocalbody, getRouteName } from "../../../helpers/globalFunctions";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
// import applied from "../../../assets/images/apply-now-icon.png";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import BallotIcon from "@mui/icons-material/Ballot";
import AssignmentIcon from "@mui/icons-material/Assignment";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import SummarizeIcon from "@mui/icons-material/Summarize";
//import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { result } from "lodash";

const JobpostDetails = (props) => {
  const { jobId } = props.match.params;
  const [details, setDetails] = useState({});
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [popupData, setPopupData] = useState({});
  const [wasteItemDetails, setWasteItemDetails] = useState([]);
  const [popupView, setPopupView] = useState(false);
  const [agentNumber, setAgentNumber] = useState("");
  const [appliedData, setAppliedData] = useState([]);
  const [savedData, setSavedData] = useState([]);
  const [shortlistedData, setShortlistedData] = useState([]);
  const [placedData, setPlacedData] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [popupSavedView, setPopupSavedView] = useState(false);
  const [popupSavedData, setPopupSavedData] = useState({});
  const [popupAppliedView, setPopupAppliedView] = useState(false);
  const [popupAppliedData, setPopupAppliedData] = useState({});
  const [popupShortlistedView, setPopupShortlistedView] = useState(false);
  const [popupShortlistedData, setPopupShortlistedData] = useState({});
  const [popupPlacedView, setPopupPlacedView] = useState(false);
  const [popupPlacedData, setPopupPlacedData] = useState({});
  const [totalApplied, setTotalApplied] = useState(0);
  const [totalSaved, setTotalSaved] = useState(0);
  const [totalShortlisted, setTotalShortlisted] = useState(0);
  const [totalPlaced, setTotalPlaced] = useState(0);
  const [jobStatusLogData, setJobStatusLogData] = useState([]);
  const [showDeclineAppliedForm, setShowDeclineAppliedForm] = useState(false);
  const [declineAppliedData, setDeclineAppliedData] = useState({});
  const [appliedErrors, setAppliedErrors] = useState({
    remarkError: "",
  });
  const [selectedDeclineApplied, setSelectedDeclineApplied] = useState(null);
  const [selectedDeclineShortlisted, setSelectedDeclineShortlisted] =
    useState(null);
  const [selectedDeclinePlaced, setSelectedDeclinePlaced] = useState(null);
  const [declinePlacedData, setDeclinePlacededData] = useState({});
  const [placedErrors, setPlacedErrors] = useState({
    remarkError: "",
  });
  const [showDeclinePlacedForm, setShowDeclinePlacedForm] = useState(false);
  const [ReasonsOption, setReasonsOptions] = useState([]);
  const [showDeclineShortlistedForm, setShowDeclineShortlistedForm] =
    useState(false);
  const [declineShortlistedData, setDeclineShortlistedData] = useState({});
  const [shortlistedErrors, setShortlistedErrors] = useState({
    remarkError: "",
  });
  const [videoForm, setVideoForm] = useState(false);
  // const [candidateVideo, setCandidateVideo] = useState({});
  const [candidateVideo1, setCandidateVideo1] = useState("");
  const [candidateVideo2, setCandidateVideo2] = useState("");
  const [candidateVideo1Hash, setCandidateVideo1Hash] = React.useState("");
  const [candidateVideo2Hash, setCandidateVideo2Hash] = React.useState("");
  const history = useHistory();
  /** pagination */
  const [searchValueJob, setSearchValueJob] = useState("");
  const [searchValueApp, setSearchValueApp] = useState("");
  const [searchValueShort, setSearchValueShort] = useState("");
  const [searchValuePlaced, setSearchValuePlaced] = useState("");
  const [searchValueActivity, setSearchValueActivity] = useState("");

  const [totalPagesJob, setTotalPagesJob] = useState(0);
  const [totalPagesApp, setTotalPagesApp] = useState(0);
  const [totalPagesShort, setTotalPagesShort] = useState(0);
  const [totalPagesPlaced, setTotalPagesPlaced] = useState(0);
  const [totalPagesRemarks, setTotalPagesRemarks] = useState(0);

  const [currentPageJob, setCurrentPageJob] = useState(1);
  const [currentPageApp, setCurrentPageApp] = useState(1);
  const [currentPageShort, setCurrentPageShort] = useState(1);
  const [currentPagePlaced, setCurrentPagePlaced] = useState(1);
  const [currentPageReamrks, setCurrentPageRemarks] = useState(1);

  const [PerPageCount, setPerPageCount] = useState(20);

  const [indexOfLastTransfer1, setindexOfLastTransfer1] = useState(
    currentPageJob * PerPageCount
  );
  const [indexOfFirstTransfer1, setindexOfFirstTransfer1] = useState(
    indexOfLastTransfer1 - PerPageCount
  );
  const [indexOfLastTransfer2, setindexOfLastTransfer2] = useState(
    currentPageApp * PerPageCount
  );

  const [indexOfFirstTransfer2, setindexOfFirstTransfer2] = useState(
    indexOfLastTransfer2 - PerPageCount
  );
  const [indexOfLastTransfer3, setindexOfLastTransfer3] = useState(
    currentPageShort * PerPageCount
  );

  const [indexOfFirstTransfer3, setindexOfFirstTransfer3] = useState(
    indexOfLastTransfer3 - PerPageCount
  );
  const [indexOfLastTransfer4, setindexOfLastTransfer4] = useState(
    currentPageShort * PerPageCount
  );

  const [indexOfFirstTransfer4, setindexOfFirstTransfer4] = useState(
    indexOfLastTransfer4 - PerPageCount
  );
  const [indexOfLastTransfer5, setindexOfLastTransfer5] = useState(
    currentPageReamrks * PerPageCount
  );

  const [indexOfFirstTransfer5, setindexOfFirstTransfer5] = useState(
    indexOfLastTransfer5 - PerPageCount
  );
  const paginateJob = (event, value) => {
    setCurrentPageJob(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer1(indexOfFirstTransfer);
    setindexOfLastTransfer1(indexOfLastTransfer);
    fetch_saved(
      details?._id,
      searchValueJob,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginateApp = (event, value) => {
    setCurrentPageApp(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer2(indexOfFirstTransfer);
    setindexOfLastTransfer2(indexOfLastTransfer);
    fetch_applied(
      details?._id,
      searchValueApp,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginateShort = (event, value) => {
    setCurrentPageShort(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer3(indexOfFirstTransfer);
    setindexOfLastTransfer3(indexOfLastTransfer);
    fetch_shortlisted(
      details?._id,
      searchValueShort,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginatePlaced = (event, value) => {
    setCurrentPagePlaced(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer4(indexOfFirstTransfer);
    setindexOfLastTransfer4(indexOfLastTransfer);
    fetch_placed(
      details?._id,
      searchValuePlaced,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const paginateRemarks = (event, value) => {
    setCurrentPageRemarks(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer5(indexOfFirstTransfer);
    setindexOfLastTransfer5(indexOfLastTransfer);
    fetch_job_activity(
      jobId,
      searchValueActivity,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const resetPaginateJob = () => {
    setCurrentPageJob(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer1(indexOfFirstTransfer);
    setindexOfLastTransfer1(indexOfLastTransfer);
  };
  const resetPaginateApp = () => {
    setCurrentPageApp(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer2(indexOfFirstTransfer);
    setindexOfLastTransfer2(indexOfLastTransfer);
  };
  const resetPaginateShort = () => {
    setCurrentPageShort(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer3(indexOfFirstTransfer);
    setindexOfLastTransfer3(indexOfLastTransfer);
  };
  const resetPaginatePlaced = () => {
    setCurrentPagePlaced(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer4(indexOfFirstTransfer);
    setindexOfLastTransfer4(indexOfLastTransfer);
  };
  const resetPaginateRemarks = () => {
    setCurrentPageRemarks(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer5(indexOfFirstTransfer);
    setindexOfLastTransfer5(indexOfLastTransfer);
  };
  const handleSearchJob = (value) => {
    setSearchValueJob(value);
    resetPaginateJob();
    fetch_saved(details?._id, value);
  };
  const handleSearchApp = (value) => {
    setSearchValueApp(value);
    resetPaginateApp();
    fetch_applied(details?._id, value);
  };
  const handleSearchShort = (value) => {
    setSearchValueShort(value);
    resetPaginateShort();
    fetch_shortlisted(details?._id, value);
  };
  const handleSearchPlaced = (value) => {
    setSearchValuePlaced(value);
    resetPaginatePlaced();
    fetch_placed(details?._id, value);
  };
  const handleSearchActivity = (value) => {
    setSearchValueActivity(value);
    resetPaginateRemarks();
    fetch_job_activity(jobId, value);
  };

  useEffect(() => {
    if (savedData.length == 0 && indexOfFirstTransfer1 != 0) {
      resetPaginateJob();
      fetch_saved(details?._id, searchValueJob);
    }
  }, [savedData]);
  useEffect(() => {
    if (appliedData.length == 0 && indexOfFirstTransfer2 != 0) {
      resetPaginateApp();
      fetch_applied(details?._id, searchValueApp);
    }
  }, [appliedData]);
  useEffect(() => {
    if (shortlistedData.length == 0 && indexOfFirstTransfer3 != 0) {
      resetPaginateShort();
      fetch_shortlisted(details?._id, searchValueShort);
    }
  }, [shortlistedData]);
  useEffect(() => {
    if (placedData.length == 0 && indexOfFirstTransfer4 != 0) {
      resetPaginatePlaced();
      fetch_placed(details?._id, searchValuePlaced);
    }
  }, [placedData]);
  /** end pagination */

  useEffect(() => {
    if (jobId) {
      handleTableData(jobId);
      fetch_job_activity(jobId, searchValueActivity);
      fetch_all_reasons();
    }
  }, []);
  // const fetch_user_details = () => {
  //   let userid = "";
  //   if (localStorage.getItem("authUser")) {
  //     var data = localStorage.getItem("authUser");
  //     var user_obj = JSON.parse(data);
  //     userid = user_obj._id;
  //   }
  //   let Userdata = {
  //     user_id: userid,
  //   };
  //   axios
  //     .post(`${API_URL}api/staff/user_profile`, Userdata, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       let result = res.data.result;
  //       if (result && result.length > 0) {
  //         setAgentNumber(result[0].mobile);
  //       }
  //     });
  // };
  const fetch_job_activity = (reg, search = "", start = 0, end = 20) => {
    axios
      .get(
        `${API_URL}job/activity/?job_reg=` +
        reg +
        "&search=" +
        search +
        "&start=" +
        start +
        "&end=" +
        end,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        setTotalPagesRemarks(res.data.limit);
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.date = moment(item?.date).format("DD-MM-YYYY");
            item.time = moment(item?.time, "hhmmss").format("hh:mm a");
            if (item?.old_status === 0) item.prevStatus = "Ongoing";
            else if (item?.old_status === 1) item.prevStatus = "Ongoing";
            else if (item?.old_status === 2) item.prevStatus = "Filled";
            else if (item?.old_status === 3) item.prevStatus = "Inactive";
            else item.prevStatus = "";

            if (item?.new_status === 0) item.currentStatus = "Ongoing";
            else if (item?.new_status === 1) item.currentStatus = "Ongoing";
            else if (item?.new_status === 2) item.currentStatus = "Filled";
            else if (item?.new_status === 3) item.currentStatus = "Inactive";
            else item.currentStatus = "";
          });
        setJobStatusLogData(result);
      });
  };
  const fetch_all_reasons = () => {
    axios
      .get(`${API_URL}manage/decline`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let reason_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item?.remark,
              value: item?._id,
            };
          });
        setReasonsOptions([
          {
            options: reason_data,
          },
        ]);
      });
  };
  const fetch_applied = (id, search = "", start = 0, end = 20) => {
    var url = `${API_URL}application/list`;
    url =
      url +
      "?fromDate=" +
      "" +
      "&toDate=" +
      "" +
      "&candidate_id=" +
      "" +
      "&job_id=" +
      id +
      "&institute_id=" +
      "" +
      "&start=" +
      start +
      "&end=" +
      end +
      "&search=" +
      search;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPagesApp(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.application_date).format("DD-MM-YYYY");

            item.qualificationName = "";
            item.cand_qualification &&
              item.cand_qualification.map((el) => {
                if (item.qualificationName === "")
                  item.qualificationName = el?.qualification_name;
                else
                  item.qualificationName =
                    item.qualificationName + "," + el?.qualification_name;
              });
            item.name = (
              <a
                href={"/candidate/" + item?.application_cand_reg_no}
                target="_blacnk"
                style={{ color: "#495057" }}
              >
                {item?.application_cand_name}
              </a>
            );
            if (item?.application_current_status === 1)
              item.status = "Shortlisted";
            else if (item?.application_current_status === 2)
              item.status = "Placed";
            else if (item?.application_current_status === 0)
              item.status = "Applied";
            else if (item?.application_current_status === 3)
              item.status = "Declined";
            else item.status = "";
            item.action = (
              <div>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenAppliedForm(item);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>
                <button
                  onClick={() => {
                    openVideoForm(item, "applied");
                  }}
                  className="button_class"
                >
                  <i className="fas fa-video"></i>
                </button>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Candidate Profile
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    onClick={() => {
                      window.open(
                        "/candidate/" + item?.application_cand_reg_no
                      );
                    }}
                  >
                    <i className="fas fa-user"></i>
                  </button>
                </OverlayTrigger>
                <button
                  onClick={() => {
                    openDeclineAppliedForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-thumbs-down"></i>
                </button>

                <br />
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Shortlist
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    onClick={() => {
                      shortlistApplication(item, search, start, end);
                    }}
                  >
                    <i className="fas fa-clipboard-check"></i>
                  </button>
                </OverlayTrigger>
              </div>
            );
          });
        setAppliedData(result);
        setTotalApplied(res.data.limit);
      });
  };
  const openVideoForm = (item, name) => {
    let candidate_id = "";

    switch (name) {
      case "applied":
        candidate_id = item.application_cand_id;
        break;
      case "shortlisted":
        candidate_id = item.shortlisted_cand_id;
        break;
      case "placed":
        candidate_id = item.placed_cand_id;
        break;
      default:
        break;
    }
    axios
      .get(`${API_URL}candidate/file?candidate_id=` + candidate_id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        if (result?.cand_video1 || result?.cand_video2) {
          if (result?.cand_video1) {
            let vdo = result.cand_video1.split("/");
            let data1 = vdo.slice(-1).pop();
            let data = vdo.at(-2);
            setCandidateVideo1(data);
            setCandidateVideo1Hash(data1);
          } else {
            setCandidateVideo1("");
            setCandidateVideo1Hash("");
          }
          if (result?.cand_video2) {
            let vdo2 = result?.cand_video2.split("/");
            let value2 = vdo2.slice(-1).pop();
            let value = vdo2.at(-2);
            setCandidateVideo2(value);
            setCandidateVideo2Hash(value2);
          } else {
            setCandidateVideo2("");
            setCandidateVideo2Hash("");
          }
          setVideoForm(true);
        } else {
          toastr.error("No video is available");
          setCandidateVideo1("");
          setCandidateVideo2("");
          setCandidateVideo1Hash("");
          setCandidateVideo2Hash("");
        }
      });
  };
  const openDeclineAppliedForm = (item) => {
    if (item?.application_current_status === 3) {
      toastr.error("Application is already declined");
    } else {
      setDeclineAppliedData({
        application_id: item?._id,
      });
      setAppliedErrors({
        appliedErrors: "",
      });
      setSelectedDeclineApplied(null);
      setShowDeclineAppliedForm(true);
    }
  };
  const openDeclinePlacedForm = (item) => {
    if (item?.placed_current_status === 1) {
      toastr.error("Application is already declined");
    } else {
      setDeclinePlacededData({
        placed_id: item?._id,
      });
      setSelectedDeclinePlaced(null);
      setPlacedErrors({
        remarkError: "",
      });
      setShowDeclinePlacedForm(true);
    }
  };
  const openDeclineShortlistedForm = (item) => {
    if (item?.shortlisted_current_status === 2) {
      toastr.error("Application is already declined");
    } else {
      setDeclineShortlistedData({
        shortlist_id: item?._id,
      });
      setShortlistedErrors({
        remarkError: "",
      });
      setSelectedDeclineShortlisted(null);
      setShowDeclineShortlistedForm(true);
    }
  };

  const shortlistApplication = (item, search, start, end) => {
    if (item?.application_current_status === 1) {
      toastr.error("Application is already shortlisted");
    } else if (item?.application_current_status === 2) {
      toastr.error("Application is already placed");
    } else if (item?.application_current_status === 3) {
      toastr.error("Can't shortlist declined application");
    } else {
      let data = {
        application_id: item?._id,
      };
      axios
        .post(`${API_URL}shortlist/`, data, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application is shortlisted");
            fetch_applied(item?.application_job_id, search, start, end);
            // fetch_shortlisted(item?.application_job_id);
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const placeApplication = (item, search = "", start = 0, end = 20) => {
    if (item?.shortlisted_current_status === 1) {
      toastr.error("Application is already placed");
    } else if (item?.shortlisted_current_status === 2) {
      toastr.error("Can't place declined application");
    } else {
      let data = {
        application_id: item?.application_id,
      };
      axios
        .post(`${API_URL}placed/`, data, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application is placed");
            fetch_shortlisted(item?.shortlisted_job_id, search, start, end);
            //fetch_placed(item?.shortlisted_job_id);
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const fetch_saved = (id, search = "", start = 0, end = 20) => {
    var url = `${API_URL}saved/list`;
    url =
      url +
      "?fromDate=" +
      "" +
      "&toDate=" +
      "" +
      "&candidate_id=" +
      "" +
      "&job_id=" +
      id +
      "&institute_id=" +
      "" +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPagesJob(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.saved_date).format("DD-MM-YYYY");

            item.qualificationName = "";
            item.saved_qualification &&
              item.saved_qualification.map((el) => {
                if (item.qualificationName === "")
                  item.qualificationName = el?.qualification_name;
                else
                  item.qualificationName =
                    item.qualificationName + "," + el?.qualification_name;
              });
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenSavedForm(item);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Candidate Profile
                    </Tooltip>
                  }
                >
                  <button
                    onClick={() => {
                      // history.push("/candidate/" + item?.saved_cand_reg_no);
                      window.open("/candidate/" + item?.saved_cand_reg_no);
                    }}
                    className="button_class"
                  >
                    <i className="fas fa-user"></i>
                  </button>
                </OverlayTrigger>
              </div>
            );
          });
        setSavedData(result);
        setTotalSaved(res.data.limit);
      });
  };
  const fetch_shortlisted = (id, search = "", start = 0, end = 20) => {
    var url = `${API_URL}shortlist/list`;
    url =
      url +
      "?from_date=" +
      "" +
      "&to_date=" +
      "" +
      "&candidate_id=" +
      "" +
      "&job_id=" +
      id +
      "&institute_id=" +
      "" +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setTotalPagesShort(res.data.limit);
        var id = start;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.shortlisted_date).format("DD-MM-YYYY");
            // item.interviewdate = moment(
            //   item?.shortlisted_interview_date
            // ).format("DD-MM-YYYY");
            if (item?.shortlisted_current_status === 0)
              item.status = "Shortlisted";
            else if (item?.shortlisted_current_status === 1)
              item.status = "Placed";
            else if (item?.shortlisted_current_status === 2)
              item.status = "Declined";
            else item.status = "";
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenShortlistedForm(item);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>
                <button
                  onClick={() => {
                    openVideoForm(item, "shortlisted");
                  }}
                  className="button_class"
                >
                  <i className="fas fa-video"></i>
                </button>
                <button
                  onClick={() => {
                    openDeclineShortlistedForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-thumbs-down"></i>
                </button>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Place Application
                    </Tooltip>
                  }
                >
                  <button
                    className="button_class"
                    onClick={() => {
                      placeApplication(item, search, start, end);
                    }}
                  >
                    <i className="fas fa-user-check"></i>
                  </button>
                </OverlayTrigger>
              </div>
            );
          });
        setShortlistedData(result);
        // let arr =
        //   result && result.filter((el) => el?.shortlisted_current_status === 0);
        setTotalShortlisted(res.data.short_list_count);
      });
  };
  const fetch_placed = (id, search = "", start = 1, end = 20) => {
    var url = `${API_URL}placed/list`;
    url =
      url +
      "?from_date=" +
      "" +
      "&to_date=" +
      "" +
      "&candidate_id=" +
      "" +
      "&job_id=" +
      id +
      "&institute_id=" +
      "" +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      end;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log(res, "res")
        let result = res.data.data;
        setTotalPagesPlaced(res.data.limit);
        var id = (start - 1) * 20;
        result &&
          result.map((item, index) => {
            id++;
            item.id = id;
            item.date = moment(item?.placed_date).format("DD-MM-YYYY");
            item.joiningdate = moment(item?.joining_date).format("DD-MM-YYYY");
            item.qualificationName = "";
            item.saved_qualification &&
              item.saved_qualification.map((el) => {
                if (item.qualificationName === "")
                  item.qualificationName = el?.qualification_name;
                else
                  item.qualificationName =
                    item.qualificationName + "," + el?.qualification_name;
              });
            if (item?.placed_current_status === 0) item.status = "Placed";
            else if (item?.placed_current_status === 1)
              item.status = "Declined";
            else item.status = "";
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="button_class"
                  onClick={() => {
                    handleClickOpenPlacedForm(item);
                  }}
                >
                  <i className="fas fa-eye"></i>
                </button>
                <button
                  onClick={() => {
                    openVideoForm(item, "placed");
                  }}
                  className="button_class"
                >
                  <i className="fas fa-video"></i>
                </button>
                <button
                  onClick={() => {
                    openDeclinePlacedForm(item);
                  }}
                  className="button_class"
                >
                  <i className="fas fa-thumbs-down"></i>
                </button>
                {item?.offerltr_pdf_link &&
                  item?.placed_current_status === 0 ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                      >
                        Offer Letter
                      </Tooltip>
                    }
                  >
                    <button
                      className="button_class"
                      onClick={() => {
                        downloadOfferletter(item);
                      }}
                    >
                      {" "}
                      <i
                        className="fas fa-file-pdf"
                        style={{ color: "red" }}
                      ></i>
                    </button>
                  </OverlayTrigger>
                ) : !item?.offerltr_pdf_link &&
                  item?.placed_current_status === 0 ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip
                        id="button-tooltip-2"
                        style={{ position: "absolute", top: "-30px" }}
                      >
                        Generate offer letter
                      </Tooltip>
                    }
                  >
                    <button
                      onClick={() => {
                        const queryParams = new URLSearchParams({
                          jobId: item?.placed_job_id,
                          candId: item?.placed_cand_id,
                        }).toString();

                        window.open(
                          `/offerLetter?${queryParams}`,
                          "_blank",
                          "noopener noreferrer"
                        );
                        // history.push("/offerLetter", {
                        //   jobId: item?.placed_job_id,
                        //   candId: item?.placed_cand_id,
                        // });
                      }}
                      className="button_class"
                    >
                      <i className="fas fa-file-invoice"></i>
                    </button>
                  </OverlayTrigger>
                ) : null}
              </div>
            );
          });
        setPlacedData(result);
        // let arr =
        //   result && result.filter((el) => el?.placed_current_status === 0);
        setTotalPlaced(res.data.placed_count);
      });
  };
  const downloadOfferletter = (item) => {
    saveAs(
      `${API_URL}` + item?.offerltr_pdf_link,
      "Indiana Global Teachers.pdf"
    );
  };
  function handleTableData(id) {
    axios
      .get(`${API_URL}job/?job_reg=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        // let instData = result.job_institute_id || {};
        // result.instName = instData?.inst_name;
        result.qualificationName = "";
        result.job_qualification &&
          result.job_qualification.map((el) => {
            if (result.qualificationName === "")
              result.qualificationName = el?.qualification_name;
            else
              result.qualificationName =
                result?.qualificationName + "," + el?.qualification_name;
          });
        let subData = result.job_subject || {};
        result.subjectName = subData?.subject_name;
        let mediumData = result.job_medium || {};
        result.mediumName = mediumData?.name;
        let syllabusData = result.job_syllabus || {};
        result.syllabusName = syllabusData?.name;
        let locationData = result.job_district || {};
        result.locationName = locationData?.district_name;
        let expData = result.job_experience || {};
        result.expName = expData?.name;
        let mngData = result.job_management || {};
        result.management = mngData?.name;
        // let salary = "";
        // if(result?.salary_range_from &&result?.salary_range_to)
        if (result?.salary_range_from && result?.salary_range_to) {
          result.salary =
            result?.salary_range_from + "-" + result?.salary_range_to;
        } else if (result?.salary_range_from && !result?.salary_range_to) {
          result.salary = result?.salary_range_from;
        } else result.salary = "";

        result.provision = "";
        result.job_provision &&
          result.job_provision.map((el) => {
            if (result.provision === "") result.provision = el?.provision_name;
            else
              result.provision = result?.provision + "," + el?.provision_name;
          });

        if (result.job_current_status === 0) result.jobStatus = "Ongoing";
        else if (result.job_current_status === 1) result.jobStatus = "Expired";
        else if (result.job_current_status === 2) result.jobStatus = "Filled";
        else result.jobStatus = "Inactive";

        result.jobDate = result?.job_date
          ? moment(result.job_date).format("DD-MM-YYYY")
          : "";

        let updatedBy = result.status_updated_by || {};
        result.updatedBy = updatedBy?.firstName;
        result.updatedByby = "";
        if (result?.status_updated_at || result?.status_updated_at) {
          let date = moment(result?.status_updated_at).format("DD-MM-YYYY");
          let time = moment(
            result?.status_updated_at,
            "YYYY-MM-DD hhmmss"
          ).format("hh:mm a");
          result.updatedByby =
            "Status has been updated by" +
            " " +
            updatedBy?.firstName +
            " " +
            "on" +
            " " +
            date +
            " " +
            "at" +
            " " +
            time;
        }

        setDetails(result);
        fetch_applied(result?._id, searchValueApp);
        fetch_saved(result?._id, searchValueJob);
        fetch_shortlisted(result?._id, searchValueShort);
        fetch_placed(result?._id, searchValuePlaced);
      });
  }
  const handleClickOpenPlacedForm = (item) => {
    axios
      .get(`${API_URL}placed/view?placed_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.joiningdate = moment(result?.joining_date).format("DD-MM-YYYY");
        result.date = moment(result?.placed_date).format("DD-MM-YYYY");
        result.time = moment(result?.placed_time, "hhmmss").format("hh:mm a");
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupPlacedData(result);
        setPopupPlacedView(true);
      });
  };
  const handleClickOpenShortlistedForm = (item) => {
    axios
      .get(`${API_URL}shortlist/view?shortlisted_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.date = moment(result?.shortlisted_date).format("DD-MM-YYYY");
        result.time = moment(result?.shortlisted_time, "hhmmss").format(
          "hh:mm a"
        );
        // result.interviewdate = moment(
        //   result?.shortlisted_interview_date
        // ).format("DD-MM-YYYY");
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupShortlistedData(result);
        setPopupShortlistedView(true);
      });
  };
  const handleClickOpenAppliedForm = (item) => {
    axios
      .get(`${API_URL}application?application_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.dob = moment(result?.application_cand_dob).format("DD-MM-YYYY");
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.class = "";
        jobDetails.job_classes &&
          jobDetails.job_classes.map((el) => {
            if (result.class === "") result.class = el;
            else result.class = result.class + "," + el;
          });
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupAppliedData(result);
        setPopupAppliedView(true);
      });
  };
  const handleClickOpenSavedForm = (item) => {
    axios
      .get(`${API_URL}saved?saved_id=` + item?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.dob = moment(result?.saved_cand_dob).format("DD-MM-YYYY");
        result.date = moment(result?.saved_date).format("DD-MM-YYYY");
        result.time = moment(result?.saved_time, "hhmmss").format("hh:mm a");
        let jobDetails = result.job_details || {};
        result.district = jobDetails?.job_district;
        result.state = jobDetails?.job_state;
        result.subject = jobDetails?.job_subject;
        result.exp = jobDetails?.job_experience;
        result.noOfOpenings = jobDetails?.no_of_openings;
        result.class = "";
        jobDetails.job_classes &&
          jobDetails.job_classes.map((el) => {
            if (result.class === "") result.class = el;
            else result.class = result.class + "," + el;
          });
        result.qualification = "";
        jobDetails.job_qualification &&
          jobDetails.job_qualification.map((el) => {
            if (result.qualification === "") result.qualification = el;
            else result.qualification = result.qualification + "," + el;
          });
        setPopupSavedData(result);
        setPopupSavedView(true);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reg No",
        field: "application_cand_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "application_cand_phone",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Qualification",
      //   field: "qualificationName",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Experience",
      //   field: "application_experience",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: appliedData,
  };
  const saved = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Reg No",
        field: "saved_reg_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "saved_cand_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mobile",
        field: "saved_cand_phone",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Qualification",
      //   field: "qualificationName",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Experience",
      //   field: "saved_experience",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Location",
      //   field: "saved_district",
      //   sort: "asc",
      //   width: 200,
      // },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: savedData,
  };

  const shortlisted = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "shortlisted_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "shortlisted_cand_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "shortlisted_cand_mob",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Experience",
      //   field: "shortlisted_cand_experience",
      //   sort: "asc",
      //   width: 200,
      // },
      //   {
      //     label: "Expected CTC",
      //     field: "expCtc",
      //     sort: "asc",
      //     width: 200,
      //   },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: shortlistedData,
  };

  const placed = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "placed_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "placed_cand_name",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Institute",
      //   field: "placed_inst_name",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Experience",
      //   field: "placed_cand_experience",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Mobile",
        field: "placed_cand_mob",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: placedData,
  };
  const jobpostStatus = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 400,
      },
      {
        label: "Staff",
        field: "user_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Prev Status",
        field: "prevStatus",
        sort: "asc",
        width: 200,
      },
      {
        label: "Current Status",
        field: "currentStatus",
        sort: "asc",
        width: 200,
      },
    ],
    rows: jobStatusLogData,
  };

  function createData1(si, date, receiptno, amount, dueamount, staff) {
    return { si, date, receiptno, amount, dueamount, staff };
  }

  const rows2 = [
    createData1(1, "24 - 11 - 2021", "RE2111000004", "50/-", "50/-", "Prabija"),
    createData1(
      2,
      "24 - 11 - 2021",
      "RE2111000005",
      "400/-",
      "-350/-",
      "Prabija"
    ),
  ];

  function createData3(
    si,
    date,
    time,
    referenceNo,
    customerId,
    customerName,
    amount,
    comment,
    due,
    staff
  ) {
    return {
      si,
      date,
      time,
      referenceNo,
      customerId,
      customerName,
      amount,
      comment,
      due,
      staff,
    };
  }

  const rows3 = [
    createData3(
      1,
      "26-11-2020",
      "11:11:24",
      123,
      123,
      "admin",
      700,
      "abc",
      100,
      "srv"
    ),
  ];

  const [tab3, setTab3] = React.useState("5");

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);
    resetPaginateJob();
    setSearchValueJob("");
    fetch_saved(details?._id);

    resetPaginateApp();
    fetch_applied(details?._id);
    setSearchValueApp("");

    resetPaginateShort();
    fetch_shortlisted(details?._id);
    setSearchValueShort("");

    resetPaginatePlaced();
    fetch_placed(details?._id);
    setSearchValuePlaced("");

    resetPaginateRemarks();
    fetch_job_activity(jobId);
    setSearchValueActivity("");
  };

  const [date, setDate] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };

  function createData4(
    si,
    date,
    time,
    customerName,
    type,
    invoiceNo,
    debit,
    credit,
    balance
  ) {
    return {
      si,
      date,
      time,
      customerName,
      type,
      invoiceNo,
      debit,
      credit,
      balance,
    };
  }

  const rows4 = [
    createData4(
      1,
      "26-11-2020",
      "11:11:24",
      "admin",
      "Invoice",
      "SRV123",
      700,
      100,
      100
    ),
  ];

  const userCall = () => {
    let data = {
      customer_no: 0 + details?.mobile,
      agent_no: 0 + agentNumber,
      localbody: currentLocalbody,
    };
    axios
      .post(`${API_URL}api/staff/customer_call`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        let result = response.data;
        if (result && result.success === true) {
          toastr.success("Call Send successfully");
        } else {
          toastr.error(result.message);
        }
      });
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "appliedReason":
        setSelectedDeclineApplied(selected);
        setDeclineAppliedData({
          ...declineAppliedData,
          decline_remark: selected.label,
        });
        setAppliedErrors({
          remarkError: "",
        });
        break;
      case "shortlistedReason":
        setSelectedDeclineShortlisted(selected);
        setDeclineShortlistedData({
          ...declineShortlistedData,
          decline_remark: selected.label,
        });
        setShortlistedErrors({
          remarkError: "",
        });
        break;
      case "placedReason":
        setSelectedDeclinePlaced(selected);
        setDeclinePlacededData({
          ...declinePlacedData,
          decline_remark: selected.label,
        });
        setPlacedErrors({
          remarkError: "",
        });
        break;
      default:
        break;
    }
  };
  const submitDeclineAppliedForm = () => {
    if (selectedDeclineApplied === null) {
      setAppliedErrors({
        remarkError: "Please select the reason",
      });
    } else {
      axios
        .put(
          `${API_URL}application/decline?applied_id=` +
          declineAppliedData?.application_id,
          declineAppliedData,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application declined successfully");
            fetch_applied(
              details?._id,
              searchValueApp,
              indexOfFirstTransfer2,
              indexOfLastTransfer2
            );
            setDeclineAppliedData({});
            setSelectedDeclineApplied(null);
            setShowDeclineAppliedForm(false);
          } else {
            toastr.error("Failed to decline application");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const submitDeclineShortlistedForm = () => {
    if (selectedDeclineShortlisted === null) {
      setShortlistedErrors({
        remarkError: "Please select the reason",
      });
    } else {
      axios
        .put(
          `${API_URL}shortlist/decline?shortlist_id=` +
          declineShortlistedData?.shortlist_id,
          declineShortlistedData,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application declined successfully");
            setSelectedDeclineShortlisted(null);
            fetch_shortlisted(
              details?._id,
              searchValueShort,
              indexOfFirstTransfer3,
              indexOfLastTransfer3
            );
            setDeclineShortlistedData({});
            setShowDeclineShortlistedForm(false);
          } else {
            toastr.error("Failed to decline application");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };
  const submitDeclinePlacedForm = () => {
    if (selectedDeclinePlaced === null) {
      setPlacedErrors({
        remarkError: "Please select the reason",
      });
    } else {
      axios
        .put(
          `${API_URL}placed/decline?placed_id=` + declinePlacedData?.placed_id,
          declinePlacedData,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Application declined successfully");
            setSelectedDeclinePlaced(null);
            fetch_placed(details?._id);
            setDeclinePlacededData({});
            setShowDeclinePlacedForm(false);
          } else {
            toastr.error("Failed to decline application");
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };

  const [tableContacted, settableContacted] = useState([]);
  const [tableAppointed, settableAppointed] = useState([]);

  useEffect(() => {
    fetchAllContacted();
    fetchAllAppointed();
  }, []);

  function fetchAllContacted() {
    axios
      .post(
        `${API_URL}appointment/contactedjob`,
        {
          job_id: jobId,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        let contactedData = [];
        result.map((item, index) => {
          item.id = index + 1;
          item.date1 = moment(item.cand_date).format("DD-MM-YYYY");
          item.name1 = (
            <div>
              <a href={"/candidate/" + item.cand_reg_no} target="_blank">
                {item.cand_name}
              </a>
            </div>
          );
          contactedData.push(item);
        });
        settableContacted(contactedData);
      });
  }

  function fetchAllAppointed() {
    axios
      .post(
        `${API_URL}appointment/appointedjob`,
        {
          job_id: jobId,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res?.data?.data;
        let appointedData = [];
        result?.map((item, index) => {
          item.id = index + 1;
          item.date1 = moment(item.cand_date).format("DD-MM-YYYY");
          item.name1 = (
            <div>
              <a href={"/candidate/" + item.cand_reg_no} target="_blank">
                {item.cand_name}
              </a>
            </div>
          );
          appointedData.push(item);
        });
        settableAppointed(appointedData);
      });
  }

  const contacted = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "cand_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "name1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "cand_mob",
        sort: "asc",
        width: 200,
      },
    ],
    rows: tableContacted,
  };

  const appointed = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Reg No",
        field: "cand_reg_no",
        sort: "asc",
        width: 400,
      },
      {
        label: "Name",
        field: "name1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "cand_mob",
        sort: "asc",
        width: 200,
      },
    ],
    rows: tableAppointed,
  };

  return (
    <React.Fragment>
      <Modal show={videoForm} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Candidate Videos</h5>
          <button
            type="button"
            onClick={() => {
              setVideoForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <Row>
              <Col xl={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Intro video</CardTitle>
                    <p className="card-title-desc"></p>

                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                      {candidateVideo1 && (
                        <iframe
                          title="test"
                          className="embed-responsive-item"
                          src={`https://player.vimeo.com/video/${candidateVideo1}?h=${candidateVideo1Hash}`}
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Teaching video</CardTitle>
                    <p className="card-title-desc"></p>

                    <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                      {candidateVideo2 && (
                        <iframe
                          title="test"
                          className="embed-responsive-item"
                          src={`https://player.vimeo.com/video/${candidateVideo2}?h=${candidateVideo2Hash}`}
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={showDeclineAppliedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineAppliedForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            submitDeclineAppliedForm();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    value={selectedDeclineApplied}
                    onChange={(selected) => {
                      handleSelectChange(selected, "appliedReason");
                    }}
                    options={ReasonsOption}
                    classNamePrefix="select2-selection"
                  />
                  <p className="text-danger" style={{ fontSize: "11px" }}>
                    {selectedDeclineApplied === null
                      ? appliedErrors.remarkError
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={showDeclineShortlistedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclineShortlistedForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            submitDeclineShortlistedForm();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    value={selectedDeclineShortlisted}
                    onChange={(selected) => {
                      handleSelectChange(selected, "shortlistedReason");
                    }}
                    options={ReasonsOption}
                    classNamePrefix="select2-selection"
                  />
                  <p className="text-danger" style={{ fontSize: "11px" }}>
                    {selectedDeclineShortlisted === null
                      ? shortlistedErrors.remarkError
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={showDeclinePlacedForm} size="md" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Decline Candidate</h5>
          <button
            type="button"
            onClick={() => {
              setShowDeclinePlacedForm(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            submitDeclinePlacedForm();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label>Reason</Label>
                  <Select
                    name="decline_remark"
                    value={selectedDeclinePlaced}
                    onChange={(selected) => {
                      handleSelectChange(selected, "placedReason");
                    }}
                    options={ReasonsOption}
                    classNamePrefix="select2-selection"
                  />
                  <p className="text-danger" style={{ fontSize: "11px" }}>
                    {selectedDeclinePlaced === null
                      ? placedErrors.remarkError
                      : ""}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="row mb-0">
              <Col className="col-12 text-end">
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupShortlistedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Shortlisted Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupShortlistedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupShortlistedData?.shortlisted_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Application ID :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.job_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Shortlisted Date :</td>
                <td>{popupShortlistedData?.date}</td>
                <td style={{ paddingLeft: "25px" }}>Shortlisted Time :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.time}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupShortlistedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}>
                  {popupShortlistedData?.shortlisted_inst_name}
                </td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}>
                  {popupShortlistedData?.shortlisted_cand_name}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td>{popupShortlistedData?.exp}</td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupShortlistedData?.qualification}
                </td>
              </tr>

              <tr
                hidden={popupShortlistedData?.shortlisted_current_status !== 2}
              >
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupShortlistedData?.decline_remark}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupPlacedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Placed Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupPlacedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupPlacedData?.placed_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}>
                  {popupPlacedData?.job_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Placed Date :</td>
                <td>{popupPlacedData?.date}</td>
                <td style={{ paddingLeft: "25px" }}>Placed Time :</td>
                <td style={{ textAlign: "left" }}>{popupPlacedData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupPlacedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupPlacedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Institute :</td>
                <td style={{ width: "200px", wordBreak: "break-all" }}>
                  {popupPlacedData?.placed_inst_name}
                </td>
                <td style={{ paddingLeft: "25px" }}>Candidate :</td>
                <td style={{ textAlign: "left" }}>
                  {popupPlacedData?.placed_cand_name}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Experience :</td>
                <td>{popupPlacedData?.exp}</td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupPlacedData?.qualification}
                </td>
              </tr>
              <tr hidden={popupPlacedData?.placed_current_status !== 1}>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupPlacedData?.decline_remark}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupSavedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Saved Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupSavedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupSavedData?.saved_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Reg No :</td>
                <td style={{ textAlign: "left" }}>
                  {popupSavedData?.saved_job_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Saved Date :</td>
                <td>{popupSavedData?.date}</td>
                <td style={{ paddingLeft: "25px" }}>Saved Time :</td>
                <td style={{ textAlign: "left" }}>{popupSavedData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupSavedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupSavedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Subject :</td>
                <td>{popupSavedData?.subject}</td>
                <td style={{ paddingLeft: "25px" }}>Experience :</td>
                <td style={{ textAlign: "left" }}>{popupSavedData?.exp}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Applicant :</td>
                <td>{popupSavedData?.saved_cand_name}</td>
                <td style={{ paddingLeft: "25px" }}>No of openings :</td>
                <td style={{ textAlign: "left" }}>
                  {popupSavedData?.noOfOpenings}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Class :</td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}>
                  {popupSavedData?.class}
                </td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupSavedData?.qualification}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <Modal show={popupAppliedView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Application Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupAppliedView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Application Name :
                </td>
                <td>{popupAppliedData?.application_job_name}</td>
                <td style={{ paddingLeft: "25px" }}>Application ID :</td>
                <td style={{ textAlign: "left" }}>
                  {popupAppliedData?.application_reg_no}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>State :</td>
                <td>{popupAppliedData?.state}</td>
                <td style={{ paddingLeft: "25px" }}>District :</td>
                <td style={{ textAlign: "left" }}>
                  {popupAppliedData?.district}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Subject :</td>
                <td>{popupAppliedData?.subject}</td>
                <td style={{ paddingLeft: "25px" }}>Experience :</td>
                <td style={{ textAlign: "left" }}>{popupAppliedData?.exp}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Applicant :</td>
                <td>{popupAppliedData?.application_cand_name}</td>
                <td style={{ paddingLeft: "25px" }}>Mobile :</td>
                <td style={{ textAlign: "left" }}>
                  {popupAppliedData?.application_cand_phone}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Class :</td>
                <td style={{ width: "191.67px", wordBreak: "break-all" }}>
                  {popupAppliedData?.class}
                </td>
                <td style={{ paddingLeft: "25px" }}>qualification :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupAppliedData?.qualification}
                </td>
              </tr>
              <tr hidden={popupAppliedData?.application_current_status !== 3}>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{"Declined"}</td>
                <td style={{ paddingLeft: "25px" }}>Remarks :</td>
                <td
                  style={{
                    textAlign: "left",
                    width: "189.97px",
                    wordBreak: "break-all",
                  }}
                >
                  {popupAppliedData?.decline_remark}
                </td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem={"Job Post" + " " + "-" + " " + details?.job_reg_no}
          />
          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown">
                          <a
                            aria-haspopup="true"
                            className="text-body font-size-16 ddropdown-toggle"
                            aria-expanded="false"
                          ></a>
                          <div
                            tabindex="-1"
                            role="menu"
                            aria-hidden="true"
                            className="dropdown-menu-end dropdown-menu"
                          >
                            <a
                              to="/"
                              tabindex="0"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Edit
                            </a>
                            <a
                              to="/"
                              tabindex="1"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Action
                            </a>
                            <a
                              to="/"
                              tabindex="2"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Remove
                            </a>
                          </div>
                        </div>

                        <h5
                          className="mt-2 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {details?.job_name}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              class="btn btn-success"
                              style={{
                                borderRadius: "40px",
                                marginRight: "10px",
                                padding: "0",
                                width: "28px",
                                cursor: "default",
                              }}
                              hidden={!details?.whatsapp_no}
                            >
                              <i
                                className="uil-phone-alt"
                                style={{ fontSize: "15px" }}
                              />
                            </button>
                            {/* </OverlayTrigger> */}
                          </div>
                          {details?.whatsapp_no}
                        </div>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        >
                          {details?.jobStatus}
                        </p>

                        <Divider />
                        <div className="mt-2" hidden={!details?.jobDate}>
                          <p className="mb-1">Date:</p>
                          <h5 className="font-size-14">{details?.jobDate}</h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Institute:</p>
                          <h5 className="font-size-14">
                            {details?.job_institute_name}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Management:</p>
                          <h5 className="font-size-14">
                            {details?.management}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Qualification:</p>

                          <h5 className="font-size-14">
                            {details?.qualificationName}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Subject:</p>
                          <h5 className="font-size-14">
                            {details?.subjectName}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Medium:</p>
                          <h5 className="font-size-14">
                            {details?.mediumName}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Syllabus:</p>
                          <h5 className="font-size-14">
                            {details?.syllabusName}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Location:</p>
                          <h5 className="font-size-14">
                            {details?.locationName}
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Experience:</p>
                          <h5 className="font-size-14">{details?.expName}</h5>
                        </div>
                        <div className="mt-2" hidden={!details?.salary}>
                          <p className="mb-1">Salary:</p>
                          <h5 className="font-size-14">{details?.salary}</h5>
                        </div>
                        <div className="mt-2" hidden={!details?.provision}>
                          <p className="mb-1">Provisions:</p>
                          <h5 className="font-size-14">{details?.provision}</h5>
                        </div>
                        <div className="mt-2" hidden={!details?.description}>
                          <p className="mb-1">Job Description:</p>
                          <h5 className="font-size-14">
                            {details?.description}
                          </h5>
                        </div>
                        <div className="mt-2" hidden={!details?.updatedByby}>
                          <p className="mb-1">Status Summary:</p>
                          <h5 className="font-size-14">
                            {details?.updatedByby}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <SummarizeIcon style={{ fontSize: "20px" }} />
                                }
                                label="Summary"
                                value="5"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  //   <img
                                  //     src={applied}
                                  //     style={{ height: "20px" }}
                                  //   />
                                  <AssignmentIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Applied"
                                value="1"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <BookmarkIcon style={{ fontSize: "20px" }} />
                                }
                                label="Saved"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <FactCheckIcon style={{ fontSize: "20px" }} />
                                }
                                label="Shortlisted"
                                value="3"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <BallotIcon style={{ fontSize: "20px" }} />
                                }
                                label="Placed"
                                value="4"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <ContactPhoneIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="contacted"
                                value="6"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <Work
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Appointed"
                                value="7"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>

                          <TabPanel
                            value="7"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif ",
                            }}
                          >
                            <Row>
                              <Col md="12">
                                <MDBDataTable
                                  id="PlacedTableId"
                                  responsive
                                  bordered
                                  data={appointed}
                                  searching={true}
                                />
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="6"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md="12">
                                <MDBDataTable
                                  id="contactedTableId"
                                  responsive
                                  bordered
                                  data={contacted}
                                  searching={true}
                                />
                              </Col>
                            </Row>
                          </TabPanel>

                          <TabPanel
                            value="1"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="appliedDataId"
                                  responsive
                                  bordered
                                  data={data}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchApp(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesApp > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer2 + 1} to{" "}
                                      {indexOfFirstTransfer2 +
                                        appliedData.length}{" "}
                                      of {totalPagesApp} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesApp / PerPageCount
                                      )}
                                      page={currentPageApp}
                                      onChange={paginateApp}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="2"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="savedJobDataId"
                                  responsive
                                  bordered
                                  data={saved}
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchJob(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesJob > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer1 + 1} to{" "}
                                      {indexOfFirstTransfer1 + savedData.length}{" "}
                                      of {totalPagesJob} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesJob / PerPageCount
                                      )}
                                      page={currentPageJob}
                                      onChange={paginateJob}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="3"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="shortlistedJobDataId"
                                  data={shortlisted}
                                  responsive
                                  bordered
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchShort(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesShort > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer3 + 1} to{" "}
                                      {indexOfFirstTransfer3 +
                                        shortlistedData.length}{" "}
                                      of {totalPagesShort} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesShort / PerPageCount
                                      )}
                                      page={currentPageShort}
                                      onChange={paginateShort}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="4"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <MDBDataTable
                                  id="placedJobTableID"
                                  data={placed}
                                  responsive
                                  bordered
                                  searching={true}
                                  info={false}
                                  disableRetreatAfterSorting={true}
                                  paging={false}
                                  onSearch={(value) => {
                                    handleSearchPlaced(value);
                                  }}
                                  entries={PerPageCount}
                                />
                                {totalPagesPlaced > 20 && (
                                  <div className="pagination-div">
                                    <div className="page-details">
                                      Showing {indexOfFirstTransfer4 + 1} to{" "}
                                      {indexOfFirstTransfer4 +
                                        placedData.length}{" "}
                                      of {totalPagesPlaced} entries
                                    </div>
                                    <Pagination
                                      color="standard"
                                      shape="rounded"
                                      defaultPage={1}
                                      count={Math.ceil(
                                        totalPagesPlaced / PerPageCount
                                      )}
                                      page={currentPagePlaced}
                                      onChange={paginatePlaced}
                                      size="large"
                                    />
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel
                            value="5"
                            style={{
                              fontSize: "13px",
                              fontFamily: "IBM Plex Sans,sans-serif",
                            }}
                          >
                            <Row>
                              <Col md="12">
                                <Card>
                                  <CardBody>
                                    <table
                                      id="product_available_day"
                                      className="table table-bordered dataTable"
                                    >
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            width: "184px",
                                          }}
                                        >
                                          Total no of Openings:
                                        </td>
                                        <td>{details?.no_of_openings}</td>
                                        <td
                                          style={{
                                            paddingLeft: "25px",
                                            width: "184px",
                                          }}
                                        >
                                          Total Applied:
                                        </td>
                                        <td>{totalApplied}</td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            width: "184px",
                                          }}
                                        >
                                          Total Saved:
                                        </td>
                                        <td>{totalSaved}</td>
                                        <td
                                          style={{
                                            paddingLeft: "25px",
                                            width: "184px",
                                          }}
                                        >
                                          Total Shortlisted :
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                          {totalShortlisted}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            padding: "10px",
                                            width: "184px",
                                          }}
                                        >
                                          Total Placed:
                                        </td>
                                        <td>{totalPlaced}</td>
                                        <td
                                          style={{
                                            paddingLeft: "25px",
                                            width: "184px",
                                          }}
                                        ></td>
                                        <td style={{ textAlign: "left" }}>
                                          { }
                                        </td>
                                      </tr>
                                    </table>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardHeader style={{ fontSize: "18px" }}>
                                    Job post status update log
                                  </CardHeader>
                                  <CardBody>
                                    <MDBDataTable
                                      id="jobpostStatusLogTable"
                                      responsive
                                      bordered
                                      data={jobpostStatus}
                                      searching={true}
                                      info={false}
                                      disableRetreatAfterSorting={true}
                                      paging={false}
                                      onSearch={(value) => {
                                        handleSearchActivity(value);
                                      }}
                                      entries={PerPageCount}
                                    />
                                    {totalPagesRemarks > 20 && (
                                      <div className="pagination-div">
                                        <div className="page-details">
                                          Showing {indexOfFirstTransfer5 + 1} to{" "}
                                          {indexOfFirstTransfer5 +
                                            jobStatusLogData.length}{" "}
                                          of {totalPagesRemarks} entries
                                        </div>
                                        <Pagination
                                          color="standard"
                                          shape="rounded"
                                          defaultPage={1}
                                          count={Math.ceil(
                                            totalPagesRemarks / PerPageCount
                                          )}
                                          page={currentPageReamrks}
                                          onChange={paginateRemarks}
                                          size="large"
                                        />
                                      </div>
                                    )}
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(
  connect(mapStateToProps, { apiError })(JobpostDetails)
);

// StaffDetails.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
