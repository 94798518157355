import React, { useState, useEffect, useRef } from "react";
import "./Package.scss";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Switch from "@mui/material/Switch";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import toastr, { error } from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { Modal } from "react-bootstrap";


function Package() {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [masterObj, setmasterObj] = useState({});
  const [confirmDelete, setconfirmDelete] = useState(false);
  const [DataTobeDeleted, setDataTobeDeleted] = useState(null);
  const [packageTable, setpackageTable] = useState([]);
  const [packageIdToBeUpdated, setpackageIdToBeUpdated] = useState(null);
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [selectPackageType, setselectPackageType] = useState(null);
  const [popupView, setPopupView] = useState(false)
  const [dispalay, setdispalay] = useState(null);
  const [packDet, setpackDet] = useState(null)
  const [basicDetails, setBasicDetails] = useState(null)
  const [packDetails, setPackDetails] = useState([])
  const [errorsV, setErrorsV] = useState({
    packageError: "",
  });
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [instituteOptions, setInstituteOptions] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    handleTabledata();
  }, []);

  const OnTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    packBasic(packDet);
  }, []);

  const fetch_all_institute = (id) => {
    axios
      .get(`${API_URL}lists/selectInst?package_id=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let institute_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.inst_name,
              value: el?._id,
            };
          });
        setInstituteOptions([
          {
            options: institute_data,
          },
        ]);
      });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      {
        label: "ID",
        field: "packageId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "packageName",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Type",
      //   field: "type",
      //   width: 150,
      // },
      {
        label: "Resume/job/service",
        field: "noOf",
        sort: "asc",
        width: 150,
      },
      {
        label: "Validity",
        field: "validity",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "GST",
        field: "gst",
        sort: "asc",
        width: 150,
      },
      {
        label: "Convenience Fee",
        field: "convenience_fee",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total",
        field: "total",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: packageTable,
  };

  let preupdatePackage = (item) => {
    setpackageIdToBeUpdated(item._id);
    setmasterObj(item);

    if (item.packageType == 0) {
      setselectPackageType({ label: "Find Resume", value: 0 });
      setdispalay(0);
    }

    if (item.packageType == 1) {
      setselectPackageType({ label: "Job Post ", value: 1 });
      setdispalay(1);
    }

    if (item.packageType == 2) {
      setselectPackageType({ label: "Recruit with Support", value: 2 });
      setdispalay(2);
    }
  };

  function handleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObj({ ...masterObj, [name]: value });
  }

  function calculateConv() {
    let convenience = masterObj?.amount
      ? (masterObj.amount * (1.85 / 100)).toFixed(0)
      : "";
    let gstamount = masterObj?.amount
      ? (masterObj.amount * (18 / 100)).toFixed(0)
      : "";
    let amount = parseFloat(masterObj?.amount);
    convenience = parseFloat(convenience);
    gstamount = parseFloat(gstamount);
    let total = amount + convenience + gstamount;
    setmasterObj({
      ...masterObj,
      conveniencefee: convenience,
      gst: gstamount,
      total: total,
    });
  }

  useEffect(() => {
    calculateConv();
  }, [masterObj?.amount]);

  function toggleActiveStatus(id, status) {
    if (status == 1) {
      const newStatus = 0;
      axios
        .put(
          `${API_URL}package/toggle-status?id=${id}&newStatus=${newStatus}`,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          handleTabledata();
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
          handleTabledata();
        });
    } else if (status == 0) {
      const newStatus = 1;
      axios
        .put(
          `${API_URL}package/toggle-status?id=${id}&newStatus=${newStatus}`,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          handleTabledata();
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
        });
    }
  }

  function handleTabledata() {
    axios
      .get(`${API_URL}package`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            const isStatusChecked = item.packageStatus === 0;
            item.status1 = (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="form-check form-switch"
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`customSwitchsizesm${index}`}
                  checked={isStatusChecked}
                  onChange={(e) => {
                    toggleActiveStatus(item._id, item?.packageStatus);
                    settoggleSwitch(!toggleSwitch);
                  }}
                />
              </div>
            );
            item.id = index + 1;
            item.date1 = moment(item?.date).format("DD-MM-YYYY");
            if (item.noOfResume) {
              item.noOf = item.noOfResume;
            }
            if (item.noOfJobPort) {
              item.noOf = item.noOfJobPort;
            }
            if (item.noOfServices) {
              item.noOf = item.noOfServices;
            }

            if (item.packageType == 0) {
              item.type = "Resume Wise";
            }
            if (item.packageType == 1) {
              item.type = "Job post wise";
            }
            if (item.packageType == 2) {
              item.type = "Service base";
            }

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1rem",
                    cursor: "pointer",
                    marginLeft: "0.4rem",
                    marginRight: "0.4rem", marginTop: "0.4rem"
                  }}
                  onClick={() => {
                    setPopupView(true)
                    fetch_all_institute(item?._id);
                    setpackDet(item?._id)
                  }}
                ></i> */}
                <i
                  className="uil-edit-alt"
                  style={{
                    fontSize: "1rem",
                    cursor: "pointer",
                    marginLeft: "0.4rem",
                    marginRight: "0.4rem",
                  }}
                  onClick={() => {
                    OnTop();
                    preupdatePackage(item);
                  }}
                ></i>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                  onClick={() => {
                    setDataTobeDeleted(item._id);
                    setconfirmDelete(true);
                  }}
                ></i>
              </div>
            );
          });

        setpackageTable(result);
      });
  }


  function handleValidSubmit(e, v) {
    e.preventDefault();

    if (selectPackageType === null) {
      let err = errorsV;
      if (selectPackageType === null)
        err.packageError = "Please select package Type";
      setErrorsV({ ...err });
    } else {
      if (packageIdToBeUpdated) {
        setIsSubmitting(false);
        axios
          .put(`${API_URL}package`, masterObj, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              setIsSubmitting(false);
              toastr.success("package updated successfully");
              handleTabledata();
              setmasterObj({});
              setdispalay(null);
              setErrorsV({});
              setselectPackageType(null);
              setpackageIdToBeUpdated(null);
              formRef.current.reset();
            }
          })
          .catch((err) => {
            setIsSubmitting(false);
            toastr.error(err.response.data.message);
          });
      } else {
        setIsSubmitting(true);

        axios
          .post(`${API_URL}package`, masterObj, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              setIsSubmitting(false);
              toastr.success("Package added successfully");
              formRef.current.reset();
              reset();
              handleTabledata();
              setmasterObj({});
              setdispalay(null);
              setErrorsV({});
            } else {
              toastr.error("Failed to add");
            }
          })
          .catch((error) => {
            setIsSubmitting(false);
            toastr.error(error.response.data.message);
          });
      }
    }
  }

  function reset() {
    formRef.current.reset();
    setmasterObj({});
    setpackageIdToBeUpdated(null);
    setselectPackageType(null);
    setdispalay(null);
  }

  function selectChange(selected, name) {
    switch (name) {
      case "pacakgetype":
        setselectPackageType(selected);
        setdispalay(selected.value);
        setmasterObj({ ...masterObj, packageType: selected.value });
        setErrorsV({ ...errorsV, packageError: "" });
        break;

      case "institute":
        setSelectedInstitute(selected)
        setmasterObj({ ...masterObj, institute: selected.value })
        packBasic(selected.value)
        break;

      default:
        break;
    }
  }

  const packageData = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Purchase Date",
        field: "subscription_date",
        width: 50,
      },
      {
        label: "Expiry Date",
        field: "expiry",
        width: 50,
      },
      {
        label: "Validity",
        field: "validity",
        width: 50,
      },
      {
        label: "Total",
        field: "total_quota",
        width: 50,
      },
      {
        label: "Consumed",
        field: "used_quota",
        width: 50,
      },
      {
        label: "Balanced",
        field: "balance_quota",
        width: 50,
      },
      {
        label: "Total Amount",
        field: "subtotal",
        width: 50,
      },
    ],
    rows: packDetails
  }

  function packBasic(id) {
    axios
      .get(`${API_URL}lists/usedsub?package_id=${packDet}&inst_id=${id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let Data = [];
        let result = res.data.data
        result.map((item) => {

          item.package.map((el, indx) => {

            el.id = indx + 1

            Data.push(el)
          })
          setBasicDetails(item)

        })
        setPackDetails(Data)
      })
  }


  return (
    <React.Fragment>
      {confirmDelete ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onCancel={() => setconfirmDelete(false)}
          onConfirm={() => {
            axios
              .delete(`${API_URL}package?id=${DataTobeDeleted}`, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.status === 200) {
                  toastr.success("Package Deleted Successfully");
                  handleTabledata();
                  setconfirmDelete(false);
                  setmasterObj({});
                  setdispalay(null);
                  setErrorsV({});
                  setpackageIdToBeUpdated(null);
                  formRef.current.reset();
                  setselectPackageType(null);
                } else {
                  toastr.error("Failed to delete");
                }
              })
              .catch((error) => {
                toastr.error(error?.response?.message);
              });
          }}
        >
          Are you sure want to delete it?
        </SweetAlert>
      ) : null}

      <Modal
        show={popupView}
        // toggle={handleCloseModal}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Package Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
              setSelectedInstitute(null)
              setBasicDetails(null)
              setPackDetails([])
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm
          className="needs-validation"
        >

          <Row>
            <Col className="col-12">
              <AvForm>
                <Row style={{ paddingLeft: "15px" }}>
                  <Col md="4">
                    <div className="mb-3">
                      <Label>Institute</Label>
                      <Select
                        name="institute"
                        value={selectedInstitute}
                        onChange={(selected) => {
                          selectChange(selected, "institute");
                        }}
                        options={instituteOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                </Row>

                <div className="modal-body">
                  <h5>Basic Details</h5>
                  <table id="product_available_day"
                    className="table table-bordered dataTable"
                  >
                    <tr>
                      <td style={{ padding: "10px", width: '184px' }}>Reg No. :</td>
                      <td style={{ width: "200px", wordBreak: "break-all" }}>{basicDetails?.inst_reg_no ? (
                        <a style={{ cursor: "pointer" }} target="blank"
                          href={"/institute/" + basicDetails?.inst_reg_no}
                        >{basicDetails?.inst_reg_no}</a>
                      ) : "No Data"}</td>
                      <td style={{ paddingLeft: "25px" }}>Mobile :</td>
                      <td style={{ textAlign: "left" }}>{basicDetails?.inst_phone ? basicDetails?.inst_phone : "No Data"}</td>
                    </tr>
                    <tr>
                      <td style={{ padding: "10px" }} >Address :</td>
                      <td>{basicDetails?.inst_address ? basicDetails?.inst_address : "No Data"}</td>
                    </tr>
                  </table>
                </div>

                <div className="modal-body">
                  <h5>Package Details</h5>

                  <Row>
                    <Col xl="12" className="mt-4">
                      <MDBDataTable
                        id="packageTableId"
                        responsive
                        bordered
                        info={true}
                        data={packageData}
                      />
                    </Col>
                  </Row>
                </div>
              </AvForm>
            </Col>
          </Row>

        </AvForm>

      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Add Package" />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                    ref={formRef}
                    className="needs-validation"
                  >

                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Package Name</Label>
                          <AvField
                            name="packageName"
                            placeholder="Package Name"
                            onChange={(e) => handleChange(e)}
                            value={masterObj?.packageName}
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Package Type</Label>
                          <Select
                            name="institute"
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "Find Resume", value: 0 },
                              { label: "Job Post ", value: 1 },
                              { label: "Recruit with Support", value: 2 },
                            ]}
                            value={selectPackageType}
                            onChange={(selected) => {
                              selectChange(selected, "pacakgetype");
                            }}
                            isDisabled={packageIdToBeUpdated}
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectPackageType === null
                              ? errorsV.packageError
                              : ""}
                          </p>
                        </div>
                      </Col>

                      {dispalay === 0 && (
                        <Col md="2">
                          <div className="mb-3">
                            <Label>No of Resume</Label>
                            <AvField
                              type="number"
                              name="noOfResume"
                              placeholder="No Of Resumes"
                              onChange={(e) => handleChange(e)}
                              value={masterObj?.noOfResume}
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </Col>
                      )}
                      {dispalay === 1 && (
                        <Col md="2">
                          <div className="mb-3">
                            <Label>No Of Job Post</Label>
                            <AvField
                              name="noOfJobPort"
                              type="number"
                              placeholder="No Of Job Post"
                              value={masterObj?.noOfJobPort}
                              validate={{ required: { value: true } }}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </Col>
                      )}

                      {dispalay === 2 && (
                        <Col md="2">
                          <div className="mb-3">
                            <Label>No Of Services</Label>
                            <AvField
                              name="noOfServices"
                              type="number"
                              placeholder="No Of services "
                              value={masterObj?.noOfServices}
                              validate={{ required: { value: true } }}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </Col>
                      )}

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Validity</Label>
                          <AvField
                            type="number"
                            name="validity"
                            placeholder="Validity"
                            onChange={(e) => handleChange(e)}
                            value={masterObj?.validity}
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Amount</Label>
                          <AvField
                            type="number"
                            name="amount"
                            placeholder="Amount"
                            onChange={(e) => handleChange(e)}
                            value={masterObj?.amount}
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      {/* <Col md="2">
                        <div className="mb-3">
                          <Label>Offer Amount</Label>
                          <AvField
                            type="number"
                            name="offerAmount"
                            placeholder="Offer Amount"
                            onChange={(e) => handleChange(e)}
                            value={masterObj?.offerAmount}
                          />
                        </div>
                      </Col> */}
                      <Col md="2">
                        <div className="mb-3">
                          <Label>GST</Label>
                          <AvField
                            readOnly={true}
                            type="number"
                            name="gst"
                            placeholder="GST amount"
                            onChange={(e) => handleChange(e)}
                            value={masterObj?.gst}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Convenience Fee</Label>
                          <AvField
                            readOnly={true}
                            type="number"
                            name="conveniencefee"
                            placeholder="Convenience Fee"
                            onChange={(e) => handleChange(e)}
                            value={masterObj?.conveniencefee}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Total</Label>
                          <AvField
                            readOnly={true}
                            type="number"
                            name="total"
                            placeholder="Total amount"
                            onChange={(e) => handleChange(e)}
                            value={masterObj?.total}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div
                          className="mb-4 mt-4"
                          style={{ paddingTop: "5px" }}
                        >
                          {packageIdToBeUpdated ? (
                            <Button
                              className="mx-2"
                              color="primary"
                              type="Update"
                              disabled={isSubmitting}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              className="mx-2"
                              color="primary"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Submit
                            </Button>
                          )}
                          <Button
                            color="danger"
                            type="reset"
                            className="mx-3"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="pakagetable"
                    responsive
                    bordered
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Package;
