import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import Select from "react-select"
import axios from "axios"
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import { Modal } from "react-bootstrap";
import "./style.scss"
import ConnectWithoutContactRoundedIcon from "@mui/icons-material/ConnectWithoutContactRounded";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import $ from "jquery"


function PackageManagement() {

    const API_URL = process.env.REACT_APP_APIURL || "https://localhost:3099/"
    const [selectedType, setSelectedType] = useState(null)
    const [searchData, setSearchData] = useState({})
    const [tableData, setTableData] = useState([])
    const [popupView, setPopupView] = useState(false)
    const [tab3, setTab3] = React.useState("1");
    const [instituteOptions, setInstituteOptions] = useState([])
    const [selectedInstitute, setSelectedInstitute] = useState(null)
    const [details, setDetails] = useState([])


    // Pagination
    const [searchValue, setSearchValue] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [PerPageCount, setPerPageCount] = useState(12);
    const [indexOfLastTransfer, setindexOfLastTransfer] = useState(currentPage * PerPageCount);
    const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(indexOfLastTransfer - PerPageCount);

    const paginate = (event, value) => {
        setCurrentPage(value)
        handleTableData(searchData?.from_date, searchData?.to_date, searchData?.instituteId, searchData?.packageType, value, PerPageCount)
    }

    const resetPaginate = () => {
        setCurrentPage(1);
        const indexOfLastTransfer = 12;
        const indexOfFirstTransfer = 1;
        setindexOfFirstTransfer(indexOfFirstTransfer);
        setindexOfLastTransfer(indexOfLastTransfer);
    }

    const handleSearch = (value) => {
        setSearchValue(value)
        resetPaginate()
        handleTableData(searchData?.from_date, searchData?.to_date, searchData?.instituteId, searchData?.packageType)
    }

    useEffect(() => {
        if (tableData.length === 0 && indexOfFirstTransfer != 0) {
            resetPaginate();
            handleTableData(
                // searchValue,
                searchData?.from_date,
                searchData?.to_date,
                searchData?.instituteId,
                searchData?.packageType,
                currentPage,
                PerPageCount
            );
        }
    }, [tableData]);



    useEffect(() => {
        handleTableData()
        fetch_all_institute()
    }, [])


    const handleChangeTab = (event, newValue) => {
        setTab3(newValue);
    };

    const fetch_all_institute = () => {
        axios
            .get(`${API_URL}institute/options`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                let institute_data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el?.inst_reg_no + "-" + el?.inst_name,
                            value: el?._id,
                        };
                    });
                setInstituteOptions([
                    {
                        options: institute_data,
                    },
                ]);
            });
    };

    function handleTableData(from_date = "", to_date = "", instituteId = "", packageType = "", start = 1, limit = 12) {
        var url = `${API_URL}subscription/subscription-list`;
        url =
            url +
            "?from_date=" +
            from_date +
            "&to_date=" +
            to_date +
            "&instituteId=" +
            instituteId +
            "&packageType=" +
            packageType +
            "&start=" +
            start +
            "&limit=" +
            limit;
        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then(res => {

                let result = res.data.result
                setTotalPages(res.data.count);
                let id = (start - 1) * limit;
                result.map((item, indx) => {

                    id++
                    item.id = id
                    item.date = moment(item.inst_date).format("DD-MM-YYYY")

                    if (item.verify_inst === 0) {
                        item.status = (
                            <h6 style={{ color: "red", alignItems: "center" }}>Not Verified</h6>
                        )
                    } else {
                        item.status = (
                            <h6 style={{ color: "green", alignItems: "center" }}>Verified</h6>
                        )
                    }

                    item.inst = (
                        <div>
                            <a
                                href={`/institute/${item?.inst_reg_no}`}
                                target="blank"
                            >
                                {item?.name}
                            </a>
                        </div>
                    );

                    item.action = (
                        <div style={{ justifyContent: "center", display: "flex" }}>
                            <i className="fas fa-eye"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginRight: "0.5rem",
                                    marginLeft: "0.2rem"
                                }}
                                onClick={() => {
                                    setPopupView(true)
                                    handleClickOpenModal(item._id)
                                }}
                            ></i>
                        </div>
                    )

                })
                setTableData(result)
            })
    }

    const selectChange = (selected, name) => {
        let packageType = searchData?.packageType ? searchData?.packageType : "";
        let instituteId = searchData?.instituteId ? searchData?.instituteId : "";

        resetPaginate()
        switch (name) {
            case "packageType":
                setSelectedType(selected)
                setSearchData({ ...searchData, packageType: selected.value })
                handleTableData(searchData.from_date, searchData.to_date, searchData.instituteId, selected.value);
                break;

            case "instituteId":
                setSelectedInstitute(selected)
                setSearchData({ ...searchData, instituteId: selected.value })
                handleTableData(searchData.from_date, searchData.to_date, selected.value, searchData.packageType);
                break;

            default:
                break;
        }
    }


    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 50,
            },
            {
                label: "Reg. No",
                field: "inst_reg_no",
                width: 50,
            },
            {
                label: "Institute",
                field: "inst",
                width: 50,
            },
            {
                label: "Mobile",
                field: "phone",
                width: 50,
            },
            {
                label: "Email",
                field: "inst_date",
                width: 50,
            },
            {
                label: "Status",
                field: "status",
                width: 50,
            },
            {
                label: "Action",
                field: "action",
                width: 50,
            },
        ],
        rows: tableData,
    }

    function reset() {
        setSelectedType(null)
        setSelectedInstitute(null)
        handleTableData()
        setSearchData({
            ...searchData,
            instituteId: "",
            packageType: "",
            from_date: "",
            to_date: ""
        })
    }

    function handleClickOpenModal(id) {
        axios
            .get(`${API_URL}subscription/package/details?institute_id=${id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then(res => {
                let result = res.data.data

                setDetails(result)

            })
    }

    let handleDate = (e) => {
        if (e.target.name == "from_date") {
            var date1 = e.target.value;
            var date2 = searchData?.to_date;
        } else {
            var date1 = searchData?.from_date;
            var date2 = e.target.value;
        }

        let toDate = moment(date1)
        let fromDate = moment(date2)
        let result = fromDate.diff(toDate, "days")

        if (result + 1 <= 0) {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
                ["to_date"]: ""
            })
        } else {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value
            })
        }

        handleTableData(
            // searchValue,
            date1,
            date2,
            searchData.instituteId,
            searchData.packageType
        )
    }


    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Package Management" />

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>From Date</Label>
                                                <input
                                                    name="from_date"
                                                    value={searchData?.from_date}
                                                    className="form-control"
                                                    onChange={handleDate}
                                                    type="date"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>To Date</Label>
                                                <input
                                                    name="to_date"
                                                    value={searchData?.to_date}
                                                    className="form-control"
                                                    onChange={handleDate}
                                                    type="date"
                                                    min={searchData?.from_date}
                                                />
                                            </div>
                                        </Col>
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Institute</Label>
                                                <Select
                                                    name="instituteId"
                                                    classNamePrefix="select2-selection"
                                                    value={selectedInstitute}
                                                    options={instituteOptions}
                                                    onChange={(selected) => {
                                                        selectChange(selected, "instituteId");
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label>Package Type</Label>
                                                <Select
                                                    name="packageType"
                                                    classNamePrefix="select2-selection"
                                                    value={selectedType}
                                                    options={[
                                                        { label: "Find Resume", value: 0 },
                                                        { label: "Job Post", value: 1 },
                                                        { label: "Recruit With Support", value: 2 },
                                                    ]}
                                                    onChange={(selected) => {
                                                        selectChange(selected, "packageType");
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="2" style={{ marginTop: "27px" }}>
                                            <Button color="danger" type="reset" onClick={() => reset()}>Reset</Button>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            <MDBDataTable
                                id="packageManageTableId"
                                responsive
                                bordered
                                data={data}
                                info={false}
                                paging={false}
                                disableRetreatAfterSorting={true}
                            />
                            {totalPages > 2 && (
                                <div className="pagination-div">
                                    <div className="page-details">
                                        Showing {((currentPage - 1) * PerPageCount) + 1} to{" "}
                                        {Math.min(currentPage * PerPageCount, totalPages)} of{" "}
                                        {totalPages} entries
                                    </div>
                                    <Pagination
                                        color="standard"
                                        shape="rounded"
                                        defaultPage={1}
                                        count={Math.ceil(totalPages / PerPageCount)}
                                        page={currentPage}
                                        onChange={paginate}
                                        info={true}
                                        size="large"
                                    />
                                </div>
                            )}
                        </Col>
                    </Row>

                </div>
            </div>

            <Modal show={popupView} centered={true} size="lg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Package Details</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setPopupView(false);
                            setTab3("1")
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="col-xl-12" style={{ marginBottom: "30px" }}>
                        <div
                            className="mb-0 card"
                            style={{
                                boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                                width: "100%",
                            }}
                        >
                            <TabContext value={tab3} xl="12">
                                <TabList
                                    style={{ margin: 0, padding: 0 }} // Remove default margin and padding
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    onChange={handleChangeTab}
                                    aria-label="lab API tabs example"
                                >
                                    <Tab
                                        icon={<ConnectWithoutContactRoundedIcon style={{ fontSize: "20px" }} />}
                                        label="Find Resume"
                                        value="1"
                                        style={{
                                            textTransform: "capitalize",
                                            fontFamily: "IBM Plex Sans,sans-serif",
                                            margin: 0, // Adjust margin
                                        }}
                                    />

                                    <Tab
                                        icon={<FactCheckIcon style={{ fontSize: "20px" }} />}
                                        label="Job Post"
                                        value="2"
                                        style={{
                                            textTransform: "capitalize",
                                            fontFamily: "IBM Plex Sans,sans-serif",
                                            margin: 0, // Adjust margin
                                        }}
                                    />
                                    <Tab
                                        icon={
                                            <ReceiptLongIcon
                                                style={{ fontSize: "20px" }}
                                            />
                                        }
                                        label="Recruit With Support"
                                        value="3"
                                        size="small"
                                        style={{
                                            textTransform: "capitalize",
                                            fontFamily: "IBM Plex Sans,sans-serif",
                                            margin: 0, // Adjust margin
                                        }}
                                    />

                                </TabList>

                                <TabPanel
                                    value="1"
                                    style={{
                                        fontSize: "13px",
                                        fontFamily: "IBM Plex Sans,sans-serif",
                                    }}
                                >
                                    <Row>
                                        <Col md="12">
                                            <table id="product_available_day" className="table table-bordered dataTable">
                                                <thead>
                                                    <th>Date</th>
                                                    <th>Package Name</th>
                                                    <th>Amount</th>
                                                    <th>Total Quota</th>
                                                    <th>Used Quota</th>
                                                    <th>Balance</th>
                                                </thead>
                                                <tbody>
                                                    {details && details.length > 0 ? (
                                                        details.map((el, indx) => (
                                                            el.packageTypes === 0 ? (
                                                                <tr key={indx}>
                                                                    <td>{moment(el.created_date).format("DD-MM-YYYY")}</td>
                                                                    <td>{el.package.name}</td>
                                                                    <td>{el.package.amount}</td>
                                                                    <td>{el.total_quota}</td>
                                                                    <td>{el.total_used_quota}</td>
                                                                    <td>{el.balance}</td>
                                                                </tr>
                                                            ) : ""
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="5">No Package</td>
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </table>
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel
                                    value="2"
                                    style={{
                                        fontSize: "13px",
                                        fontFamily: "IBM Plex Sans,sans-serif",
                                    }}
                                >
                                    <Row>
                                        <Col md="12">
                                            <table id="product_available_day" className="table table-bordered dataTable">
                                                <thead>
                                                    <th>Date</th>
                                                    <th>Package Name</th>
                                                    <th>Amount</th>
                                                    <th>Total Quota</th>
                                                    <th>Used Quota</th>
                                                    <th>Balance</th>
                                                </thead>
                                                <tbody>
                                                    {details && details.length > 0 ? (
                                                        details.map((el, indx) => (
                                                            el.packageTypes === 1 ? (
                                                                <tr key={indx}>
                                                                    <td>{moment(el.created_date).format("DD-MM-YYYY")}</td>
                                                                    <td>{el.package.name}</td>
                                                                    <td>{el.package.amount}</td>
                                                                    <td>{el.total_quota}</td>
                                                                    <td>{el.total_used_quota}</td>
                                                                    <td>{el.balance}</td>
                                                                </tr>
                                                            ) : ""
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="5">No Package</td>
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </table>
                                        </Col>
                                    </Row>
                                </TabPanel>

                                <TabPanel
                                    value="3"
                                    style={{
                                        fontSize: "13px",
                                        fontFamily: "IBM Plex Sans,sans-serif",
                                    }}
                                >
                                    <Row>
                                        <Col md="12">
                                            <table id="product_available_day" className="table table-bordered dataTable">
                                                <thead>
                                                    <th>Date</th>
                                                    <th>Package Name</th>
                                                    <th>Amount</th>
                                                    <th>Total Quota</th>
                                                    <th>Used Quota</th>
                                                    <th>Balance</th>
                                                </thead>
                                                <tbody>
                                                    {details && details.length > 0 ? (
                                                        details.map((el, indx) => (
                                                            el.packageTypes === 2 ? (
                                                                <tr key={indx}>
                                                                    <td>{moment(el.created_date).format("DD-MM-YYYY")}</td>
                                                                    <td>{el.package.name}</td>
                                                                    <td>{el.package.amount}</td>
                                                                    <td>{el.total_quota}</td>
                                                                    <td>{el.total_used_quota}</td>
                                                                    <td>{el.balance}</td>
                                                                </tr>
                                                            ) : ""
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="5">No Package</td>
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </table>
                                        </Col>
                                    </Row>
                                </TabPanel>

                            </TabContext>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )

}

export default PackageManagement;