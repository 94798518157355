import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import "./institute.scss";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { apiError } from "../../../store/actions";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "@mui/material/Pagination";
import { CSVLink } from "react-csv";

//Redux
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";

const Institute = (props) => {
  const [master, setMaster] = useState({
    inst_reg_from: 0,
    web_update: 0,
  });
  const [details, setDetails] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [uploadProgress, setUploadProgress] = useState();
  const [instIdTobeUpdated, setInstIdTobeUpdated] = useState(null);
  const [instIdToBeDeleted, setInstIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [selectedVerifiedSearch, setSelectedVerifiedSearch] = useState(null)
  const [showPopup, setShowPopup] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const [showRequirements, setShowRequirements] = useState(false);
  const [require, setRequire] = useState(null);
  const [syllabus, setSyllabus] = useState(null);
  const [medium, setMedium] = useState(null);
  const [campus, setCampus] = useState(null);
  const [manage, setManage] = useState(null);
  const [searchData, setSearchData] = useState({});
  const [mediumOptions, setMediumOptions] = useState([]);
  const [managementOptions, setManagementOptions] = useState([]);
  const [syllabusOptions, setSyllabusOptions] = useState([]);
  const [districtOptionsSearch, setDistrictOptionsSearch] = useState([]);
  const [selectedDistrictSearch, setSelectedDistrictSearch] = useState(null);
  const [syllabusSearch, setSyllabusSearch] = useState(null);
  const [mediumSearch, setMediumSearch] = useState(null);
  const [selectedStateSearch, setSelectedStateSearch] = useState(null);
  const [selectedModeOfCampus, setSelectedModeOfCampus] = useState(null);
  const [managementSearch, setManagementSearch] = useState(null);
  const [errors, setErrors] = useState({
    stateError: "",
    districtError: "",
    syllabusError: "",
    mediumError: "",
    managementError: "",
    campustypeError: "",
    modeofcampusError: "",
  });
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [dataToBeExported, setDataToBeExported] = useState([]);

  const { webRegID } = props.match.params;
  // const dispatch = useDispatch();
  const formRef = useRef();

  let history = useHistory();

  /** pagination */
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [PerPageCount, setPerPageCount] = useState(20);
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(
    currentPage * PerPageCount
  );

  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(
    indexOfLastTransfer - PerPageCount
  );
  const paginate = (event, value) => {
    setCurrentPage(value);
    const indexOfLastTransfer = value * PerPageCount;
    const indexOfFirstTransfer = indexOfLastTransfer - PerPageCount;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
    handleTableData(
      searchValue,
      syllabusSearch?.value,
      mediumSearch?.value,
      managementSearch?.value,
      selectedStateSearch?.value,
      selectedDistrictSearch?.value,
      selectedVerifiedSearch?.value,
      indexOfFirstTransfer,
      indexOfLastTransfer
    );
  };
  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfLastTransfer = 20;
    const indexOfFirstTransfer = 0;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    resetPaginate();
    handleTableData(
      value,
      syllabusSearch?.value,
      mediumSearch?.value,
      managementSearch?.value,
      selectedStateSearch?.value,
      selectedDistrictSearch?.value,
      selectedVerifiedSearch?.value,
    );
  };
  useEffect(() => {
    if (details.length == 0 && indexOfFirstTransfer != 0) {
      resetPaginate();
      handleTableData(
        searchValue,
        syllabusSearch?.value,
        mediumSearch?.value,
        managementSearch?.value,
        selectedStateSearch?.value,
        selectedDistrictSearch?.value,
        selectedVerifiedSearch?.value,
      );
    }
  }, [details]);
  /** end pagination */

  useEffect(() => {
    fetch_all_states();
    fetch_syllabus();
    fetch_medium();
    handleTableData(searchValue);
    fetch_management();
  }, []);

  useEffect(() => {
    if (webRegID) {
      let item = {
        _id: webRegID,
      };
      preUpdateInstitute(item);
    }
  }, []);

  const fetch_syllabus = () => {
    axios
      .get(`${API_URL}manage/syllabus`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let syllabus_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setSyllabusOptions([
          {
            options: syllabus_data,
          },
        ]);
      });
  };
  const fetch_management = () => {
    axios
      .get(`${API_URL}manage/management`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let manage_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setManagementOptions([
          {
            options: manage_data,
          },
        ]);
      });
  };
  const fetch_medium = () => {
    axios
      .get(`${API_URL}manage/medium`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let medium_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.name,
              value: el?._id,
            };
          });
        setMediumOptions([
          {
            options: medium_data,
          },
        ]);
      });
  };
  function fetch_all_districtSearch(id) {
    axios
      .get(`${API_URL}districts/list?state_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var district_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.district_name,
              value: el._id,
            };
          });

        setDistrictOptionsSearch([
          {
            options: district_data,
          },
        ]);
      });
  }
  const handleSearchSelectChange = (selected, name) => {
    let syllabus = searchData?.syllabus ? searchData.syllabus : "";
    let medium = searchData?.medium ? searchData.medium : "";
    let state = searchData?.state ? searchData.state : "";
    let district_id = searchData?.district_id ? searchData.district_id : "";
    let verify = searchData?.verify ? searchData.verify : "";
    resetPaginate();
    switch (name) {
      case "syllabus":
        setSyllabusSearch(selected);
        setSearchData({
          ...searchData,
          syllabus: selected.value,
        });
        handleTableData(
          searchValue,
          selected.value,
          medium,
          managementSearch?.value,
          state,
          district_id,
          verify
        );
        break;
      case "medium":
        setMediumSearch(selected);
        setSearchData({
          ...searchData,
          medium: selected.value,
        });
        handleTableData(
          searchValue,
          syllabus,
          selected.value,
          managementSearch?.value,
          state,
          district_id,
          verify
        );
        break;
      case "management":
        setManagementSearch(selected);
        setSearchData({
          ...searchData,
          management: selected.value,
        });
        handleTableData(
          searchValue,
          syllabus,
          medium,
          selected.value,
          state,
          district_id,
          verify
        );
        break;
      case "state":
        setSelectedStateSearch(selected);
        setSelectedDistrictSearch(null);
        setSearchData({
          ...searchData,
          state: selected.value,
          district_id: "",
        });
        fetch_all_districtSearch(selected.value);
        handleTableData(
          searchValue,
          syllabus,
          medium,
          managementSearch?.value,
          selected.value,
          district_id,
          verify
        );
        break;
      case "district":
        setSelectedDistrictSearch(selected);
        setSearchData({
          ...searchData,
          district_id: selected.value,
        });
        handleTableData(
          searchValue,
          syllabus,
          medium,
          managementSearch?.value,
          state,
          selected.value,
          verify
        );
        break;
      case "verify":
        setSelectedVerifiedSearch(selected);
        setSearchData({
          ...searchData,
          verify: selected.value,
        });
        handleTableData(
          searchValue,
          syllabus,
          medium,
          managementSearch?.value,
          state,
          district_id,
          selected.value
        );
        break;
      default:
        break;
    }
  };

  function fetch_all_states() {
    axios
      .get(`${API_URL}states/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var state_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el.id,
            };
          });

        setStateOptions([
          {
            options: state_data,
          },
        ]);
      });
  }
  function handleApiCall() {
    axios
      .post(
        `${API_URL}institute/log/creation`,
        { type: 6 },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
      });
  }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const preUpdateInstitute = (item) => {
    if (webRegID) {
      setInstIdTobeUpdated(item._id);
      axios
        .get(
          `${API_URL}institute/view?institute_id=` +
          item._id +
          "&institute_reg=" +
          "",
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          let result = res.data.data;
          result.institute_id = result?._id;
          result.inst_reg_from = 1;

          let syllabusArr = [];
          result.inst_syllabus &&
            result.inst_syllabus.forEach((element) => {
              let arrSyllabus = {
                label: element.name,
                value: element._id,
              };
              syllabusArr.push(arrSyllabus);
            });
          setSyllabus(syllabusArr);

          let mediumArr = [];
          result.inst_medium &&
            result.inst_medium.forEach((element) => {
              let arrMedium = {
                label: element.name,
                value: element._id,
              };
              mediumArr.push(arrMedium);
            });
          setMedium(mediumArr);

          let state = null;
          if (result?.inst_state) {
            let stateData = result.inst_state;
            state = {
              label: stateData?.name,
              value: stateData?._id,
            };
            fetch_all_districts(state?.value);
          }

          setSelectedState(state);
          let district = null;
          if (result?.inst_district) {
            let districtData = result.inst_district;
            district = {
              label: districtData?.district_name,
              value: districtData?._id,
            };
            setSelectedDistrict(district);
          }

          let requireOpt = {};
          if (result?.require === 1) {
            setShowRequirements(true);
            requireOpt = {
              label: "Yes",
              value: 1,
            };
          } else if (result?.require === 2) {
            setShowRequirements(false);
            requireOpt = {
              label: "No",
              value: 2,
            };
          } else {
            setShowRequirements(false);
            requireOpt = null;
          }
          setRequire(requireOpt);
          let management = null;
          if (result?.inst_management) {
            let managementData = result.inst_management;
            management = {
              label: managementData?.name,
              value: managementData?._id,
            };
          }
          setManage(management);

          let campusTypeArr = [];
          result.inst_campus_type &&
            result.inst_campus_type.forEach((element) => {
              let arrCampusType = {};
              if (element === 0) {
                arrCampusType = {
                  label: "Day School",
                  value: 0,
                };
              } else if (element === 1) {
                arrCampusType = {
                  label: "Residential",
                  value: 1,
                };
              } else if (element === 2) {
                arrCampusType = {
                  label: "Semi Residential",
                  value: 2,
                };
              }
              campusTypeArr.push(arrCampusType);
            });
          setCampus(campusTypeArr);

          let modeofCamp = [];
          result.mode_of_campus &&
            result.mode_of_campus.forEach((element) => {
              let arrModeofCamp = {};
              if (element === 0) {
                arrModeofCamp = {
                  label: "A/C",
                  value: 0,
                };
              } else if (element === 1) {
                arrModeofCamp = {
                  label: "Non A/C",
                  value: 1,
                };
              }
              modeofCamp.push(arrModeofCamp);
            });

          setSelectedModeOfCampus(modeofCamp);

          setMaster(result);
        });
    } else {
      setInstIdTobeUpdated(item._id);
      axios
        .get(
          `${API_URL}institute/view?institute_id=` +
          item._id +
          "&institute_reg=" +
          "",
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          let result = res.data.data;

          result.institute_id = result?._id;

          let syllabusArr = [];
          result.inst_syllabus &&
            result.inst_syllabus.forEach((element) => {
              let arrSyllabus = {
                label: element.name,
                value: element._id,
              };
              syllabusArr.push(arrSyllabus);
            });
          setSyllabus(syllabusArr);

          let mediumArr = [];
          result.inst_medium &&
            result.inst_medium.forEach((element) => {
              let arrMedium = {
                label: element.name,
                value: element._id,
              };
              mediumArr.push(arrMedium);
            });
          setMedium(mediumArr);
          let state = null;
          if (result?.inst_state) {
            let stateData = result.inst_state;
            state = {
              label: stateData?.name,
              value: stateData?._id,
            };
            fetch_all_districts(state?.value);
          }

          setSelectedState(state);

          let district = null;
          if (result?.inst_district) {
            let districtData = result.inst_district;
            district = {
              label: districtData?.district_name,
              value: districtData?._id,
            };
            setSelectedDistrict(district);
          }
          let requireOpt = {};
          if (result?.require === 1) {
            setShowRequirements(true);
            requireOpt = {
              label: "Yes",
              value: 1,
            };
          } else if (result?.require === 2) {
            setShowRequirements(false);
            requireOpt = {
              label: "No",
              value: 2,
            };
          } else {
            setShowRequirements(false);
            requireOpt = null;
          }
          setRequire(requireOpt);

          let management = null;
          if (result?.inst_management) {
            let managementData = result.inst_management;
            management = {
              label: managementData?.name,
              value: managementData?._id,
            };
          }
          setManage(management);

          let campusTypeArr = [];
          result.inst_campus_type &&
            result.inst_campus_type.forEach((element) => {
              let arrCampusType = {};
              if (element === 0) {
                arrCampusType = {
                  label: "Day School",
                  value: 0,
                };
              } else if (element === 1) {
                arrCampusType = {
                  label: "Residential",
                  value: 1,
                };
              } else if (element === 2) {
                arrCampusType = {
                  label: "Semi Residential",
                  value: 2,
                };
              }
              campusTypeArr.push(arrCampusType);
            });
          setCampus(campusTypeArr);

          let modeofCamp = [];
          result.mode_of_campus &&
            result.mode_of_campus.forEach((element) => {
              let arrModeofCamp = {};
              if (element === 0) {
                arrModeofCamp = {
                  label: "A/C",
                  value: 0,
                };
              } else if (element === 1) {
                arrModeofCamp = {
                  label: "Non A/C",
                  value: 1,
                };
              }
              modeofCamp.push(arrModeofCamp);
            });
          setSelectedModeOfCampus(modeofCamp);
          setMaster(result);
        });
    }
  };
  const handleClickOpen = (item) => {
    axios
      .get(`${API_URL}company/singlelist?company_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item) => {
            item.date = moment(item.company_date).format("DD-MM-YYYY");
            item.time = moment(item.company_time, "HHmmss").format("hh:mm a");
            setCompanyDetails(item);
            setShowPopup(true);
          });
      });
  };

  function handleTableData(
    search = "",
    syllabus = "",
    medium = "",
    management = "",
    state = "",
    district_id = "",
    verify = "",
    start = 0,
    end = 20
  ) {
    var url = `${API_URL}institute/list`;
    url =
      url +

      "?syllabus_id=" +
      syllabus +
      "&medium_id=" +
      medium +
      "&management=" +
      management +
      "&state=" +
      state +
      "&district_id=" +
      district_id +
      "&verify=" +
      verify +
      "&search=" +
      search +
      "&start=" +
      start +
      "&end=" +
      end;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let dataToBeExported = [];
        var result = res.data.data;
        setTotalPages(res.data.limit);
        let id = start
        result.map((item, index) => {
          let exportItem = {}
          id = id + 1;
          item.id = id;
          item.syllabusName = "";
          item.syllabus &&
            item.syllabus.map((el) => {
              if (item.syllabusName === "") item.syllabusName = el?.name;
              else item.syllabusName = item?.syllabusName + "," + el?.name;
            });
          item.mediumName = "";
          item.medium &&
            item.medium.map((el) => {
              if (item.mediumName === "") item.mediumName = el?.name;
              else item.mediumName = item?.mediumName + "," + el?.name;
            });

          if (item.inst_status === 0) {
            item.status = (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Block
                    </Tooltip>
                  }
                >
                  <LockOpenIcon
                    style={{
                      color: "green",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                    onClick={() => updateStatus(item)}
                  />
                </OverlayTrigger>
              </>
            );
          } else if (item.inst_status === 2) {
            item.status = (
              <>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip
                      id="button-tooltip-2"
                      style={{ position: "absolute", top: "-30px" }}
                    >
                      Unblock
                    </Tooltip>
                  }
                >
                  <LockOpenIcon
                    style={{
                      color: "red",
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                    onClick={() => updateStatus(item)}
                  />
                </OverlayTrigger>
              </>
            );
          }

          item.inst_name1 = (
            <div>
              <a
                style={{ cursor: "pointer" }}
                target="blank"
                href={"/institute/" + item?.inst_reg_no}
              >
                {item?.inst_name}
              </a>
            </div>
          );

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  window.open("/institute/" + item.inst_reg_no);
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  toTop();
                  preUpdateInstitute(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setInstIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );

          exportItem.Id = item.id;
          exportItem.Reg_No = item?.inst_reg_no;
          exportItem.Name = item?.inst_name;
          exportItem.State = item?.state_name;
          exportItem.District = item?.district_name;
          exportItem.Mobile = item?.inst_phone;
          exportItem.Management = item?.management_name;
          exportItem.Medium = item.mediumName;
          exportItem.Syllabus = item?.syllabusName;
          exportItem.Status = item?.inst_status === "0" ? "Block" : "Unblock";


          dataToBeExported.push(exportItem)
        });
        setDataToBeExported(dataToBeExported)

        setDetails(result);
      });
  }
  const updateStatus = (item) => {
    axios
      .put(`${API_URL}institute/inst_block?institute_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.data.inst_status === 0) {
            toastr.success("Institute unblocked successfully");
          } else toastr.success("Institute blocked successfully");
          handleTableData(
            searchValue,
            syllabusSearch?.value,
            mediumSearch?.value,
            managementSearch?.value,
            selectedStateSearch?.value,
            selectedDistrictSearch?.value,
            indexOfFirstTransfer,
            indexOfLastTransfer
          );
        } else {
          toastr.error("Failed");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  function fetch_all_districts(id) {
    axios
      .get(`${API_URL}districts/list?state_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var district_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.district_name,
              value: el._id,
            };
          });

        setDistrictOptions([
          {
            options: district_data,
          },
        ]);
      });
  }
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "district":
        setSelectedDistrict(selected);
        setMaster({
          ...master,
          ["inst_district"]: selected.value,
        });
        setErrors({
          ...errors,
          districtError: "",
        });
        break;
      case "state":
        setSelectedState(selected);
        setSelectedDistrict(null);
        setMaster({
          ...master,
          ["inst_state"]: selected.value,
        });
        setErrors({
          ...errors,
          stateError: "",
        });
        fetch_all_districts(selected.value);
        break;
      case "syllabus":
        setSyllabus(selected);
        let arrayVal = [];
        selected &&
          selected.forEach((element) => {
            arrayVal.push(element.value);
          });

        setMaster({
          ...master,
          inst_syllabus: arrayVal,
        });
        setErrors({
          ...errors,
          syllabusError: "",
        });
        break;
      case "medium":
        setMedium(selected);
        let arrayMedium = [];
        selected &&
          selected.forEach((element) => {
            arrayMedium.push(element.value);
          });

        setMaster({
          ...master,
          inst_medium: arrayMedium,
        });
        setErrors({
          ...errors,
          mediumError: "",
        });
        break;
      case "campusType":
        let campusTypeVal = [];
        selected &&
          selected.forEach((element) => {
            campusTypeVal.push(element.value);
          });
        setCampus(selected);
        setMaster({
          ...master,
          inst_campus_type: campusTypeVal,
        });
        setErrors({
          ...errors,
          campustypeError: "",
        });
        break;
      case "modeofcampus":
        let modeOfCampusVal = [];
        selected &&
          selected.forEach((element) => {
            modeOfCampusVal.push(element.value);
          });
        setSelectedModeOfCampus(selected);
        setMaster({
          ...master,
          mode_of_campus: modeOfCampusVal,
        });
        setErrors({
          ...errors,
          modeofcampusError: "",
        });
        break;
      case "management":
        setManage(selected);
        setMaster({
          ...master,
          inst_management: selected.value,
        });
        setErrors({
          ...errors,
          managementError: "",
        });
        break;
      case "require":
        setRequire(selected);
        if (selected.label === "Yes") {
          setShowRequirements(true);
          setMaster({
            ...master,
            require: selected.value,
          });
        } else {
          setShowRequirements(false);
          setMaster({
            ...master,
            require: selected.value,
            requirements: "",
          });
        }

        break;
      default:
        break;
    }
  };
  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("inst_logo", e.target.files[0]);
    axios
      .post(`${API_URL}institute/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setMaster({
            ...master,
            [`inst_logo`]: response.data.file.filename,
          });
          // toastr.success("Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const handleValidSubmit = (event, values) => {
    if (
      selectedState === null ||
      selectedDistrict === null ||
      manage === null ||
      syllabus === null ||
      medium === null ||
      campus === null ||
      selectedModeOfCampus === null
    ) {
      let errorVal = errors;
      if (selectedState === null) errorVal.stateError = "Please select state";

      if (selectedDistrict === null)
        errorVal.districtError = "Please select district";

      if (manage === null)
        errorVal.managementError = "Please select management";

      if (syllabus === null) errorVal.syllabusError = "Please select syllabus";

      if (medium === null) errorVal.mediumError = "Please select medium";

      if (campus === null)
        errorVal.campustypeError = "Please select campus type";

      if (selectedModeOfCampus === null)
        errorVal.modeofcampusError = "Please select mode of campus";

      setErrors({
        ...errorVal,
      });
    } else {
      if (instIdTobeUpdated) {
        axios
          .put(`${API_URL}institute/update`, master, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Institute updated successfully");
              formRef.current.reset();
              setSelectedState(null);
              setSelectedDistrict(null);
              setDistrictOptions([]);
              setMedium(null);
              setSyllabus(null);
              setCampus(null);
              setManage(null);
              setSelectedModeOfCampus(null);
              setRequire(null);
              setShowRequirements(false);
              setMaster({
                inst_reg_from: 0,
                web_update: 0,
              });
              setErrors({
                stateError: "",
                districtError: "",
                syllabusError: "",
                mediumError: "",
                managementError: "",
                campustypeError: "",
                modeofcampusError: "",
              });
              handleTableData(
                searchValue,
                syllabusSearch?.value,
                mediumSearch?.value,
                managementSearch?.value,
                selectedStateSearch?.value,
                selectedDistrictSearch?.value,
                indexOfFirstTransfer,
                indexOfLastTransfer
              );

              setInstIdTobeUpdated(null);
              if (webRegID) {
                history.push("/institute");
              }
            } else {
              toastr.error(res.data.message, "Failed to update institute");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}institute/`, master, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Institute created successfully");
              formRef.current.reset();
              setSelectedState(null);
              setSelectedDistrict(null);
              setDistrictOptions([]);
              setMedium(null);
              setSyllabus(null);
              setCampus(null);
              setManage(null);
              setSelectedModeOfCampus(null);
              setRequire(null);
              setShowRequirements(false);
              setMaster({
                inst_reg_from: 0,
                web_update: 0,
              });
              setErrors({
                stateError: "",
                districtError: "",
                managementError: "",
                syllabusError: "",
                mediumError: "",
                campustypeError: "",
                modeofcampusError: "",
              });
              handleTableData(
                searchValue,
                syllabusSearch?.value,
                mediumSearch?.value,
                managementSearch?.value,
                selectedStateSearch?.value,
                selectedDistrictSearch?.value,
                indexOfFirstTransfer,
                indexOfLastTransfer
              );
            } else {
              toastr.error(res.data.message, "Failed to create institute");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  const deleteCompanyIcon = (e) => {
    e.preventDefault();
    setMaster({
      ...master,
      [`inst_logo`]: "",
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "ID",
        field: "inst_reg_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "inst_name1",
        sort: "asc",
        width: 270,
      },
      {
        label: "State",
        field: "state_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "District",
        field: "district_name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Mobile",
        field: "inst_phone",
        sort: "asc",
        width: 100,
      },
      {
        label: "Mangement",
        field: "management_name",
        sort: "asc",
        width: 10,
      },
      {
        label: "Syllabus",
        field: "syllabusName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Medium",
        field: "mediumName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: details,
  };
  const reset = () => {
    setSearchData({});
    setSyllabusSearch(null);
    setMediumSearch(null);
    setSelectedStateSearch(null);
    setSelectedDistrictSearch(null);
    setSelectedVerifiedSearch(null)
    setDistrictOptionsSearch([]);
    setManagementSearch(null);
    handleTableData(searchValue);
    resetPaginate();
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}institute` + "/" + instIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Institute deleted successfully");
                  if (master && master?._id === instIdToBeDeleted) {
                    formRef.current.reset();
                    setSelectedState(null);
                    setSelectedDistrict(null);
                    setDistrictOptions([]);
                    setMedium(null);
                    setSyllabus(null);
                    setCampus(null);
                    setManage(null);
                    setSelectedModeOfCampus(null);
                    setRequire(null);
                    setShowRequirements(false);
                    setMaster({
                      inst_reg_from: 0,
                      web_update: 0,
                    });
                    setErrors({
                      stateError: "",
                      districtError: "",
                      managementError: "",
                      syllabusError: "",
                      mediumError: "",
                      campustypeError: "",
                      modeofcampusError: "",
                    });
                    setInstIdTobeUpdated(null);
                  }
                  handleTableData(
                    searchValue,
                    syllabusSearch?.value,
                    mediumSearch?.value,
                    managementSearch?.value,
                    selectedStateSearch?.value,
                    selectedDistrictSearch?.value,
                    indexOfFirstTransfer,
                    indexOfLastTransfer
                  );
                } else {
                  toastr.error("Failed to delete institute");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Institution" />

          {/*Text field 1*/}

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Institution Name
                          </Label>
                          <AvField
                            name="inst_name"
                            placeholder="Institution Name"
                            type="text"
                            errorMessage="Enter Institution Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleInputChange}
                            value={master?.inst_name}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Mobile No.</Label>
                          <AvField
                            name="inst_phone"
                            placeholder="Mobile No."
                            type="phone"
                            errorMessage="Enter valid mobile no"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              minLength: { value: 10 },
                              maxLength: { value: 10 },
                              pattern: { value: "/^[6-9][0-9]{9}$/" },
                            }}
                            id="validationCustom03"
                            onChange={handleInputChange}
                            value={master?.inst_phone ? master.inst_phone : ""}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">
                            Whatsapp No.
                          </Label>
                          <AvField
                            name="inst_whatsapp_no"
                            placeholder=" Whatsapp No."
                            type="phone"
                            errorMessage="Enter valid 10 digit number"
                            className="form-control"
                            validate={{
                              required: { value: true },
                              minLength: { value: 10 },
                              maxLength: { value: 10 },
                              pattern: { value: "/^[6-9][0-9]{9}$/" },
                            }}
                            id="validationCustom04"
                            onChange={handleInputChange}
                            value={
                              master?.inst_whatsapp_no
                                ? master.inst_whatsapp_no
                                : ""
                            }
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            name="inst_state"
                            value={selectedState}
                            onChange={(selected) => {
                              handleSelectChange(selected, "state");
                            }}
                            options={stateOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedState === null ? errors.stateError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="inst_district"
                            value={selectedDistrict}
                            onChange={(selected) => {
                              handleSelectChange(selected, "district");
                            }}
                            options={districtOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedDistrict === null
                              ? errors.districtError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Locality</Label>
                          <AvField
                            name="inst_locality"
                            placeholder="Locality"
                            type="text"
                            className="form-control"
                            id="validationCustom05"
                            onChange={handleInputChange}
                            value={
                              master?.inst_locality ? master.inst_locality : ""
                            }
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Do you Require Teacher?
                          </Label>
                          <Select
                            name="require"
                            value={require}
                            onChange={(selected) => {
                              handleSelectChange(selected, "require");
                            }}
                            options={[
                              {
                                label: "Yes",
                                value: 1,
                              },
                              {
                                label: "No",
                                value: 2,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      {showRequirements === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom04">
                              Requirements
                            </Label>
                            <AvField
                              name="requirements"
                              placeholder="Requirements"
                              type="text"
                              className="form-control"
                              id="validationCustom04"
                              onChange={handleInputChange}
                              value={
                                master?.requirements ? master.requirements : ""
                              }
                            />
                          </div>
                        </Col>
                      ) : null}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Syllabus</Label>
                          <Select
                            isMulti
                            name="inst_syllabus"
                            value={syllabus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "syllabus");
                            }}
                            options={syllabusOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {syllabus === null ? errors.syllabusError : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Medium</Label>
                          <Select
                            isMulti
                            name="inst_medium"
                            value={medium}
                            onChange={(selected) => {
                              handleSelectChange(selected, "medium");
                            }}
                            options={mediumOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {medium === null ? errors.mediumError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Campus Type
                          </Label>
                          <Select
                            isMulti
                            name="inst_campus_type"
                            value={campus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "campusType");
                            }}
                            options={[
                              {
                                label: "Day School",
                                value: 0,
                              },
                              {
                                label: "Residential",
                                value: 1,
                              },
                              {
                                label: "Semi Residential",
                                value: 2,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {campus === null ? errors.campustypeError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Mode Of Campus
                          </Label>
                          <Select
                            isMulti
                            name="mode_of_campus"
                            value={selectedModeOfCampus}
                            onChange={(selected) => {
                              handleSelectChange(selected, "modeofcampus");
                            }}
                            options={[
                              {
                                label: "A/C",
                                value: 0,
                              },
                              {
                                label: "Non A/C",
                                value: 1,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedModeOfCampus === null
                              ? errors.modeofcampusError
                              : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Strength</Label>
                          <AvField
                            name="inst_strength"
                            placeholder="Strength"
                            type="number"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleInputChange}
                            value={
                              master?.inst_strength ? master.inst_strength : ""
                            }
                            min={0}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Management</Label>
                          <Select
                            name="inst_management"
                            value={manage}
                            onChange={(selected) => {
                              handleSelectChange(selected, "management");
                            }}
                            options={managementOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {manage === null ? errors.managementError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            No. of Branch
                          </Label>
                          <AvField
                            name="no_of_branch"
                            placeholder="No. of Branch"
                            type="number"
                            className="form-control"
                            id="validationCustom03"
                            onChange={handleInputChange}
                            value={
                              master?.no_of_branch ? master.no_of_branch : ""
                            }
                            min={0}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">
                            Institution Logo
                          </Label>
                          {master?.inst_logo ? (
                            <div div className="img-wraps">
                              {master.inst_logo &&
                                master.inst_logo.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${master.inst_logo}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}uploads/institute_images/${master.inst_logo}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteCompanyIcon}
                                style={{ width: "150px" }}
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="inst_logo"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>

                      <Col md="3" style={{ marginTop: "2.5%" }}>
                        <div className="mb-3">
                          {instIdTobeUpdated ? (
                            <Button color="primary" type="submit">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Syllabus</Label>
                        <Select
                          name="syllabus"
                          value={syllabusSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "syllabus");
                          }}
                          options={syllabusOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Medium</Label>
                        <Select
                          name="medium"
                          value={mediumSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "medium");
                          }}
                          options={mediumOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Management</Label>
                        <Select
                          name="medium"
                          value={managementSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "management");
                          }}
                          options={managementOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>State</Label>
                        <Select
                          name="state"
                          value={selectedStateSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "state");
                          }}
                          options={stateOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>District</Label>
                        <Select
                          name="verification_status"
                          value={selectedDistrictSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "district");
                          }}
                          options={districtOptionsSearch}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Verified/Unverified</Label>
                        <Select
                          name="verify"
                          value={selectedVerifiedSearch}
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "verify");
                          }}
                          options={[
                            { label: "Verified", value: 1 },
                            { label: "Unverified", value: 0 }
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="1">
                      <div className="mt-4 mb-3" style={{ paddingTop: "5px", display: "flex" }}>
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>

                        <Button onClick={handleApiCall} color="success" type="" style={{ marginLeft: "10px" }}>
                          <CSVLink
                            data={dataToBeExported}
                            filename={"Institute.xls"}
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>

                      </div>

                    </Col>
                  </Row>
                  <MDBDataTable
                    id="instTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    paging={false}
                    onSearch={(value) => {
                      handleSearch(value);
                    }}
                    entries={PerPageCount}
                  />
                  {totalPages > 20 && (
                    <div className="pagination-div">
                      <div className="page-details">
                        Showing {indexOfFirstTransfer + 1} to{" "}
                        {indexOfFirstTransfer + details.length} of {totalPages}{" "}
                        entries
                      </div>
                      <Pagination
                        color="standard"
                        shape="rounded"
                        defaultPage={1}
                        count={Math.ceil(totalPages / PerPageCount)}
                        page={currentPage}
                        onChange={paginate}
                        size="large"
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };
export default withRouter(connect(mapStateToProps, { apiError })(Institute));
//export default Institute;